define('templates/searchPanel',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (mobileApp) {
buf.push("<div class=\"panel searchPanel\"><div class=\"head\"><input placeholder=\"Skriv søkeord\" aria-label=\"Søk\" class=\"search-box\"/><button type=\"submit\" class=\"btn-search\">SØK</button><button aria-label=\"Lukk søkepanel\" class=\"close-icon\">");
if ( mobileApp)
{
buf.push("<span class=\"icon\"></span>");
}
else
{
buf.push("<svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_lukk.svg#lukk\"></use></svg>");
}
buf.push("</button></div><div class=\"searchCont\"><span class=\"label-searchResults\">Søkeresultat:</span><span class=\"count\"></span></div><div class=\"content scrollableContent\"></div></div>");}.call(this,"mobileApp" in locals_for_with?locals_for_with.mobileApp:typeof mobileApp!=="undefined"?mobileApp:undefined));;return buf.join("");
}

});
