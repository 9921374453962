/* globals window */

/*
 * Function which redirects to /login with a correct redirect query param
 *
 */
define('redirectToLogin',[], function() {
    return function() {
        // collect the current url
        var redirect = window.location.pathname;

        if (window.location.search) {
            redirect += window.location.search;
        }

        if (window.location.hash) {
            redirect += window.location.hash;
        }

        // and redirect to loging with redirect info
        window.location = '/login?redirect=' + encodeURIComponent(redirect);
    };
});


