define('templates/settingsPanel',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (fontSize, keyboardShortcuts, mobileApp) {
buf.push("<div id=\"settingsPanel\" class=\"panel settingsPanel\"><div class=\"head\"><h2 class=\"label\">Innstillinger</h2><button aria-label=\"Lukk innstillinger\" class=\"close-icon\">");
if ( mobileApp)
{
buf.push("<span class=\"icon\"></span>");
}
else
{
buf.push("<svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_lukk.svg#lukk\"></use></svg>");
}
buf.push("</button></div><h3 id=\"fontsize-label\" class=\"settingsPanel-label\">Skriftstørrelse</h3><div id=\"fontsizeWrapper\" class=\"settings-fontsize\"><button aria-label=\"Mindre tekststørrelse\" class=\"fontsize-icon smaller-text\">");
if ( mobileApp)
{
buf.push("<span class=\"icon-smaller\"></span>");
}
else
{
buf.push("<svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_litenbokstav.svg#litenbokstav\"></use></svg>");
}
buf.push("</button><div class=\"slider-container\"><div class=\"default-marker\"></div><input id=\"fontsize-slider\" name=\"Endre skriftstørrelse\" aria-labelledby=\"fontsize-label\" type=\"range\" min=\"0\" max=\"200\" step=\"10\"" + (jade.attr("value", fontSize, true, false)) + " class=\"fontsize-slider\"/></div><button aria-label=\"Større tekststørrelse\" class=\"fontsize-icon larger-text\">");
if ( mobileApp)
{
buf.push("<span class=\"icon-larger\"></span>");
}
else
{
buf.push("<svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_storbokstav.svg#storbokstav\"></use></svg>");
}
buf.push("</button></div><div id=\"glossarylangWrapper\" hidden=\"hidden\" class=\"settings-lang\"><label for=\"langSelect\" class=\"settingsPanel-label\">Glosespråk</label><select id=\"langSelect\" class=\"panelLangSelect\"></select><button class=\"btn-lang\">velg</button></div><span class=\"funnel\"><span class=\"funnelDiamond\"></span></span><h3 class=\"settingsPanel-label\">Tastatur</h3><div id=\"keyboardshortcutsWrapper\" class=\"settings-keyboard-shortcuts\"><input id=\"toggle-shortcuts\" type=\"checkbox\" name=\"toggle-shortcuts\"" + (jade.attr("checked", keyboardShortcuts, true, false)) + "/><label for=\"toggle-shortcuts\">Tastatursnarveier i toppmenyen</label></div></div>");}.call(this,"fontSize" in locals_for_with?locals_for_with.fontSize:typeof fontSize!=="undefined"?fontSize:undefined,"keyboardShortcuts" in locals_for_with?locals_for_with.keyboardShortcuts:typeof keyboardShortcuts!=="undefined"?keyboardShortcuts:undefined,"mobileApp" in locals_for_with?locals_for_with.mobileApp:typeof mobileApp!=="undefined"?mobileApp:undefined));;return buf.join("");
}

});
