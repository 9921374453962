/* globals alert, window, console, cordova */
define('views/LoginView',['jquery', 'backbone', 'templates/loginView', 'views/DialogView'], function ($, Backbone, mainTemplate, DialogView) {

    // midlertidig her
    var userInfo = null; // populated on login
    var sessionId = null;

    return Backbone.View.extend({
        initialize: function (appModel) {
            this._appModel = appModel;
            this.render();
        },

        events: {
            'click .login': 'vendor_login'
        },

        vendor_login: function (e) {
            var me=this;
            if ( ! me._appModel.get('isOnline')) {
                $(e.target).attr({ 'data-dialog-spawned': 1 });
                var confirmDialog = new DialogView({
                    "title": "Innlogging uten nettilgang",
                    "message": "Du har forsøkt å logge inn i Unibok. For å logge inn i Unibok må du være tilkoblet internett.",
                    "isAlert": true,
                    "appendTo": 'body'
                });
                return false;
            }

            var login_vendor = $(e.target).data('vendor');
            var loginUrl, win;

            if (login_vendor === 'Feide') {
                loginUrl = me._appModel.get('backendServerUrl') + '/feidelogin?deviceid=' + window.device.uuid;
            } else if (login_vendor === 'Cdu') {
                loginUrl = me._appModel.get('backendServerUrl') + '/login_cdu?deviceid=' + window.device.uuid;
            } else if (login_vendor === 'Aco') {
                loginUrl = me._appModel.get('backendServerUrl') + '/login_aco2?deviceid=' + window.device.uuid;
            } else {
                return false;
            }

            if(me._appModel.get('mobileApp')) {
                win = cordova.InAppBrowser.open(loginUrl, '_blank', 'location=no,toolbar=yes,closebuttoncaption=Tilbake');
            } else {
                win = window.open(loginUrl, '_blank', 'location=no,toolbar=yes,closebuttoncaption=Tilbake');
            }

            var myCallback = function(event) {
                if (event.url.match(new RegExp(me._appModel.get('backendServerUrl') + "/$"))) {
                    //successful login
                    me._appModel.trigger('loggedInFromVendor');
                    win.close();

                }
            };
            win.addEventListener('loadstart', myCallback, false);
            return false;
        },

        jukselogin: function () {
            var me = this;
            var user =  me._appModel.getUser("mobileelev@example.com", "NO944025391");
            user.load(function () {
                me._appModel.set('activeUser', user);
            });
        },

        render: function () {
            var data = this._appModel.get('users').map(function (user) {
                return {
                    userid: user.get('userid'),
                    organizationid: user.get('organizationid')
                };
            });
            this.setElement(mainTemplate({users: data}));
            return this;
        },

        login: function (e) {
            var me = this;
            var elem = $(e.currentTarget);
            var userid = elem.data('userid');
            var organizationid = elem.data('organizationid');

            var user = this._appModel.getUser(userid, organizationid);
            user.load(function (err) {

                if (err) {
                    elem.css('background-color', 'red');
                    //me.$el.find('.loginDialogBox').html(err);
                    alert(err);

                } else {
                    elem.css('background-color', '');
                    me._appModel.set('activeUser', user);
                }


                // me._appModel.set('activeUser', user);
            });
        },

        enter: function () {
            this.$el.addClass('active').removeAttr('hidden');

        },

        leave: function () {
            this.$el.removeClass('active').attr('hidden', 'hidden');
        }
    });
});

