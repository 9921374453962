/*global setInterval, clearInterval, location, localStorage */

/**
 * @module webapp
 * @namespace  Syncers
 * @class  SyncManager
 */
define('SyncManager',['backbone', 'underscore', 'jquery', 'timer', 'syncers/AppSyncer', 'syncers/UserSyncer', 'syncers/UserbookSyncer'], function (Backbone, _, $, timer, AppSyncer, UserSyncer, UserbookSyncer) {


    /**
     * The SyncManager knows about the model structure in the app, and will initialize Syncers for model instances as needed.
     * The models, does NOT know about syncers.
     *
     * Because the chosen design does not allow models to know about syncers, we must create and destroy syncers based on events in the models.
     *
     * @constructor
     * @class SyncManager
     */
    function SyncManager(app) {
        this._app = app;

        var activeUser = null;
        var activeUserbook = null;

        this._appSyncer = new AppSyncer(app);
        this._activeUserSyncer = null;
        this._userbooksSyncerMapping = {};

        this._app.on('change:isOnline', function (model, isOnline) {
            if (isOnline) {
                timer.start();
            } else {
                timer.stop();
            }
        });


        // TODO: create syncers for bookresources ?


        var me = this;

        this._app.on('change:activeUser', function (app, user) {
            if (app.previous('activeUser')) {
                app.previous('activeUser').get('userbooks').off('add remove', null, me);
                _.each(me._userbooksSyncerMapping, function (userbookSyncer, key) {
                    userbookSyncer.destroy();
                });
                me._userbooksSyncerMapping = {};
                me._activeUserSyncer.destroy();
                me._activeUserSyncer = null;


            }
            if (user) {
                me._activeUserSyncer = new UserSyncer(user);
                user.get('userbooks').on('add', me.onActiveUser_userbooks_added, me);
                user.get('userbooks').on('remove', me.onActiveUser_userbooks_removed, me);
            }
        }, this);
    }

    SyncManager.prototype = {
        getSyncer: function (model) {
            if (model === this._app) {
                return this._appSyncer;
            } else if (model === this._app.get('activeUser')) {
                return this._activeUserSyncer;
            } else {
                return this._userbooksSyncerMapping[model.cid] || null;
            }
        },

        onActiveUser_userbooks_added: function (userbook) {
            this._userbooksSyncerMapping[userbook.cid] = new UserbookSyncer(userbook);
        },

        onActiveUser_userbooks_removed: function (userbook) {
            var userbookSyncer = this._userbooksSyncerMapping[userbook.cid];
            if (userbookSyncer) {
                userbookSyncer.destroy();
                delete this._userbooksSyncerMapping[userbook.cid];
            }
        },

    };

    return SyncManager;
});

