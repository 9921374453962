/* global window, document */
define('views/PanelBase',['jquery', 'backbone'], function ($, Backbone) {

    return Backbone.View.extend({
        initialize: function () {

        },

        onClose: function (e) {
            this.panelController.hidePanel(e);
            return false;
        },

        show: function () {
        },

        hide: function () {
        }
    });

});

