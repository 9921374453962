define('templates/splash',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (coverTitle, coverUrl, ie11, mobileApp) {
buf.push("<div class=\"splashscreen\"><div class=\"splashscreen-content\"><div class=\"logo\">");
if ( mobileApp)
{
buf.push("<span class=\"app-logo\"></span>");
}
else
{
buf.push("<img src=\"../images/uniboklogo_gronn.svg#logo\" alt=\"Unibok logo\"/>");
}
buf.push("</div><div class=\"book-cover\"><img" + (jade.attr("src", coverUrl, true, false)) + (jade.attr("alt", coverTitle, true, false)) + "/></div><div class=\"loading\">");
if ( ie11 || mobileApp)
{
buf.push("<div class=\"miniIndicator\"><span class=\"loadIndicator\"></span></div>");
}
else
{
buf.push("<span><img src=\"../images/leser_ikoner/leser_ikoner_spinner.svg#spinner\" alt=\"\"/></span>");
}
buf.push("Åpner boka</div></div></div>");}.call(this,"coverTitle" in locals_for_with?locals_for_with.coverTitle:typeof coverTitle!=="undefined"?coverTitle:undefined,"coverUrl" in locals_for_with?locals_for_with.coverUrl:typeof coverUrl!=="undefined"?coverUrl:undefined,"ie11" in locals_for_with?locals_for_with.ie11:typeof ie11!=="undefined"?ie11:undefined,"mobileApp" in locals_for_with?locals_for_with.mobileApp:typeof mobileApp!=="undefined"?mobileApp:undefined));;return buf.join("");
}

});
