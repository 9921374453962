/* global define, window, console */
define('views/NavigationBarView',['jquery', 'backbone', 'templates/navigationBarView', 'hammer'], function ($, Backbone, mainTemplate, Hammer) {
    return Backbone.View.extend({
        events: {
        },

        initialize: function (options) {
            this._onGoto = options.onGoto;
            this._mobileApp = options.mobileApp;
            this.render();
        },

        render: function () {
            var me = this;
            this.$el.append(mainTemplate({
                mobileApp: this._mobileApp
            }));

            // ----------
            // Setup drag
            // ----------
            var mc = new Hammer.Manager(this.$el.find('.hitArea')[0], {});
            mc.add( new Hammer.Pan({direction: Hammer.DIRECTION_ALL, threshold: 0}));
            mc.add( new Hammer.Press({time: 0}));

            var dragSession = {};
            mc.on('press', function (e) {
                // NOTE: Always triggered on mousedown/touchstart
                dragSession = {};
                me._onDragStart(e, dragSession);
            });
            mc.on('panmove', function (e) {
                me._onDragMove(e, dragSession);
            });
            mc.on('panend', function (e) {
                me._onDragEnd(e, dragSession);
            });
            mc.on('pressup', function (e) {
                // NOTE: Only triggered if the pan never got started
                me._onDragEnd(e, dragSession);
            });
            return this;
        },

        update: function (pageChangeData, currentBookpartId, bookResource, readium) {
            /* Vi bruker ikke denne koden for øyeblikket. Vi benytter heller Readium's spineItemIndex
             var allPagebreakElems = doc.find('[epub\\:type="pagebreak"]');
             var visiblePageNumbers = [], previousPageNumber = [];
             var currentPageNumber = 0;
             var iframeWidth = $('#epubContentIframe').width();
             $.each(allPagebreakElems, function (index, value) {
             var boundingRect = this.getBoundingClientRect();
             if (boundingRect.left > 0 && boundingRect.left < iframeWidth) {
             visiblePageNumbers[index] = value.getAttribute('title');
             }
             else if (boundingRect.left < 0) {
             previousPageNumber[index] = value.getAttribute('title');
             }
             });

             if (visiblePageNumbers.length > 0) {
             currentPageNumber = visiblePageNumbers.filter(function (e, i, a) { return e !== undefined;})[0];
             }
             else {
             currentPageNumber = previousPageNumber.pop();
             }
             */
            this._bookResource = bookResource;

            try { // if pinfo is undefined, nav-bar will reach end too soon, but scrolling will continue
                var pinfo = pageChangeData.paginationInfo;
                var pageLeft = pinfo.openPages[0];
                var pageRight = pinfo.openPages[pinfo.openPages.length - 1]; // Kan være lik pageLeft

                var fraction = pageRight.spineItemPageIndex / (pageRight.spineItemPageCount - 1) * 100;
                //var bookResource = this._userbook.getBookResource();
                var physicalPageNumberStr = '';
                if (bookResource.hasPhysicalPages()) {
                    this._firstPhysicalPage = bookResource.getFirstPhysicalPage(currentBookpartId);
                    this._lastPhysicalPage = bookResource.getLastPhysicalPage(currentBookpartId);
                    this.$el.find('.firstPageNumber').html(this._firstPhysicalPage);
                    this.$el.find('.lastPageNumber').html(this._lastPhysicalPage);
                    this.$el.find('.progressbar .progressFill').css('width', fraction + '%');

                    var curCfi = readium.reader.bookmarkCurrentPage();
                    if (curCfi) {
                        curCfi = JSON.parse(curCfi).contentCFI;

                        /*
                         TODO:
                         We will probably want to report page-numbers for all visible pages.
                         var pageNums = bookResource.getPageNumbersInRange(idref, startCfi, endCfi);
                         This method should probably use Searcher.js, where we would implement getPageNumbersInRange.
                         */
                        var pageBrk = bookResource.getClosestPagebreak(currentBookpartId, curCfi);
                        if (pageBrk) {
                            physicalPageNumberStr = ' (side ' + pageBrk + ')';
                        }
                    }
                } else {
                    this.$el.find('.firstPageNumber').html(1);
                    this.$el.find('.lastPageNumber').html(pageRight.spineItemPageCount);
                    this.$el.find('.progressbar .progressFill').css('width', fraction + '%');
                }
                var title = bookResource.getTitleFromIdref(currentBookpartId) || '';
                this.$el.find('.chapterLabel').html(title + physicalPageNumberStr);
            } catch(e) {
                // Updating navigation bar failed! What should we do?
                // Is this still relevant?
            }
        },

        _updatePreviewBox: function (dragSession) {
            dragSession.previewE.text('side ' + dragSession.jumpToPhysicalPage);
        },

        _onDragStart: function (e, dragSession) {
            dragSession.previewE = this.$el.find('.pagePreviewBox > div:first-child');
            dragSession.progressFillE = this.$el.find('.progressbar .progressFill');

            this.$el.find('.progressbar').addClass('dragging');
            this.$el.find('.progressbar .knob').addClass('dragging');

            var progressbarBox = this.$el.find('.progressbar')[0].getBoundingClientRect();
            dragSession.x1 = progressbarBox.left;
            dragSession.x2 = progressbarBox.right;
            dragSession.jumpToPhysicalPage = null;

            var fraction = parseFloat(this.$el.find('.progressFill')[0].style.width) / 100;
            dragSession.jumpToPhysicalPage = this._firstPhysicalPage + Math.round((this._lastPhysicalPage - this._firstPhysicalPage) * fraction);
            this._updatePreviewBox(dragSession);
        },

        _onDragMove: function (e, dragSession) {
            var x = e.center.x;
            var fraction = 0;
            if (x > dragSession.x2) {
                fraction = 1;
            } else if (x > dragSession.x1) {
                fraction = (x - dragSession.x1) / (dragSession.x2 - dragSession.x1);
            }

            dragSession.progressFillE.css('width', fraction * 100 + '%');
            dragSession.jumpToPhysicalPage = this._firstPhysicalPage + Math.round((this._lastPhysicalPage - this._firstPhysicalPage) * fraction);
            this._updatePreviewBox(dragSession);
        },

        _onDragEnd: function (e, dragSession) {
            this.$el.find('.progressbar .knob').removeClass('dragging');
            this.$el.find('.progressbar').removeClass('dragging');

            // if jumpToPhysicalPage !== current page
            if (this._bookResource.getPhysicalPagemap()) {
                var pageNumberMapping = this._bookResource.getPhysicalPagemap();
                var url = pageNumberMapping[dragSession.jumpToPhysicalPage];
                if (url) {
                    this._onGoto(url);
                }
            }
        }
    });
});

