/*global window, location, console, DOMParser, EPUBcfi, Blob, localforage, rangy,
   alert, unescape, cordova, URI */

/**
 * @module webapp
 * @namespace models
 * @class  BookResource
 */


define('models/BookResource',[
    'require',
    'backbone',
    'jquery',
    'underscore',
    'bookResourceMap',
    'Searcher',
    'ResourceManager',
    'views/DialogView',
    'models/bookResource/Spine',
    'repairAndFilterXmlString'
], function (require, Backbone, $, _, bookResourceMap, Searcher, ResourceManager, DialogView, Spine, repairAndFilterXmlString) {


    function generateCfiSortkey(cfi) {
        if (!cfi) {
            return null;
        }
        else {
            return cfi.split('/').map(function (part) {
                var m = part.match(/^\d+/);
                return m ? ('00000' + m[0]).substr(m[0].length) : '';
            }).join('-');
        }
    }

    return Backbone.Model.extend({
        defaults: {
            /**
             * The version (by epubid) of the most recent published epub
             * @attribute {epubid}
             */
            epubid: 0,
            /**
             * Publications with attribute singlebook are hidden in the bookshelf
             * @attribute {singlebook}
             */
            singlebook: false,
            /**
             * Publications with attribute ordboklang are set to null before get function
             * @attribute {ordboklang}
             */
            ordboklang: null,
            /**
             * The version (by epubid) of the local epub
             * @attribute {downloadedEpubid}
             */
            downloadedEpubid: null,
            /**
             * Needs documentation
             * @attribute  isLoaded
             * @type {boolean}
             */
            isLoaded: false,
             /**
             * True if a local version exist
             * @attribute  isDownloaded
             * @type {Boolean}
             */
            isDownloaded: false,
            /**
             * True while the .ub-file is downloading
             * @attribute  isDownloading
             * @type {Boolean}
             */
            isDownloading: false,
            /**
             * True while the downloaded .ub-file is unzipping
             * @attribute  isUnpacking
             * @type {Boolean}
             */
            isUnpacking: false,
            /**
             * True while the downloaded .ub-file is being removed
             * @attribute  isRemoving
             * @type {Boolean}
             */
            isRemoving: false,
            /**
             * Is used both for downloading and unpacking. When downloading is finished and downloadProgress is 100, it resets to 0 and starts again on unpacking
             * @attribute  downloadProgress
             * @type {Number}
             */
            downloadProgress: 0,
            /**
             * If the cover is stored locally use local version, otherwise use internet url
             * @attribute  coverUrlToDisplay
             * @type {[type]}
             */
            coverUrlToDisplay: null,

            /**
             * If the loading of the books, set this attribute to error code
             * @attribute  loadError
             * @type {Number}
             */
            loadError: null,

            /**
             * The hash of the ub file on server
             * @type {String}
             */
            latestUbFileHash: null,

            /**
             * The hash of the ub file that was used to download this book
             * @type {String}
             */
            downloadedUbFileHash: null,

            /**
             * The file size for offline.ub in kb
             */
            offlineUbFileSize: null
        },

        /**
         *
         * for mobile app, it resolves bookcover and checks downloadstatus
         * @method  initialize
         * @param  {object} data
         * @param  {object} options appModel
         */
        initialize: function (data, options) {
            var me = this;
            this._app = options.app;
            this._bookInfo = null; // Populated in .load()
            this._textualResources = {};
            this._textualResources_new = {}; // TEMP: used while refactoring
            this._textualResourcesLoaded = false;
            this._blobUrlMap = {};
            this._tempDocuments = {};
            this._searchers = {}; // Instances of Searcher, one for each textual resource
            this._linearSpine = null; // Populated in .load()


            // FIXME: Cannot be set here - comes from packageDocument
            this._baseUrl = null; // Will be set to this._rootUrl/this._mobileRootUrl + '/OEBPS/' in load()
            this._opfPath = null;

            // midlertidig:
            this.set('options', {local: false});

            this._resourceManager = new ResourceManager(this);
            this._resourceManager.setImplementation();

            this._resourceManager.resolveBookCover();

            if (this._app.get('mobileApp')) {
                this.data = data;
                if (this.get('isUnpacking')) {
                    // see if we can find the .ub-file
                    me._app.unpackingQueue.queue(function(){
                        me._resourceManager.unpack();
                    });
                } else {
                    this._resourceManager.checkIsDownloaded();
                }
                // on startup no bookResources can be downloading
                // The app must have quit while downloading
                if (this.get('isDownloading')){
                    this.set('isDownloading', false);
                }
            } else if (this._app.get('offlineApp')) {
                // on startup no bookResources can be downloading
                // The app must have quit while downloading
                
                if (this.get('isDownloading')){
                    this.set('isDownloading', false); //EB-1304, if user no longer has session the server returns a 403 which causes ziplibrary to crash
                }
                if (this.get('isRemoving')) {
                    this._resourceManager.removeDownloadedBook(function() {
                        // do nothing
                    });
                }
                this._resourceManager.checkIsDownloaded();
            }

            this.on('change:latestUbFileHash', $.proxy(this.onLatestUbFileHashChanged, this));
            this.onLatestUbFileHashChanged();
        },

        /**
         * Opens spine
         * @method  load
         */
        load: function () {
            var me = this;
            var deferred = $.Deferred();
            if (this.get('isLoaded')) {
                deferred.resolve();
                return deferred.promise();
            }

            var epubid = me.get('epubid');

            var readBookInfo = function (bookInfoObj) {
                var deferred = $.Deferred();
                me._bookInfo = bookInfoObj;
                if (bookInfoObj.opfPath) {
                    me._opfPath = bookInfoObj.opfPath.substring(0, bookInfoObj.opfPath.indexOf('/'));
                    me._baseUrl = me._resourceManager.getBaseUrl();
                    me._opfPath = me._opfPath + '/';

          /*          var opfPath = bookInfoObj.opfPath.substring(0, bookInfoObj.opfPath.indexOf('/'));
-                    me._baseUrl = (me.get('isDownloaded') ? me._mobileRootUrl : me._rootUrl) + '/' + opfPath + '/';
-                    me._opfPath = opfPath + '/';*/
                }
                // TODO: Move this to change handler for epubid
                /* TEMP OVERWRITE BECAUSE WE AVE TO CONNECT TO PROD ENVIRONMENT */
                //me._rootUrl = me._rootUrl + '/epub/' + epubid;
                // When acceessing books as a backend-user, epubid might be given.
                // Otherwise, the latest epubid is picked.
                if (!epubid) {
                    // FIXME: this code does not work since request is not defined.
                    //epubid = request.getResponseHeader('x-unibok-epubid');
                    //me.set('epubid', epubid);
                }
                // generate sorkeys for cfis, and make array of linear spine items
                me._spine = new Spine(me, me._bookInfo.spine);

                me.set('isLoaded', true);
                bookResourceMap.registerBookResource(me);
                deferred.resolve();
                return deferred.promise();
            };
            return this._resourceManager.checkIsDownloaded()
                .then(function(isDownloaded){
                    return me._resourceManager.checkIntegrity();
                })
                .then(function(){
                    return me._resourceManager.getBookinfo();
                })
                .then(readBookInfo);
        },

        /**
         * resets bookresource
         * @method  unload
         * @param  {function} callback
         */
        unload: function (callback) {
            var key;
            for (key in this._blobUrlMap) {
                if (this._blobUrlMap.hasOwnProperty(key)) {
                    window.URL.revokeObjectURL(this._blobUrlMap[key]);
                }
            }
            this._blobUrlMap = {};
            this._textualResources = {};
            this._textualResources_new = {};
            this._tempDocuments = {};
            this._searchers = {};
            this._textualResourcesLoaded = false;
            this._linearSpine = null;
            bookResourceMap.unregisterBookResource(this);
            this.set('isLoaded', false);
            callback();
        },

        /**
         * @method search
         * @async
         * @param {string} searchString the string to search for
         * @param {function}[callback] the function to call when the search completes. If
         * @return {object} searchRequest handler, that can be used to cancel a search. (A canceled search will not call its callback argument)
         *
         * Search is an area that probably can be tweaked and optimized in several ways. The current implementation makes use of the Searcher.js code.
         * Previous implementation operated with regexp directly on xhtml-strings (risky business), and also depended on the readium EPUBcfi module.
         * An attempt was also made to use rangy (the now deleted this._searchWithRangy), but rangy didn't like detached documents because it uses the
         * current style to determine which elements are visible.
         */

        search: function (searchString, callback) {
            var me = this;
            var searchRequest = {
                cancelled: false,
                cancel: function () {
                    searchRequest.cancelled = true;
                }
            };

            this._spine.getSpineItems()
                .then(function () {
                    if (!searchRequest.cancelled) {
                        me._search(searchString)
                            .then(function (searchResults) {
                                callback(searchResults);
                            });
                    }
                });

            return searchRequest;
        },

        /**
         * @method  _deobfuscate
         * Needs documentation
         */
        _deobfuscate: function (result, key) {
            return unescape(decodeURIComponent(window.atob(result)));
        },

        /**
         * Needs documentation
         * @method  _search
         * @param  {string}   searchString
         * @param  {function} callback
         */
        _search: function (searchString) {
            var me = this;
            var searchResultsByPart = [];
            var parts = [];

            if (searchString.length > 1) {
                this._spine.getLinearSpineItems().forEach(function (item) {
                    var href = me.getUrlFromIdref(item.idref);
                    parts.push(me.getSearcher(item.idref, href)
                        .then(function (searcher) {
                            var ranges = searcher.findAll(searchString);
                            if (ranges.length) {
                                var part = [];
                                ranges.forEach(function (range) {
                                    var contextRange = searcher.getRangeWithContext(range);
                                    var result = contextRange.getText(['em', 'sub', 'sup', 'span', 'svg', 'g', 'line']);
                                    var startSearchStringOffset = range._startPosition.offset - contextRange._startPosition.offset;
                                    var endSearchStringOffset = range._endPosition.offset - contextRange._startPosition.offset;
                                    
                                    /* Trimming html */
                                    while(result && (result[0] !== ' ' && result[0] !== '<')) {
                                        result = result.substr(1);
                                        startSearchStringOffset -= 1;
                                        endSearchStringOffset -= 1;
                                    }
                                    while(result && (result.slice(-1) !== ' ' && result.slice(-1) !== '>')) {
                                        result = result.slice(0, -1);
                                    }
                                    part.push({
                                        offsets: [startSearchStringOffset, endSearchStringOffset],
                                        resultText: result,
                                        idref: item.idref,
                                        cfi: range.cfi,
                                        pageNum: me.getClosestPagebreak(item.idref, range.cfi)
                                    });
                                });

                                searchResultsByPart.push(part);
                            }
                        }));
                });
            }
            return $.when.apply($, parts)
                .then(function(){
                    return searchResultsByPart;
                })
                .fail(function(err) {
                    throw err;
                });
        },

        /**
         * Needs documentation
         * @method loadPackageDocument
         * @param  {object}   readium
         * @param  {function} callback
         */
        loadPackageDocument: function (readium, callback, openPageRequest) {
            var me = this;
            var packageDocumentURL = me._resourceManager.getRootUrl();

            readium.openPackageDocument(packageDocumentURL, function(packageDocument, options) {
                me._baseUrl = packageDocument.getSharedJsPackageData().rootUrl;
                // always add trailing slash
                if (me._baseUrl.substr(me._baseUrl.length - 1) !== '/') {
                    me._baseUrl += '/';
                }
                callback(packageDocument);
            }, openPageRequest);
        },

        /**
         * Public API function
         * @method  getBaseUrl
         * @return {String} Baseurl of epub
         */
        getBaseUrl: function () {
            return this._resourceManager.getBaseUrl();
        },

        getOpfUrl: function() {
          return URI.joinPaths(this.getBaseUrl(), this.getTocBasepath()).absoluteTo(this.getBaseUrl()).toString();
        },

        getResourceUrl: function(url, callback) {
            this._resourceManager.getResourceUrl(url, callback);
        },

        /**
         * Public API function
         * @method  getAssetUrl
         * @return {String}
         */
        getAssetUrl: function (originalUrl, mainCallback) {
            return this._resourceManager.getAssetUrl(originalUrl, mainCallback);
        },

        /**
         * Public API function
         * @method  getIframeContentCssUrl
         * @param {function} callback with an interface of function({String} cssUrl).
         */
        getIframeContentCssUrl: function (callback) {
            this._resourceManager.getIframeContentCssUrl(callback);
        },

        /**
         * Public API function
         * @method  fetchFileContentsText
         */
        fetchFileContentsText: function (fileUrl, fetchCallback, onerror) {
            this._resourceManager.fetchFileContentsText(fileUrl)
                .then(function(text) {
                    fetchCallback(text);
                })
                .fail(function(err) {
                    onerror(err);
                });
        },

        /**
         * Needs documentation
         * @method cleanupTemporaryDocuments
         */
        cleanupTemporaryDocuments: function () {
            this._tempDocuments = {};
        },

        /**
         * Needs documentation
         * @method  _getDocument
         *
         */
        _getDocument: function (idref, keep) {
            var me = this;
            var deferred = $.Deferred();
            var contentDocument = this._tempDocuments[idref];
            if (!contentDocument) {
                var value = this._textualResources_new[idref];
                if (value) {
                    contentDocument = (new DOMParser()).parseFromString(value, "application/xhtml+xml");
                    if (keep) {
                        this._tempDocuments[idref] = contentDocument;
                    }
                    deferred.resolve(contentDocument);
                } else {
                    // load content document
                    //var href = this.getUrlFromIdref(idref);
                    this._spine.getSpineItemHtmlByIdref(idref)
                        .then(function (value) {
                            contentDocument = (new DOMParser()).parseFromString(value, "application/xhtml+xml");
                            if (keep) {
                                me._tempDocuments[idref] = contentDocument;
                            }
                            deferred.resolve(contentDocument);
                        });
                }
            }
            return deferred.promise();
        },

        /**
         * Needs documentation
         * @method  validateCfi
         * @param  {[type]} idref            [description]
         * @param  {[type]} cfi              [description]
         * @param  {[type]} keepTempDocument [description]
         * @return {[type]}                  [description]
         */
        validateCfi: function (idref, cfi, keepTempDocument) {
            var deferred = $.Deferred();
            var validated = false;
            this._getDocument(idref, keepTempDocument)
                .then(function (contentDocument) {
                    if (contentDocument && cfi) {
                    cfi = cfi.split('@')[0];
                    cfi = "epubcfi(/99!" + cfi + ")";
                    var classBlacklist = ["cfi-marker", "mo-cfi-highlight", 'MathJax_Preview'];
                    var elementBlacklist = [];
                    var idBlacklist = ["MathJax_Message", 'MathJax_Hidden', 'MathJax_SVG_Hidden'];
                    try {
                        var x = EPUBcfi.getTargetElement(cfi, contentDocument, classBlacklist, elementBlacklist, idBlacklist);
                        validated = x ? true : false;
                    } catch (err) {
                        //console.log('EPUBcfi error', err);
                        deferred.resolve(false);
                    }
                    deferred.resolve(validated);
                }
            });
            return deferred.promise();
        },

        /**
         * Validates a range cfi against readium.
         *
         * @method  validateRangeCfi
         * @param  {String} idref
         * @param  {String} cfi
         * @param  {Boolean} keepTempDocument
         * @return {Boolean}
         */
        validateRangeCfi: function (idref, cfi, keepTempDocument) {
            var deferred = $.Deferred();
            var validated = false;
            this._getDocument(idref, keepTempDocument)
                .then(function (contentDocument) {
                if (!(contentDocument && cfi)) {
                    // could'nt find contentDocument or cfi was undefined/null
                    deferred.reject('could\'nt find contentDocument or cfi was undefined/null');
                }

                var rangeCFI = "epubcfi(/99!" + cfi + ")";
                var classBlacklist = ["cfi-marker", "mo-cfi-highlight", 'MathJax_Preview'];
                var elementBlacklist = [];
                var idBlacklist = ["MathJax_Message", 'MathJax_Hidden', 'MathJax_SVG_Hidden'];

                try {
                    var targetRange = EPUBcfi.getRangeTargetElements(rangeCFI, contentDocument, classBlacklist, elementBlacklist, idBlacklist);

                    var decodedCFI = decodeURI(rangeCFI);
                    var CFIAST = EPUBcfi.Parser.parse(decodedCFI);

                    var range = contentDocument.createRange();
                    // throws an exception if offsetValue is greater than length of element
                    range.setStart(targetRange.startElement, CFIAST.cfiString.range1.termStep.offsetValue);
                    range.setEnd(targetRange.endElement, CFIAST.cfiString.range2.termStep.offsetValue);

                    // TODO: Possibly to make this test better by testing range against the expected text.

                    validated = true;
                }
                catch (err) {
                    // Probably an EPUBcfi.CFIAssertionError
                }

                deferred.resolve(validated);
            });
            return deferred.promise();
        },

        /**
         * Needs documentation
         * @method  getSearcher
         * @param  {[type]} idref [description]
         * @return {[type]}       [description]
         */
        getSearcher: function (idref, href) {
            var deferred = $.Deferred();

            var me = this;
            var searcher = this._searchers[idref] || {};
            if (_.isEmpty(searcher)) {
                this._spine.getSpineItemHtmlByIdref(idref)
                    .then(function(markupString) {
                        if (markupString) {
                            searcher = new Searcher(markupString);
                            me._searchers[idref] = searcher;
                            deferred.resolve(searcher);
                        }
                    });
            } else {
                deferred.resolve(searcher);
            }
            return deferred.promise();
        },

        /**
         * Needs documentation
         * @method  hasPhysicalPages
         * @return {boolean} [description]
         */
        hasPhysicalPages: function () {
            return true;
        },

        /**
         * Needs documentation
         * @method  getSpineItems
         * @return {[type]} [description]
         */
        getSpineItems: function () {
            return this._bookInfo ? this._bookInfo.spine : [];
        },

        getPreviousSpineItemOnIdref: function (idref) {
            return this._spine.getPreviousSpineItemOnIdref(idref);
        },

        getNextSpineItemOnIdref: function (idref) {
            return this._spine.getNextSpineItemOnIdref(idref);
        },

        /**
         * Needs documentation
         * @method  getToc
         * @return {[type]} [description]
         */
        getToc: function () {
            return this._bookInfo ? this._bookInfo.toc : null;
        },

        /**
         * [getResourcesByChapter description]
         * @method  getResourcesByChapter
         * @return {Object} Keys chapter hrefs, value are the resource and a spine idref
         */
        getResourcesByChapter: function () {
            var me = this;
            var resourcesByChapter = {};
            var spine = this._bookInfo.spine;
            spine.forEach(function(spineElm){
                if (spineElm.resources) {
                    var tocPath = spineElm.href;//me._bookInfo.navHref + spineElm.idref;
                    resourcesByChapter[tocPath] = spineElm.resources;
                    resourcesByChapter[tocPath].idref = spineElm.idref;
                }
            });
            return resourcesByChapter;
        },

        /**
         * Gets the path to the nav.xhtml-file where the toc is found
         * @method  getTocBasepath
         * @return {String} Path to file
         */
        getTocBasepath: function () {
            return this._bookInfo ? this._bookInfo.tocBasepath : null;
        },

        /**
         * Needs documentation
         * @method  getTocUrl
         * @return {[type]} [description]
         */
        getTocUrl: function () {
            return this._bookInfo ? this._bookInfo.navHref : null;
        },

        /**
         * Needs documentation
         * @method  getFirstPhysicalPage
         * @param  {[type]} idref [description]
         * @return {[type]}       [description]
         */
        getFirstPhysicalPage: function (idref) {
            var pageNumber = null, spineItem;
            if (this._bookInfo && this.hasPhysicalPages()) {
                spineItem = idref ? this._getSpineItemFromIdref(idref) : this._bookInfo.spine[0];
                if (spineItem && spineItem.physicalPages.length) {
                    pageNumber = parseInt(spineItem.physicalPages[0][1], 10);
                }
            }
            return pageNumber;
        },

        /**
         * Needs documentation
         * @method  getLastPhysicalPage
         * @param  {[type]} idref [description]
         * @return {[type]}       [description]
         */
        getLastPhysicalPage: function (idref) {
            var pageNumber = null, spineItem;
            if (this._bookInfo && this.hasPhysicalPages()) {
                spineItem = idref ? this._getSpineItemFromIdref(idref) : this._bookInfo.spine[this._bookInfo.spine.length - 1];
                if (spineItem && spineItem.physicalPages.length) {
                    pageNumber = parseInt(spineItem.physicalPages[spineItem.physicalPages.length - 1][1], 10);
                }
            }
            if (pageNumber) {
                return pageNumber;
            } else {
                return this._bookInfo.lastpage;
            }
        },

        /**
         * Needs documentation
         * @method  getPhysicalPagemap
         * @return {[type]} [description]
         */
        getPhysicalPagemap: function () {
            return this._bookInfo ? this._bookInfo.pagemap : null;
        },

        /**
         * Needs documentation
         * @method  getClosestPageBreak
         * @param  {[type]} idref [description]
         * @param  {[type]} cfi   [description]
         * @return {[type]}       [description]
         */
        getClosestPagebreak: function (idref, cfi) {
            var result = null;
            var spineItem = this._getSpineItemFromIdref(idref);
            if (spineItem) {
                var cfiSortkey = generateCfiSortkey(cfi);
                if (!cfiSortkey) {
                    return result; // whereas null
                }
                var physicalPages = spineItem.physicalPages;
                var pageInfo;
                var i = physicalPages.length;
                while (i--) {
                    pageInfo = physicalPages[i];
                    if (pageInfo[2] < cfiSortkey) {
                        break;
                    }
                }
                result = pageInfo ? pageInfo[1] : null;
            }
            return result;
        },

        getGlossaryLang: function () {
            return this._bookInfo ? this._bookInfo.glossaryLang : null;
        },

        /**
         * Needs documentation
         * @method  _getSpineItemFromIdref
         * @param  {[type]} idref [description]
         * @return {[type]}       [description]
         */
        _getSpineItemFromIdref: function (idref) {
            var spineItem = null;
            this.getSpineItems().forEach(function (item) {
                if (item.idref === idref) {
                    spineItem = item;
                    return false;
                }
            });
            return spineItem;
        },

        /**
         * Needs documentation
         * @method  getTitleFromIdref
         * @param  {[type]} idref [description]
         * @return {[type]}       [description]
         */
        getTitleFromIdref: function (idref) {
            var spineItem = this._getSpineItemFromIdref(idref);
            return spineItem ? spineItem.label : '';
        },

        /**
         * Fæl jævel for å fikse at toc-urler og spine-urler ikke matcher
         * tar også høyde for bokstruktur med del/kapittel
         * @method  _getTocItemFromIdref
         * @param  {[type]} idref [description]
         * @return {[type]}       [description]
         */
        _getTocItemFromIdref: function (idref) {
            var item = null;
            var chapterItems = this.getToc();
            var subItems = [];
            var flattened =  [];
            chapterItems.forEach(function (chapItem) {
                if (chapItem.subitems) {
                    subItems.push(chapItem.subitems);
                }
            });
            flattened = flattened.concat.apply(flattened, subItems);
            var tocItems = chapterItems.concat(flattened);

            this.getSpineItems().forEach(function (spineItem) {
                if (spineItem.idref === idref) {
                    // try to match spine href with toc href
                    tocItems.forEach(function (tocItem) {
                        if (tocItem.href === spineItem.href) {
                            item = tocItem;
                            return false;
                        }
                    });
                }
            });
            return item;
        },

        /**
         * Needs documentation
         * @method getUrlFromIdref
         * @param  {[type]} idref [description]
         * @return {[type]}       [description]
         */
        getUrlFromIdref: function (idref) { // Used by TocPanel
            var tocItem = this._getTocItemFromIdref(idref);

            return tocItem ? tocItem.href : null;
        },


        /**
         * download fetches a .ub from server and unpacks it in offline storage
         * Checks the available disk space through our own custom function in cordova file plugin
         * This function is not 100% accurate, it seems
         * @method  download
         */
        download: function (callback) {
            var me = this;
            var onDownloadFail = function(err) {
                if (err.name === 'Download aborted' || err.message === 'Download aborted' || err.code === 4) {
                    console.log('user aborted download');
                } else {
                    var title = "Nedlastning av bok feilet";
                    var message = null;
                    if (err.name === 'QuotaExceededError') {
                        title = "Lite lagringsplass";
                        message = "Nettleseren trenger mer plass for å gjøre boka tilgjengelig frakoblet. Dersom enheten din er full, kan du frigjøre plass og forsøke på nytt.";
                    } else {
                        message = err.name;
                    }

                    new DialogView({
                        'isAlert': true,
                        'title': title,
                        'message': message,
                        "appendTo": 'body'
                    });
                }
            };

            if (this._app.get('mobileApp')) {
                return this._resourceManager.checkAvailableDiskSpace()
                    .then(function(){
                        me._resourceManager.download()
                            .then(function(){
                                me.set('downloadedUbFileHash', me.get('latestUbFileHash'));
                            })
                            .fail(onDownloadFail);
                    })
                    .fail(function(err){
                        new DialogView({
                            'isConfirm': true,
                            'title': 'Lite lagringsplass',
                            'message': 'Du har lite lagringsplass på enheten din. Vil du fortsatt gjøre boka tilgjengelig frakoblet?',
                            "appendTo": 'body',
                            'onConfirm': function(){
                                me._resourceManager.download()
                                    .then(function(){
                                         me.set('downloadedUbFileHash', me.get('latestUbFileHash'));
                                     })
                                    .fail(onDownloadFail);
                            }
                        });
                    });
            } else {
                return me._resourceManager.download()
                    .then(function(){
                        me.set('downloadedUbFileHash', me.get('latestUbFileHash'));
                        callback();
                    })
                    .fail(onDownloadFail);
            }
        },


        /**
         * aborts current download, removes all associated files
         * @method  abortDownload
         */
        abortDownload: function () {
            this._resourceManager.abortDownload();
        },

        /**
         * Remove an epub from offline storage
         * @method  remove
         */
        remove: function () {
            return this._resourceManager.removeDownloadedBook();
        },

        /**
         * @method  toJSON
         * @return {[type]} [description]
         */
        toJSON: function () {
            var attrs = _.clone(this.attributes);

            delete attrs.isLoaded;
            delete attrs.isDownloaded;
            delete attrs.downloadProgress;
            delete attrs.coverUrlToDisplay;

            return attrs;
        },

        /**
         * @method newVersionAvailable
         * @return {Boolean} indicates if a new version of this book is available for download
         */
        newVersionAvailable: function() {
            if (!this.get('downloadedEpubid')) {
                return false;
            }

            if (this.get('downloadedEpubid') !== this.get('epubid')) {
                return true;
            }

            if (!this.get('downloadedUbFileHash')) {
                return false;
            }

            if (this.get('downloadedUbFileHash') !== this.get('latestUbFileHash')) {
                return true;
            }

            return false;
        },

        /**
         * Handles changes to the latestUbFileHash value.
         *
         * @method onLatestUbFileHashChanged
         */
        onLatestUbFileHashChanged: function() {
            if (this.get('downloadedEpubid') && !this.get('downloadedUbFileHash')) {
                this.set('downloadedUbFileHash', this.get('latestUbFileHash'));
            }
        },

        /**
         * Set all the states related to the download state to 'false'.
         *
         * @method setStateToNotDownloaded
         */
        setStateToNotDownloaded: function() {
            // set 'downloadedEpubid' to null before 'isDownloaded' to false
            // ResourceManager::setImplementation triggers on 'change:isDownloaded' and that
            // method expects 'downloadedEpubid' to be updated aswell.
            this.set('downloadedEpubid', null);
            this.set('downloadedUbFileHash', null);
            this.set('isDownloaded', false);

            this._app.trigger('offlineBooks');
        },

        /**
         * Checks if this book is available offline.
         */
        isAvailableOffline: function() {
            return this.get('downloadedEpubid') ||
                   this.get('isDownloaded');
        },

        reloadResourceManager: function() {
            this._resourceManager = new ResourceManager(this);
            this._resourceManager.setImplementation();
        }
    });
});

