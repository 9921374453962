define('templates/bookshelfView',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (iframe, isFeide, mobileApp, offlineApp) {
buf.push("<div id=\"bookshelf\" hidden=\"hidden\" class=\"bookshelf\"><header" + (jade.cls(['bookshelf-topbar',typeof iframe === "undefined" ? undefined : "iframe"], [null,true])) + "><a href=\"https://www.unibok.no/\" aria-label=\"www.unibok.no\" class=\"logoCont\"><div class=\"logo\"></div></a><div class=\"bookshelf-userinfo\"><div class=\"loginInfo\"></div>");
if ( !iframe)
{
buf.push("<div><button class=\"btn-logout\">Logg ut</button></div>");
}
buf.push("<div" + (jade.cls(['publisherCont',isFeide ? 'isFeide': ''], [null,true])) + "> <button class=\"btn-publisherTermsInfo\">Kontoer</button></div></div></header><div class=\"bookshelf-content\"><div class=\"bookshelf-header\"><div class=\"header-group\">");
if ( mobileApp)
{
buf.push("<span class=\"version\">cordovaVersion</span>");
}
buf.push("<h1 tabindex=\"-1\">Mine unibøker</h1><div class=\"searchCont\"><label for=\"searchInput\">Søk</label><input id=\"searchInput\" type=\"text\" placeholder=\"Søk i titler\" class=\"search-box\"/></div><div class=\"malformCont\"><fieldset class=\"malform\"><div data-lang=\"nob\" class=\"malform-valg\"><input type=\"radio\" name=\"malform\" value=\"bokmål\" id=\"nob\" data-lang=\"nob\" class=\"maalform-input--hidden\"/><span class=\"maalform-input--fake\"></span><label for=\"nob\" class=\"btn-nob\"><span>Bokmål</span></label></div><div data-lang=\"nno\" class=\"malform-valg\"><input type=\"radio\" name=\"malform\" value=\"nynorsk\" id=\"nno\" data-lang=\"nno\" class=\"maalform-input--hidden\"/><span class=\"maalform-input--fake\"></span><label for=\"nno\" class=\"btn-nno\"><span>Nynorsk</span></label></div></fieldset></div></div></div><div" + (jade.cls(['bookshelf-maincont',typeof iframe === "undefined" ? undefined : "iframe"], [null,true])) + "><div class=\"bookshelf-infocont\">");
if ( offlineApp || mobileApp)
{
buf.push("<div class=\"bookshelf-offlineFilter\"><div class=\"filter-offline-check\"><div class=\"offline-check\"><input type=\"radio\" name=\"offline-filter\" value=\"alle\" id=\"alle\" checked=\"checked\" class=\"offline-input--hidden\"/><label for=\"alle\"><div>Vis alle bøker</div></label></div><div class=\"offline-check\"><input type=\"radio\" name=\"offline-filter\" value=\"frakoblet\" id=\"frakoblet\" class=\"offline-input--hidden\"/><label for=\"frakoblet\"><div>Tilgjengelig frakoblet (offline)</div></label></div></div></div>");
}
buf.push("<div class=\"bookshelf-tips\"><div class=\"tips-header\"><h2>Tips</h2><span class=\"expand-icon\"></span></div><div id=\"tipsFromJson\" class=\"tip\"></div></div></div><main class=\"bookshelf-bookcont\"><h2 class=\"bookshelf-title visually-hidden\">Alle bøker</h2><div class=\"bookshelf-booklist\"></div><div class=\"no-offline-books\"><p>Du har ingen bøker tilgjengelig frakoblet</p></div></main></div></div></div>");}.call(this,"iframe" in locals_for_with?locals_for_with.iframe:typeof iframe!=="undefined"?iframe:undefined,"isFeide" in locals_for_with?locals_for_with.isFeide:typeof isFeide!=="undefined"?isFeide:undefined,"mobileApp" in locals_for_with?locals_for_with.mobileApp:typeof mobileApp!=="undefined"?mobileApp:undefined,"offlineApp" in locals_for_with?locals_for_with.offlineApp:typeof offlineApp!=="undefined"?offlineApp:undefined));;return buf.join("");
}

});
