/* globals console, alert, location, window, Blob */

/**
 * This class finds resources for a bookResource when it is located on the server
 *
 * @module webapp
 * @namespace  ResourceManagers
 * @class  HttpResourceFetcher
 */
define('resourcefetchers/HttpResourceFetcher',['jquery', 'URIjs', 'resourcefetchers/ResourceFetcherBase', 'bowser', 'views/DialogView'], function($, URI, ResourceFetcherBase, bowser, DialogView) {

    var HttpResourceFetcher = function(bookResource) {
        ResourceFetcherBase.call(this, bookResource);
    };

    HttpResourceFetcher.prototype = Object.create(ResourceFetcherBase.prototype);

    HttpResourceFetcher.prototype.getRootUrl = function() {
        return URI().origin() + ResourceFetcherBase.prototype.getRootUrl.call(this);
    };

    HttpResourceFetcher.prototype.getBookinfo = function() {
        var me = this;
        var deferred = $.Deferred();
        var url = this._appModel.get('backendServerUrl') + '/api/v1/publisher/' + this._bookResource.get('publisherRef') + '/book/' + encodeURIComponent(this._bookResource.get('bookId')) + '/epub/' + encodeURIComponent(this._bookResource.get('epubid')) + '/book-info';
        $.ajax({
            url: url,
            dataType: 'json',
            success: function(data) {
                deferred.resolve(data);
            },
            error: function(xhr, status, errorThrown) {
                me._bookResource.set('loadError', 404);
                me._appModel.onBackendRequestFail(xhr, function() {
                    if (xhr.status === 0)  {
                        new DialogView({
                            "title": "Kan ikke åpne boka",
                            "message": "Du er ikke tilkoblet internett. For å bruke Unibok uten nettilgang må du bruke Google Chrome eller laste ned vår app for iPad eller iPhone, og deretter velge å gjøre bøkene tilgjengelig frakoblet.",
                            "isAlert": true,
                            "appendTo": 'body'
                        });
                        me._appModel.trigger('resourceIsUnavailable');
                    }
                });
                deferred.reject("Kan ikke åpne boka");
            }
        });
        return deferred.promise();
    };

    /**
     * [fetchSpineItem description]
     * @param  {[type]}   item     [description]
     * @param  {Function} callback [description]
     * @return {[type]}            [description]
     */
    HttpResourceFetcher.prototype.fetchSpineItem = function(href) {
        var me = this;
        return $.ajax({
            url: me.getBaseUrl() + href,
            dataType: 'text',
            success: function(result, testStatus, jqXHR) {
                // Only deobfuscate if obfuscated
                if (jqXHR.getResponseHeader('X-UNIBOK-OBFUSCATED')) {
                    var filename = href.split('/').pop();
                    result = me._bookResource._deobfuscate(result, filename);
                }
                return result;
            }
        });
    };

    HttpResourceFetcher.prototype.fetchFileContentsText = function(fileUrl) {
        // NOTE: her kan vi hente fra localForage, og eventuelt manipulere
        var deferred = $.Deferred();
        if (/META-INF\/com.apple.ibooks.display-options.xml$/.test(fileUrl)) {
            return deferred.reject('Not Found');
        }
        if (/META-INF\/encryption.xml$/.test(fileUrl)) {
            return deferred.reject('Not Found');
        }

        $.ajax({
            url: fileUrl,
            dataType: 'text',
            async: true,
            success: function(result) {
                deferred.resolve(result);
            },
            error: function(xhr, status, errorThrown) {
                console.error('Error when AJAX fetching ' + fileUrl);
                console.error(status);
                console.error(errorThrown);
                deferred.reject(errorThrown);
            }
        });

        return deferred;
    };

    HttpResourceFetcher.prototype.getAssetUrl = function(originalUrlStr, mainCallback) {
        var me = this;
        var originalUrl = new URI(originalUrlStr);
        var localUrl = originalUrlStr.replace(this.getBaseUrl(), '');

        // fix to allow for different folder structures in epub
        var baseUrl = originalUrl.filename('').toString();

        var idref = this._bookResource._spine._idrefMap[localUrl];
        var useBlob = !(bowser.msie || bowser.msedge || this._appModel.get('mobileApp'));
        // IE doesn't support blobs in iframes
        if (!useBlob) {
            mainCallback(originalUrlStr, useBlob);
            return;
        }

        if (this._bookResource._blobUrlMap[localUrl]) {
            // using previously prepared blobUrl
            mainCallback(this._bookResource._blobUrlMap[localUrl], useBlob);
        } else {
            this._bookResource._spine.getSpineItemHtmlByHref(localUrl)
                .then(function(rawStr){
                    rawStr = rawStr.replace('<head>', '<head>' + '<base href="' + baseUrl + '"/>');
                    rawStr = rawStr.replace(/(<object .*?\b)(type)(=.*?>)/g, function (match, p1, p2, p3) { // See EB-1200
                        return p1 + 'typo' + p3;
                    });

                    // creating and using blobUrl
                    var mimetype = 'application/xhtml+xml'; // TODO: vi bør sjekke mimetype
                    var blob = new Blob([rawStr], {
                        type: mimetype
                    });
                    var url = window.URL.createObjectURL(blob);
                    me._bookResource._blobUrlMap[localUrl] = url;

                    // TODO: load and create blob-urls of all other resources in document. Replace original urls with blob-urls.
                    mainCallback(url, useBlob);
                });

        }
    };

    HttpResourceFetcher.prototype.unpack = function()  {
        console.log('the unpack method is not available for the HttpResourceFetcher');
    };

    HttpResourceFetcher.prototype.resolveBookCover = function()  {
        this._bookResource.set('coverUrlToDisplay', this._bookResource.get('thumbnail'));
    };

    return HttpResourceFetcher;
});

