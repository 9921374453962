/* global window */

/**
 * @module  webapp
 * @namespace  Views
 * @class  ErrorInfoView
 */
define('views/ErrorInfoView',[
        'backbone',
        'templates/errorInfoView'
    ],
    function(Backbone, errorInfoViewTemplate) {
        return Backbone.View.extend({
            events: {
                'click #copyToClipboardBtn': 'copyToClipboard'
            },

            initialize: function (options) {
                this.error = options.error;
                this.envInfo = options.envInfo;
                this.render();
            },

            render: function() {
                this.setElement(errorInfoViewTemplate({
                    envInfo: this.envInfo,
                    stackTrace: this.error.stack.toString()
                }));
                return this;
            },

            copyToClipboard: function() {
                this.$el.find('.stackTrace').select();
                window.document.execCommand("copy");
            }
        });
    });

