/* global EPUBcfi */

/**
 * @module webapp
 * @namespace  Models
 * @class  BookmarkIndicator
 */
define('models/BookmarkIndicator',['jquery', 'models/MarginIndicatorBase'], function($, MarginIndicatorBase) {

    function getBookmarkPosition(bookmark, contentDocument) {
        var classBlacklist = ["cfi-marker", "mo-cfi-highlight", 'MathJax_Preview'];
        var elementBlacklist = $(contentDocument).find('script[type="math/mml"]');
        var idBlacklist = ["MathJax_Message", 'MathJax_Hidden', 'MathJax_SVG_Hidden'];

        var pointCfi = "epubcfi(/99!" + stripCfiForSpatialOffset(bookmark.attributes.cfi) + ")";
        var target = EPUBcfi.getTargetElement(pointCfi, contentDocument, classBlacklist, elementBlacklist, idBlacklist);
        if (target && target.context) {
            return target.context.getBoundingClientRect();
        }
    }

    function stripCfiForSpatialOffset(cfi) {
        return cfi.replace(/@\d+:\d+$/, '');
    }

    var BookmarkIndicator = function(bookmark, marginManager) {
        this.bookmarkId = bookmark.cid;
        var contentDocument = marginManager._readerView.$el.find('iframe').contents()[0];
        var rect = getBookmarkPosition(bookmark, contentDocument);
        if (rect) {
            MarginIndicatorBase.call(this, rect.top, 0, marginManager);
            marginManager._bookmarkIndicators[this.bookmarkId] = this;
        }
    };

    BookmarkIndicator.prototype = Object.create(MarginIndicatorBase.prototype);

    BookmarkIndicator.prototype.render = function() {
        this.$el = $('<div class="bookmarkIndicator"></div>');
        return this.$el;
    };

    return BookmarkIndicator;

});

