/**
 * @module webapp
 * @namespace  Models
 * @class  MarginIndicatorContainer
 */
define('models/MarginIndicatorContainer',['jquery'], function($) {

    /**
     * MarginIndicatorContainer
     * @param {Object} margin jQuery-object, left or right bookmargin.
     * @param {Object} marginManager the margin manager that will controle the indicators.
     * @constructor
     * @class MarginIndicatorContainer
     */
    var MarginIndicatorContainer = function (margin, marginManager) {
        this._items = [];
        this._marginManager = marginManager;
        this._top = undefined;
        this.$el = $('<div class="marginContainer"></div>');

        margin.append(this.$el);
    };

    MarginIndicatorContainer.prototype.insert = function(item) {
        this._items.push(item);
        item._container = this;
        if (this._top === undefined) {
            this._top = item.yPos;
        }
    };

    MarginIndicatorContainer.prototype.removeObject = function(item) {
        item.$el.remove();
        this._items.splice(this._items.indexOf(item), 1);
    };

    MarginIndicatorContainer.prototype.render = function(item) {
        var me = this;
        this.$el.empty();
        this.$el.css("top", me._top + "px");
        this._items.forEach(function (item) {
            me.$el.append(item.render());
        });
    };

    return MarginIndicatorContainer;
});

