/* globals alert, window, localStorage, document */
define('views/LogoutView',['jquery', 'backbone', 'templates/logoutView'], function ($, Backbone, mainTemplate) {

    return Backbone.View.extend({
        initialize: function (appModel) {
            this._appModel = appModel;
            this.render();
        },

        events: {
            'click .logout': 'logout',
            'click .logout-cancel': 'logoutCancel',
            'keyup #dontShowMessageAgain': 'toggleLogoutMessageChoice'
        },

        render: function () {
            this.setElement(mainTemplate());
            return this;
        },

        toggleLogoutMessageChoice: function (e) {
            // If the pressed key is not 'Enter Button', do nothing.
            if (e.type === 'keyup' && e.keyCode !== 13) {
                return;
            }
            var msgCheckBox = $("#dontShowMessageAgain");
            if (msgCheckBox.is(":checked")) {
                msgCheckBox.prop("checked", false);
            }
            else {
                msgCheckBox.prop("checked", true);
            }
        },

        logout: function () {

            if ($("input#dontShowMessageAgain").is(':checked')) {
                document.cookie = "dontShowMessageAgain=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
            }

            var me = this;
            me._appModel.get('activeUser').logout();
            return false;
        },

        logoutCancel: function () {
            this._appModel.trigger('requestCancelLogout');
            return false;
        },

        enter: function () {
            this.$el.addClass('active').removeAttr('hidden');
        },

        leave: function () {
            this.$el.removeClass('active').attr('hidden', 'hidden');
        }
    });
});

