/* globals document, define, require, window, cordova, console */

/**
 * Exports annotations in a userbook to a docx document
 * Uses the docxTemplater library, https://github.com/open-xml-templating/docxtemplater
 * @module webapp
 * @namespace Views
 * @class  AnnotationsToDOCX
 */
define('AnnotationsToDOCX',['underscore', 'docxTemplater', 'FileSaver', 'jszipUtils'], function(_, docxTemplater, saveAs, jszipUtils) {
    /**
     * amd module
     * @method  annotationsToDOCX
     * @return {[type]}          [description]
     */
    function AnnotationsToDOCX() {
        var me = this;
        var rawXmlNamespace = 'http://schemas.openxmlformats.org/wordprocessingml/2006/main';

        me.generateDocX = function (userbook) {
            jszipUtils.getBinaryContent('js/thirdparty/docxtemplater/annotasjon_template_pages.docx',
                function (err, content) {
                    if (err) {
                        throw err;
                    }

                    var templateData = {
                        bookTitle: userbook._bookResource.get('title'),
                        annotations: me.generateChapterData(userbook)
                    };

                    var doc = new docxTemplater(content);
                    doc.setData(templateData);
                    doc.render();
                    var blob = doc.getZip().generate({type: "blob"});
                    var filename = 'annotasjoner_' + userbook._bookResource.get('title').replace(/[\/ ]/g, '_') + '.docx';
                    if (window.cordova) {
                        window.resolveLocalFileSystemURL(cordova.file.dataDirectory, function(dir) {
                            dir.getFile(filename, {
                                create: true
                            }, function(file) {
                                file.createWriter(function(fileWriter) {
                                    fileWriter.write(blob);
                                    /* Open it on the device */
                                    cordova.plugins.fileOpener2.open(
                                        cordova.file.dataDirectory + filename,
                                        'application/vnd.msword', {
                                            error: function(e) {
                                                console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
                                            }
                                        }
                                    );
                                });
                            });
                        });
                    } else {
                        saveAs(blob, filename);
                    }
                }
            );
        };

        me.generateChapterData = function(userbook) {
            var annotationsGroupedByChapter = userbook.get('annotations').groupBy(function(model) {
                return model.get('bookpartId');
            });

            var validIdrefs = {};
            var itemsFoundCount = 0;

            var chapterData = [];

            userbook._bookResource.getSpineItems().some(function(item) {
                validIdrefs[item.idref] = true;
                var annotations = annotationsGroupedByChapter[item.idref];
                if (annotations) {
                    chapterData.push({
                        chapterName: item.label,
                        chapterAnnotations: annotations.map(function(annotation) {
                            return {
                                createdAt   : me.formatDate(annotation.get('timestamp')),
                                annotation  : me.generateRawXML(annotation),
                                userComment : annotation.get('text'),
                                physicalPageNumber : annotation.get('physicalPageNumber')
                            };
                        })
                    });
                    //exit if we already found all chapters
                    return ++itemsFoundCount === Object.keys(annotationsGroupedByChapter).length;
                }
                return false;
            });

            _.each(annotationsGroupedByChapter, function(annotations, key) {
                if (!validIdrefs[key]) {
                    chapterData.push({
                        chapterName: 'Manglende kapittel',
                        chapterAnnotations: annotations.map(function(annotation) {
                            return {
                                createdAt   : me.formatDate(annotation.get('timestamp')),
                                annotation  : me.generateRawXML(annotation),
                                userComment : annotation.get('text'),
                                physicalPageNumber : annotation.get('physicalPageNumber')
                            };
                        })
                    });
                }
            });

            return chapterData;
        };

        me.formatDate = function(timestamp) {
            var dateOptions = {
                weekday: "short",
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
            };
            return (new Date(timestamp)).toLocaleDateString('no-NO', dateOptions);
        };

        me.generateRawXML = function(annotation) {
            //generate annotation paragraph properties
            var paragraphProperties = document.createElementNS(rawXmlNamespace, 'w:pPr');

            var pStyle = document.createElementNS(rawXmlNamespace, 'w:pStyle');
            pStyle.setAttribute('w:val', 'Normal');

            var ind = document.createElementNS(rawXmlNamespace, 'w:ind');
            ind.setAttribute('w:left', '1400'); //left indent
            ind.setAttribute('w:right', '0');
            ind.setAttribute('w:hanging', '0');

            var rPr1 = document.createElementNS(rawXmlNamespace, 'w:rPr');
            paragraphProperties.appendChild(pStyle);
            paragraphProperties.appendChild(ind);
            paragraphProperties.appendChild(rPr1);

            var paragraph = document.createElementNS(rawXmlNamespace, 'w:p');

            paragraph.appendChild(paragraphProperties);
            paragraph.appendChild(me.createTextRun(annotation.get('markedText'), me.getColorIdAsHex(annotation.get('colorid'))));

            return paragraph.outerHTML.replace("&nbsp;", " ");
        };

        me.getColorIdAsHex = function(colorId) {
            switch (parseInt(colorId)) {
                case 1:
                    return 'yellow';
                case 2:
                    return 'green';
                case 3:
                    return 'cyan';
                case 4:
                    return 'red';
            }
        };

        /**
         * Generates a text run xml node
         * @param annotationText The text to display to the user
         * @param colorHex the colorHex if this text should be highlighted
         * @returns {Element}
         */
        me.createTextRun = function(annotationText, colorHex) {
            /**
             <w:r>
                <w:rPr> <!-- contains data if colorHex is set>
                <w:t xml:space="preserve">annotationText</w:t>
             </w:r>
             */
            var textRun = document.createElementNS(rawXmlNamespace, 'w:r');
            var textRunProperties = document.createElementNS(rawXmlNamespace, 'w:rPr');
            var text = document.createElementNS(rawXmlNamespace, 'w:t');
            text.textContent = annotationText;
            text.setAttribute('xml:space', 'preserve');

            if(colorHex) {
                var highlight = document.createElementNS(rawXmlNamespace, 'w:highlight');
                highlight.setAttribute('w:val', colorHex);
                textRunProperties.appendChild(highlight);
            }

            textRun.appendChild(textRunProperties);
            textRun.appendChild(text);

            return textRun;
        };
    }

    return AnnotationsToDOCX;
});

