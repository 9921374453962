/* globals define, location, navigator, console, window, document, localStorage, alert, require */

/**
 *
 * @module webapp
 * @namespace  Models
 * @class  User
 */

define(
    'models/User',[
        'jquery',
        'backbone',
        'underscore',
        'collections/Userbooks',
        'models/Userbook',
        'views/DialogView'
    ],
    function (
        $,
        Backbone,
        _,
        Userbooks,
        Userbook,
        DialogView
    ) {

        return Backbone.Model.extend({

            defaults: {
                activeUserbook: null,
                isLoaded: false,
                dirty: false,
                sortOption: 'subjectArea',
                anonymous: null,
                ordbokHash: null,
                termsAccepted: false,
                keyboardShortcuts: true,
            },

            /**
             * Sets up events for when userbooks change
             * @method  initialize
             * @param  {object} data
             * @param  {object} options contains appModel
             */
            initialize: function (data, options) {
                var me = this;
                this.set('userbooks', new Userbooks());

                this._app = options.app;

                this._waitingForInitialData = {};

                this.on('change:sortOption change:termsAccepted change:language change:ordbokHash change:keyboardShortcuts', this.onDataChanged, this);
                this.on('change:activeUserbook', this.onActiveUserbookChanged, this);
                this.get('userbooks').on('add remove change', this.onDataChanged, this);
            },

            /**
             * Gets userbook from userbooks-collection
             * @method  getUserbookFromShelf
             * @param  {object} bookResource
             * @return {object} userbook
             */
            getUserbookFromShelf: function (bookResource) {
                return this.get('userbooks').where({ bookId: '' + bookResource.get('bookId') })[0] || null;
            },

            /**
             * getter for publisherTermsAccepted
             * @method  getPublisherTerms
             * @return {boolean}
             */
            getPublisherTerms: function () {
                return this.get('publisherTermsAccepted');
            },

            setTermsAccepted: function (value) {
                this.set('termsAccepted', value);
            },

            getTermsAccepted: function () {
                return this.get('termsAccepted');
            },

            /**
             * [setMalform description]
             * @method  setMalform
             * @param {string} language [description]
             */
            setMalform: function (language) {
                this.set('language', language);
            },

            /**
             * @method  getMalform
             */
            getMalform: function () {
                return this.get('language');
            },

            /**
             * @method  setOrdbokHash
             */
            setOrdbokHash: function (hash) {
                return this.set('ordbokHash', hash);
            },

            /**
             * @method  getOrdbokHash
             */
            getOrdbokHash: function () {
                return this.get('ordbokHash');
            },

            setKeyboardShortcuts: function (toggleValue) {
                this.set('keyboardShortcuts', toggleValue);
            },


            /**
             * [setSortOption description]
             * @method  setSortOption
             */
            setSortOption: function (sortOption) {
                this.set('sortOption', sortOption);
            },

            /**
             * [getSortOption description]
             * @method  getSortOption
             * @return {string} [description]
             */
            getSortOption: function () {
                return this.get('sortOption');
            },

            /**
             * Called from UserSyncer.js when remote settings-data is received
             * @method  onRemoteChange_settings
             * @param  {object} incomingData User data from unibok
             */
            onRemoteChange_settings: function (incomingData) {
                this.set({
                    language: incomingData.language,
                    // store this to alert user if terms not accepted
                    publisherTermsAccepted: incomingData.publisherTermsAccepted,
                    termsAccepted: incomingData.termsAccepted,
                    organizationName: incomingData.organizationName,
                    name: incomingData.name,
                    ordbokHash: incomingData.ordbokHash,
                    keyboardShortcuts: incomingData.keyboardShortcuts,
                });

                if (incomingData.sortOption) {
                    this.set('sortOption', incomingData.sortOption);
                }

                if (!this._waitingForInitialData.settingsData) {
                    this._waitingForInitialData.settingsData = true;
                    if (Object.keys(this._waitingForInitialData).length === 2) {
                        this.set('isLoaded', true);
                    }
                }
            },

            onActiveUserbookChanged: function (user, userbook) {
                if (this.previous('activeUserbook')) {
                    this.previous('activeUserbook').unload();
                    this.previous('activeUserbook').getBookResource().off(null, null, this);
                }
            },

            /**
             * Called from UserSyncer.js when bookshelf-data is received
             * @method onRemoteChange_bookshelf
             * @param  {object} bookshelfdata
             */
            onRemoteChange_bookshelf: function (incomingData) {
                var me = this;
                var userbooks = this.get('userbooks');
                var _userbooks = [];

                /* var _incomingUserbooksMap = {}; // Used handling books that stops appearing in bookshelf for whatever reason */

                function _prettyPrint(subject) {
                    this.subject = subject ? subject.toLowerCase() : 'annet';
                    return this.subject.charAt(0).toUpperCase() + this.subject.slice(1);
                }

                // loop through the bookshelf from server and repopulate the userbooks collection
                incomingData.forEach(function (bookInfo) {
                    // TODO: better error handeling here?
                    var preppedSubject = _prettyPrint(bookInfo.titleInfo.subject[0]);
                    var thumbnail;
                    thumbnail = ((bookInfo.thumbnail || bookInfo.titleInfo.thumbnail) ?
                        me._app.get('backendServerUrl') + '/api/v1/thumbnail/publisher/' + encodeURIComponent(bookInfo.publisherid) +
                        '/publication/' + encodeURIComponent(bookInfo.productid) + '/small' : null);
                    var bookResource = me._app.getBookResource({
                        epubid: bookInfo.epubid ? '' + bookInfo.epubid : undefined,
                        bookId: '' + bookInfo.productid, // TODO: skal være en unik id
                        productId: '' + bookInfo.productid,
                        publisherRef: bookInfo.publisherid,
                        titleid: bookInfo.titleid,
                        title: bookInfo.title,
                        thumbnail: thumbnail,
                        subject: preppedSubject,
                        subjectArea: bookInfo.titleInfo.subjectArea,
                        level: bookInfo.titleInfo.level,
                        ubFileUrl: bookInfo.ubFileUrl,
                        language: bookInfo.languagecode,
                        singlebook: bookInfo.singlebook,
                        ordboklang: bookInfo.ordboklang,
                        latestUbFileHash: bookInfo.ubFileHash,
                        offlineUbFileSize: bookInfo.offlineUbFileSize
                    }, true);

                    var userbook = userbooks.where({ bookId: '' + bookResource.get('bookId') })[0] || null;
                    if (!userbook) {
                        userbook = new Userbook({}, { user: me, bookResource: bookResource });
                    }

                    _userbooks.push(userbook);

                });

                userbooks.set(_userbooks);

                if (!this._waitingForInitialData.bookshelfData) {
                    this._waitingForInitialData.bookshelfData = true;
                    if (Object.keys(this._waitingForInitialData).length === 2) {
                        this.set('isLoaded', true);
                    }
                }
            },

            /**
             * Called from UserSyncer.js the first time the User-object receives data
             * @method  setInitialBookshelfData
             * @param {object} userbooksData
             */
            setInitialBookshelfData: function (userbooksData) {
                var me = this;
                var userbooks = userbooksData.map(function (userbookData) {
                    var bookResource = me._app.getBookResource({
                        bookId: userbookData.bookId,
                        publisherRef: userbookData.publisherRef
                    });
                    if (!bookResource) {
                        console.log("Could not find bookresource with bookId: " + userbookData.bookId + " and publisherRef: " + userbookData.publisherRef + ". Bookresources: in localStorage:" + localStorage.getItem('bookResources') + " userbooks: " + JSON.stringify(userbooksData));
                    }
                    if (!bookResource && window.Bugsnag) {
                        // trying to figure out EB-1838
                        var bookResourcesLocalStorage = localStorage.getItem('bookResources');
                        if (!bookResourcesLocalStorage) {
                            window.Bugsnag.notify(new Error("userbooks: " + JSON.stringify(userbooksData)), function (event) {
                                event.errors[0].errorClass = 'EB-1838- no bookresource data in localstorage';
                                event.severity = 'warning';
                            });
                            if (navigator.storage && navigator.storage.persisted) {
                                navigator.storage.persisted()
                                    .then(function (persistent) {
                                        window.Bugsnag.notify(new Error("Persistence is : " + persistent), function (event) {
                                            event.errors[0].errorClass = 'EB-1838- no localstorage data: persistence check';
                                            event.severity = 'warning';
                                        });
                                    });
                            }
                        } else {
                            var errMsg = "Could not find bookresource with bookId: " + userbookData.bookId + " and publisherRef: " + userbookData.publisherRef + ". Bookresources: in localStorage:" + bookResourcesLocalStorage + " userbooks: " + JSON.stringify(userbooksData);
                            window.Bugsnag.notify(new Error(errMsg), function (event) {
                                event.errors[0].errorClass = 'Initial userbook/bookresource data exception EB-1838';
                                event.severity = 'warning';
                            });
                        }
                    }


                    return new Userbook({ noLicence: userbookData.noLicence }, { user: me, bookResource: bookResource });
                });
                this.get('userbooks').set(userbooks);

                if (!this._waitingForInitialData.bookshelfData) {
                    this._waitingForInitialData.bookshelfData = true;
                    if (Object.keys(this._waitingForInitialData).length === 2) {
                        this.set('isLoaded', true);
                    }
                }
            },

            /**
             * Load the user-model with data.
             *
             * This is a slightly convoluted operation. This model has no awareness of how to get data. But we kick the associated
             * UserSyncer instance into action by triggering 'requestInitialData'. The UserSyncer will
             * then fetch remote or local data and give it to 'onRemoteChange_settings' and 'onRemoteChange_bookshelf' or 'setInitialBookshelfData'.
             *
             * When the required data has been received, 'isLoaded' is set to true, and this will be detected by the this.once('change:isLoaded')
             * and allow the callback to be executed.
             *
             * @method  load
             * @param {function} callback function
             */
            load: function (callback) {
                if (this.get('isLoaded')) {
                    callback();
                } else {
                    this.once('change:isLoaded', function () {
                        callback();
                    }, this);
                    this.trigger('requestInitialData');
                }
            },

            /**
             * set isloaded to false and _waitingForInitialData to empty
             * @method unload
             * @param {function} callback [description]
             * @return {string} value of this.logout???
             *
             */
            unload: function (callback) {
                this.set('isLoaded', false);
                this._waitingForInitialData = {};
                return this.logout(callback);
            },

            /**
             * If webapp, logout redirects to /logout. If mobile app, it validates if user is allowed to logout at the moment and if so removes downloaded books and clears localStorage
             * @method logout
             */
            logout: function (callback) {
                var me = this;
                if (this._app.get('mobileApp')) {
                    // if we are offline, we cannot logout
                    if (!me._app.get('isOnline')) {
                        $(document.activeElement).attr({ 'data-dialog-spawned': 1 });
                        new DialogView({
                            "title": "Utlogging uten nettilgang",
                            "message": "Du har forsøkt å logge deg ut av Unibok. For å logge ut av Unibok må du være tilkoblet internett.",
                            "isAlert": true,
                            "appendTo": 'body'
                        });
                        return false;
                    }

                    // if a bookresource is unpacking, we must wait until it is finished
                    // because the zip-plugin currently does not have abort functionality
                    if (me.get('userbooks').where({ cloudStatus: 'isUnpacking' }).length) {
                        $(document.activeElement).attr({ 'data-dialog-spawned': 1 });
                        new DialogView({
                            "title": "Kan ikke logge ut nå",
                            "message": "Du har én eller flere bøker som er i ferd med å pakke ut. Vent til alle bøkene er pakket ut og forsøk å logge ut på nytt.",
                            "isAlert": true,
                            "appendTo": 'body'
                        });
                        return false;
                    }

                    $('body').addClass('blocking');
                    me.get('userbooks').where({ cloudStatus: 'isDownloading' }).forEach(function (userbook) {
                        userbook.getBookResource().abortDownload();
                    });
                    $.ajax({
                        url: me._app.get('backendServerUrl') + '/logout',
                        success: function () {
                            me.get('userbooks').forEach(function (userbook) {
                                var bookResource = userbook.getBookResource();
                                if (bookResource.get('isDownloaded')) {
                                    bookResource.remove();
                                }
                            });
                            me._app.set('activeUser', null);
                            localStorage.clear();
                            $('body').removeClass('blocking');
                        },
                        error: function (xhr, status, errorThrown) {
                            // TODO
                            alert('Noe gikk galt under utlogging, har du nettilgang?');
                            $('body').removeClass('blocking');
                            //me._app.set('activeUser', null);
                        }
                    });

                    return false;
                } else {
                    if (this._app.get('isOnline')) {
                        localStorage.clear();
                        window.location = "/logout";
                    }
                    else {
                        $(document.activeElement).attr({ 'data-dialog-spawned': 1 });
                        new DialogView({
                            "title": "Utlogging uten nettilgang",
                            "message": "Du har forsøkt å logge deg ut av Unibok. For å logge ut av Unibok må du være tilkoblet internett.",
                            "isAlert": true,
                            "appendTo": 'body'
                        });
                        return false;
                    }
                }
            },

            /**
             * Sets attribute "dirty" to true
             * @method onDataChanged
             */
            onDataChanged: function () {
                this.set('dirty', true);
            }
        });
    });

