define('templates/errorInfoView',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (envInfo, stackTrace) {
buf.push("<div id=\"errorInfo\" hidden=\"hidden\"><div class=\"envInfo\">" + (jade.escape(null == (jade_interp = envInfo) ? "" : jade_interp)) + "</div><textarea readonly=\"readonly\" class=\"stackTrace\">" + (jade.escape(null == (jade_interp = stackTrace) ? "" : jade_interp)) + "</textarea><br/><button id=\"copyToClipboardBtn\">Kopier</button></div>");}.call(this,"envInfo" in locals_for_with?locals_for_with.envInfo:typeof envInfo!=="undefined"?envInfo:undefined,"stackTrace" in locals_for_with?locals_for_with.stackTrace:typeof stackTrace!=="undefined"?stackTrace:undefined));;return buf.join("");
}

});
