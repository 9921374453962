/*global console, document, navigator, screen, window, device*/
/**
 * @module webapp
 * @class  PlausibleManager
 */
define('PlausibleManager',['jquery', 'Config', 'URIjs'], function ($, config, URI) {

  var DOMAINS = config.get('plausible');

  var PlausibleManager = function (app) {
    this._app = app;
    this._app.on('change:isOnline', this._onOnlineStatusChanged, this);
  };


  PlausibleManager.prototype = {
    sendPageView: function (publisher, page) {
      // wrapped in try..catch so this code never will crash the application.
      try {
        this._sendHit('pageview', DOMAINS[publisher], {
          uri: page,
          time: (new Date()).toLocaleString(),
          screen: screen.width + 'x' + screen.height,
          viewport: window.innerWidth + 'x' + window.innerHeight,
          screen_vs_viewport: screen.width + 'x' + screen.height + ' vs ' + window.innerWidth + 'x' + window.innerHeight
        });
      }
      catch (e) {
        if (window.Bugsnag) {
          window.Bugsnag.notify(new Error(e));
        }
        else {
          console.log("Error while sending page statistics to Plausible.");
        }
      }
    },

    sendEvent: function (publisher, hitSource, hitType, bookTitle) {
      // wrapped in try..catch so this code never will crash the application.
      try {
        this._sendHit(hitType, DOMAINS[publisher], {
          source: hitSource,
          title: bookTitle,
          time: (new Date()).toLocaleString()
        });
      }
      catch (e) {
        if (window.Bugsnag) {
          window.Bugsnag.notify(new Error(e));
        }
        else {
          console.log("Error while sending event statistics to Plausible.");
        }
      }
    },

    sendPageViewToAllTrackers: function (page, options = {}) {
      options.uri = page;
      options.screen = screen.width + 'x' + screen.height;
      options.viewport = window.innerWidth + 'x' + window.innerHeight;
      options.screen_vs_viewport = screen.width + 'x' + screen.height + ' vs ' + window.innerWidth + 'x' + window.innerHeight;
      Object.values(DOMAINS).forEach(domain => {
        this._sendHit('pageview', domain, options);
      });
    },

    sendPageHitToAllTrackers: function (page, options = {}) {
      Object.values(DOMAINS).forEach(domain => {
        this._sendHit(page, domain, options);
      });
    },

    _sendHit: function (hitType, domain, options) {
      var url = 'https://plausible.io/api/event';

      var uri = window.location.protocol + "//" + domain + "/" + window.location.hash.substring(1);
      var data = {
        n: hitType,
        u: uri,
        d: domain,
        p: options
      };

      $.ajax({
        url: url.toString(),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        method: "POST",
        error: function (xhr, textStatus, errorThrown) {
          console.log("Couldn't send pageview to Plausible " + textStatus);
        }
      });
    },
  };

  return PlausibleManager;
});

