define('templates/loginView',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div id=\"login\" hidden=\"hidden\"><div class=\"page-head\"><div class=\"container\"><img src=\"images/login/sitename_x2.png\" alt=\"Unibok - Digitale lærebøker\"/></div></div><div class=\"page-main\"><div class=\"container\"><h1>Velkommen til Unibok</h1><p><span>Klikk på FEIDE for å logge inn.</span></p><p><span>Har du opprettet en privatbruker og kjøpt bøker direkte fra Aschehoug eller Cappelen Damm, logger du inn ved å klikke på forlagets navn.</span></p><p><a href=\"#\" data-vendor=\"Feide\" class=\"btn login feide\"><img src=\"images/feide_login_white.svg\" alt=\"Feide\" class=\"feideLogo\"/>FEIDE</a></p><p><a href=\"#\" data-vendor=\"Aco\" class=\"btn login\">Aschehoug</a><a href=\"#\" data-vendor=\"Cdu\" class=\"btn login\">Cappelen Damm</a></p></div></div></div>");;return buf.join("");
}

});
