define('templates/dialogTemplate',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (btnText, isAlert, isConfirm, message, title) {
buf.push("<div role=\"dialog\" aria-labelledby=\"dialog-title\" aria-describedby=\"dialog-intro\" class=\"genericDialog\"><div class=\"modal-content\"><div class=\"modal-header\"><h3 id=\"dialog-title\" class=\"modal-title\">" + (jade.escape((jade_interp = title) == null ? '' : jade_interp)) + "</h3><button title=\"Lukk dialog\" class=\"close-btn\"></button></div><div class=\"modal-body\"><p id=\"dialog-intro\">" + (((jade_interp = message) == null ? '' : jade_interp)) + "</p></div><div class=\"modal-footer\">");
if ( isAlert)
{
if ( btnText)
{
buf.push("<button class=\"btn btn-primary ok\">" + (((jade_interp = btnText) == null ? '' : jade_interp)) + "</button>");
}
else
{
buf.push("<button class=\"btn btn-primary ok\">OK</button>");
}
}
else if ( isConfirm)
{
buf.push("<button class=\"btn btn-primary confirm\">Ja</button><button class=\"btn btn-primary cancel\">Nei</button>");
}
buf.push("</div></div></div>");}.call(this,"btnText" in locals_for_with?locals_for_with.btnText:typeof btnText!=="undefined"?btnText:undefined,"isAlert" in locals_for_with?locals_for_with.isAlert:typeof isAlert!=="undefined"?isAlert:undefined,"isConfirm" in locals_for_with?locals_for_with.isConfirm:typeof isConfirm!=="undefined"?isConfirm:undefined,"message" in locals_for_with?locals_for_with.message:typeof message!=="undefined"?message:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}

});
