/* global window, navigator */
define('soundmanager',['jquery', 'EventMediaHandler', 'audiojs'], function ($, EventMediaHandler, audiojs) {

    function SoundManager() {
        this._cssClassPlaying = "playing";
        this._player = null;
        this._playingElem = null;
        this._audioWrapper = null;
        this._progressEventHandler = null;
        this._app = null;
    }

    SoundManager.prototype = {
        initialize: function (app) {
            var me = this;
            this._app = app;
            var audioElem = $('<audio class="synthetic_audiojs"></audio>');
            $('#audioPlayer').append(audioElem);

            var speedControls = $('<div class="speed-controls"></div>');
            var speedDownBtn = $('<button class="speed-down" title="Senk hastighet">-</button>');
            speedDownBtn.click(function() { me.speedDown(); });
            speedControls.append(speedDownBtn);
            speedControls.append('<div class="speed-indicator" title="valgt hastighet">' + '1.0x' + '</button>');
            var speedUpBtn = $('<button class="speed-up" title="Øk hastighet">+</button>');
            speedUpBtn.click(function() { me.speedUp(); });
            speedControls.append(speedUpBtn);
            $('#audioPlayer').append(speedControls);


            this._eventMediaHandler = new EventMediaHandler(app, 'Lyd');

            audiojs.events.ready(function() {
                me._player = audiojs.create(audioElem);

                var player = me._player[0];
                var orgUpdatePlayhead = $.proxy(player.settings.updatePlayhead, player);

                player.element.onplay = function() {
                    me._eventMediaHandler.start();
                };

                player.element.onpause = function() {
                    me._eventMediaHandler.pause();
                };

                player.settings.updatePlayhead = function(percent) {
                    me._eventMediaHandler.checkAndSend(percent);
                    orgUpdatePlayhead(percent);
                };
            });
        },

        onNew: function(observerCallback) {
            if (!this.onNewObservers) {
                this.onNewObservers = [];
            }

            this.onNewObservers.push(observerCallback);
        },

        emitOnNew: function(newUrl) {
            if (!this.onNewObservers) {
                return;
            }

            for (var i = 0; i < this.onNewObservers.length; i++) {
                this.onNewObservers[i](newUrl);
            }
        },

        getPlayer: function() {
            return this._player[0];
        },

        // dummy function to enable autoplay in ios safarifrom TocPanel
        preLoad: function () {
            if(!this._playingElem) {
                this.getPlayer().load('js/thirdparty/audiojs/dummy.wav');
            }
        },

        keyToggle: function () {
            var player = this.getPlayer();
            player.playPause();
        },

        speedUp: function () {
            var player = this.getPlayer();
            if (player.element.playbackRate >= 2.0) { return; }
            player.element.playbackRate = player.element.playbackRate + 0.25;
            $('.speed-indicator').text(player.element.playbackRate.toFixed(2) + 'x');
        },

        speedDown: function () {
            var player = this.getPlayer();
            if (player.element.playbackRate <= 0.25) { return; }
            player.element.playbackRate = player.element.playbackRate - 0.25;
            $('.speed-indicator').text(player.element.playbackRate.toFixed(2) + 'x');
        },

        skipForward: function () {
            var player = this.getPlayer();
            player.element.currentTime = player.element.currentTime + 30;
        },

        skipBackward: function () {
            var player = this.getPlayer();
            player.element.currentTime = player.element.currentTime - 30;
        },
        toggle: function (elem, soundurl) {
            var player = this.getPlayer();
            player.element.playbackRate = 1.0;
            $('.speed-indicator').text('1.0x');

            if (elem.is(this._playingElem)) {
                if (player.playing) {
                    this.hideAudioWrapper();
                    elem.removeClass(this._cssClassPlaying);
                    this._eventMediaHandler.pause();
                }
                else {
                    this.showAudioWrapper();
                    elem.addClass(this._cssClassPlaying);
                    this._eventMediaHandler.start();
                }
                player.playPause();
            }
            else {// stop annen + play
                if (this._app.get('activeUser').get('activeUserbook').get('publisherRef') === 'aschehoug') {
                    var token = this._app.get('activeUser').get('activeUserbook').get('jwt');
                    soundurl = soundurl + '?access_token='+token;
                }
                this.emitOnNew(soundurl);

                this.showAudioWrapper();
                if (this._playingElem) {
                    this._playingElem.removeClass(this._cssClassPlaying);
                }
                elem.addClass(this._cssClassPlaying);
                player.load(soundurl);

                player.playPause();

                this._playingElem = elem;
                this._eventMediaHandler.restart();
            }
        },

        play: function() {
            if (this._playingElem) {
                this._playingElem.removeClass(this._cssClassPlaying);
            }
            this._playingElem = null;
            var player = this.getPlayer();
            player.element.playbackRate = 1.0;
            $('.speed-indicator').text('1.0x');
            player.play();

            $('.play-pause').focus();
        },

        loadPlayer: function(soundUrl) {
            if (this._playingElem) {
                this._playingElem.removeClass(this._cssClassPlaying);
            }
            this._playingElem = null;

            this.showAudioWrapper();
            this.getPlayer().load(soundUrl);
        },

        kill: function () {
            if (! this._player) {
                return;
            }
            var player = this._player[0];
            if (this._playingElem) {
                this._playingElem.removeClass(this._cssClassPlaying);
            }
            player.pause();
        },

        getAudioWrapper: function() {
            if (!this._audioWrapper) {
                this._audioWrapper = $('.audioWrapper');
            }

            return this._audioWrapper;
        },

        showAudioWrapper: function() {
            if ($(".hideMenus").length === 0) {
                $("#goBack-btn").animate({'bottom': '60px'}, 500, function() {
                    $(".audiojs > .play-pause").focus();
                });   // menu is shown
            }
            else {
                $("#goBack-btn").animate({'bottom': '79px'}, 500, function() {
                    $(".audiojs > .play-pause").focus();
                });   // menu is hidden
            }
            this.getAudioWrapper().css('display', 'flex');
            this.getAudioWrapper().css({'opacity': '1'});
        },

        hideAudioWrapper: function() {
            if ($(".hideMenus").length === 0) {
                $("#goBack-btn").animate({'bottom': '25px'}, 500, function() {});   // menu is shown
            }
            else {
                $("#goBack-btn").animate({'bottom': '44px'}, 500, function() {});   // menu is hidden
            }

            $(".highlight-button-container").css('bottom', '');
            var me = this;
            this.getAudioWrapper().animate({'opacity': '0'}, 500, function() {
                me.getAudioWrapper().css('display', 'none');
            });
            this.focusSpawnElement();
        },

        close: function() {
            this.kill();
            this.hideAudioWrapper();
        },

        focusSpawnElement: function () {
            var spawnedElement = $('[data-dialog-spawned]').length ? $('[data-dialog-spawned]') : $('iframe').contents().find('[data-dialog-spawned]');
            if (spawnedElement) {
                window.setTimeout(() => {
                    spawnedElement.focus();
                    spawnedElement.removeAttr('data-dialog-spawned');
                }, 100);
            }
        }
    };

    return new SoundManager();
});

