define('templates/annotationItem',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (cfi, colorid, date, id, idref, invalid, markedText, mobileApp, physicalPageNumber, text) {
var cls = invalid ? 'invalid' : '';
buf.push("<div" + (jade.attr("data-colorid", colorid, true, false)) + (jade.attr("data-cfi", cfi, true, false)) + (jade.attr("data-idref", idref, true, false)) + (jade.attr("data-id", id, true, false)) + (jade.cls(['annotation',cls], [null,true])) + "><div class=\"controls\"><button type=\"button\" aria-label=\"Slett markering\" class=\"btn-delete\">");
if ( mobileApp)
{
buf.push("<span class=\"icon\"></span>");
}
else
{
buf.push("<svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_soppel.svg#soppel\" aria-label=\"Slett markering\" class=\"svgicon\"></use></svg>");
}
buf.push("</button><div class=\"highlight-color\"></div>");
if ( invalid)
{
buf.push("<div class=\"btn-invalid\"></div>");
}
buf.push("<div class=\"confirmationDialog\"><div class=\"deleteLabel\">Slette?</div><button class=\"button btn-confirm\">Ja</button><button class=\"button btn-cancel\">Nei</button></div></div><div class=\"content\"><div class=\"meta\"><span class=\"date\">" + (jade.escape(null == (jade_interp = date) ? "" : jade_interp)) + "</span>");
if ( physicalPageNumber)
{
buf.push("<div class=\"physicalPageNumber\">" + (jade.escape(null == (jade_interp = physicalPageNumber) ? "" : jade_interp)) + "</div>");
}
buf.push("</div><a href=\"#\" class=\"data\">" + (((jade_interp = markedText) == null ? '' : jade_interp)) + "</a><div class=\"wrapping\">");
if ( text)
{
buf.push("<span class=\"comment\">" + (((jade_interp = text) == null ? '' : jade_interp)) + "</span><button tabindex=\"0\" aria-label=\"Rediger notat\" role=\"button\" class=\"edit-comment\">");
if ( mobileApp)
{
buf.push("<span class=\"icon\"></span>");
}
else
{
buf.push("<svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_blyant.svg#blyant\"></use></svg>");
}
buf.push("</button>");
}
else
{
buf.push("<button type=\"button\" class=\"write-comment\">Skriv et notat</button>");
}
buf.push("<label" + (jade.attr("for", 'active-note-edit'+id, true, false)) + " class=\"note-edit-label\">Notat</label><input type=\"text\"" + (jade.attr("id", 'active-note-edit'+id, true, false)) + (jade.attr("value", ''+text, true, false)) + " class=\"edit-label\"/><button aria-label=\"lagre notat\" role=\"button\" class=\"confirm-edit\">");
if ( mobileApp)
{
buf.push("<span class=\"icon\"></span>");
}
else
{
buf.push("<svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_confirm.svg#confirm\"></use></svg>");
}
buf.push("</button></div></div></div>");}.call(this,"cfi" in locals_for_with?locals_for_with.cfi:typeof cfi!=="undefined"?cfi:undefined,"colorid" in locals_for_with?locals_for_with.colorid:typeof colorid!=="undefined"?colorid:undefined,"date" in locals_for_with?locals_for_with.date:typeof date!=="undefined"?date:undefined,"id" in locals_for_with?locals_for_with.id:typeof id!=="undefined"?id:undefined,"idref" in locals_for_with?locals_for_with.idref:typeof idref!=="undefined"?idref:undefined,"invalid" in locals_for_with?locals_for_with.invalid:typeof invalid!=="undefined"?invalid:undefined,"markedText" in locals_for_with?locals_for_with.markedText:typeof markedText!=="undefined"?markedText:undefined,"mobileApp" in locals_for_with?locals_for_with.mobileApp:typeof mobileApp!=="undefined"?mobileApp:undefined,"physicalPageNumber" in locals_for_with?locals_for_with.physicalPageNumber:typeof physicalPageNumber!=="undefined"?physicalPageNumber:undefined,"text" in locals_for_with?locals_for_with.text:typeof text!=="undefined"?text:undefined));;return buf.join("");
}

});
