/* global console */

define('resourcefetchers/UnibokPublicationFetcher',[
    'readium_js/epub-fetch/publication_fetcher',
    'resourcefetchers/UnibokPlainResourceFetcher',
],
function(ReadiumPublicationFetcher, UnibokPlainResourceFetcher) {
    var UnibokPublicationFetcher = function(ebookURL, jsLibRoot, sourceWindow, cacheSizeEvictThreshold, contentDocumentTextPreprocessor, contentType) {
        ReadiumPublicationFetcher.call(this, ebookURL, jsLibRoot, sourceWindow, cacheSizeEvictThreshold, contentDocumentTextPreprocessor, contentType);

        this.createResourceFetcher = function (isExploded, callback) {
            this._resourceFetcher = new UnibokPlainResourceFetcher(this);
            callback(this._resourceFetcher);
        };
    };

    return UnibokPublicationFetcher;
});

