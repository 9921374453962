/* globals URL, window, File, Image, navigator */

/**
 * Exports a printable section in a userbook to a pdf document
 * Uses the jsPDF library, http://jspdf.com
 * @module webapp
 * @namespace  Views
 * @class  SectionsToPdf
 */
define('SectionsToPDF',['jquery', 'bowser', 'jsPDF', 'html2canvas'], function($, bowser, jsPDF, html2canvas) {
    /**
     * amd module
     * @param  {object} userbook [description]
     * @method  annotationsToPDF
     * @return {[type]}          [description]
     */
    function SectionsToPDF()  {
        this.doc = new jsPDF('p', 'pt', 'a4');

        /**
         * The main method gets the annotation from the userbook and sends them to the method printChapter
         *
         * @method  generatePDF
         * @return {[type]} [description]
         */
        this.generatePDFfromDialog = function(callback) {
            var me = this;
            me.offsetY = 20;
            var printCont = $('.printDialog').find('.clonedContent');
            var textArea = $('.printDialog').find('.userComment').val();
            var imageCont = $('.printDialog').find('.imageContainer');

            var contWidth = printCont[0].scrollWidth;
            var contHeight = printCont[0].scrollHeight;
            var imgContWidth = imageCont[0].scrollWidth;
            var imgContHeight =  imageCont[0].scrollHeight;

            var contOptions = {
                width: contWidth*1.6, //tweaked to fit a4
                height: contHeight*1.6,
                letterRendering: true,
                allowTaint: true
            };

            var imgOptions = {
                width: imgContWidth*1.5, //tweaked to fit a4
                height: imgContHeight*1.5,
                allowTaint: true
            };

            var file_name = $('h3').text() + " - oppskrift.pdf";

            printCont.scrollTop(0);
            $('.printDialog').scrollTop(0);
            html2canvas(printCont, contOptions).then(function (canvas) {
                //$('.printDialog').append(canvas);
                var canvasData = canvas.toDataURL('image/png');
                me.doc.addImage(canvasData, 'PNG', 20, me.offsetY, contWidth, contHeight);

                if (contHeight > 1000) {
                    me.doc.addPage();
                } else {
                    me.offsetY = me.offsetY + (contHeight / 1.6) + 20;
                }

                if (textArea) {
                    me.doc.line(20, me.offsetY, 560, me.offsetY);
                    me.doc.setFontSize(14);
                    var textLines = me.doc.splitTextToSize(textArea, 520);

                    textLines.forEach(function (textLine) {
                        me.doc.text(textLine, 20, me.offsetY + 20);
                        me.offsetY = me.offsetY + 15; // lineHeight
                    });
                }

                $('.printDialog').scrollTop($('.printDialog').height());
                html2canvas(imageCont, imgOptions, file_name).then(function (canvas) {
                    if (canvas.height) {
                        me.doc.addPage();
                        me.offsetY = 20;
                        var canvasData = canvas.toDataURL('image/png');
                        me.doc.addImage(canvasData, 'PNG', 0, me.offsetY, imgContWidth, imgContHeight);
                        me.offsetY = me.offsetY + (imgContHeight/1.5) + 20;
                    }

                    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
                        me.doc.output('dataurl');
                    } else if (bowser.name === "Safari") {
                        window.open(me.doc.output('dataurl'), '_self');
                    }
                    else {
                        me.doc.save(file_name);
                    }
                    callback();
                });
            });
        };
    }

    return SectionsToPDF;
});
