/**
 * Singleton Config class, the singleton reads from localStorage if
 * available. Config.init() should be called from App to setup
 * update functionality.
 *
 * Possible to use directly, e.g.:
 *     define(['Config'], function(Config) {
 *         var lingitApiCode = Config.get('lingit_code');
 *     });
 *
 * @module webapp
 * @class  Config
 */
define('Config',['ClientConfig'], function (ClientConfig) {

    var configSingleton = null;

    var Config = function() {
        this._configParams = ClientConfig.CONFIG;
    };

    // public methods

    Config.prototype.get = function(key) {
        return this._configParams[key];
    };

    if (!configSingleton) {
        configSingleton = new Config();
    }

    return configSingleton;
});

