define('templates/ordbokLightbox',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (source) {
buf.push("<dialog class=\"lightbox\"><div class=\"lightbox-header\"><button title=\"Lukk dialog\" class=\"closeButton\"></button></div><div class=\"content\"><iframe" + (jade.attr("src", source, true, false)) + " style=\"width:1px;height:1px;min-width:100%;min-height:100%;\" scrolling=\"no\"></iframe></div></dialog>");}.call(this,"source" in locals_for_with?locals_for_with.source:typeof source!=="undefined"?source:undefined));;return buf.join("");
}

});
