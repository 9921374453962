define('templates/tocPanel',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (mobileApp) {
buf.push("<div class=\"panel tocPanel\"><div class=\"head\"><div role=\"tablist\" aria-label=\"Type innhold\" class=\"tab-header\">");
if ( mobileApp)
{
buf.push("<button id=\"toc\" role=\"tab\" aria-controls=\"tab-toc\" title=\"Innholdsfortegnelse\" class=\"resource-tab toc-tab\"><span class=\"icon\"></span></button><button id=\"video\" role=\"tab\" aria-controls=\"tab-video\" title=\"Video\" class=\"resource-tab video-tab\"><span class=\"icon\"></span></button><button id=\"gallery\" role=\"tab\" aria-controls=\"tab-bildegallerier\" title=\"Bildegalleri\" class=\"resource-tab gallery-tab\"><span class=\"icon\"></span></button><button id=\"audio\" role=\"tab\" aria-controls=\"tab-lyder\" title=\"Lyd\" class=\"resource-tab audio-tab\"><span class=\"icon\"></span></button><button id=\"external\" role=\"tab\" aria-controls=\"tab-eksterntinnhold\" title=\"Ekstern Ressurs\" class=\"resource-tab external-tab\"><span class=\"icon\"></span></button>");
}
else
{
buf.push("<button id=\"toc\" role=\"tab\" aria-controls=\"tab-toc\" title=\"Innholdsfortegnelse\" class=\"resource-tab toc-tab\"><svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_toc.svg#toc\"></use></svg></button><button id=\"video\" role=\"tab\" aria-controls=\"tab-video\" title=\"Video\" class=\"resource-tab video-tab\"><svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_video.svg#video\"></use></svg></button><button id=\"gallery\" role=\"tab\" aria-controls=\"tab-bildegallerier\" title=\"Bildegalleri\" class=\"resource-tab gallery-tab\"><svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_bilde.svg#bilde\"></use></svg></button><button id=\"audio\" role=\"tab\" aria-controls=\"tab-lyder\" title=\"Lyd\" class=\"resource-tab audio-tab\"><svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_lyd.svg#lyd\"></use></svg></button><button id=\"external\" role=\"tab\" aria-controls=\"tab-eksterntinnhold\" title=\"Ekstern Ressurs\" class=\"resource-tab external-tab\"><svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_externalcontent.svg#external\"></use></svg></button>");
}
buf.push("</div>");
if ( mobileApp)
{
buf.push("<button aria-label=\"Lukk innholdsfortegnelse\" class=\"close-icon\"><span class=\"icon\"></span></button>");
}
else
{
buf.push("<button aria-label=\"Lukk innholdsfortegnelse\" class=\"close-icon\"><svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_lukk.svg#lukk\"></use></svg></button>");
}
buf.push("</div><div class=\"gotoPageCont\"><div class=\"gotopage\"><label for=\"sidetall\" class=\"gotoPage-label\">Gå til side</label><input id=\"sidetall\"/><span>av</span><span class=\"pageCount\">315</span></div><button role=\"button\" class=\"btn-gotoPage\">Gå</button><div id=\"nonExistingPageNumPopup\" hidden=\"hidden\"><span class=\"nonExistingPageNumPopupText\">Sidetallet du har søkt på eksisterer ikke.</span><span class=\"funnel\"><span class=\"funnelDiamond\"></span></span></div></div><div id=\"tab-content\" class=\"content scrollableContent\"></div></div>");}.call(this,"mobileApp" in locals_for_with?locals_for_with.mobileApp:typeof mobileApp!=="undefined"?mobileApp:undefined));;return buf.join("");
}

});
