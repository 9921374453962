/*global URI, define, window, alert, console, LocalFileSystem, FileReader, FileTransfer, cordova, require, Blob, Uint8Array */

/**
 * This class handles downloading to device and fetching downloaded content on cordova apps
 *
 * @module webapp
 * @namespace  ResourceManagers
 * @class  LocalFileResourceFetcher
 */
define('resourcefetchers/LocalFileResourceFetcher',['resourcefetchers/ResourceFetcherBase', 'jquery', 'async', 'cordova-zip', 'blobifier', 'views/DialogView'], function(ResourceFetcherBase, $, asyn, cordova_zip, blobifier, DialogView) {

    var LocalFileResourceFetcher = function(bookResource) {
        ResourceFetcherBase.call(this, bookResource);
        this.fetchSpineQueue = asyn.queue(function (task, callback) {
            console.log("fetchSpineQueue() adding task", task.filepath);
            readTextFile(task.filepath, null, function(err, result) {
                console.log("fetchSpineQueue() finished task", task.filepath);
                if (err) {
                    task.deferred.reject("Could not get file:" + task.filepath + "  :" + JSON.stringify(err));
                }
                task.deferred.resolve(result);
                callback();
            });
        }, 5 /* MAX 5 filer samtidig */);
        this.fetchSpineQueue.drain = function () {
            console.log("fetchSpineQueue(): All items processed");
        };
    };

    LocalFileResourceFetcher.prototype = Object.create(ResourceFetcherBase.prototype);

    LocalFileResourceFetcher.prototype.getIframeContentCssUrl = function(callback) {
       var me = this;
        ResourceFetcherBase.prototype.getIframeContentCssUrl.call(this, function(cssUrl) {
                console.log("getIframeContentCssUrl(", cssUrl, ")");
                var options = {};
                options.fetcher = function(url) {
                  url = cordova.file.applicationDirectory + "www" + url;
                  return me.fetchFileContentsText(url);
                };
                blobifier.generateCssBlobUrl(cssUrl, options)
                .then(function(iframeContentCssBlobUrl) {
                    callback(iframeContentCssBlobUrl);
                })
                .fail(function(err) {
                    console.error(err);
                    callback(cssUrl);
                    return;
                });
        });
    };

    LocalFileResourceFetcher.prototype.getBookinfo = function() {
        var me = this;
        var deferred = $.Deferred();
        var filepath = getRootDirectory() + me._bookResource.get('publisherRef') + "/" +
            me._bookResource.get('bookId') + "/epub/" + me._bookResource.get('downloadedEpubid') + "/ravn-bookinfo.json";
        readTextFile(filepath, null, function(err, text) {
            if (err) {
                me._bookResource.remove(function() {
                    me._bookResource.set('isDownloaded', false);
                    alert('Noe har gått galt med den frakoblede versjonen av denne boka. Du må gjøre den tilgjengelig frakoblet på nytt');
                    console.log("Could not get book-info" + JSON.stringify(err));
                    me._bookResource._app.get('activeUser').set('activeUserbook', null);
                    deferred.reject(err);
                });
                return false;
            }
            try {
                var bookinfo = JSON.parse(text);
                deferred.resolve(bookinfo);
            } catch (e) {
                console.log("Could not parse JSON in book-info");
                deferred.reject(err);
            }
        });
        return deferred.promise();
    };

    LocalFileResourceFetcher.prototype.getBaseUrl = function() {
        return getRootDirectory() + this._bookResource.get('publisherRef') + "/" + this._bookResource.get('bookId') + '/epub/' + this._bookResource.get('downloadedEpubid') + '/' + this._bookResource._opfPath;
    };

    LocalFileResourceFetcher.prototype.getRootUrl = function() {
        return getRootDirectory() + this._bookResource.get('publisherRef') + "/" + this._bookResource.get('bookId') + '/epub/' + this._bookResource.get('downloadedEpubid');
    };

    LocalFileResourceFetcher.prototype.fetchSpineItem = function(href) {
        var me = this;
        var deferred = $.Deferred();
        var filepath = this.getBaseUrl() + href;
        this.fetchSpineQueue.push({filepath: filepath, deferred: deferred});
        return deferred.promise();
    };

    LocalFileResourceFetcher.prototype.fetchFileContentsText = function(fileUrl) {
        // NOTE: her kan vi hente fra localForage, og eventuelt maipulere
        var deferred = $.Deferred();
        if (/META-INF\/com.apple.ibooks.display-options.xml$/.test(fileUrl)) {
            return deferred.reject('Not Found');
        }
        if (/META-INF\/encryption.xml$/.test(fileUrl)) {
            return deferred.reject('Not Found');
        }

        if (fileUrl.endsWith(".ttf") || fileUrl.endsWith(".woff") || fileUrl.endsWith(".otf")) {
            readBinaryFile(fileUrl, null, function(err, result) {
                if (err) {
                    deferred.reject(err);
                    console.error('Error when reading from file: ' + fileUrl + ':' + JSON.stringify(err));
                }
                deferred.resolve(result);
            });
        } else {
            readTextFile(fileUrl, null, function(err, result) {
                if (err) {
                    deferred.reject('Error when reading from file: ' + fileUrl + ':' + JSON.stringify(err));
                    console.error('Error when reading from file: ' + fileUrl + ':' + JSON.stringify(err));
                }
                deferred.resolve(result);
            });
        }
        
        return deferred;
    };

    LocalFileResourceFetcher.prototype.getAssetUrl = function(originalUrlStr, mainCallback)  {
        console.log("getAssetUrl", originalUrlStr);
        var me = this;
        var originalUrl = new URI(originalUrlStr);
        var idref;
        if (this._bookResource) {
            idref = this._bookResource._spine._idrefMap[originalUrl.path().replace(me.getBaseUrl().replace("file://", ""), "")];
            console.log("getAssetUrl(): got idref:", idref, originalUrl.path());
        } else {
            console.log("getAssetUrl(): Bookresource undefined");
        }
        var useBlob = true;
        var options = {
          bloburlKey: originalUrlStr,
          fetcher: function(url) {
              var deferred = $.Deferred();
              if (["xhtml"].indexOf(new URI(url).suffix()) > -1) {
                  console.log("url === idref", url);
                  me._bookResource._spine.getSpineItemHtmlByHref(originalUrl.path().replace(me.getBaseUrl().replace("file://", ""), ""))
                      .then(function(htmlStr){
                          var loc = 'file://';
                          var baseUrl = originalUrl.directory() + "/";

                          htmlStr = htmlStr.replace('<head>', '<head>' + '<base href="' + loc + baseUrl + '"/>');

                          /*
                          if (!browser.safari) {
                              htmlStr = htmlStr.replace(/(<object .*?\b)(type)(=.*?>)/g, function (match, p1, p2, p3) { // See EB-1200
                                  return p1 + 'typo' + p3;
                              });
                          }
                          */

                          deferred.resolve(htmlStr);
                      });

              } else if (["css", "xml"].indexOf(new URI(url).suffix()) > -1) {
                  console.log("Got CSS or XML", url);
                  console.log("Fetching file from file system", url);
                  var errHandlerCss = function (err) {
                      console.log("Kunne ikke hente fil:", err);
                      deferred.reject(err);
                  };
                  var successCss = function (entry) {
                      entry.file(function (file) {
                          var reader = new FileReader();
                          reader.onloadend = function() {
                              console.log("getAssetUrl(), result = ", this.result);
                              if (!this.result) {
                                  console.log("result is empty");
                              }
                              //var blob = new Blob([new Uint8Array(this.result)], { type: "text/xhtml" });
                              //var bloburl = window.URL.createObjectURL(blob);
                              return deferred.resolve(this.result);
                          };
                          reader.readAsText(file);
                      }, errHandler);
                  };
                  window.resolveLocalFileSystemURL("file://" + url, successCss, errHandlerCss);
              }
              else {
                  console.log("Fetching file from file system", url);
                  var errHandler = function (err) {
                      console.log("Kunne ikke hente fil:", err);
                  };
                  var success = function (entry) {
                      entry.file(function (file) {
                          var reader = new FileReader();
                          reader.onloadend = function() {
                              console.log("getAssetUrl(), result = ", this.result);
                              if (!this.result) {
                                  console.log("result is empty");
                              }
                              //var blob = new Blob([new Uint8Array(this.result)], { type: "text/xhtml" });
                              //var bloburl = window.URL.createObjectURL(blob);
                              //return callback(null, bloburl);
                              return deferred.resolve(this.result);
                          };
                          reader.readAsArrayBuffer(file);
                      }, errHandler);
                  };
                  window.resolveLocalFileSystemURL("file://" + url, success, errHandler);
              }
              return deferred;
          }
        };
        blobifier.generateXhtmlBlobUrl(originalUrl, options)
        .then(function(blobUrl) {
            mainCallback(blobUrl, useBlob);
        })
        .fail(function(err) {
            console.log("getAssetUrl()/generateXhtmlBlobUrl-callback", err);

        });
    };
  LocalFileResourceFetcher.prototype.unpack = function(deferred) {
    var me = this;
    var bookId = me._bookResource.get('bookId'); // 'produkt-101'
    var publisherRef = me._bookResource.get('publisherRef');
        var epubid = me._bookResource.get('epubid'); //Latest epubid
        var localUbFileLocation = getLocalUbFileLocation(bookId);

        checkIfFileExists(
            // Do we have a .ub-file?
            localUbFileLocation,
            function(ubFileEntry) {
                // Create the directory where to unpack
                createPath(
                    'NoCloud/' + publisherRef + "/" + bookId + '/epub/' + epubid,
                    function(err, destEntry) {
                        if (err) {
                            //TODO: proper error handling, communicate to user
                            alert('Kunne ikke pakke ut bok');
                            me._bookResource.set('isUnpacking', false);
                            deferred.reject('Could not unpack book');
                            return false;
                        }

                        // Removing the old offline version
                        if (me._bookResource.get('downloadedEpubid')) {
                            var oldEpubUrl = 'NoCloud/' + me._bookResource.get('publisherRef') + "/" + me._bookResource.get('bookId') + "/epub/" + me._bookResource.get('downloadedEpubid');
                            removeDirectory(oldEpubUrl, function(err) {
                                if (err) {
                                    alert('Kunne ikke fjerne den gamle versjonen av epuben');
                                    deferred.reject('Kunne ikke fjerne den gamle versjonen av epuben');
                                }
                            });
                        }

                        var progressCallback = function(progressEvent) {
                            var percentage = (progressEvent.total ? Math.round((progressEvent.loaded / progressEvent.total) * 100) : "???");
                            me._bookResource.set('downloadProgress', percentage);
                        };
                        unzip(
                            localUbFileLocation,
                            destEntry.toURL(),
                            progressCallback,
                            function(ret) {
                                me._bookResource._app.unpackingQueue.dequeue();

                                // Remove the .ub-file
                                removeEntry(
                                    ubFileEntry,
                                    function(err) {
                                        if (err) {
                                            // TODO, proper error handling, communicate to user
                                            alert("Could not remove file: " + JSON.stringify(err));
                                            me._bookResource.set('isUnpacking', false);
                                            deferred.reject(err);
                                            return false;
                                        }
                                        if (ret < 0)  {
                                            alert('Noe gikk dessverre galt under utpakkingen av filen.');
                                            me._bookResource.set('isUnpacking', false);
                                        } else {
                                            me._bookResource.set('isUnpacking', false);
                                            me._bookResource.set('downloadedEpubid', epubid);
                                            me._bookResource.set('isDownloaded', true);
                                        }
                                        deferred.resolve();
                                    }
                                );
                            }
                        );
                    }
                );
            },
            function(err) {
                me._bookResource.set('isUnpacking', false);
                deferred.reject(err);
            }
        );
    };

    LocalFileResourceFetcher.prototype.checkAvailableDiskSpace = function () {
        var deferred = $.Deferred();
        var success = function(freeSpace) {
            if (parseInt(freeSpace) < 1000 * 1000 * 400) {
                deferred.reject();
            } else {
                deferred.resolve();
            }
        };
        var fail = function(){
            // cordova plugin failed, we do not know
            deferred.resolve();
        };
        cordova.exec(success, fail, "File", "getFreeDiskSpaceCustom", []);
        return deferred.promise();
    };

    /**
     * Download .ub-file to device.
     * When completed it queues the unpacking of the .ub-file
     *
     * @method download
     */
    LocalFileResourceFetcher.prototype.download = function() {
        var me = this;
        var deferred = $.Deferred();

        var bookId = me._bookResource.get('bookId'); // 'produkt-101'
        var publisherRef = me._bookResource.get('publisherRef');
        var epubid = me._bookResource.get('epubid'); //Latest epubid

        me._bookResource.set('isDownloading', true);
        // Download an EPUB, create a directory for the unzipped files,
        // unzip the EPUB to this directory and remove the temporary EPUB file.

        var sourceUrl = me._bookResource._app.get('backendServerUrl') + '/bookresource/publisher/' + publisherRef + '/book/' + bookId + '/epub/' + epubid + '/offline.ub';
        me._bookResource.set('downloadProgress', 0);
        me._fileTransferObj = downloadFileFromUrl(sourceUrl, getLocalUbFileLocation(bookId), null, function(progressEvent) {
            if (progressEvent.lengthComputable) {
                var percentage = (progressEvent.total ? Math.round((progressEvent.loaded / progressEvent.total) * 100) : "???");
                me._bookResource.set('downloadProgress', percentage);
            }
        }, function(err, ubFileEntry) {
            me._bookResource.set('isDownloading', false);
            if (err) {
                if (err.code !== 4) { // 4 == user aborted  download
                    // TODO: this displaying of modal windows does not belong in this class, move to a view
                    new DialogView({
                        "title": "Noe gikk galt",
                        "message": "Noe gikk galt med nedlastingen av <strong>" + me._bookResource.get('title') + "</strong>. Sjekk at du er tilkoblet internett og forsøk igjen.",
                        "isAlert": true,
                        "appendTo": 'body'
                    });
                }
                deferred.reject(err);
                console.log("Could not download file:", JSON.stringify(err, null, 2));
            } else {
                me._bookResource.set('isUnpacking', true);
                me._bookResource._app.unpackingQueue.queue(function() {
                    me.unpack(deferred);
                });

            }
        });
        return deferred.promise();
    };

    /**
     * aborts current download
     * If the filetransferObj is not found, it means something has already happened to the download
     * @method  abort
     * @param {object} bookResource
     */
    LocalFileResourceFetcher.prototype.abortDownload = function() {
        var me = this;
        if (me._bookResource.get('isDownloading')) {
            if (me._fileTransferObj) {
                me._fileTransferObj.abort();
            } else {
                me._bookResource.set('isDownloading', false);
            }
        }
    };

    /**
     * Checks local file storage for cover. If it is not saved locally, download it
     * from server and update the bookresource model.
     *
     * @method resolveBookCover
     * @param  {object} bookResource
     */
    LocalFileResourceFetcher.prototype.resolveBookCover = function()  {
        var me = this;
        var path = getRootDirectory() + 'bookcovers/' + me._bookResource.get('bookId');

        // even though thumbnail exist, download a possible new version of it if we are online
        // this is done at app startup
        var ifExist_cb = function() {
            if (me._appModel.get('isOnline')) {
                //download to a tmpPath in case the download fails
                var tmpPath = getRootDirectory() + '/tmp/bookcover_' + me._bookResource.get('bookId');
                downloadFileFromUrl(me._bookResource.get('thumbnail'), tmpPath, {}, undefined, function(err, entry) {
                    if (err) {
                        console.log('Noe gikk galt ved nedlasting av et bokomslag');
                    } else {
                        window.resolveLocalFileSystemURL(
                            getRootDirectory() + 'bookcovers',
                            function(dirEntry) {
                                entry.moveTo(dirEntry, me._bookResource.get('bookId'));
                            }
                        );
                    }
                });

            } else {
                // do not download, use existing offline cover
            }
            // Convert thumbnail to blob url
            var errHandler = function (err) {
              console.log("Kunne ikke konvertere thumbnail til blob:", err);
            };
            var success = function (entry) {
              entry.file(function (file) {
                var reader = new FileReader();
                reader.onloadend = function() {
                  var blob = new Blob([new Uint8Array(this.result)], { type: "image/png" });
                  var bloburl = window.URL.createObjectURL(blob);
                  me._bookResource.set('coverUrlToDisplay', bloburl);
                };
                reader.readAsArrayBuffer(file);
              }, errHandler);
            };
            window.resolveLocalFileSystemURL(path, success, errHandler);
        };
        var ifNotExist_cb = function() {
            downloadFileFromUrl(me._bookResource.get('thumbnail'), path, {}, undefined, function(err, entry) {
                if (err)  {
                    console.log('Noe gikk galt ved nedlasting av et bokomslag');
                    me._bookResource.set('coverUrlToDisplay', me._bookResource.get('thumbnail'));
                } else {
                    // Convert thumbnail to blob url
                    var errHandler = function (err) {
                        console.log("Kunne ikke konvertere thumbnail til blob:", err);
                    };
                    var success = function (entry) {
                        entry.file(function (file) {
                            var reader = new FileReader();
                            reader.onloadend = function() {
                                var blob = new Blob([new Uint8Array(this.result)], { type: "image/png" });
                                var bloburl = window.URL.createObjectURL(blob);
                                me._bookResource.set('coverUrlToDisplay', bloburl);
                            };
                            reader.readAsArrayBuffer(file);
                        }, errHandler);
                    };
                    window.resolveLocalFileSystemURL(path, success, errHandler);
                }
            });
        };
        checkIfFileExists(path, ifExist_cb, ifNotExist_cb);
    };


    /**
     * Removes a downloaded file from device.
     *
     * @method remove
     * @param  {object} bookResource
     */
    LocalFileResourceFetcher.prototype.removeDownloadedBook = function() {
        var me = this;
        var deferred = $.Deferred();
        me._bookResource.set('isRemoving', true);
        me._bookResource.setStateToNotDownloaded();
        var bookId = me._bookResource.get('bookId'); // 'produkt-101'
        var publisherRef = me._bookResource.get('publisherRef');
        var url = 'NoCloud/' + publisherRef + "/" + bookId;
        removeDirectory(url, function() {
            me._bookResource.set('isRemoving', false);
            deferred.resolve();
        });
        return deferred.promise();
    };

    /**
     * CheckIsDownloaded checks if BookResource exists offline
     * If it does it retrieves which version the local epub is (by epubid)
     * Updates attribute downloadedEpubid on BookResource-model
     * @method checkIsDownloaded
     * @param { function } callback Option callback when the download status is resolved
     */
    LocalFileResourceFetcher.prototype.checkIsDownloaded = function() {
        var me = this;
        var deferred = $.Deferred();
        var success_cb = function(dirEntry) {
            var dirReader = dirEntry.createReader();
            dirReader.readEntries(function(entries) {
                for (var i = 0; i < entries.length; i++) {
                    var entry = entries[i];
                    if (entry.isDirectory) {
                        var downloadedEpubid = entry.fullPath.replace(/.*\/(\d*)\//, "$1");
                        me._bookResource.set('downloadedEpubid', downloadedEpubid);
                    }
                }
            });
            me._bookResource.set('isDownloaded', true);
            deferred.resolve(true);
        };
        var error_cb = function(msg) {
            me._bookResource.set('isDownloaded', false);
            me.checkIfPrepackaged();
            deferred.resolve(false);
        };
        me.checkIfLocalBookResourceExists(success_cb, error_cb);
        return deferred.promise();
    };

    LocalFileResourceFetcher.prototype.checkIfPrepackaged = function () {
        var me = this;
        var bookId = me._bookResource.get('bookId'); // 'produkt-101'
        var publisherRef = me._bookResource.get('publisherRef');
        var epubid = me._bookResource.get('epubid'); //Latest epubid
        var prepackagedPath = cordova.file.applicationDirectory + "www/prepackagedBooks/publisher/" + publisherRef + '/book/' + bookId + '/epub/' + epubid + '/offline.ub';
        checkIfFileExists(
            prepackagedPath,
            function(){me.download(prepackagedPath);},
            function(){}
        );
    };

    /**
     * Check if a BookResource is downloaded on device.
     *
     * @method checkIfLocalBookResourceExists
     * @param  {object} bookResource
     * @param  {function} success_cb   [description]
     * @param  {function} error_cb     [description]
     */
    LocalFileResourceFetcher.prototype.checkIfLocalBookResourceExists = function(success_cb, error_cb) {
        var bookId = this._bookResource.get('bookId'); // 'produkt-101'
        var publisherRef = this._bookResource.get('publisherRef');
        // Needs a relative path
        var path = "NoCloud/" + publisherRef + "/" + bookId + '/epub';

        window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function(fs) {
            var root = fs.root;
            fs.root.getDirectory(path, {
                create: false,
                exclusive: false
            }, success_cb, error_cb);
        });
    };

    /* Do not need to check integrity of files in app */
    LocalFileResourceFetcher.prototype.checkIntegrity = function () {
        return $.Deferred().resolve().promise();
    };

    /**
     * Gets the resource url for the specified url. For LocalFileResourceFetcher
     * this is to use the specified url as key to look up in the file system.
     * @method getResourceUrl
     * @param {String} url The url to use to get the resource url.
     * @param {Function} callback A callback of the type 'function(err, resourceUrl)'.
     *
     */
  LocalFileResourceFetcher.prototype.getResourceUrl = function (url, callback) {
      var me = this;
      console.log("getResourceUrl(", url, ")");
      if (new URI(url).is("absolute")) {
          callback(null, url);
          return;
      }
      // FIXME: Implement
      console.log("Fetching of relative resourceurl is not implemented in LocalFileResourceFetcher()");
      console.log("See LocalForageResourceFetcher for details");
      console.log("url = ", url);
      var options = {
          fetcher: function(url) {
              var deferred = $.Deferred();
              console.log("Fetching file from file system", url);
              var errHandler = function (err) {
                  console.log("Kunne ikke hente fil:", err);
                  deferred.reject(err);
              };
              var success = function (entry) {
                  entry.file(function (file) {
                      var reader = new FileReader();
                      reader.onloadend = function() {
                          console.log("getResourceUrl(), result = ", this.result);
                          if (!this.result) {
                              console.log("result is empty");
                          }
                          deferred.resolve(this.result);
                      };
                      reader.readAsArrayBuffer(file);
                  }, errHandler);
              };
              window.resolveLocalFileSystemURL(url, success, errHandler);
              return deferred;
          }
      };

      blobifier.generateGenericBlobUrl(url, options)
        .then(function(blobUrl) {
            callback(null, blobUrl);
        });
  };

    /**
     * Unzips the file zippath into destdir. progresscb is optional. Example code:
     *
     * var progressCallback = function(progressEvent) {
         *     $("#progressbar").progressbar("value", Math.round((progressEvent.loaded / progressEvent.total) * 100));
         * };
     * cb has a single argument. If its value is 0, the file was unzipped successfully.
     * On failure, the value is -1.
     *
     * This function uses the cordova zip plugin (works on Android and iOS).
     *  - https://github.com/MobileChromeApps/zip
     *
     * @method unzip
     */
    var unzip = function(zippath, destdir, progresscb, cb) {
        cordova_zip.unzip(zippath, destdir, cb, progresscb);
    };

    /**
     * Recursivly remove a path and all files inside.
     *
     * @method removeDirectory
     */
    var removeDirectory = function(path, cb) {
        var fail = function(evt) {
            //console.log("FILE SYSTEM FAILURE" + evt.target.error.code);
            //alert("FILE SYSTEM FAILURE" + evt.target.error.code);
            cb(evt);
        };
        var onFileSystemSuccess = function(fileSystem) {
            fileSystem.root.getDirectory(
                path, {
                    create: true,
                    exclusive: false
                },
                function(entry) {
                    entry.removeRecursively(function() {
                        cb(null);
                    }, fail);
                }, fail);
        };
        window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, onFileSystemSuccess, fail);
    };

    /**
     * Creates a relative path. First parameter of cb is err. On success, the second parameter
     * is a DirectoryEntry-object.
     * The path is relative to the Documents directory.
     *
     * @method createPath
     */
    var createPath = function(path, cb) {
        window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function(fs) {
            var dirs = path.split("/").reverse();
            var root = fs.root;

            var createDir = function(dir) {
                root.getDirectory(dir, {
                    create: true,
                    exclusive: false
                }, successCB, failCB);
            };

            var successCB = function(entry) {
                root = entry;
                if (dirs.length > 0) {
                    createDir(dirs.pop());
                } else {
                    cb(null, entry);
                }
            };

            var failCB = function(error) {
                console.log("failed to create dir " + path);
                cb(error);
            };

            createDir(dirs.pop());
        }, function(error) {
            console.log("Could not get filesystem");
            cb(error);
        });
    };


    var checkIfFileExists = function(path, success_cb, error_cb) {
        window.resolveLocalFileSystemURL(path, success_cb, error_cb);
        /* window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fs) {
         var root = fs.root;
         fs.root.getDirectory(path, {create: false, exclusive: false}, success_cb, error_cb);
         });*/
    };

    /**
     * Gets the location where this bookresource stores the downloaded .ub-file on current device
     * @method  getLocalUbFileLocation
     * @return {string} Location of .ub-file
     */
    var getLocalUbFileLocation = function(bookId) {
        return getRootDirectory() + "tmp/" + bookId + ".ub";
    };

    /**
     * Gets the Root directory for the device
     *
     * @method getRootDirectory
     * @return {String} Absolute path to the root storage directory
     */
    var getRootDirectory = function() {
        return cordova.file.applicationStorageDirectory + "Documents/NoCloud/";
    };

    /**
     * Reads a file on device and applies a callback function to its contents.
     *
     * @method readTextFile
     * @param  {String}   path
     * @param  {Function} progresscb
     * @param  {Function} cb
     */
    var readTextFile = function(path, progresscb, cb) {
        window.resolveLocalFileSystemURL(path, function(entry) {
            entry.file(function(file) {
                var reader = new FileReader();

                var loaded = function(evt) {
                    // Obtain the read file data
                    var fileString = evt.target.result;
                    cb(null, fileString);
                };

                var errorHandler = function(evt) {
                    // alert("Could not read file:" + JSON.stringify(evt.target.error));
                    cb(evt.target.error);
                };

                var defaultHandler = function(x) {
                    //console.log("default handler: " + JSON.stringify(x));
                };
                // Handle progress, success, and errors
                //reader.onprogress = progresscb;
                reader.onloadend = loaded;
                reader.onload = defaultHandler;
                reader.onerror = errorHandler;
                reader.onloadstart = defaultHandler;
                reader.onprogress = defaultHandler;
                reader.onabort = defaultHandler;

                // Read file into memory as UTF-8
                var ret = reader.readAsText(file);
            }, function(err) {
                alert("Could not get file: " + JSON.stringify(err));
                cb(err);
            });
        }, function(err) {
            console.log("Could not get filesystem " + path);
            cb(err);
        });
    };

    var readBinaryFile = function(path, progresscb, cb) {
        window.resolveLocalFileSystemURL(path, function(entry) {
            entry.file(function(file) {
                var reader = new FileReader();

                var loaded = function(evt) {
                    // Obtain the read file data
                    var fileString = evt.target.result;
                    cb(null, fileString);
                };

                var errorHandler = function(evt) {
                    // alert("Could not read file:" + JSON.stringify(evt.target.error));
                    cb(evt.target.error);
                };

                var defaultHandler = function(x) {
                    //console.log("default handler: " + JSON.stringify(x));
                };
                // Handle progress, success, and errors
                //reader.onprogress = progresscb;
                reader.onloadend = loaded;
                reader.onload = defaultHandler;
                reader.onerror = errorHandler;
                reader.onloadstart = defaultHandler;
                reader.onprogress = defaultHandler;
                reader.onabort = defaultHandler;

                // Read file into memory as UTF-8
                var ret = reader.readAsArrayBuffer(file);
            }, function(err) {
                alert("Could not get file: " + JSON.stringify(err));
                cb(err);
            });
        }, function(err) {
            console.log("Could not get filesystem " + path);
            cb(err);
        });
    };

    /** Downloads a file from a URL. Returns a FileTransfer object (see
     * https://github.com/apache/cordova-plugin-file-transfer/blob/master/doc/index.md
     * The file transfer object can be used to abort the request.
     *
     * Example of an onprogress function:
     *
     *     function(progressEvent) {
 *         if (progressEvent.lengthComputable) {
 *             loadingStatus.setPercentage(progressEvent.loaded / progressEvent.total);
 *         } else {
 *             loadingStatus.increment();
 *         }
 *      };
     *
     * @method downloadFileFromUrl
     * @param url {string} URL of the file to be downloaded
     * @param headers {object} Hash with HTTP headers
     */
    var downloadFileFromUrl = function(url, destinationUrl, headers, onprogress, cb) {
        var fileTransfer = new FileTransfer();
        var uri = encodeURI(url);
        var headerHash = headers || {};
        if (onprogress) {
            fileTransfer.onprogress = onprogress;
        }

        fileTransfer.download(
            uri,
            destinationUrl,
            function(entry) {
                cb(null, entry);
            },
            function(error) {
                console.log("download error source " + error.source);
                console.log("download error target " + error.target);
                console.log("upload error code" + error.code);
                cb(error);
            },
            false, {
                headers: headerHash
            }
        );
        return fileTransfer;
    };

    /**
     * Removes a file or directory and calls a callback function
     *
     * @method removeEntry
     * @param  {fileEntry}   entry
     * @param  {Function} cb    [description]
     */
    var removeEntry = function(entry, cb) {
        entry.remove(function() {
            //alert("Removed file " + entry.toURL());
            cb(null, entry);
        }, function(err) {
            cb(err);
        });
    };


    return LocalFileResourceFetcher;

});

