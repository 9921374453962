define('templates/logoutView',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div id=\"logout\" hidden=\"hidden\"><div class=\"page-head\"><div class=\"container\"><img alt=\"Unibok - Digitale lærebøker\" src=\"images/login/sitename_x2.png\"/></div></div><div class=\"page-main\"><div class=\"container\"><h1>Logge ut?</h1><p class=\"warning-text\">NB! Nedlastede bøker blir fjernet fra enheten din hvis du logger ut.</p><p>Er du sikker på at du vil logge ut? Hvis dette er en enhet som bare du bruker er det ikke nødvendig å logge ut av Unibok. Unibok er din personlige bokhylle, med dine bøker og dine notater.</p><p>Hvis du likevel vil logge ut, kanskje fordi det er flere som bruker Unibok på denne enheten, er det et par ting som er greit å vite:</p><ul><li>Bøkene du har gjort tilgjengelig frakoblet vil fjernes fra enheten og du må gjøre dem tilgjengelig frakoblet på nytt neste gang du logger inn</li><li>Hvis du har notater eller bokmerker du ikke har synkronisert vil de bli tatt vare på og synkronisert neste gang du åpner Unibok og har nettilgang</li></ul><a href=\"#\" class=\"btn logout\"><span>Logg ut</span></a><a href=\"#\" class=\"btn bokhylle logout-cancel\"><span>Avbryt</span></a><div class=\"rememberChoice\"><input id=\"dontShowMessageAgain\" type=\"checkbox\"/><label for=\"dontShowMessageAgain\">Ikke vise meldingen igjen</label></div></div></div></div>");;return buf.join("");
}

});
