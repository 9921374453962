define('templates/annotationEditor',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div class=\"panel annotation-editor\"><div class=\"head\"><h3>Markering</h3><div class=\"color-options\"><div class=\"colors\"><div class=\"color\"><input type=\"radio\" aria-label=\"grønn\" name=\"color\" id=\"1\" value=\"1\" data-colorid=\"1\" class=\"marking-input\"/><div class=\"color-1\"><div data-colorid=\"1\" class=\"icon\"></div></div></div><div class=\"color\"><input type=\"radio\" aria-label=\"grønn\" name=\"color\" id=\"2\" value=\"2\" data-colorid=\"2\" class=\"marking-input\"/><div class=\"color-2\"><div data-colorid=\"2\" class=\"icon\"></div></div></div><div class=\"color\"><input type=\"radio\" aria-label=\"blå\" name=\"color\" id=\"3\" value=\"3\" data-colorid=\"3\" class=\"marking-input\"/><div class=\"color-3\"><div data-colorid=\"3\" class=\"icon\"></div></div></div><div class=\"color\"><input type=\"radio\" aria-label=\"rød\" name=\"color\" id=\"4\" value=\"4\" data-colorid=\"4\" class=\"marking-input\"/><div class=\"color-4\"><div data-colorid=\"4\" class=\"icon\"></div></div></div></div></div></div><div class=\"content scrollableContent\"><label for=\"notefield\" class=\"notefield-label\">Notattekst</label><textarea maxlength=\"1000\" id=\"notefield\" aria-label=\"Skriv notat\" class=\"notefield\"></textarea><div class=\"button-bar\"><button type=\"button\" class=\"button btn-save\">Lagre</button><button type=\"button\" class=\"button btn-remove\">Avbryt</button></div><div class=\"confirmationDialog\"><button type=\"button\" class=\"button btn-confirm\">Ja</button><button type=\"button\" class=\"button btn-cancel\">Nei</button></div></div></div>");;return buf.join("");
}

});
