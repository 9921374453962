define('resourcefetchers/convertLocalForage',[
        'localforage',
        'jquery'
    ],
    function (localforage, $) {
        function converter() {
            this.convert = function () {
                var self = this;
                var deferred = $.Deferred();
                var keysInLocalForage = {};
                var stores = {};
                stores.bookcovers = localforage.createInstance({
                    name: "bookcovers"
                });

                localforage.keys(function (err, keys) {
                    var convertedKeys = 0;
                    var increaseConvertedKeys = function(){
                        convertedKeys++;
                        deferred.notify({"converted": convertedKeys, "total": keys.length});
                        console.log('another converted key');
                        $('#heifallera').text(convertedKeys);
                        if (convertedKeys === keys.length) {
                            localforage.clear()
                                .then(
                                    function(){
                                        deferred.resolve();
                                    });

                        }
                    };

                    keys.forEach(function (key) {
                        if (key.match(/^bookcovers/)) {
                            increaseConvertedKeys();
                        } else {
                            var re = new RegExp("/publisher/([^/]*)/book/([^/]*)/epub/([^/]*)/(.*)");
                            if (key.match(re)) {
                                var publisher = RegExp.$1;
                                var bookId = RegExp.$2;
                                var epubId = RegExp.$3;
                                var keyWithoutPrefix = RegExp.$4;
                                localforage.getItem(key)
                                    .then(function(value){
                                        if (stores["bookId_" + bookId]) {
                                            localforage.removeItem(key)
                                                .then(function(){
                                                    stores["bookId_" + bookId].setItem(key, value)
                                                        .then(increaseConvertedKeys);
                                                });

                                        } else {
                                            stores["bookId_" + bookId] = localforage.createInstance({
                                                name: "bookId_" + bookId
                                            });
                                            localforage.removeItem(key)
                                                .then(function(){
                                                    stores["bookId_" + bookId].setItem(key, value)
                                                        .then(increaseConvertedKeys);
                                                });

                                        }
                                    });
                                }
                            else {
                                console.log('no way!');
                            }
                        }
                    });
                });

                return deferred.promise();
            };
        }
        return converter;
    });
