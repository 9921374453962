/*global window, console, setTimeout */
define('views/AnnotationEditor',['jquery', 'underscore', 'views/PanelBase', 'templates/annotationEditor'], function ($, _, PanelBase, mainTemplate) {
    /**
     *
     */
    return PanelBase.extend({
        initialize: function (options) {
            this._readerView = options.readerView;
            this._annotation = null;
            this._defaultColorid = '1';

            this.render();
            this._notefieldE = this.$el.find('.notefield');
            this._updateColorIndicator();
        },

        events: {
            'click .notefield': 'createAndExpand',
            'touchstart .notefield': 'createAndExpand', // IOS HACK

            'click .btn-save': 'save',
            'keyup .btn-save': 'save_pressed',
            'click .btn-remove': 'remove',
            'keyup .btn-remove': 'remove_pressed',
            'click .btn-confirm': 'remove_confirm',
            'keyup .btn-confirm': 'remove_confirm_pressed',
            'click .btn-cancel': 'remove_cancel',
            'keyup .btn-cancel': 'remove_cancel_pressed',
            'click input[name="color"]': 'setColor',
            'touchstart input[name="color"]': 'setColor',

            'click .icon': 'setColor',
            'touchstart .icon': 'setColor',

            'propertychange .notefield': 'onTextinput',
            'keyup .notefield': 'onTextinput',
            'input .notefield': 'onTextinput',
            'paste .notefield': 'onTextinput'
        },

        render: function () {
            this.setElement(mainTemplate());
            return this;
        },

        block: function (e) {
            e.preventDefault();
            return false;
        },

        /**
         * Prevents iOS from loosing text-focus on tap.
         */
        setColorKeepFocus: function (e) {
            this.setColor(e);
            return false; // prevent default and stop propagation
        },

        createAndExpand: function () {
            $('#annotation-widget-btn').find('.icon').addClass('active');
            this._readerView.createAnnotation(null, this._defaultColorid, true);
            return true;
        },

        save_pressed: function(e) {
            if (e.which === 13) // Enter Key
            {
                this.save(e);
            }
        },

        save: function () {
            var text = this._notefieldE.val();
            text = _.escape(text);
            this._annotation.setText(text);
            this.close();
        },

        close: function () {
            this._readerView._annotationDialogShowing = false;
            this._readerView._clickedAnnotation = false;
            PanelBase.prototype.onClose.call(this);
        },

        remove_pressed: function(e) {
            if (e.which === 13) // Enter Key
            {
                this.remove();
            }
        },

        remove: function () {
            if (this._readerView._clickedAnnotation) {
                this.close();
            } else if (this._annotation.getText()) {
                this.$el.addClass('confirmation');
            } else {
                this._annotation.delete();
                this.close();
            }
        },

        remove_confirm_pressed: function (e) {
            if (e.which === 13) // Enter Key
            {
                this.remove_confirm();
            }
        },

        remove_confirm: function () {
            this._annotation.delete();
            this.close();
        },

        remove_cancel_pressed: function (e) {
            if (e.which === 13) // Enter Key
            {
                this.remove_cancel();
                $("div.btn-remove").focus();
            }
        },

        remove_cancel: function () {
            this.$el.removeClass('confirmation');
        },

        setColor: function (e) {
            var colorid = $(e.currentTarget).data('colorid');
            if (this._annotation) {
                this._annotation.setColor(colorid);
            } else {
                this._defaultColorid = colorid;
                this.$el.find('.colors .active').removeClass('active');
                this.$el.find('[data-colorid="' + colorid + '"]').addClass('active');
                this._updateColorIndicator();
            }

            if (this._readerView._selectionManager.hasSelection()) { // Hack for UIWebView
                this._readerView._selectionManager.preventNextBlur();
            }
            return false;
        },

        onTextinput: function (e) {
            if (!this._annotation) {
                return;
            }
            if (e.keyCode === 27) {
                this.close();
            }
        },

        setAnnotation: function (annotation) {
            if (this._annotation !== annotation) {
                if (this._annotation) {
                    this._annotation.off(null, null, this);
                    this.$el.removeClass('confirmation');
                }
                if (annotation) {
                    this.$el.addClass('editing');
                    annotation.on('change:colorid', this.onAnnotationColoridChanged, this);
                    annotation.on('change:text', this.onAnnotationTextChanged, this);
                    this._notefieldE.val(_.unescape(annotation.getText()));
                    this._notefieldE.focus();
                } else {
                    this.$el.removeClass('editing');
                    this._notefieldE.val('');
                }
                this._annotation = annotation;
                this._updateColorIndicator();
            }
        },

        setUserbook: function (userbook) {
            if (userbook !== this._userbook) {
                if (this._userbook) {
                    this._userbook.off(null, null, this);
                }
                this._userbook = userbook;
            }
        },

        onAnnotationColoridChanged: function () {
            this._updateColorIndicator();
        },

        /**
         * Receives change events for the annotation's 'text'.
         * Although the notefield is already reflects the curent value while the user types,
         * it is possible that the value can be changed by another part of the application.
         */
        onAnnotationTextChanged: function (model, value) {
            if (this._notefieldE.val() !== value) {
                this._notefieldE.val(value);
            }
        },

        _updateColorIndicator: function () {
            var colorid;
            if (this._annotation) {
                colorid = this._annotation.get('colorid');
            } else {
                colorid = this._defaultColorid;
            }
            this.$el.find('.colors .active').removeClass('active');
            this.$el.find('[data-colorid="' + colorid + '"]').addClass('active');
        }
    });
});

