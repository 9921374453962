define('UnibokIFrameLoader',['bookResourceMap', 'underscore', 'jquery', 'readium_shared_js/views/iframe_loader'], function (bookResourceMap, _, $, ReadiumIFrameLoader) {
    var UnibokIFrameLoader = function(contentDocumentPreprocess) {
        var self = this;

        ReadiumIFrameLoader.call(this);
        this._contentDocumentPreprocess = contentDocumentPreprocess;
        this._originalLoadIframe = this.loadIframe.bind(this);

        this.loadIframe = function(iframe, src, callback, context, attachedData) {
            var publicationid = bookResourceMap.extractBookIdFromURL(src);
            var bookResource = bookResourceMap.getBookResource(publicationid);
            if (!bookResource) {
                return;
            }
            bookResource.getAssetUrl(src, function (assetSrc, useBlob) {
                var spineItem = null;
                if (context.hasOwnProperty("currentSpineItem")) {
                    spineItem = context.currentSpineItem();
                }
                else {
                    spineItem = context.getLoadedSpineItems()[0];
                }

                self._originalLoadIframe(
                    iframe,
                    assetSrc,
                    function() {
                        self._contentDocumentPreprocess($(iframe), spineItem, function() {
                            callback.call(context, true, attachedData);
                        });
                    },
                    context,
                    attachedData);
            });
        };
    };

    UnibokIFrameLoader.prototype = Object.create(ReadiumIFrameLoader.prototype);

    return UnibokIFrameLoader;
});


