/* globals console, require, Math */

define('resourcefetchers/EpubDownloader',[
    'jquery',
    'localforage',
    'async',
    'URIjs',
    'zip',
    'zip-ext'
],
function($, localforage, async, URI, zip, zipExt) {

    var suffixContentTypeMap = {
        css: 'text/css',
        epub: 'application/epub+zip',
        gif: 'image/gif',
        html: 'text/html',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        ncx: 'application/x-dtbncx+xml',
        opf: 'application/oebps-package+xml',
        png: 'image/png',
        svg: 'image/svg+xml',
        xhtml: 'application/xhtml+xml',
        xml: 'text/xml',
        json: 'application/json',
        ttf: 'font/ttf'
    };

    function identifyContentTypeFromFileName(contentUrl) {
        var contentUrlSuffix = URI(contentUrl).suffix();
        var contentType = 'application/octet-stream';
        if (typeof suffixContentTypeMap[contentUrlSuffix] !== 'undefined') {
            contentType = suffixContentTypeMap[contentUrlSuffix];
        }
        return contentType;
    }

    var EpubDownloader = function(resourceFetcher) {
        this._resourceFetcher = resourceFetcher;
    };

    EpubDownloader.prototype.download = function(downloadUrl, keyPrefix) {
        var me = this;
        this.deferred = $.Deferred();
        this.keyPrefix = keyPrefix;
        require(['resourcefetchers/RavnHttpReader'], function (RavnHttpReader) {
            var httpReader = new RavnHttpReader(downloadUrl);
            var request = httpReader.getRequest();
            var aborted = false;

            function downloadProgressCallback(e) {
                if (me._resourceFetcher.downloadAborted) {
                    // request.abort() will lead to infinite recursive calls in Safari if not forced to stop:
                    //   - request.abort() (triggers 'progress' event)
                    //   - downloadProgressCallback
                    //   - etc..
                    if (!aborted) {
                        // aborted need to be set to 'true' before 'request.abort()' is called
                        aborted = true;
                        request.abort();

                        me.deferred.reject(new Error('Download aborted'));
                        me.closeZipReader();
                    }
                    return;
                }

                if (e.lengthComputable) {
                    var percentage = Math.round((e.loaded / e.total) * 100);
                    me._resourceFetcher._bookResource.set('downloadProgress', percentage);
                }
                else {
                    me._resourceFetcher._bookResource.set('downloadProgress', '???');
                }
            }

            request.addEventListener('progress', downloadProgressCallback, false);

            zip.workerScriptsPath = "/js/thirdparty/zip/";
            zip.createReader(httpReader, function (reader) {
                me.updateProgressbar('0');
                me.setDownloadingStatus(true);
                me._resourceFetcher.downloadAborted = false;
                me.reader = reader;

                reader.getEntries(me.processAllEntries.bind(me));
            }, function (error) {
                me.deferred.reject(new Error('Klarte ikke pakke ut bok'));
            });
        });
        return this.deferred.promise();
    };

    EpubDownloader.prototype.closeZipReader = function() {
        var me = this;

        // close the zip reader
        this.reader.close(function () {
            me.setDownloadingStatus(false);
        });
    };

    EpubDownloader.prototype.processAllEntries = function(entries) {
        var me = this;

        // Create generic key with name of all entries
        var entryList = entries.map(function (entry) {
            return entry.filename;
        });

        this.entriesProcessed = 0;
        this.numOfEntries = entries.length;

        // Try to save the filename list first so that we'll be able to delete
        // files even if the download fails.
        me._resourceFetcher.getDataStore()
            .then(function(dataStore) {
                dataStore.setItem(me.keyPrefix + 'filelist', entryList, function (err) {
                    if (err) {
                        me.deferred.reject(err);
                        return me.closeZipReader();
                    }

                    var entriesProcessed = 0;

                    async.mapSeries(entries, me.processEntry.bind(me), function(err, results) {
                        if (err) {
                            me.deferred.reject(err);
                            entryList.push('filelist');
                            me._resourceFetcher.removeFromLocalForage(entryList, function (err) {
                                me.closeZipReader();
                            });
                        }
                        else {
                            // Write a final entry to inicate that the whole package
                            // has been downloaded and extracted:
                            dataStore.setItem(me.keyPrefix + 'downloadedEpubId', me._resourceFetcher._bookResource.get('epubid'), function (err) {
                                me._resourceFetcher._bookResource.set('downloadedEpubid', me._resourceFetcher._bookResource.get('epubid'));
                                me._resourceFetcher._bookResource.set('isDownloaded', true);
                                me.deferred.resolve();
                                return me.closeZipReader();
                            });
                        }
                    });
                });
            });
    };

    EpubDownloader.prototype.processEntry = function(entry, asyncDoneCallback) {
        var me = this;

        if (this._resourceFetcher.downloadAborted) {
            // One of the previous entries has failed. Stop the processing of all entries.
            var err = new Error("Download aborted");
            err.code = 4; // Use same error code as cordovas file download
            return asyncDoneCallback(err);
        }

        entry.getData(
            new zip.BlobWriter(identifyContentTypeFromFileName(entry.filename)),
            function (blob) {
                var retry = true;
                me.storeDataToLocalForage(blob, entry.filename, retry, asyncDoneCallback);
            }
        );
    };

    EpubDownloader.prototype.storeDataToLocalForage = function(dataBlob, filename, retry, asyncDoneCallback) {
        var me = this;

        me._resourceFetcher.getDataStore()
            .then(function(dataStore) {
                dataStore.setItem(me.keyPrefix + filename, dataBlob, function (err) {
                    if (err && retry) {
                        me.storeDataToLocalForage(dataBlob, filename, false, asyncDoneCallback);
                        return;
                    }

                    me.entriesProcessed++;
                    var percentage = Math.round((me.entriesProcessed / me.numOfEntries) * 100);
                    me.updateProgressbar(percentage);

                    if (err) {
                        asyncDoneCallback(err);
                    }
                    else {
                        asyncDoneCallback(null);
                    }
                });
            });
    };

    EpubDownloader.prototype.updateProgressbar = function(percentage) {
        this._resourceFetcher._bookResource.set('downloadProgress', percentage);
    };

    EpubDownloader.prototype.setDownloadingStatus = function(isDownloading) {
        this._resourceFetcher._bookResource.set('isDownloading', isDownloading);
    };

    return EpubDownloader;
});

