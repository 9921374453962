define('templates/imageLightbox',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (alt, bookCss, caption, imageurl, title) {
buf.push("<dialog class=\"lightbox\"><div class=\"lightbox-header\"><div class=\"zoom-container\"><button title=\"zoom inn\" class=\"zoom-in\"></button><button title=\"zoom ut\" class=\"zoom-out\"></button></div><button title=\"Endre posisjon\" class=\"move-handler\"></button><button title=\"Endre størrelse\" class=\"resize-handler\"></button><button title=\"Lukk dialog\" class=\"closeButton\"></button></div><div class=\"content\"><link xmlns=\"http://www.w3.org/1999/xhtml\"" + (jade.attr("href", "" + (bookCss) + "", true, false)) + " rel=\"stylesheet\" type=\"text/css\"/>");
if ( title)
{
buf.push("<div class=\"title\">" + (((jade_interp = title) == null ? '' : jade_interp)) + "</div>");
}
buf.push("<figure role=\"figure\" class=\"image\">");
if ( alt)
{
buf.push("<img" + (jade.attr("src", imageurl, true, false)) + (jade.attr("alt", alt, true, false)) + "/>");
}
else
{
buf.push("<img" + (jade.attr("src", imageurl, true, false)) + " alt=\"\"/>");
}
buf.push("</figure>");
if ( caption)
{
buf.push("<figcaption class=\"caption\">" + (((jade_interp = caption) == null ? '' : jade_interp)) + "</figcaption>");
}
buf.push("</div></dialog>");}.call(this,"alt" in locals_for_with?locals_for_with.alt:typeof alt!=="undefined"?alt:undefined,"bookCss" in locals_for_with?locals_for_with.bookCss:typeof bookCss!=="undefined"?bookCss:undefined,"caption" in locals_for_with?locals_for_with.caption:typeof caption!=="undefined"?caption:undefined,"imageurl" in locals_for_with?locals_for_with.imageurl:typeof imageurl!=="undefined"?imageurl:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}

});
