/*global window */
define('views/CarouselLightboxView',[
  "jquery",
  "underscore",
  "views/LightboxBase",
  "templates/carouselLightbox"
], function ($, _, LightboxBase, mainTemplate) {
  return LightboxBase.extend({
    initialize: function (data) {
      LightboxBase.prototype.initialize.call(this, data);
      _.extend(this.events, LightboxBase.prototype.events);
      this._data = data;
      this._data.bookCss = this.getBookCssPath(data.bookResource.getBaseUrl());
      this._slideCnt = data.slides.length;
      this._index = 0;
      this.dotIndex();
      this.render();
      this.requestSize();
      this.show();
      this.updateLayout();
    },

    events: {
      "click .clickArea-prev": "_showPreviousSlide",
      "keyup .clickArea-prev": "_showPreviousSlide",
      "click .clickArea-next": "_showNextSlide",
      "keyup .clickArea-next": "_showNextSlide",
      "click .dot": "_goToSlide",
    },

    render: function () {
      var me = this;
      this.setElement(mainTemplate(this._data));
      this.$el.addClass("carouselLightbox");
      this._data.slides.forEach(function (slide, index) {
        me.$el
          .find(".dotIndex")
          .append('<span class="dot" data-index="' + index + '"></span>');
      });
      this.$el.find(".dotIndex").css("width", this._data.slides.length * 30);
      this.$el.find(".dotIndex").children(":first").addClass("active");

      return this;
    },

    _showPreviousSlide: function (e) {
      if (e.keyCode !== undefined && e.keyCode !== 13) { return; }
      this.setIndex((this._index - 1 + this._slideCnt) % this._slideCnt);
      $(".dot").removeClass("active");
      $(".dotIndex").find(".dot").eq(this._index).addClass("active");
    },

    _showNextSlide: function (e) {
      if (e.keyCode !== undefined && e.keyCode !== 13) { return; }
      this.setIndex((this._index + 1) % this._slideCnt);
      $(".dot").removeClass("active");
      $(".dotIndex").find(".dot").eq(this._index).addClass("active");
    },

    _goToSlide: function (e) {
      this.setIndex(parseInt(e.target.getAttribute("data-index")));
      $(".dot").removeClass("active");
      $(".dotIndex").find(".dot").eq(this._index).addClass("active");
    },

    setIndex: function (index) {
      this._slidesE.css("left", -(index * this._slideWidth) + "px");
      this._index = index;
      this._hideNonVisibleSlides(index);
    },

    _hideNonVisibleSlides: function (index) {
      var slideElms = this.$el.find(".slide");
      slideElms.each(function (index, slide) {
        $(slide).attr("aria-hidden", true);
      });
      $(slideElms[index]).attr("aria-hidden", false);
    },

    updateLayout: function () {
      this._slidesE = this.$el.find(".slides");
      this.requestSize(
        this._requestedContentWidth,
        this._requestedContentHeight,
        this._keepAspectRatio,
        70,
      );

      this._slideWidth = this._actualContentWidth;
      this._slidesE.width(this._slideWidth * this._data.slides.length);
      this._slidesE.find(".slide").width(this._slideWidth);
      this.setIndex(this._index);
    },

    dotIndex: function () {
      this._data.slides.forEach(function (slide, index) {
        slide.index = index;
      });
    },
  });
});

