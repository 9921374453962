/* globals ga, console, document, alert, cordova, window, setTimeout */
define('views/BookshelfView',[
    'jquery',
    'backbone',
    'underscore',
    'views/UserbookView',
    'views/DialogView',
    'templates/bookshelfView',
    'templates/publisherTermsBox',
    'models/App'
], function ($, Backbone, _, UserbookView, DialogView, mainTemplate, publisherTermsBoxTemplate, AppModel) {

    function getCookie(name) {
        var value = "; " + document.cookie.toString();
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) {
            return parts.pop().split(";").shift();
        }
        else {
            return "";
        }
    }

    function handleLogout(_this) {
        var msgCookie = getCookie("dontShowMessageAgain");
        if ($("div.isDownloaded").length > 0 && (msgCookie === "false" || msgCookie === "")) {
            _this._app.trigger('requestLogout');
        }
        else {
            _this._user.logout();
        }
    }

    return Backbone.View.extend({
        initialize: function (app, options) {
            var me = this;
            this._app = app;
            this._user = null;
            this._mobileApp = options.mobileApp;
            this.render();
            this._app.on('offlineBooks', me.showHideOfflineMessage, this);
            this._app.on('change:isOnline', me.onlineStatusChange, this);
            this._app.on('change:activeSessionInfo', me.checkAuthsource, this);
            this._app.on('change:cordovaVersion', function () {
                me.$el.find('span.version').html(me._app.get('cordovaVersion'));
            });
        },

        events: {
            'keyup .btn-publisherTermsInfo': 'publisherTermsOnBoxKeyUp',
            'click .btn-publisherTermsInfo': 'publisherTermsBox',

            'click .malform-valg': 'changeMalform',
            'change input[class="maalform-input--hidden"]': 'changeMalform',

            'change input[class="offline-input--hidden"]': 'toggleOfflineBooks',

            'click .tips-header': 'toggleTips',
            'keyup .tips-header': 'toggleTips',

            'click .btn-logout': 'logout',
            'keyup .btn-logout': 'logoutOnKeyUp',

        },

        render: function () {
            var authsource = this._app.get('activeSessionInfo') ? this._app.get('activeSessionInfo').authsource : '';
            this.setElement(mainTemplate({
                mobileApp: this._mobileApp,
                offlineApp: this._app.get('offlineApp'),
                cordovaVersion: this._app.get('cordovaVersion'),
                isFeide: authsource === 'saml'
            }));
            return this;
        },

        checkPublisherTerms: function () {
            var me = this;

            $.get(me._app.get('backendServerUrl') + '/samtykke-internal')
                .done(function (response) {
                    me._pubTerms = response.publishers;

                    var showDialog = false;
                    for (var publisher in me._pubTerms) {
                        if (me._pubTerms.hasOwnProperty(publisher) && !me._pubTerms[publisher]) {
                            showDialog = true;
                            break;
                        }
                    }

                    // If the user is logged in with feide and terms are not accepted
                    // show the terms box first time
                    showDialog = !me._user.getTermsAccepted();
                    var authsource = me._app.get('activeSessionInfo').authsource;
                    if ($(".genericDialog").length === 0 && authsource === 'saml' && (showDialog || me._showSamtykke)) {
                        me.publisherTermsBox();
                    }

                });

        },

        checkAuthsource: function (appModel, activeSessionInfo) {
            if (activeSessionInfo && activeSessionInfo.authsource === 'saml') {
                this.$el.find('.publisherCont').addClass('isFeide');
            }
        },

        publisherTermsBox: function (event) {
            if (event) {
                event.stopPropagation();
            }
            var me = this;

            var blockingLayer = $('<div class="blockingLayer"></div>');
            $('body').append(blockingLayer);

            blockingLayer.append(publisherTermsBoxTemplate({
                mobileApp: me._app.get('mobileApp'),
                pubTermsAschehoug: me._pubTerms && me._pubTerms.aschehoug ? me._pubTerms.aschehoug : false,
                pubTermsCapdamm: me._pubTerms && me._pubTerms.cappelendamm ? me._pubTerms.cappelendamm : false
            }));
            blockingLayer.show();
            setTimeout(function () {
                $('.close-terms-modal, .blockingLayer').focus();
            }, 500);
            $('.close-terms-modal, .blockingLayer').on('click', function (e) {
                if (e.target !== this) { return; }
                me._user.setTermsAccepted(true);
                blockingLayer.detach();
                return false;
            });
        },

        publisherTermsOnBoxKeyUp: function (e) {
            if (e.keyCode === 13) {     // Enter Key Down
                this.publisherTermsBox(e);
            }
        },

        changeMalform: function (e) {
            var elem = $(e.currentTarget);
            this._user.setMalform(elem.data('lang'));
        },

        /**
         * Fired when logout button is clicked
         * - If the user has no Downloaded books,               log out right away.
         * - If the user set the cookie (dontShowMessageAgain), log out right away.
         * Except two conditions above,
         *                              show logoutView.
         * @method  logout
         */
        logout: function () {
            handleLogout(this);
        },

        logoutOnKeyUp: function (e) {
            if (e.keyCode === 13) {     // Enter Key Down
                handleLogout(this);
            }
        },

        // Internal function that returns a new userbook view.
        // Overridden in sub-classes (for use in iframe)
        getUserbookView: function (model) {
            return new UserbookView({ model: model, mobileApp: this._mobileApp, app: this._app });
        },

        toggleOfflineBooks: function (e, isOffline) {
            var clickedBtn = $(e.target);

            // If the pressed key is not 'Enter Button', do nothing.
            if (e.type === 'keyup') {
                if (e.keyCode !== 13) {
                    return;
                }
            }

            if ((e.type !== 'keyup' && e.type !== 'click' && e.type !== 'touchstart' && e.type !== 'change') && !isOffline) {
                $('.offline-check input[value="alle"]').prop('checked', true);
                this.$el.find('.bookshelf-booklist').children().show();
                this.$el.find('.bookshelf-title').text("Alle bøker");
                $('.btn-remove-download').hide();
            }

            else if (clickedBtn.prop('value') !== 'frakoblet' && !isOffline) {
                $('.offline-check input[value="alle"]').prop('checked', true);
                this.$el.find('.bookshelf-booklist').children().show();
                this.$el.find('.bookshelf-title').text("Alle bøker");
                $('.btn-remove-download').hide();
            } else {
                $('.offline-check input[value="frakoblet"]').prop('checked', true);
                this.$el.find('.bookshelf-title').text("Bøker som er tilgjengelig frakoblet");
            }
            this.showHideOfflineMessage();
        },

        onlineStatusChange: function (e) {
            var isOffline = !this._app.get('isOnline');
            this.toggleOfflineBooks(e, isOffline);
            this.updateFiltering();
        },

        hideBooksNotAvailableOffline: function (shouldHide) {
            if (shouldHide) {
                var bookListE = this.$el.find('.bookshelf-booklist').children();
                bookListE.each(function () {
                    if ($(this).hasClass('isNotDownloaded')) {
                        $(this).hide();
                    } else {
                        $(this).find('.btn-remove-download').show();
                    }
                });
            }
        },

        showHideOfflineMessage: function () {
            if ($('.offline-check input[value="frakoblet"]').prop('checked')) {
                this.hideBooksNotAvailableOffline(true);
                if ($('.bookshelf-booklist').children().length && $('.bookshelf-booklist').children().length === $('.bookshelf-booklist').find('.isNotDownloaded').length) {
                    $('.no-offline-books').show();
                } else {
                    $('.no-offline-books').hide();
                }
            } else {
                $('.no-offline-books').hide();
            }
            this.fillTipsBox();
        },

        onMalformChanged: function (user, malform) {
            this.$el.find('.bookshelf-header .malform input[class="maalform-input--hidden"][id=' + malform + ']').prop('checked', true);
            this.updateFiltering();
        },

        onSortOptionChanged: function () {
            this.updateFiltering();
        },

        fillTipsBox: _.debounce(function () {
            var me = this;
            var tips = "Du kan velge mellom å lese bøkene dine på bokmål eller nynorsk ved å markere ønsket målform øverst i bokhylla.";
            var randomNumber = 0;
            me.$el.find('.bookshelf-tips').removeClass('yellow');
            function getRandomInt(max) {
                return Math.floor(Math.random() * Math.floor(max));
            }

            $.getJSON("./js/tipsmap.json", function (tipsMap) {
                if (me._app.get('mobileApp')) {
                    // get app store version and use localeCompare to compare version numbers
                    // this will return -1 if appstoreVersion > cordovaVersion
                    // NOTE: requires X.X.X format
                    let appstoreVersion;
                    $.getJSON('https://itunes.apple.com/lookup?bundleId=no.unibok.les', function (response) {
                        const results = response.results;
                        appstoreVersion = results ? results[0].version : '';
                        if (me._app.get('cordovaVersion') && me._app.get('cordovaVersion').localeCompare(appstoreVersion) === -1) {
                            tips = "Vi anbefaler at du oppdaterer Unibok til siste versjon i App Store for å unngå problemer med åpning av bøker.";
                            tips += "<br /> Du har versjon " + me._app.get('cordovaVersion') + ", nyeste versjon er " + appstoreVersion + ".";
                            me.$el.find('.bookshelf-tips').addClass('yellow');
                        } else {
                            if ($('.offline-check input[value="frakoblet"]').prop('checked') &&
                                $('.bookshelf-booklist').children().length === $('.bookshelf-booklist').find('.isNotDownloaded').length &&
                                tipsMap.mobileApp.offlineTips) {
                                randomNumber = getRandomInt(Object.keys(tipsMap.mobileApp.offlineTips).length);
                                tips = tipsMap.mobileApp.offlineTips[randomNumber];
                                me.$el.find('.bookshelf-tips').addClass('yellow');
                            } else if (me._app.get('isOnline') && tipsMap.mobileApp.onlineTips) {
                                randomNumber = getRandomInt(Object.keys(tipsMap.mobileApp.onlineTips).length);
                                tips = tipsMap.mobileApp.onlineTips[randomNumber];
                            }
                        }
                        me.$el.find('#tipsFromJson').empty().append(tips);
                    }).fail(function (error) {
                        console.log('error', error);
                    });
                } else {
                    if (!me._app.get('offlineApp') && tipsMap.noOfflineTips) {
                        randomNumber = getRandomInt(Object.keys(tipsMap.noOfflineTips).length);
                        tips = tipsMap.noOfflineTips[randomNumber];
                    }
                    else if ($('.offline-check input[value="frakoblet"]').prop('checked') &&
                        $('.bookshelf-booklist').children().length === $('.bookshelf-booklist').find('.isNotDownloaded').length &&
                        tipsMap.offlineTips) {
                        randomNumber = getRandomInt(Object.keys(tipsMap.offlineTips).length);
                        tips = tipsMap.offlineTips[randomNumber];
                        me.$el.find('.bookshelf-tips').addClass('yellow');
                    }
                    else if (me._app.get('isOnline') && tipsMap.onlineTips) {
                        randomNumber = getRandomInt(Object.keys(tipsMap.onlineTips).length);
                        tips = tipsMap.onlineTips[randomNumber];
                    }
                }
                me.$el.find('#tipsFromJson').empty().append(tips);
            }).fail(function (error) {
                console.error("getJSON failed while retrieving tipsmap.json", error);
            });
        }, 100),

        toggleTips: function (e) {
            if (e.type === 'keyup' && e.keyCode !== 13) {
                return;
            }
            $('.tip').slideToggle();
        },

        resizeBookshelf: function () {
            var emWidth = $(window).width() / parseFloat($("body").css("font-size"));
            if (emWidth >= 38) {
                $('.expand-icon').addClass('hidden');
                $('.tips-header').removeAttr('aria-label');
                $('.tips-header').removeAttr('tabindex');
            } else {
                $('.expand-icon').removeClass('hidden');
                $('.tips-header').attr('aria-label', 'Se tips');
                $('.tips-header').attr('tabindex', 0);
            }
        },

        updateSearch: function (value) {
            var list = $('.bookshelf-booklist');
            if (value) {
                // this finds all links in a list that contain the input,
                // and hide the ones not containing the input while showing the ones that do
                $(list).find(".tittel:not(:iContains(" + value.toLowerCase() + "))").parents('.book').hide();
                $(list).find(".tittel:iContains(" + value.toLowerCase() + ")").parents('.book').show();

            } else {
                $(list).find(".book").show();
            }
        },

        getFilteredBookViews: function () {
            function filterOutWrongLang(bookView) {
                var bookResource = bookView.model.getBookResource();
                if (bookResource.get('language') === malform) {
                    return true;
                } else {
                    return !bookViews.some(function (bookView2) {
                        var bookResource2 = bookView2.model.getBookResource();
                        return bookResource2.get('titleid') === bookResource.get('titleid') && bookResource2.get('language') === malform &&
                            bookResource2.get('epubid') !== bookView.model.getBookResource().get('epubid');
                    });
                }
            }

            var malform = this._user.getMalform();
            var me = this;

            var bookViews = this._user.get('userbooks').map(function (userBook) {
                return me.getUserbookView(userBook);
            }).filter(function (bookView) {
                return !bookView.model.getBookResource().get('singlebook'); //remove all single books
            });

            return bookViews.filter(filterOutWrongLang);
        },

        updateFiltering: _.debounce(function () {
            if (!this._user) {
                return;
            }
            var sortOption = this._user.getSortOption();
            var subjectSortOrder = ['Norsk', 'Språkfag', 'Samfunnsfag', 'Realfag',
                'Kroppsøving', 'Helse- og oppvekstfag', 'Alfabetisering',
                'Arbeidsliv og jobbsøk', 'Grunnleggende norsk (1-10)',
                'Norsk for nybegynnere (A1-A2)', 'Norsk for viderekomne (B1-C1)',
                'Samfunnskunnskap', 'Akademisk', //TODO: alle fag under akademisk?
                'Andre fag', '', undefined];
            var secondarySort = 'title'; //sort on title within primary sort

            var isOffline = !this._app.get('isOnline');

            var bookViews = this.getFilteredBookViews();

            function sortBookViews(a, b) {
                a = a.model.getBookResource().get(secondarySort);
                b = b.model.getBookResource().get(secondarySort);
                return a.localeCompare(b, undefined, { numeric: true });
            }

            // sort on subjectArea array
            var sortedBookViews = [];
            var sortedOfflineBookViews = [];
            subjectSortOrder.forEach(function (subject) {
                var subjectArray = [];
                var subjectOfflineArray = [];
                var bruksanvisning;
                bookViews.forEach(function (bookView) {
                    var bookResource = bookView.model.getBookResource();

                    // plukke ut bruksanvisning
                    if (bookResource.get('publisherRef') === 'unibok') {
                        bruksanvisning = bookView;
                        return;
                    }

                    if (bookResource.get(sortOption) === subject) {
                        if (isOffline && bookResource.get('isDownloaded')) {
                            subjectOfflineArray.push(bookView);
                        } else {
                            subjectArray.push(bookView);
                        }
                    }
                });

                subjectArray.sort(sortBookViews);

                sortedBookViews.push(subjectArray);

                // dytte inn bruksanvisning først
                if (bruksanvisning) {
                    sortedBookViews.unshift(bruksanvisning);
                }

                subjectOfflineArray.sort(sortBookViews);
                sortedOfflineBookViews.push(subjectOfflineArray);
            });

            sortedBookViews = sortedOfflineBookViews.concat(sortedBookViews);

            sortedBookViews = sortedBookViews.reduce(function (a, b) {
                return a.concat(b);
            });

            this.$el.find('.bookshelf-booklist').empty().append(sortedBookViews.map(function (bookView) {
                return bookView.$el;
            }));

            if ($('.offline-check[value="alle"]').prop('checked')) {
                $('.btn-remove-download').hide();
            }

            this.hideBooksNotAvailableOffline($('.offline-check input[value="frakoblet"]').prop("checked"));
        }, 100),

        enter: function (user) {
            var me = this;
            var displayname;

            this.$el.addClass('active').removeAttr('hidden');

            // Plausible pageview
            me._app.plausibleManager.sendPageViewToAllTrackers('/bokhylla');

            // searchfield for book titles on dev servers
            $.expr[':'].iContains = function (a, i, m) {
                return $(a).text().toLowerCase()
                    .indexOf(m[3].toLowerCase()) >= 0;
            };
            document.getElementById('searchInput').addEventListener('input', function (evt) {
                me.updateSearch(this.value);
            });

            window.addEventListener('resize', this.resizeBookshelf);
            this.resizeBookshelf();

            // accessibility
            $('#aria-live-region').html('');
            if (me._app.get('fromReader')) {
                $('.header-group').find('h1').focus();
            }

            if (this._user !== user) {
                if (this._user) {
                    this._user.get('userbooks').off('all', null, this);
                    this._user.off('all', null, this);
                }
                this._user = user;
                $("select option").filter(function () {
                    return this.text === user.get('sortOption');
                }).attr('selected', true);

                this.updateFiltering();
                this._user.get('userbooks').on('add', this.updateFiltering, this);
                this._user.get('userbooks').on('remove', this.updateFiltering, this);
                this._user.on('change:language', this.onMalformChanged, this);
                this._user.on('change:sortOption', this.onSortOptionChanged, this);

                if (user.get('authsource') !== "saml") {
                    // Login-source is not Feide, and so organizationName will contain the identity provider
                    displayname = user.get('name') + ' (' + user.get('organizationName') + ')';
                } else {
                    displayname = user.get('name') + ', ' + user.get('organizationName');
                }

                this._user.on('change:isLoaded', function (model, isLoaded) {
                    if (isLoaded) {
                        // Removed .toptopbar since iframe has loginInfo somewhere else
                        me.$el.find('.loginInfo').html(displayname);
                    }
                });

                // Removed .toptopbar since iframe has loginInfo somewhere else
                this.$el.find('.loginInfo').html(displayname);

                this.checkPublisherTerms();

                this.onMalformChanged(this._user, this._user.get('language'));

                this.fillTipsBox();

                // TODO: BookshelfView should be initialized with an instance of AppModel
                if (AppModel.prototype.isDebugMode()) {
                    this.$el.find('.topbar .button.debugUser').show();
                }
            }
        },

        leave: function () {
            this.$el.removeClass('active').attr('hidden', 'hidden');
            this._app.set('fromReader', false);
        }
    });
});

