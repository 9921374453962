define('templates/gotoPageResultItem',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (pageHref, pageNum) {
buf.push("<div" + (jade.attr("data-page-href", pageHref, true, false)) + " class=\"gotoPageResultItem\"><div class=\"resultText\"><span class=\"headText\">" + (jade.escape(null == (jade_interp = 'Gå til side: ') ? "" : jade_interp)) + "</span><span class=\"pageNum\">" + (jade.escape(null == (jade_interp = pageNum) ? "" : jade_interp)) + "</span></div></div>");}.call(this,"pageHref" in locals_for_with?locals_for_with.pageHref:typeof pageHref!=="undefined"?pageHref:undefined,"pageNum" in locals_for_with?locals_for_with.pageNum:typeof pageNum!=="undefined"?pageNum:undefined));;return buf.join("");
}

});
