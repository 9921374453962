define('EventMediaHandler',['underscore'], function (_) {

    var EventMediaHandler = function(app, mediaPlaybackType) {
        this._app = app;
        this._mediaPlaybackType = mediaPlaybackType;
        this._step = 0.1;
        this._limit = 0;
        this._stop = false;
        this._firstRun = true;

        this._equalLimit = this._step / 10;
    };

    EventMediaHandler.prototype.pause = function() {
        this._stop = true;
    };

    EventMediaHandler.prototype.start = function() {
        this._stop = false;
    };

    EventMediaHandler.prototype.restart = function() {
        this._limit = 0;
        this._stop = false;
    };

    EventMediaHandler.prototype.checkAndSend = _.throttle(function(percent) {
        if (this._firstRun) {
            // hack, because first percent is way off for some reason.
            this._firstRun = false;
            return;
        }

        if (this._stop) {
            return;
        }

        while (percent > (this._limit + this._step)) {
            // update _limit until it's larger than percent
            // this is typically if the user skips to the middle of the media stream.
            this._limit += this._step;
        }

        if (this._isEqual(percent, this._limit) || (this._isEqual(this._limit, 1) && this._isEqual(percent, 0))) {

            this._sendEventCallback(this._limitToDisplayPercent());

            this._limit += this._step;
            if (this._limit > 1) {
                this._limit = 0;
                this._stop = true;
            }
        }
    }, 100);

    EventMediaHandler.prototype._isEqual = function(a, b) {
        return (Math.abs(a - b) < this._equalLimit);
    };

    EventMediaHandler.prototype._limitToDisplayPercent = function() {
        return Math.round(this._limit * 100) + '%';
    };

    EventMediaHandler.prototype._sendEventCallback = function(percent) {
        var user = this._app.get('activeUser');
        if (!user) {
            return;
        }

        var userbook = user.get('activeUserbook');
        if (!userbook) {
            return;
        }

        var publisher = userbook.get('publisherRef');

        var bookresource = userbook.getBookResource();
        var bookTitle = '';
        if (bookresource) {
            bookTitle = bookresource.get('title');
        }

        this._app.plausibleManager.sendEvent(
            publisher,
            "Leseren",
            this._mediaPlaybackType + "avspilling (" + percent + ")",
            bookTitle
        );
    };

    return EventMediaHandler;
});


