define('templates/errorView',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (incognitoMode, loggedin) {
buf.push("<div id=\"errorview\" hidden=\"hidden\"><div class=\"toptopbar\"><span class=\"leftSide\"><!-- - span.forside-link Forside--><!-- - span.katalog-link Katalog--></span><span class=\"rightSide\"><span class=\"loginInfo\"></span>");
if ( loggedin)
{
buf.push("<button class=\"btn-logout\">Logg ut</button>");
}
buf.push("</span></div><div class=\"topbar\"><span class=\"logo\"><img src=\"images/unibok_logo.png\" alt=\"Unibok logo\"/></span></div><div class=\"contentBox\"><span class=\"errorLabel\">Det oppstod en feil.</span><div class=\"content\"><p>Boka du prøvde å åpne finnes ikke eller du har ikke lisens.</p>");
if ( !incognitoMode)
{
buf.push("<ul><li> <a href=\"#/\">Gå til bokhylla for å se alle bøker du har lisens på</a></li></ul>");
}
buf.push("</div></div></div>");}.call(this,"incognitoMode" in locals_for_with?locals_for_with.incognitoMode:typeof incognitoMode!=="undefined"?incognitoMode:undefined,"loggedin" in locals_for_with?locals_for_with.loggedin:typeof loggedin!=="undefined"?loggedin:undefined));;return buf.join("");
}

});
