define('templates/carouselLightbox',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (bookCss, slides, undefined) {
buf.push("<dialog class=\"lightbox\"><div class=\"lightbox-header\"><button title=\"Lukk dialog\" class=\"closeButton\"></button></div><div class=\"content\"><section aria-label=\"Bildegalleri\"><p class=\"visually-hidden\">Det vises ett bilde av gangen. Bruk forrige og neste-knappene for å navigere. </p><link xmlns=\"http://www.w3.org/1999/xhtml\"" + (jade.attr("href", "" + (bookCss) + "", true, false)) + " rel=\"stylesheet\" type=\"text/css\"/><div class=\"clickArea-prev\"><button aria-label=\"Forrige bilde\" class=\"btn-prev\"></button></div><div class=\"slides\">");
// iterate slides
;(function(){
  var $$obj = slides;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var slide = $$obj[$index];

buf.push("<div" + (jade.attr("data-index", "" + (slide.index) + "", true, false)) + " role=\"group\"" + (jade.attr("aria-label", "Bilde " + (slide.index + 1) + " av " + (slides.length) + "", true, false)) + " class=\"slide\"><div class=\"image\"><div class=\"alignmentHelper\"><img" + (jade.attr("src", slide.imgUrl, true, false)) + (jade.attr("alt", slide.accessibilityDescription, true, false)) + "/></div></div>");
if ( slide.captionTitle || slide.caption)
{
buf.push("<div class=\"caption\">");
if ( slide.captionTitle)
{
buf.push("<span class=\"caption-title\">" + (jade.escape(null == (jade_interp = slide.captionTitle) ? "" : jade_interp)) + "</span>");
}
buf.push((null == (jade_interp = slide.caption) ? "" : jade_interp) + "</div>");
}
buf.push("</div>");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var slide = $$obj[$index];

buf.push("<div" + (jade.attr("data-index", "" + (slide.index) + "", true, false)) + " role=\"group\"" + (jade.attr("aria-label", "Bilde " + (slide.index + 1) + " av " + (slides.length) + "", true, false)) + " class=\"slide\"><div class=\"image\"><div class=\"alignmentHelper\"><img" + (jade.attr("src", slide.imgUrl, true, false)) + (jade.attr("alt", slide.accessibilityDescription, true, false)) + "/></div></div>");
if ( slide.captionTitle || slide.caption)
{
buf.push("<div class=\"caption\">");
if ( slide.captionTitle)
{
buf.push("<span class=\"caption-title\">" + (jade.escape(null == (jade_interp = slide.captionTitle) ? "" : jade_interp)) + "</span>");
}
buf.push((null == (jade_interp = slide.caption) ? "" : jade_interp) + "</div>");
}
buf.push("</div>");
    }

  }
}).call(this);

buf.push("</div><div class=\"dotIndex\"></div><div class=\"clickArea-next\"><button aria-label=\"Neste bilde\" class=\"btn-next\"></button></div></section></div></dialog>");}.call(this,"bookCss" in locals_for_with?locals_for_with.bookCss:typeof bookCss!=="undefined"?bookCss:undefined,"slides" in locals_for_with?locals_for_with.slides:typeof slides!=="undefined"?slides:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

});
