/* global define, location, window, navigator, document, console, alert, Offline, Bugsnag, localStorage*/
/**
 * @module  webapp
 * @namespace  Views
 * @class  AppView
 */
define('views/AppView',[
    'require',
    'jquery',
    'backbone',
    'views/LoginView',
    'views/ConfirmLoginView',
    'views/LogoutView',
    'views/BookshelfView',
    'views/ErrorView',
    'views/OverlayView',
    'views/ErrorInfoView',
    'views/DialogView',
    'templates/splash',
    'bowser',
    'UnibokRouter',
    'redirectToLogin',
    'ReadiumUnloadedException',
    'underscore'
],
function (
    require,
    $,
    Backbone,
    LoginView,
    ConfirmLoginView,
    LogoutView,
    BookshelfView,
    ErrorView,
    OverlayView,
    ErrorInfoView,
    DialogView,
    splashScreen,
    bowser,
    UnibokRouter,
    redirectToLogin,
    ReadiumUnloadedException,
    _)
{

    return Backbone.View.extend({
        initialize: function (options) {
            var me = this;
            this._mobileApp = (options && options.mobileApp) || false;

            this.loginView = new LoginView(this.model);
            this.confirmLoginView = new ConfirmLoginView(this.model);
            this.logoutView = new LogoutView(this.model);
            this.bookshelfView = new BookshelfView(this.model, {mobileApp: this._mobileApp});
            this.errorView = new ErrorView(this.model);
            this.readerView = null; // Lazy init

            this.currentView = null;
            this.nohashchange = false; // Used to ignore hash url changes in onhashchange

            if (this.model.isDebugMode()) {
                $('body').addClass('debugMode');
            }


            // register global javascript error handler, but also call previously registered onerror handler
            // this is because bugsnag registers its own handler which is better at reporting bugs to bugsnag.
            //
            // Do not register global error handler when on localhost or develop is set
            if (document.URL.indexOf('localhost') !== -1 || document.URL.indexOf('develop=') !== -1) {
                console.log("DEBUG: Global error handler not installed");
            } else {
                var bugsnagErrorHandler = window.onerror;
                window.onerror = function(message, source, lineno, colno, error) {
                    if (error && typeof error === 'object' && error instanceof ReadiumUnloadedException) {
                        // This exception is thrown to halt readiums loading
                        // should not trigger any bugsnag or error message.
                        // See ReadiumFactory.js:~80
                        return;
                    }

                    if (message === "ResizeObserver loop limit exceeded" ) {
                        // This happens for some videos and should be ignored
                        return;
                    }

                    if (bugsnagErrorHandler) {
                        bugsnagErrorHandler.apply(me, arguments);
                    }

                    me.errorHandler.apply(me, arguments);
                };
            }

            this._readerViewPlaceholder = $('<div/>').hide();

            // Used for global error messages
            var message = $('<div/>').attr({'id': 'message','hidden': 'hidden'}).hide();
            var blockingLayer = $('#blockingLayer');

            // accessibility div for screen reader
            this.ariaLiveRegion = $('<div id="aria-live-region" aria-live="assertive" aria-atomic="true" aria-relevant="all" class="visually-hidden"></div>');

            $('body').append(
                message,
                this.ariaLiveRegion,
                me.loginView.$el,
                me.confirmLoginView.$el,
                me.logoutView.$el,
                me.bookshelfView.$el,
                this._readerViewPlaceholder,
                blockingLayer,
                me.errorView.$el
            );

            this.model.on('change:activeUser', this.onActiveUserChanged, this);
            this.model.on('change:isOnline', this.setOnlineStatusOnBody, this);
            this.model.on('loggedInFromVendor', this.onLoggedInFromVendor, this);
            this.model.on('requestLogout', this.enterLogoutView, this);
            this.model.on('requestCancelLogout', this.enterBookshelfView, this);
            this.model.on('resourceIsUnavailable', this.onResourceIsUnavailable, this);
            this.model.on('userSessionLost', this.onUserSessionLost, this);

            this.setOnlineStatusOnBody(this.model, this.model.get('isOnline'));

            // iOS height bug fix (EB-499)
            if (navigator.userAgent.match(/iPad/i) && window.innerHeight !== document.documentElement.clientHeight) {
                var fixViewportHeight = function() {
                    $('html').height(window.innerHeight);
                };

                window.addEventListener("orientationchange", fixViewportHeight, false);
                fixViewportHeight();
            }

            // initalize the application router
            this._router = new UnibokRouter(this);
            this.model.getUserFromSession()
                .then(function(user) {
                    me.model.set('activeUser', user);
                    me.checkOrdbokHash(user);
                    user.load(function() {
                        //call to begin monitoring uri and route changes
                        Backbone.history.start();
                    });
                })
                .fail(function(err) {
                    if (me.model.get('mobileApp')) {
                        Backbone.history.start();
                        me.enterLoginView();
                    } else {
                        if (err.status === 403 && err.responseJSON.message === 'Not authorized') {
                            redirectToLogin();
                        } else {
                            if (!me.model.get('isOnline')) {
                                new DialogView({
                                    "title": "Ingen innlogget bruker",
                                    "message": "Du ser ut til å ikke være tilkoblet internett, og det finnes ingen innlogget bruker med lokale data på denne maskinen",
                                    "isAlert": true,
                                });
                            } else {
                                new DialogView({
                                    "title": "Ingen innlogget bruker",
                                    "message": "Det finnes ingen innlogget bruker med lokale data på denne maskinen",
                                    "isAlert": true,
                                    "onConfirm": function () {
                                        redirectToLogin();
                                    }
                                });
                            }
                        }
                    }
                });
        },

        navigateToEpub: function(fragments, options) {
            var url = fragments.join('/');

            this._router.navigate(url, options);
        },

        /**
         * Custom errorhandler. Does _not_ report to Bugsnag because we use bugsnags own
         * 'onerror' handler. See 'onerror' registration in the constructor.
         * @method  errorHandler
         * @param  {[type]} msg      [description]
         * @param  {[type]} url      [description]
         * @param  {[type]} line     [description]
         * @param  {[type]} column   [description]
         * @param  {[type]} errorObj [description]
         * @return {boolean}          supressErrorAlert
         */
        errorHandler: function (msg, url, line, column, errorObj) {
            var message = "Detected uncaught exception: " + msg + "\nurl: " + url + "\nline #: " + line;

            console.log(message);
            // https://stackoverflow.com/questions/5916900/detect-version-of-browser
            navigator.sayswho = (function () {
                var ua = navigator.userAgent, tem,
                    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
                if (/trident/i.test(M[1])) {
                    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                    return 'IE '+(tem[1] || '');
                }
                if (M[1] === 'Chrome') {
                    tem = ua.match(/\bOPR\/(\d+)/);
                    if (tem !== null) { return 'Opera '+tem[1]; }
                }
                M = M[2] ? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
                if ((tem = ua.match(/version\/(\d+)/i)) !== null) {
                    M.splice(1, 1, tem[1]);
                }
                return M.join(' ');
            })();

            var errorInfo = null;
            if (errorObj !== null && errorObj !== undefined) { //so it won't blow up in the rest of the browsers
                console.log('Error: ' + errorObj.stack);
                var user = this.model.get('activeUser');
                var epubId = user.get('activeUserbook').get('epubid') ? '| EpubID: '+ user.get('activeUserbook').get('epubid') : '';

                errorInfo = new ErrorInfoView({
                    error: errorObj,
                    envInfo : Date().toLocaleString().slice(0, 24)+' | '+navigator.sayswho+' | '+user.get('eduPersonTargetedID')+epubId
                });
            }

            var successMessage = new OverlayView({
                type: 'error',
                title: 'Feilmelding',
                text: "Unibok har dessverre støtt på et problem. Denne feilen er nå registrert og vi håper å løse problemet raskt. Du kan fortsette å bruke Unibok i mellomtiden.",
                buttonText: "OK",
                buttonHandler: function () {
                    location.reload(false);
                },
                automaticClose: false,
                manualClose: false,
                moreInfo: errorInfo
            });

            // Report this error via ajax so you can keep track
            // of what pages have JS issues
            var data = {
                msg: msg,
                url: url,
                browser: navigator.sayswho,
                line: line,
                column: column,
                stack: errorObj ? errorObj.stack : 'Not available'
            };

            $.ajax({
                type: "POST",
                url: '/unhandled-client-error',
                data: data,
                dataType: 'json'
            });

            var suppressErrorAlert = true;

            // If you return true, then error alerts (like in older versions of
            // Internet Explorer) will be suppressed.
            return suppressErrorAlert;
        },

        /**
         * Unload previous user and load new user
         * @method  onActiveUserChanged
         * @param  {object} app  Models/App.js
         * @param  {object} user Models/User.js
         */
        onActiveUserChanged: function (app, user) {
            var me = this;

            if (app.previous('activeUser')) {
                app.previous('activeUser').off('change:activeUserbook', this.onActiveUserActiveUserbookChanged, this);
                app.previous('activeUser').unload();
            }

            if (user) {
                if (user.get('anonymous')) {
                    $('body').addClass('anonymousUser');
                } else {
                    $('body').removeClass('anonymousUser');
                }

                if(window.Bugsnag) {
                    window.Bugsnag.setUser(user.get('userid'));
                    window.Bugsnag.addMetadata('targetedID',
                        {
                            targetedID: user.get('eduPersonTargetedID')
                        }
                    );
                }

                user.load(function() {
                    me._router.navigate("", { trigger: true });
                });
            }
            else {
                if (me.model.get('mobileApp')) {
                    this.enterLoginView(app.previous('activeUser'));
                }
            }

            return false;
        },

        loadUserbook: function(user, publisher, bookId, epubId, page) {
            var me = this;
            var isLocalResource = false;

            var bookResource = this.model.getBookResource({
                    bookId: bookId,
                    publisherRef: publisher
                },
                isLocalResource);

            if (!bookResource) {
                // Book does not exist or user has no license
                // Book is not available for this user
                this.enterErrorView(403);
                return;
            }
            if (this.model.get('isOnline') === false && !bookResource.isAvailableOffline()) {
                $(document.activeElement).attr({ 'data-dialog-spawned': 1 });
                new DialogView({
                    "title": "Ikke tilgjengelig frakoblet",
                    "message": "Du må være tilkoblet internett for å lese denne boka. Når du har tilgang til internett kan du velge å gjøre boka tilgjengelig frakoblet og siden lese den uten nettilgang.",
                    "isAlert": true,
                    "onConfirm": function () {
                        // feideusers is sent to the bookshelf, anonymous user reloads book (hopefully with internet connection)
                        if (!user.get('anonymous')) {
                            Backbone.history.navigate('', {trigger: true});
                        } {
                            window.location.reload();
                        }
                    },
                    "appendTo": 'body'
                });
                return;
            }

            var latestEpubid = bookResource.get('epubid');

            var userbook = user.getUserbookFromShelf(bookResource);

            if (this.previewMode) {
                delete this.previewMode;
                user.set('previewMode', true);

                userbook = _.extend({}, userbook);
                userbook._bookResource = _.extend({}, bookResource);
                userbook._bookResource.set('epubid', epubId);
                latestEpubid = epubId;

                //need to re-instantiate RM implementation to propagate book resource change
                userbook._bookResource.reloadResourceManager();
            }

            if (!userbook) {
                // Book does not exist or user has no license
                // Book is not available for this user
                this.enterErrorView(403);
                return;
            }

            if (userbook.get('cloudStatus') === 'noLicence') {
                $(document.activeElement).attr({ 'data-dialog-spawned': 1 });
                new DialogView({
                    "title": "Boka er ikke tilgjengelig",
                    "message": "Du har ikke lisens på denne boka.",
                    "isAlert": true,
                    "appendTo": 'body'
                });
                Backbone.history.navigate('', {trigger: true});
                return;
            }

            document.title = userbook.getBookResource().get('title');
            userbook.set('epubid', epubId ? epubId : latestEpubid);
            if (epubId !== latestEpubid) {
                var path = [publisher, bookId, latestEpubid];
                var fragment = Backbone.history.getFragment().split('/');
                if (fragment[0] === 'bok' || fragment[0] === 'abok' || fragment[0] === 'preview') {
                    path.unshift(fragment[0]);
                }
                this.navigateToEpub(path, { trigger: false, replace: true });
            }
            user.set('activeUserbook', userbook);
            this.checkOrdbokHash(user);

            $('body').append(splashScreen({
                coverUrl: userbook.getBookResource().get('coverUrlToDisplay'),
                coverTitle: userbook.getBookResource().get('title'),
                ie11: $('body').hasClass('ie11'),
                mobileApp: this._mobileApp
            }));

            // accessibility
            if(window.liveRegionUpdater === undefined) {
                me.ariaLiveRegion.html("Laster inn boka");
                window.liveRegionUpdater = window.setInterval(function () {
                    me.ariaLiveRegion.html("");
                    me.ariaLiveRegion.html("Laster inn boka");
                }, 5000);
    
            }

            user.get('activeUserbook').getBookResource().set('loadError', null); //reset any previous load error
            user.get('activeUserbook').getBookResource().on('change:loadError', this.onBookResourceLoadError, this);
            userbook.set('isOpening', true);
            userbook.load()
                .then(function(){
                     me._enterReaderView(userbook, page);
                })
                .fail(
                    function (err) {
                        if (err.title && err.message) {
                            new DialogView({
                                "title": err.title,
                                "message": err.message,
                                "isAlert": true,
                                "appendTo": 'body'
                            });
                        }

                       user.set("activeUserbook", null);
                       me.enterBookshelfView();
                });
        },

        /**
         * Gets the reader view instance asyncronously. This is because we only
         * want to include ReaderView when needed as this will also include a lot
         * of other dependencies.
         *
         * @returns {Deffered} a promise that will resolve to the reader view.
         */
        _getReaderView: function() {
            var me = this;
            var defer = $.Deferred();

            if (!me.readerView) {
                require(['views/ReaderView', 'soundmanager'], function (ReaderView, soundmanager) {
                    me.readerView = new ReaderView({
                        app: me.model,
                        appView: me,
                    });
                    me._readerViewPlaceholder.replaceWith(me.readerView.$el);
                    soundmanager.initialize(me.model);
                    defer.resolve(me.readerView);
                });
            }
            else {
                defer.resolve(this.readerView);
            }

            return defer;
        },

        /**
         * Leaves current view and initialize readerview and enters it
         * @method  _enterReaderView
         * @param  {object} userbook
         */
        _enterReaderView: function (userbook, page) {
            var me = this;

            // set reading position if page is specified
            if (page) {
                userbook.setReaderPosition(page);
            }

            this._getReaderView().then(function() {
                if (me.currentView) {
                    me.currentView.leave();
                }

                me.currentView = me.readerView;
                me.readerView.enter(userbook);
            });
        },

        /**
         *
         * @method enterLoginView
         */
        enterLoginView: function () {
            var me = this;
            if (this.currentView) {
                this.currentView.leave();
            }
            this.currentView = this.loginView;
            this.loginView.enter();
            $('body').removeClass('blocking');
        },


        onLoggedInFromVendor: function() {
            this._router.navigate('/confirmlogin', { trigger: true });
        },

        /**
         *
         * @method  enterConfirmLoginView
         * @return {[type]} [description]
         */
        enterConfirmLoginView: function () {
            var me = this;
            if (this.currentView) {
                this.currentView.leave();
            }
            this.currentView = this.confirmLoginView;
            this.confirmLoginView.enter();
            $('body').removeClass('blocking');
        },

        /**
         * @method  enterLogoutView
         */
        enterLogoutView: function () {
            var me = this;
            if (this.currentView) {
                this.currentView.leave();
            }
            this.currentView = this.logoutView;
            this.logoutView.enter();
            $('body').removeClass('blocking');
        },

        /**
         * @method  logFail
         * @param  {[type]} evt [description]
         */
        logFail: function (evt) {
            console.log("FILE API error", evt.target.error.code);
        },

        /**
         * Leaves current view and goes to bookshelf
         * @method enterBookshelfView
         * @param  {object} user
         */
        enterBookshelfView: function (user) {
            var me = this;
            if (! user && me.model.get('activeUser')) {
                user = me.model.get('activeUser');
            }

            if (! user) {
                location.reload(false);
                return false;
            }

            if (user.get('anonymous')) {
                $(document.activeElement).attr({ 'data-dialog-spawned': 1 });
                new DialogView({
                    isAlert: true,
                    title: 'Logg inn med FEIDE',
                    message: 'Du bruker Unibok uten innlogging. For å få tilgang til bokhylla og flere nyttige studieverktøy, må du logge inn med FEIDE. Vil du logge inn? ',
                    onConfirm: function(){localStorage.clear();window.location ='/login';}
                });
                return false;
            }

            if (me.currentView) {
                me.currentView.leave();
            }

            me.bookshelfView._showSamtykke = me._showSamtykke;
            this.checkOrdbokHash(user);
            me.currentView = me.bookshelfView;
            me.bookshelfView.enter(user);
            $('body').removeClass('blocking');
        },

        /**
         * shows error message to user
         * @method  enterErrorView
         * @param  {[type]} code [description]
         */
        enterErrorView: function (code) {
            if (this.currentView) {
                this.currentView.leave();
            }
            this.currentView = this.errorView;
            this.errorView.enter(code);
            $('body').removeClass('blocking');
        },

        onUserSessionLost: function () {
            if (this._mobileApp) {
                this.enterLoginView();
            } else {
                redirectToLogin();
            }
        },

        /** Called if server is down or user is offline without the webview discovering it
         * @method  onResourceIsUnavailable
         */
        onResourceIsUnavailable: function () {
            console.debug('En ressurs er utilgjengelig');
            this.enterBookshelfView();
        },

        onBookResourceLoadError: function () {
            this.enterErrorView(404);
        },

        setOnlineStatusOnBody: function (model, isOnline) {
            if (isOnline) {
                $('body').removeClass('offline');
            } else {
                $('body').addClass('offline');
            }
        },

        checkOrdbokHash: function (user) {
            var me = this;
            var generateNew = false;
            // first check if we got valid hash already
            if(user.getOrdbokHash()) {
                var storedStamp = user.getOrdbokHash().substring(0, 20);
                var nowStamp = new Date().toISOString().replace(/\..+/, 'Z');
                var validStamp = new Date(new Date(storedStamp).getTime() + 60 * 60 * 24 * 1000)
                    .toISOString().replace(/\..+/, 'Z');
                if (validStamp <= nowStamp) {
                    generateNew = true;
                }
            } else {
                generateNew = true;
            }
            if (generateNew) {
                $.get(me.model.get('backendServerUrl') + '/ordbokhash')
                    .done(function (response) {
                        if (response.ordbokHash) {
                            user.setOrdbokHash(response.ordbokHash);
                        }
                    });
            }
        }

    });
});

