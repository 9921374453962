define('templates/overlayView',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (buttonText, text, title) {
buf.push("<div role=\"dialog\" aria-labelledby=\"dialog-title\" aria-describedby=\"dialog-intro\" class=\"overlay\"><div class=\"header\"><div class=\"logo\"></div><div class=\"separator\"></div><div class=\"unibok\"></div><button type=\"button\" title=\"lukk dialog\" class=\"btn-closebtn\"></button></div><div id=\"dialog-title\" class=\"title\">" + (jade.escape(null == (jade_interp = title) ? "" : jade_interp)) + "</div><div id=\"dialog-intro\" class=\"text\">" + (jade.escape(null == (jade_interp = text) ? "" : jade_interp)) + "</div><div class=\"moreInfo\"></div><div class=\"moreInfoBtn\">Vis/skjul mer informasjon</div><button class=\"button\">" + (jade.escape(null == (jade_interp = buttonText) ? "" : jade_interp)) + "</button></div>");}.call(this,"buttonText" in locals_for_with?locals_for_with.buttonText:typeof buttonText!=="undefined"?buttonText:undefined,"text" in locals_for_with?locals_for_with.text:typeof text!=="undefined"?text:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}

});
