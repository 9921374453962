define('templates/navigationBarView',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (mobileApp) {
buf.push("<button id=\"goBack-btn\" aria-label=\"Gå tilbake\">");
if ( mobileApp)
{
buf.push("<span class=\"icon\"></span>");
}
else
{
buf.push("<svg class=\"icon\"><use xlink:href=\"../images/leser_ikoner/leser_ikoner_tilbakepil.svg#pil\" class=\"svgicon\"></use></svg>");
}
buf.push("</button><div class=\"progressbar\"><div class=\"progressFill\"><div class=\"knob\"><div class=\"decoration bar1\"></div><div class=\"decoration bar2\"></div><div class=\"hitArea\"></div><div class=\"pagePreviewBox\"><div>Pagenumber</div><div class=\"funnel\"><div class=\"funnelDiamond\"></div></div></div></div></div></div><div class=\"textualIndicators\"><div class=\"chapterLabel\"></div><div class=\"firstPageNumber\"></div><div class=\"lastPageNumber\"></div></div>");}.call(this,"mobileApp" in locals_for_with?locals_for_with.mobileApp:typeof mobileApp!=="undefined"?mobileApp:undefined));;return buf.join("");
}

});
