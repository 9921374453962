/**
 * @module webapp
 * @namespace  Models
 * @class  PageNumberIndicator
 */
define('models/PageNumberIndicator',['jquery', 'models/MarginIndicatorBase'], function($, MarginIndicatorBase) {

    var PageNumberIndicator = function (domElm, marginManager) {
        var bounds = domElm.getBoundingClientRect();
        var top = bounds.top;
        MarginIndicatorBase.call(this, top, 0, marginManager);
        this.pageNumber = $(domElm).attr('title');
    };

    PageNumberIndicator.prototype = Object.create(MarginIndicatorBase.prototype);

    PageNumberIndicator.prototype.render = function() {
        return $('<div class="pageNumber" aria-hidden="true">' + this.pageNumber + '</div>');
    };

    return PageNumberIndicator;

});

