define('resourcefetchers/ResourceFetcherBase',[], function(){

    var ResourceFetcherBase = function (bookResource) {
        this._bookResource = bookResource;
        this._appModel = bookResource._app;
    };

    ResourceFetcherBase.prototype.getBookInfo = function () {};

    /*
     * Gets the base url of the epub, this means the path to the epub resources on the
     * server + the opf path. E.g.:
     * epub path on server <- this is the root url of the epub.
     *     OPS <- this is the base url of the epub.
     */
    ResourceFetcherBase.prototype.getBaseUrl = function () {
        return this.getRootUrl() + '/' + this._bookResource._opfPath;
    };

    /*
     * Gets the base url of the epub, this means the path to the epub resources on the
     * server.
     * epub path on server <- this is the root url of the epub.
     *     OPS <- this is the base url of the epub.
     */
    ResourceFetcherBase.prototype.getRootUrl = function () {
        return this._appModel.get('backendServerUrl') + // if this is a mobile app we prepend the servername 
               '/bookresource/publisher/' +
               encodeURIComponent(this._bookResource.get('publisherRef')) +
               '/book/' +
               encodeURIComponent(this._bookResource.get('bookId')) +
               '/epub/' +
               encodeURIComponent(this._bookResource.get('epubid'));
    };

    ResourceFetcherBase.prototype.getIframeContentCssUrl = function(callback) {
        callback('/css/iframeContent.css');
    };

    ResourceFetcherBase.prototype.fetchSpineItem = function (item, callback) {};

    ResourceFetcherBase.prototype.fetchFileContentsText = function (fileUrl) {};

    ResourceFetcherBase.prototype.getAssetUrl = function (originalUrl, mainCallback) {};

    ResourceFetcherBase.prototype.download = function (callback) {
        callback(null);
    };

    ResourceFetcherBase.prototype.abortDownload = function () {};

    ResourceFetcherBase.prototype.removeDownloadedBook = function (callback) {
        callback(null);
    };

    ResourceFetcherBase.prototype.resolveBookCover = function () {};

    ResourceFetcherBase.prototype.checkIsDownloaded = function () {};

    ResourceFetcherBase.prototype.checkIntegrity = function () {};

    ResourceFetcherBase.prototype.getResourceUrl = function (url, callback) {
        callback(null, url);
    };

    return ResourceFetcherBase;

});

