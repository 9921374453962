define('templates/TTSEditor',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div hidden=\"hidden\" class=\"dialog-TTS\"><div class=\"waitSelectionMessage\">Marker tekst i boka for å få den opplest</div><div class=\"TTS\"><select id=\"voiceList\" aria-label=\"Velg stemme\"></select><button class=\"btn-TTS\"><span>Les opp</span></button></div></div>");;return buf.join("");
}

});
