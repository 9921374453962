/* global window */

/* This module maintains an array of epubs that are stored in localforage
 * It initializes the list from localforage, and saves it to localforage every time an entry is added/removed
 */

define('DownloadedEpubsList',[
        'localforage',
        'jquery',
    ],
    function (localforage, $) {

        var EpubList = function (systemStorage) {
            var me = this;
            me.systemStorage = systemStorage;
            me._deferredFlag = false; // used to only fetch the list from localforage once
        };

        EpubList.prototype._getDownloadedEpubsList = function () {
            var me = this;
            var list = $.Deferred();
            if (this._epubArray) {
                return list.resolve(me._epubArray).promise();
            } else {
                if (!me._deferredFlag) {
                    this._getListFromLocalforage()
                        .then(function (localforageList) {
                            list.resolve(localforageList);
                        });
                }
            }
            return list.promise();
        };

        EpubList.prototype._getListFromLocalforage = function (deferred) {
            var me = this;
            return me.systemStorage.getItem('epubsDownloadedList')
                .then(function (list) {
                    if (list) {
                        me._epubArray = list;
                    } else {
                        me._epubArray = [];
                    }
                    return me._epubArray;
                });
        };

        EpubList.prototype.addToDownloadedList = function (epubId) {
            var me = this;
            return me._getDownloadedEpubsList()
                .then(function (list) {
                    if (list.indexOf(epubId) !== -1) {
                    } else {
                        list.push(epubId);
                        me._writeDownloadedBooksList();
                    }
                });

        };

        EpubList.prototype.removeFromDownloadedList = function (bookId) {
            var me = this;
            me._getDownloadedEpubsList()
                .then(function (list) {
                    if (list.indexOf(bookId) !== -1) {
                        list.splice(list.indexOf(bookId), 1);
                        me._writeDownloadedBooksList();
                    }
                });
        };

        EpubList.prototype._writeDownloadedBooksList = function () {
            var me = this;
            me._getDownloadedEpubsList()
                .then(function (list) {
                    me.systemStorage.setItem('epubsDownloadedList', list);
                });
        };


        return EpubList;
    });
