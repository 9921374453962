define('views/ConfirmLoginView',['jquery', 'backbone', 'underscore', 'templates/confirmLoginView'], function ($, Backbone, _, mainTemplate) {

    return Backbone.View.extend({
        initialize: function (appModel) {
            this._appModel = appModel;
            this.render();
        },

        events: {
            'click .login': 'confirmLogin'
        },

        render: function () {
            this.setElement(mainTemplate());
            return this;
        },

        confirmLogin: function () {
            var me = this;
            me.delegateEvents(
                _(this.events).omit(['click .login'])
            );
            me._appModel.getUserFromSession()
                .then(function (user) {
                    me._appModel.set('activeUser', user);
                    user.load(function () {
                        //call to begin monitoring uri and route changes
                        //Backbone.history.start();

                        me.delegateEvents(this.events);
                    });
                })
                .fail(function (err) {
                    me._appModel.set('activeUser', null);
                    //Backbone.history.start();
                    me.delegateEvents(this.events);
                });

            return false;
        },

        enter: function () {
            this.$el.addClass('active').removeAttr('hidden');
        },

        leave: function () {
            this.$el.removeClass('active').attr('hidden', 'hidden');
        }
    });
});

