define('templates/readerView',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (mobileApp) {
buf.push("<div id=\"reader\"><a href=\"#epub-reader-container\" class=\"skip-to-content\">Hopp til hovedinnhold</a><div class=\"topbar\"></div><div class=\"panelBox\"></div><div class=\"contentBox\"><div class=\"chapter-navigation--top\"><button class=\"chapter-btn chapter-btn--prev\">");
if ( mobileApp)
{
buf.push("<span class=\"icon\"></span>");
}
else
{
buf.push("<span class=\"svg\"><svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_forrige.svg#forrige\"></use></svg></span>");
}
buf.push("<span>Forrige kapittel</span></button><button class=\"chapter-btn chapter-btn--next\"><span>Neste kapittel</span>");
if ( mobileApp)
{
buf.push("<span class=\"icon\"></span>");
}
else
{
buf.push("<span class=\"svg\"><svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_neste.svg#neste\"></use></svg></span>");
}
buf.push("</button></div><main id=\"epub-reader-container\" tabindex=\"-1\"></main><div class=\"chapter-navigation--bottom\"><button class=\"chapter-btn chapter-btn--prev\">");
if ( mobileApp)
{
buf.push("<span class=\"icon\"></span>");
}
else
{
buf.push("<span class=\"svg\"><svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_forrige.svg#forrige\"></use></svg></span>");
}
buf.push("<span>Forrige kapittel</span></button><button class=\"chapter-btn chapter-btn--next\"><span>Neste kapittel</span>");
if ( mobileApp)
{
buf.push("<span class=\"icon\"></span>");
}
else
{
buf.push("<span class=\"svg\"><svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_neste.svg#neste\"></use></svg></span>");
}
buf.push("</button></div></div><div class=\"audioWrapper\"><button type=\"button\" aria-label=\"Minimer lydavspiller\" aria-expanded=\"true\" class=\"audioBtn-collapse\"></button><div id=\"audioPlayer\"></div><button type=\"button\" aria-label=\"Lukk lydavspiller\" class=\"audioBtn-close\"></button></div><div class=\"bottombar\"></div></div>");}.call(this,"mobileApp" in locals_for_with?locals_for_with.mobileApp:typeof mobileApp!=="undefined"?mobileApp:undefined));;return buf.join("");
}

});
