/*global, console */
define('views/ImageLightboxView',['jquery', 'underscore', 'views/LightboxBase', 'templates/imageLightbox'], function ($, _, LightboxBase, mainTemplate) {
    return LightboxBase.extend({
        initialize: function (data) {
            LightboxBase.prototype.initialize.call(this, data);
            _.extend(this.events, LightboxBase.prototype.events);

            this._data = data;
            this._data.bookCss = this.getBookCssPath(data.bookResource.getBaseUrl());
            this._imageIsSvg = this._data.imageurl.split('.').pop() === 'svg';

            this.render();

            if (this._imageIsSvg) {
                this.requestSize();
            } else {
                this.requestSize(this._data.imageWidth, this._data.imageHeight, true);
            }
            this.show();
            this.updateLayout();
        },

        /**
         * Calls baseclass implementation. The basecalls sets the height on the lightbox but we want it to adjust automatically.
         * This because the of the caption element which can be from 0 to 80 px
         */
        requestSize: function(requestedContentWidth, requestedContentHeight, keepAspectRatio, captionHeight) {
            LightboxBase.prototype.requestSize.call(this, requestedContentWidth, requestedContentHeight, keepAspectRatio, captionHeight);
            //this.$el.css('height', 'auto');
        },

        updateLayout: function () {
            var capsize = { width: 0, height: 0 };
            var imgcontsize = { width: 0, height: 0 };
            var imgsize = { width: 0, height: 0 };
            // measure image/caption and scale to fit the widest
            var cap = this.$el.find('.caption');
            if (cap.length) {
                capsize = cap[0].getBoundingClientRect();
            }
            var captionHeight = (this._data.caption && this._data.caption.trim().length > 0) ? 80 : 0;
            // reset to initial styling before calculations (to accommodate scaling up):
            this.$el.css({
                'max-width': 'none',
                height: '90%',
                top: '5%'
            });
            this.requestSize(this._requestedContentWidth, this._requestedContentHeight, this._keepAspectRatio, captionHeight);

            var img = this.$el.find('.image img');
            if (img[0]) {
                imgsize = img[0].getBoundingClientRect();
                if (!imgsize.width) {
                    var me = this;
                    img[0].onload = function() {
                        me.updateLayout();
                    };
                }
            }

            var imgcont = this.$el.find('.image');// container (the image is reported as zero height/width while rendering)
            if (imgcont.length) {
                imgcontsize = imgcont[0].getBoundingClientRect();
                var bodyWidth = $('body').width();
                var bodyHeight = $('body').height();
                var imgHeight = imgcontsize.height * 0.95;// 95% css max-height
                var imgScale = imgHeight / this._requestedContentHeight;
                var imgWidth = Math.round(this._requestedContentWidth * imgScale);
                var pad = this._lightboxPadding_width * 2;
                var contentWidth = Math.max(capsize.width + pad, imgWidth + pad);
                var maxSize = Math.floor(bodyWidth * 0.9);// 90%
                var reqWidth = Math.min(maxSize, contentWidth);
                var reqHeight = Math.round(60 + 40 + (imgsize.height || imgHeight) + capsize.height);
                this.$el.css({
                    'max-width': '100vw',
                    'height': reqHeight,
                    top: Math.round((bodyHeight - reqHeight) / 2) + 'px'
                });
            }
        },

        render: function () {
            this.setElement(mainTemplate(this._data));
            this.$el.addClass('imageLightbox');
            this.$el.on('touchmove', function (e) {if (! $(e.target).hasClass('caption')) {e.preventDefault();}});
            return this;
        }
    });
});

