/*global window, console, setTimeout */
define('views/SettingsPanel',[
    'jquery',
    'underscore',
    'views/PanelBase',
    'templates/settingsPanel'
], function ($, _, PanelBase, mainTemplate) {

    function glossLangMap(lang) {
        var langMap = {
            "no":  ["Norsk", "ltr"],
            "nb":  ["Norsk", "ltr"],
            "nn":  ["Nynorsk", "ltr"],
            "en":  ["English", "ltr"],
            "de":  ["Deutsch", "ltr"],
            "fr":  ["Français", "ltr"],
            "es":  ["Español", 'ltr'],
            "fi":  ["Suomi", "ltr"],
            "yi":  ["ייִדיש", "rtl"],            // Yiddish
            "ar":  ["العربية", "rtl"],          // Arabisk
            "prs": ["دری", "rtl"],              // Dari
            "ps":  ["پښتو", "rtl"],             // Pashto
            "ti":  ["ትግርኛ", "ltr"],            // Tigrinja
            "so":  ["Af-Soomaali", "ltr"],      // Somalisk
            "ru":  ["русский", "ltr"],          // Russisk
            "uk":  ["украї́нська мо́ва", "ltr"],  // Ukrainsk
            "ku":  ["Kurmancî", "ltr"],         // Kurmanji
            "sw":  ["Kiswahili", "ltr"],        // Swahili
            "tr":  ["Türkçe", "ltr"],           // Tyrkisk
            "pl":  ["Polski", "ltr"]            // Polsk

        };
        return langMap[lang] || ["Missing language", "ltr"];
    }


    return PanelBase.extend({
        initialize: function (options) {
            this._readerView = options.readerView;
            this._toggleValue = this._readerView._app.get('activeUser').get('keyboardShortcuts');

            this.render();
        },

        events: {
            'input #fontsize-slider': 'changeFontsize',
            'click .smaller-text': 'makeFontsizeSmaller',
            'keyup .smaller-text': 'makeFontsizeSmaller',
            'click .larger-text': 'makeFontsizeLarger',
            'keyup .larger-text': 'makeFontsizeLarger',
            'keyup #fontsize-slider': 'changeFontsizeByKeyboard',
            'click .btn-lang': 'chooseGlossaryLang',
            'click .close-icon': 'onClose', // defined in superclass
            'click #toggle-shortcuts': 'toggleShortcuts',
            'keyup #toggle-shortcuts': 'toggleShortcuts',
        },

        render: function () {
            this.setElement(mainTemplate({
                fontSize: this.convertFontSizeToSlider(this._fontSize || 100),
                mobileApp: this._readerView._app.get('mobileApp'),
                keyboardShortcuts: this._toggleValue,
            }));
            return this;
        },

        block: function (e) {
            e.preventDefault();
            return false;
        },

        setUserbook: function (userbook) {
            if (userbook !== this._userbook) {
                if (this._userbook) {
                    this._userbook.off(null, null, this);
                }
                this._userbook = userbook;

                if (userbook) {
                    this._fontSize = userbook.get('fontSize');
                }
            }
        },

        changeFontsizeByKeyboard: function (e) {

            // If it is not LEFT and RIGHT arrow, do nothing.
            if (e.keyCode !== 37 && e.keyCode !== 39) {
                return;
            }

            var fontSize = this.convertSliderToFontSize($('#fontsize-slider').val());
            if (e.keyCode === 37) {
                this.makeFontsizeSmaller(fontSize);
            } else if (e.keyCode === 39) {
                this.makeFontsizeLarger(fontSize);
            }

            this._fontSize = fontSize;
            this._readerView.changeFontsize(fontSize);
            $('#fontsize-slider').val(this.convertFontSizeToSlider(fontSize));

            // setTimeout(function() {
            //         $('#fontsize-slider').focus();
            //     }, 600);
        },

        makeFontsizeSmaller: function (e) {

            if (e.type === "keyup" && e.keyCode !== 13) {
                return;
            }
            var fontSize = this.convertSliderToFontSize($('#fontsize-slider').val());
            fontSize = parseInt(fontSize) - 10;
            this.checkStepValue(fontSize);
            this._fontSize = fontSize;
            this._readerView.changeFontsize(fontSize);
            $('#fontsize-slider').val(this.convertFontSizeToSlider(fontSize));
        },

        makeFontsizeLarger: function (e) {

            if (e.type === "keyup" && e.keyCode !== 13) {
                return;
            }
            var fontSize = this.convertSliderToFontSize($('#fontsize-slider').val());
            fontSize = parseInt(fontSize) + 10;
            this.checkStepValue(fontSize);
            this._fontSize = fontSize;
            this._readerView.changeFontsize(fontSize);
            $('#fontsize-slider').val(this.convertFontSizeToSlider(fontSize));
        },

        changeFontsize: function (e) {
            var fontSize;
            if (this._userbook.get('fontSize') === fontSize) {
                return false;
            } else {
                // Change step value based on slider position
                // so 100% is center position, 60% is min and 200% is max
                if (e.target.value >= 100) {
                    e.target.step = 10;
                } else {
                    e.target.step = 25;
                }

                if (e.target.value < 25) {
                    fontSize = 60;
                } else if (e.target.value >= 25 && e.target.value <= 49) {
                    fontSize = 70;
                } else if (e.target.value >= 50 && e.target.value <= 74) {
                    fontSize = 80;
                } else if (e.target.value >= 75 && e.target.value <= 99) {
                    fontSize = 90;
                } else {
                    fontSize = e.target.value;
                }
                this._fontSize = fontSize;
                this._readerView.changeFontsize(fontSize);
            }
            return false;
        },

        toggleShortcuts: function (e) {
            if (e.keyCode && e.keyCode !== 13) { return; }
            e.stopPropagation();
            var toggleShortcutsBox = $('#toggle-shortcuts');

            if (toggleShortcutsBox.is(":checked")) {
                toggleShortcutsBox.prop("checked", false);
            }
            else {
                toggleShortcutsBox.prop("checked", true);
            }

            const user = this._readerView._app.get('activeUser');
            user.setKeyboardShortcuts(toggleShortcutsBox[0].checked);

        },

        convertSliderToFontSize: function (sliderSize) {
            if (parseInt(sliderSize) < 25) { return 60; }
            switch (parseInt(sliderSize)) {
                case 0:
                    return 60;
                case 25:
                    return 70;
                case 50:
                    return 80;
                case 75:
                    return 90;
                default:
                    return sliderSize;
            }
        },

        convertFontSizeToSlider: function (fontSize) {
            if (parseInt(fontSize) < 60) { return 0; }
            switch (parseInt(fontSize)) {
                case 60:
                    return 0;
                case 70:
                    return 25;
                case 80:
                    return 50;
                case 90:
                    return 75;
                default:
                    return fontSize;
            }
        },

        checkStepValue: function (sliderSize) {
            if (parseInt(sliderSize) > 100) {
                $('#fontsize-slider').attr('step', 10);
            } else {
                $('#fontsize-slider').attr('step', 25);
            }
        },

        chooseGlossaryLang: function () {
            var glossaryLang = this._userbook.getBookResource().getGlossaryLang();
            this._userbook.setGlossaryLang(glossaryLang[$('.panelLangSelect')[0].selectedIndex]);
            this._readerView.closeDialogPopups();
        },

        updateSlider: function () {
            $('#fontsize-slider').val(this.convertFontSizeToSlider(this._fontSize));
        },

        populateGlossaryLang: function () {
            var userbook = this._userbook;
            var glossaryLang = userbook.getBookResource().getGlossaryLang();
            var option;
            if (glossaryLang && glossaryLang.length > 1) {
                $('#settingsPanel').addClass('hasMultilang');
                $('#glossarylangWrapper').removeAttr('hidden').show();
                $('.panelLangSelect').empty();
                glossaryLang.forEach(function (glossLang) {
                    option = $('<option>' + glossLangMap(glossLang)[0] + '</option>');
                    if (glossLang === userbook.get('glossaryLang')) {
                        option.attr('selected', 'selected');
                    }
                    $('.panelLangSelect').append(option);
                });
            } else {
                $('#glossarylangWrapper').attr('hidden', 'hidden').hide();
                $('#settingsPanel').removeClass('hasMultilang');

            }
        }
    });
});
