define('binarySearch',[], function () {

    function binarySearchWithDirection(sortedArray, checkerFunc, lo, hi, searchDirection) {
        // searchDirection must be -1 or 1
        var mid;
        var bestMatch = -1;

        while (lo <= hi) {
            mid = ((lo + hi) >> 1);
            var direction = checkerFunc(sortedArray[mid]);
            if (direction === -1) {
                lo = mid + 1;
            } else if (direction === 1) {
                hi = mid - 1;
            } else {
                bestMatch = mid;
                if (searchDirection === 1) {
                    lo = mid + 1; // RIGHT
                } else {
                    hi = mid + -1; // LEFT
                }
            }
        }
        return bestMatch;
    }


    function binarySearch(sortedArray, checkerFunc, leftmost, rightmost) {
        var lo = 0;
        var hi = sortedArray.length - 1;
        var mid;
        var bestMatch = -1;

        while (lo <= hi) {
            mid = ((lo + hi) >> 1);
            var direction = checkerFunc(sortedArray[mid]);
            if (direction === -1) {
                lo = mid + 1;
            } else if (direction === 1) {
                hi = mid - 1;
            } else {
                bestMatch = mid;
                break;
            }
        }


        if (bestMatch !== -1) {
            var leftmostIndex = -1;
            var rightmostIndex = -1;

            if (leftmost) {
                leftmostIndex = binarySearchWithDirection(sortedArray, checkerFunc, lo, mid - 1, -1);
                if (leftmostIndex === -1) {
                    leftmostIndex = bestMatch;
                }
            }

            if (rightmost) {
                rightmostIndex = binarySearchWithDirection(sortedArray, checkerFunc, mid + 1, hi, 1);
                if (rightmostIndex === -1) {
                    rightmostIndex = bestMatch;
                }
            }

            if (leftmost && rightmost) {
                bestMatch = [leftmostIndex, rightmostIndex];
            } else if (leftmost) {
                bestMatch = leftmostIndex;
            } else if (rightmost) {
                bestMatch = rightmostIndex;
            }
        }

        return bestMatch;
    }

    return binarySearch;

});

