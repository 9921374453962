/*global console */
define('repairAndFilterXmlString',['thirdparty/sax'], function (sax) {

    function repairAndFilterXmlString(rawXmlString, keepTags) {
        // Assume: rawXmlString should be a substring from a valid xmlString.
        // Assume: tags are allways complete, not cut of in the middle.
        // Assume: sax will autoclose tags
        // Assume: sax will generate normalized closing tags (as text) when resuming from an 'Unmatched closing tag' error: '< /b>' => '</b>'

        var rawText = '<root>' + rawXmlString + '</root>';
        var parser = sax.parser(true);
        var frags = [];
        var validTags = {};
        keepTags.forEach(function (tagname) {
            validTags[tagname] = true;
        });

        var nextTextHasInvalidTag = 0;
        parser.onerror = function (e) {
            if (e.message.indexOf('Unexpected close tag') === 0) {
                parser.resume();
            } else if (e.message.indexOf('Unmatched closing tag') === 0) {
                if (validTags[parser.tagName]) {
                    // We prepend a matching opening-tag in order to generate a balanced/valid markup
                    frags.unshift('<' + parser.tagName + '>');
                } else {
                    nextTextHasInvalidTag = 3 + parser.tagName.length;
                }
                // The next ontext() callack will receive the closing-tag and any following text up until the next tag.
                // If the tag is not found in validTags, it must be discared while the following text is kept.
                parser.resume();
            } else {
                // Unhandled error.
                console.log('UNHANDLED ERROR', e);
            }
        };

        parser.onopentag = function (node) {
            if (validTags[node.name]) {

                var attribStr = ' ';
                for (var attrib in node.attributes) {
                        attribStr += attrib + '="' + node.attributes[attrib] + '" ';
                }

                frags.push('<' + node.name + attribStr + '>');
            }
        };

        parser.onclosetag = function (tag) {
            var tagname = tag.name;
            if (validTags[tagname]) {
                frags.push('</' + tagname + '>');
            }
        };

        parser.ontext = function (t) {
            if (nextTextHasInvalidTag) {
                frags.push(t.substr(nextTextHasInvalidTag));
                nextTextHasInvalidTag = 0;
            } else {
                frags.push(t);
            }
        };

        parser.write(rawText).close();
        //console.log('frags', frags);
        return frags.join('');
    }

    return repairAndFilterXmlString;
});

