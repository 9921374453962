/* globals define, require, window, cordova, console, Promise */

/**
 * Exports annotations in a userbook to a pdf document
 * Uses the jsPDF library, http://jspdf.com
 * @module webapp
 * @namespace  Views
 * @class  AnnotationsToPdf
 */
define('AnnotationsToPDF',['jquery', 'bowser', 'jsPDF', 'html2canvas', 'underscore', 'async'], function($, bowser, jsPDF, html2canvas, _, async) {
    /**
     * amd module
     * @param  {object} userbook [description]
     * @method  annotationsToPDF
     * @return {[type]}          [description]
     */
    function AnnotationsToPDF()  {
        var me = this;
        this.offsetY = 20; // start printing 20 mm from top
        this.doc = new jsPDF();

        /**
         * The main method gets the annotation from the userbook and sends them to the method printChapter
         * 
         * @method  generatePDF
         * @return {[type]} [description]
         */
        this.generatePDF = function(userbook) {
            var me = this;

            this.printTitle(userbook._bookResource.get('title'));

            var groupedAnnotations = userbook.get('annotations').groupBy(function(model) {
                return model.get('bookpartId');
            });

            $('body').addClass('blocking');

            var validIdrefs = {};
            async.mapSeries(
                userbook._bookResource.getSpineItems(),
                function(item, callback) {
                    validIdrefs[item.idref] = true;
                    var annotations = groupedAnnotations[item.idref];
                    if (annotations) {
                        me.printChapter(item.label, annotations, callback);
                    } else {
			Promise.resolve().then(callback);
                    }
                },
                function (err) {
                    me._savePDF('annotasjoner_ ' + userbook._bookResource.get('title') + '.pdf');
                    $('body').removeClass('blocking');
                }
            );
        };

        /**
         * If window.cordova is defined, we use the cordova plugins to save the pdf to device and open it  with the device's default app
         * Otherwise, use the jsPDF save method which handles it in the browser
         * @param  {String} filename
         */
        this._savePDF = function(filename)  {
            filename = filename.replace(/[\/ ]/g, '_');
            if (window.cordova) {
                var blob = this.doc.output('blob');
                window.resolveLocalFileSystemURL(cordova.file.dataDirectory, function(dir) {
                    dir.getFile(filename, {
                        create: true
                    }, function(file) {
                        file.createWriter(function(fileWriter) {
                            fileWriter.write(blob);
                            /* Open it on the device */
                            cordova.plugins.fileOpener2.open(
                                cordova.file.dataDirectory + filename, 
                                'application/pdf', {
                                    error: function(e) {
                                        console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
                                    }
                                }
                            );
                        });
                    });
                });
            } else {
                this.doc.save(filename);
            }
        };

        /**
         * Prints the title and some hr's around it
         * @method  printTitle
         * @param  {string} title [description]
         */
        this.printTitle = function(title)  {

            this.doc.setDrawColor(128, 128, 128);
            this.doc.setFontSize(25);
            this.doc.text(20, this.offsetY, title);
            this.offsetY = this.offsetY + 10;

            this.doc.setFontSize(14);
            this.doc.text(20, this.offsetY, 'Notater');
        };

        /**
         * Prints a header and calls the method printAnnotation on the belonging annotations
         * @method  printChapter
         * @param  {string} title       [description]
         * @param  {Array} annotations [description]
         */
        this.printChapter = function(title, annotations, asyncCallback)  {
            var me = this;
            this.offsetY = this.offsetY + 5;
            this.doc.line(20, this.offsetY, 200, this.offsetY);
            this.offsetY = this.offsetY + 10;
            this.doc.setFontSize(14);
            this.doc.text(20, this.offsetY, title || 'Uten navn');
            this.offsetY = this.offsetY + 8;

            async.mapSeries(
                annotations,
                function(annotation, callback) {
                    me.doc.line(20, me.offsetY, 200, me.offsetY);
                    me.offsetY = me.offsetY + 10;
                    me.printAnnotation(annotation)
                        .then(callback);
                },
                function(err) {
                    asyncCallback();
                }
            );

        };

        /**
         * Prints  the annotation
         * @method  printAnnotation
         *
         */
        this.printAnnotation = function(annotation) {
            var me = this;
            this.doc.setFontSize(12);
            this.lineHeight = 6;

            // The context is the highlighted text + heading and trailing text
            // split it into lines
            var context = annotation.get('markedText');
            context = this.doc.splitTextToSize(context, 140);

            var physicalPageNumber = annotation.get('physicalPageNumber');

            // if the context is too long to fit to page, do Œa page break
            // TODO: this value probably needs adjustment
            if ((this.offsetY + (context.length * this.lineHeight)) > 270) {
                this.doc.addPage();
                this.offsetY = 20;
            }

            // print date in grey color
            this.doc.setTextColor(128, 128, 128);
            this.doc.text(40, this.offsetY, this.formatDate(annotation.get('timestamp')));
            if (physicalPageNumber) {
                this.doc.text(150, this.offsetY, "Side " + physicalPageNumber);
            }
            this.offsetY = this.offsetY + 8;

            // set text to black
            this.doc.setTextColor(0, 0, 0);

            // check if annotation is Math
            if (annotation.get('markedTextWithMarkup') && (annotation.get('markedTextWithMarkup').indexOf('mjx-char') !== -1)) {
                // this is MATH
                return this.printAnnotationWithMath(annotation.get('markedTextWithMarkup'))
                    .then(function(){
                        me.doc.line(40, me.offsetY, 200, me.offsetY);
                        me.offsetY = me.offsetY + 8;
                        me.printNote(annotation.get('text'));
                    });
            } else {
                // prints the highlighted area and its heading and trailing text
                return this.printAnnotationContext(annotation, context)
                    .then(function(){
                        me.doc.line(40, me.offsetY, 200, me.offsetY);
                        me.offsetY = me.offsetY + 8;

                        me.printNote(annotation.get('text'));
                    });
            }
        };

        this.printAnnotationContext = function(annotation, context)  {
            // prints each line in context

            for (var i = 0; i < (context.length); i++) {
                var currentLine = context[i];

                this.doc.setDrawColor(128, 128, 128);
                this.setFillColor(annotation.get('colorid'));
                this.doc.rect(40, this.offsetY - 5, 180 - 40, 6, 'F');
                this.doc.text(40, this.offsetY, context[i]);
                this.offsetY = this.offsetY + this.lineHeight;
            }
            return $.Deferred().resolve().promise();
        };

        this.printAnnotationWithMath = function(annotation) {
            var me = this;
            var deferred = $.Deferred();


            // Because "text before <span>" will fail with $(..), everything is wrapped.
            annotation = "<span>"+annotation+"</span>";

            var targetElem = $(annotation);
            var annotationWrap = $('<div class="annotation"></div>');
            $('.annotationsPanel .bookpart').append(annotationWrap.append(targetElem));

            var options = {
                height: 600,
                width: 600,
                letterRendering: true,
                useCORS: true,
                allowTaint: true

            };

            targetElem.find('svg').each(function(index, node) {

                // magical SVG adjustments
                var fontSize = Math.ceil(parseFloat(window.getComputedStyle(node).fontSize) * 1.5);
                var mLeft =  9 * parseFloat(node.style.marginLeft);
                var vAlign = Math.round(fontSize * parseFloat(node.style.verticalAlign));
                var height = Math.round(fontSize * parseFloat(node.style.height));
                var width  = Math.round(fontSize * parseFloat(node.style.width));

                if (width > height) {
                    if ($(node).closest('.mjx-mfrac').length) {
                        vAlign = '-10';
                    } else {
                        vAlign = '-20';
                    }
                }

                $(node).removeAttr('style');
                $(node).css({
                    'width': width+'px',
                    'height': height+'px',
                    'margin-left': mLeft+'px',
                    'vertical-align': vAlign+'px'
                });
                $(node).find('line').css({
                    'fill': 'black',
                    'stroke': 'black'
                });
                $(node).attr('height', height+'px');
                $(node).attr('width',  width+'px');

            }).promise().done(targetElem.find('[class*="MJXc-TeX-size"]').each(function (index, node) {

                // magical math character adjustments

                if (node.className.indexOf('size4-R') !== -1) {
                    node.style.letterSpacing = window.getComputedStyle(node).letterSpacing;
                    node.style.margin = window.getComputedStyle(node).margin;
                    node.style.paddingTop = window.getComputedStyle(node).paddingTop;
                    node.style.paddingBottom = window.getComputedStyle(node).paddingBottom;
                }
                else if (node.className.indexOf('size2-R') !== -1) {
                    $(node).css({
                        'position': 'relative',
                        'top': '7px',
                        'padding-right': '4px'
                    });
                }

            })).promise().done(html2canvas(targetElem, options).then(function (canvas) {
                var imgData = canvas.toDataURL('image/png');
                me.doc.addImage(imgData, 'PNG', 40, me.offsetY);
                me.offsetY = me.offsetY + 100;
                $(targetElem).remove();
                deferred.resolve();
            }));

            return deferred.promise();
        };

        this.printNote = function(text)  {
            var note = this.doc.splitTextToSize(text, 140);
            if ((this.offsetY + (note.length * this.lineHeight)) > 280) {
                this.doc.addPage();
                this.offsetY = 20;
            }
            note.forEach(function(textLine) {
                me.doc.text(40, me.offsetY, textLine);
                me.offsetY = me.offsetY + me.lineHeight;
            });
        };

        /**
         * Uses jsPDF's getStringUnitWidth and internal.getFontSize to calculate width of string.
         * TODO: Find out why we have to divide on 2.80, why that factor?
         * @param  {String} string [description]
         * @return {Number}
         */
        this.getStringWidth = function(string) {
            return this.doc.getStringUnitWidth(string) * (this.doc.internal.getFontSize() / 2.80);
        };

        /**
         * Calculate the total width of the text printed before the current line
         * @method getSumWidthAlreadyPrinted
         * @param  {Array} textLines         Text to print divided in lines
         * @param  {Number} currentLine_index
         * @return {Number}                   Width of text already printed
         */
        this.getSumWidthAlreadyPrinted = function(textLines, currentLine_index)  {
            var sumWidthAlreadyPrinted = 0;
            var i = 0;
            while (i < currentLine_index)  {
                sumWidthAlreadyPrinted = sumWidthAlreadyPrinted + this.getStringWidth(textLines[i]);
                i++;
            }
            return sumWidthAlreadyPrinted;
        };

        /**
         * Calculate the remaining width of text to print (not including the currentline)
         * @method  getRemainingWidthToPrint
         * @param  {Array} textLines         Text to print divided in lines
         * @param  {Number} currentLine_index [description]
         * @return {Number}                   Remaining width
         */
        this.getRemainingWidthToPrint = function(textLines, currentLine_index)  {
            var sumWidthToPrint = 0;
            for (var i = textLines.length - 1; i > currentLine_index; i--) {
                sumWidthToPrint = sumWidthToPrint + this.getStringWidth(textLines[i]);
            }
            return sumWidthToPrint;
        };

        this.setFillColor = function(colorId) {
            switch (parseInt(colorId)) {
                case 1:
                    this.doc.setFillColor(252, 244, 170);
                    break;
                case 2:
                    this.doc.setFillColor(217, 234, 211);
                    break;
                case 3:
                    this.doc.setFillColor(206, 233, 241);
                    break;
                case 4:
                    this.doc.setFillColor(247, 207, 207);
                    break;
            }
        };

        /**
         * Formats a timestamp
         * @method  formatDate
         * @param  {timestamp} timestamp [description]
         * @return {string}           formatted timestamp
         */
        this.formatDate = function(timestamp) {
            var dateOptions = {
                weekday: "short",
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
            };
            return (new Date(timestamp)).toLocaleDateString('no-NO', dateOptions);
        };

    }
    return AnnotationsToPDF;
});

