/* globals window */
/**
 * The cordova app uses a native zip plugin which sets itself as
 * a global variable on window named 'zip', which crashes with readiums zip. We rename to "zipperino". By wrapping up this global ''
 * can be required as a normal dependency.
 */
define('cordova-zip',[], function() {
    if (window.zipperino) {
        return window.zipperino;
    } else {
        // not in cordova app, return nothing
        return function() {};
    }
});

