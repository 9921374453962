/* globals window, document, navigator, cordova */

define('views/PrintView',['jquery', 'backbone', 'SectionsToPDF', 'html2canvas', 'templates/printViewTemplate', 'views/DialogView'], function ($, Backbone, SectionsToPDF , html2canvas, printTemplate, DialogView) {
    return Backbone.View.extend({
        initialize: function (options, userbook, app) {
            var me = this;
            this._userbook = userbook;
            this._app = app;
            this._sectionId = options.section_id;
            this._onlineUrl = options.base_url + 'CSS/stylesheet.css';
            this._styleUrl = '';
            this._maxFiles = 0;
            this._isIos = false;

            this._userbook.getBookResource().getResourceUrl(this._onlineUrl, function (err, resourceUrl) {
                me._styleUrl =  resourceUrl;
            });

            this.render();
        },

        events: {
            'click .blockingLayer': 'close',
            'click .closeButton': 'close',
            'click .exportPDF': 'exportPDF',
            'click .printButton': 'directPrint',
            'click .removeImg': 'removeImage',

            'dragover': 'dragOver',
            'dragenter': 'dragOver',

            'dragleave': 'dragLeave',
            'dragend': 'dragLeave',

            'drop': 'showSelectedFiles',
            'ondrop': 'showSelectedFiles',
            'change input.box__file': 'showSelectedFiles'
        },

        render: function () {
            var me = this;

            var cloneSection = $('#reader iframe').contents().find('#'+ this._sectionId).clone();
            //disable PDF button for iOS
            if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
                this._isIos = true;
            }
            this.setElement(printTemplate({
                    'cloneSection': cloneSection[0].outerHTML,
                    'styleUrl': me._styleUrl || me._onlineUrl,
                    'isIos': this._isIos
                }
            ));
            return this;
        },

        dragOver: function (e) {
            e.preventDefault();
            e.stopPropagation();
            if ($(e.target).closest(".box").length === 1) {

                $('.box').addClass('is-dragover');
            }
        },

        dragLeave: function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.box').removeClass('is-dragover');
        },

        showSelectedFiles: function (e) {
            e.preventDefault();
            e.stopPropagation();

            var files;
            // drag & drop file   >>> e.originalEvent.dataTransfer.files
            // open & select file >>> e.target.files
            if (e.target.files === undefined) {
                files = e.originalEvent.dataTransfer.files;
                if (!$('.box').hasClass("is-dragover")) {
                    return;
                }
            }
            else {
                files = e.target.files;
            }

            var imgDiv = this.$el.find('.imageContainer');
            var maxFiles = this._maxFiles;

            $.each(files, function (index, file) {
                var div = document.createElement('div');
                div.className = "imageToPrint";

                var icon = document.createElement('div');
                icon.className = "removeImg";

                var img = document.createElement('img');
                img.width = 350;
                img.src = window.URL.createObjectURL(file);
                var itIsPictureBool = itIsPicture(file.name);

                if (itIsPictureBool && maxFiles >= 6) {

                    new DialogView({
                        'isAlert': true,
                        'title': 'For mange bilder',
                        'message': 'Maksimalt 6 bilder kan legges til',
                        'btnText': 'OK'
                    });
                    $('.box').removeClass('is-dragover');
                    return false;
                }

                if (itIsPictureBool) {
                    div.appendChild(img);
                    div.appendChild(icon);
                    imgDiv.append(div);
                    maxFiles++;
                }
                else {
                    new DialogView({
                        'isAlert': true,
                        'title': 'Ugyldig fil: ' + file.name,
                        'message': 'Gyldige bildeformater er: jpg, jpeg, gif og png',
                        'btnText': 'OK'
                    });
                }
            });

            this._maxFiles = maxFiles;

            $('.box').removeClass('is-dragover');

            function itIsPicture (fileName) {
                var fileExtension = fileName.toUpperCase().substr(fileName.lastIndexOf(".")+1);
                var pictureExtensions = ['JPG', 'JPEG', 'GIF', 'PNG'];
                for (var i = 0; i < pictureExtensions.length; i++) {
                    if (pictureExtensions[i] === fileExtension) {
                        return true;
                    }
                }
                return false;
            }
        },

        removeImage: function (e) {
            $(e.target).closest(".imageToPrint").remove();
            this._maxFiles--;
            $('.box__file').replaceWith($('.box__file').val('').clone(true));  // Clear box__file input form
        },

        exportPDF: function () {
            var me = this;
            $('body').addClass('blocking');
            this.$el.css('opacity', '0');
            new SectionsToPDF().generatePDFfromDialog(function () {
                $('body').removeClass('blocking');
                me.close();
            });
        },

        directPrint: function () {
            var me = this;
            $('body').addClass('blocking');
            this.$el.css('opacity', '0');
            this._printFrame = $('<iframe id="printFrame"/>');
            var printCssLink = $('<link rel="stylesheet" type="text/css" href="'+this._styleUrl+'"/>');
            var clonedCont = this.$el.find('.clonedContent').clone();
            clonedCont.find(".screen-reader-text").remove();    // Remove sideTall from cloned content before printing
            var imageCont = this.$el.find('.imageContainer');

            var comment = $('<div class="userComment">').append(this.$el.find('.userComment').val());
            comment.css({
                'width': '100%',
                'min-height': '50px',
                'clear': 'both',
                'float': 'left',
                'margin-top': '20px',
                'border-top': '1px solid #d4d4db',
                'font-size': '18px'
            });

            this._printFrame.appendTo('body').contents().find('body').append(clonedCont);
            this._printFrame.contents().find('head').append(printCssLink);
            this._printFrame.contents().find('body').append(comment);

            var options = {
                height: imageCont[0].scrollHeight,
                width:  imageCont[0].scrollWidth,
                allowTaint: true
            };

            html2canvas(imageCont, options).then(function (canvas) {
                me._printFrame.contents().find('body').append(canvas);
                var pageStr = me._printFrame.contents().find('html')[0].innerHTML;

                window.setTimeout(function () {
                    if (window.cordova) {
                        cordova.plugins.printer.pick(function (uri) {
                            cordova.plugins.printer.print(pageStr, { printerId: uri }, function (res) {
                                console.log('print result: ', res);
                            });
                        });
                    } else {
                        try {
                            me._printFrame.get(0).contentWindow.document.execCommand('print');
                        } catch (e) {
                            me._printFrame.get(0).contentWindow.print();
                        }
                    }
                    $('body').removeClass('blocking');
                    window.setTimeout(function () {
                        me.close();
                    }, 1000);
                }, 300);
            });

            this._app.plausibleManager.sendEvent(
                this._userbook.get('publisherRef'),
                'Leseren',
                'Skriver ut oppskrift',
                this._userbook.getBookResource().get('title')
            );

        },

        close: function () {
            if (this._printFrame) {
                this._printFrame.remove();
            }
            this.$el.detach();
            this._userbook.unset('printerIconClicked', this);
            return false;
        }
    });
});
