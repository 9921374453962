/**
 * @module webapp
 * @namespace  Models
 * @class  AnnotationIndicator
 */
define('models/AnnotationIndicator',['jquery', 'models/MarginIndicatorBase'], function($, MarginIndicatorBase) {

    var AnnotationIndicator = function (domElm, marginManager) {
        if (!domElm) {
            return false;
        }
        var me = this;
        var bounds = domElm.getBoundingClientRect();
        var top = bounds.top;
        MarginIndicatorBase.call(this, top, 0, marginManager);
        this.annotationid = '' + $(domElm).data('id');
        this.colorid = $(domElm).data('colorid');
        this.annotation = marginManager._readerView._userbook.get('annotations').where({id: me.annotationid})[0];
        marginManager._annotationIndicators['' + this.annotationid] = this;
    };

    AnnotationIndicator.prototype = Object.create(MarginIndicatorBase.prototype);

    AnnotationIndicator.prototype.render = function () {
        this.$el = $('<div class="annotationIndicator"></div>');
        this.$el.attr('data-colorid', this.colorid);
        this.$el.attr('data-annotationid', this.annotationid);
        this._marginManager._readerView._clickedAnnotation = true;
        if (!this.annotation.get('text')) {
            this.$el.css('display', 'none');
        }

        this.$el.click($.proxy(this.clickHandler, this));

        return this.$el;
    };

    AnnotationIndicator.prototype.clickHandler = function () {

        this._marginManager._userbook.set('annotationBeingEdited', this.annotation);
        this._marginManager._readerView.showMenus();
        return false;
    };

    return AnnotationIndicator;

});

