/* global console, window, document */
define('views/SearchPanel',['jquery', 'views/PanelBase', 'underscore', 'templates/searchPanel', 'templates/searchResultItem', 'templates/gotoPageResultItem', 'bowser'], function ($, PanelBase, _, mainTemplate, searchResultItemTemplate, gotoPageResultItemTemplate, bowser) {

    return PanelBase.extend({
        initialize: function (onGotoPlace, onGotoFromToc, app) {
            var me = this;
            this._searchString = null;
            this._onGotoPlace = onGotoPlace;
            this._onGotoFromToc = onGotoFromToc;
            this._app = app;
            this.render();
        },

        events: {
            'click .btn-search': 'search',
            'click .searchResultItem': 'showInBook',
            'click .gotoPageResultItem': 'gotoPageInBook',
            'propertychange input': 'onTextinput',
            'keyup input': 'onKeyUpInput',
            'keyup .searchResultItem': 'onKeyUpSearchResultItem',
            'click .bookpartTitle': 'onToggleExpand',
            'click .close-icon': 'onClose'
        },

        render: function () {
            this.setElement(mainTemplate({
                mobileApp: this._app.get('mobileApp')
            }));
            return this;
        },

        _cleanScriptTags: function (resultText) {
            var cleanedResultText = resultText.replace(/<script>/g, '&lt;script&gt;');
            return cleanedResultText.replace(/<\/script>/g, '&lt;&#47;script&gt;');
        },

        _updateWithResults: function (pageResult, results, bookResource) {
            var me = this;
            var resultsE = this.$el.find('.content').empty();
            var cnt = 0;

            if (pageResult) {
                resultsE.append(gotoPageResultItemTemplate({
                    pageHref: pageResult.href,
                    pageNum: pageResult.pageNum
                }));
            }

            results.forEach(function (part) {
                var chapterRes = $('<div class="bookpart"/>');
                var chaptResHead = $('<div class="bookpartTitle"><span class="toggleExpandIcon"/><div class="label">' + bookResource.getTitleFromIdref(part[0].idref) + '</div></div>');
                resultsE.append(chapterRes.append(chaptResHead));
                cnt += part.length;
                chapterRes.append(part.map(function (hit) {
                    var s =  searchResultItemTemplate({
                        resultText: me._cleanScriptTags(hit.resultText),
                        idref: hit.idref,
                        cfi: hit.cfi,
                        pageNum: hit.pageNum
                    });
                    var t = $(s);
                    me.highlightOffsets(t[0], hit.offsets);
                    return t;
                }));
            });

            var elem = this.$el.find('.searchCont .count');
            if (cnt === 0) {
                elem.html('ingen treff');
            } else if (cnt === 1) {
                elem.html('1 treff');
            } else {
                elem.html(cnt + ' treff');
            }
           this._userbook.ariaAnnounce('Søk på ' + this._searchString + ' utført, fant ' + cnt + ' treff.');
        },

        setUserbook: function (userbook) {
            if (userbook !== this._userbook) {
                this.$el.find('.content').empty();
                this.$el.find('.head .count').html('');
                this.$el.find('.head input').val('');
                this._userbook = userbook;
            }
        },

        showInBook: function (e) {
            this.$el.find('.searchResultItem, .gotoPageResultItem').removeClass('selected');
            var elem = $(e.currentTarget);
            elem.addClass('selected');
            this._onGotoPlace(elem.data('idref'), elem.data('cfi'), this._searchString);

            // Hide Panel on mobile screens
            if (this._app.get('mobileApp') || bowser.ipad || bowser.iphone) {
                this.panelController.hidePanel();
            }
            return false;
        },

        gotoPageInBook: function (e) {
            this.$el.find('.searchResultItem, .gotoPageResultItem').removeClass('selected');
            var elem = $(e.currentTarget);
            elem.addClass('selected');
            this._onGotoFromToc(elem.data('page-href'));
        },

        focusSearchfield: function (e) {
            var me = this;
            window.setTimeout(function () {
                me.$el.find('input').focus();
            },  800);
        },

        onToggleExpand: function (e) {
            var elem = $(e.currentTarget).parent();
            elem.toggleClass('collapsed');
            return false;
        },

        highlightOffsets: function(el, offsets) {
            var currentPos = 0;
            var start;
            var end;
            function findRange(current) {
                for(var i = 0; i < current.childNodes.length; i++) {
                    var child = current.childNodes[i];
                    if(child.nodeType === 3) {
                        if (offsets[0] >= currentPos && offsets[0] <= currentPos + child.nodeValue.length && 
                            offsets[1] >= currentPos && offsets[1] <= currentPos + child.nodeValue.length) {
                            start = { node: child, offset: offsets[0] - currentPos };
                            end = { node: child, offset: offsets[1] - currentPos };
                        }
                        
                        currentPos += child.nodeValue.length;
                    }
                    else {
                        findRange(child);
                    }
                }
            }
            findRange(el);
            if (start && start.node === end.node) {
                var span = document.createElement('SPAN');
                span.className = 'markedText';
                var range = document.createRange();

                range.setStart(start.node, start.offset);
                range.setEnd(end.node, end.offset);
                range.surroundContents(span);
            }

        },

        search: function (e) {
            var me = this, bookResource = this._userbook.getBookResource();
            $('body').addClass('blocking');

            /* Using setTimeout to force the dom to refresh and show loading spinner */
            window.setTimeout(function () {
                // make sure bookResource is still defined when function is executed
                if (!bookResource) {
                    return;
                }

                me._searchString = me.$el.find('input').val();
                if (me._searchRequest) {
                    me._searchRequest.cancel();
                }
                me._searchRequest = bookResource.search(me._searchString, function (results) {
                    var pageResult = null;

                    var pageNum = parseInt(me._searchString, 10);

                    var pageHref = null;
                    var pageMap = bookResource.getPhysicalPagemap();
                    if (pageMap && pageNum && !isNaN(pageNum)) {
                        pageHref = pageMap[pageNum];

                        if (pageHref) {
                            pageResult = {
                                href: pageHref,
                                pageNum: pageNum
                            };
                        }
                    }


                    me._updateWithResults(pageResult, results, bookResource);

                    $('body').removeClass('blocking');
                });
            }, 0);
            // send plausible event
            this._app.plausibleManager.sendEvent(
                this._userbook.get('publisherRef'),
                'Leseren',
                'Søk',
                this._userbook.getBookResource().get('title')
            );
            return false;
        },

        onKeyUpInput: function (e) {
            if (e.keyCode === 13) {
                this.search(e);
                e.stopPropagation();
            }
        },

        onKeyUpSearchResultItem: function (e) {
            if (e.keyCode === 13) {
                var elem = $(e.currentTarget);
                this._onGotoPlace(elem.data('idref'), elem.data('cfi'), this._searchString);
                e.stopPropagation();
            }
        }
    });
});

