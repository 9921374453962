/**
 * This class is responsible for handling the epub resources for a bookResource
 * Has methods for fetching epub contents and for downloading and unpacking epub files(.ub-files) and getting book covers
 *
 * Has a resourceDownloader object and a resourceReader object, both are object of a class with ResourceFetcherBase as base class.
 * If the epub is downloaded, the resourceDownloader and resourceReader will be the same object
 * If the epub is not downloaded, the resourceReader will be the HttpResourceFetcher, and the resourceDownloader will be either the LocalFileResourceFetcher or the LocalForageResourceFetcher
 *
 * The resourceDownloader handles download and unpacking of epubs. The resourceReader fetches the epub-content
 *
 * There are currently 3 resourcefetcher-classes.
 *   - HttpResourceFetcher is when epub is located at the server
 *   - LocalFileResourceFetcher is for the cordova app and uses the File System API to store epubs
 *   - LocalForageResourceFetcher is used for webapp in offline mode, uses LocalStorage to store epubs
 *
 * @module webapp
 * @namespace  ResourceManagers
 * @class  ResourceManager
 */
define('ResourceManager',[
    'resourcefetchers/HttpResourceFetcher',
    'resourcefetchers/LocalFileResourceFetcher',
    'resourcefetchers/LocalForageResourceFetcher'
],
function(HttpResourceFetcher, LocalFileResourceFetcher, LocalForageResourceFetcher) {
    /**
     * This class is responsible
     * @param {Object} bookResource
     * @constructor
     * @class ResourceManager
     */
    var ResourceManager = function(bookResource) {
        this._bookResource = bookResource;
        bookResource.on('change:isDownloaded', this.setImplementation, this);
    };

    /**
     * @method  setImplementation
     * Instantiates a resourceDownloader and a resourceReader
     * If the epub is downloaded, these will be the same object
     * If the epub is not downloaded, the resourceReader will be the HttpResourceFetcher, and the resourceDownloader will be either the LocalFileResourceFetcher or the LocalForageResourceFetcher
     *
     */
    ResourceManager.prototype.setImplementation = function()  {
        var me = this;
        var localResourceFetcher = null;
        if (this._bookResource._app.get('mobileApp')) {
            localResourceFetcher = new LocalFileResourceFetcher(this._bookResource);
        }
        else {
            localResourceFetcher = new LocalForageResourceFetcher(me._bookResource);
        }

        if (this._bookResource.get('isDownloaded') || this._bookResource.get('downloadedEpubid')) {
            this._resourceReader = this._resourceDownloader = localResourceFetcher;
        }
        else {
            this._resourceReader = new HttpResourceFetcher(this._bookResource);
            this._resourceDownloader = localResourceFetcher;
        }
    };

    // methods that should be called through the resource _reader_
    ResourceManager.prototype.getBookinfo = function() {
        return this._resourceReader.getBookinfo();
    };
    ResourceManager.prototype.getBaseUrl = function() {
        return this._resourceReader.getBaseUrl();
    };
    ResourceManager.prototype.getRootUrl = function() {
        return this._resourceReader.getRootUrl();
    };
    ResourceManager.prototype.fetchSpineItem = function(item, callback)  {
        return this._resourceReader.fetchSpineItem(item, callback);
    };
    ResourceManager.prototype.fetchFileContentsText = function(fileUrl) {
        return this._resourceReader.fetchFileContentsText(fileUrl);
    };
    ResourceManager.prototype.getAssetUrl = function(originalUrl, mainCallback)  {
        this._resourceReader.getAssetUrl(originalUrl, mainCallback);
    };
    ResourceManager.prototype.getResourceUrl = function(url, callback) {
        this._resourceReader.getResourceUrl(url, callback);
    };
    ResourceManager.prototype.getIframeContentCssUrl = function(callback) {
        this._resourceReader.getIframeContentCssUrl(callback);
    };

    // methods that should be called through the resource _downloader_
    ResourceManager.prototype.resolveBookCover = function() {
        this._resourceDownloader.resolveBookCover();
    };
    ResourceManager.prototype.unpack = function() {
        this._resourceDownloader.unpack();
    };
    ResourceManager.prototype.download = function() {
        return this._resourceDownloader.download();
    };
    ResourceManager.prototype.abortDownload = function() {
        this._resourceDownloader.abortDownload();
    };
    ResourceManager.prototype.removeDownloadedBook = function() {
        return this._resourceDownloader.removeDownloadedBook();
    };
    ResourceManager.prototype.checkIsDownloaded = function() {
        return this._resourceDownloader.checkIsDownloaded();
    };

    ResourceManager.prototype.checkIntegrity = function() {
        return this._resourceDownloader.checkIntegrity();
    };
    ResourceManager.prototype.checkAvailableDiskSpace = function() {
        return this._resourceDownloader.checkAvailableDiskSpace();
    };

    return ResourceManager;
});

