/**
 * @module  webapp
 * @class  HighlightManager
 */
define('HighlightManager',[
        'jquery',
        'underscore',
    ],
    function ($, _) {
        function HighlightManager() {
            this.updateHighlights();
        }

        HighlightManager.prototype = {
            updateHighlights: function() {
                var doc = $('#reader').find('iframe').contents();
                this.highlights = doc.find('html > .highlight, html > .hover-highlight');

                for (var i = 0; i < this.highlights.length; i++) {
                    var el = this.highlights[i];
                    var rect = el.getBoundingClientRect();
                    el.position = {
                        left: rect.left,
                        right: rect.right,
                        top: rect.top,
                        bottom: rect.bottom,
                    };
                }
            },

            getHighlights: function() {
                if (!this.highlights) {
                    this.updateHighlights();
                }

                return this.highlights;
            },

            registerOnMouseMoveEventHandler: function() {
                var self = this;
                $('#reader').find('iframe')[0].contentDocument.onmousemove = _.debounce(function(event) {
                    if (!self.highlights) {
                        self.updateHighlights();
                    }

                    var id = event.target.getAttribute('data-id');
                    var highlightElements = null;
                    if (id) {
                        // set hover on highlight under the cursor
                        highlightElements = self.highlights.filter('.highlight[data-id="' + id + '"]');
                        highlightElements.removeClass('highlight');
                        highlightElements.addClass('hover-highlight');

                        // set unhover on other highlights.
                        highlightElements = self.highlights.filter('.hover-highlight').not('[data-id="' + id + '"]');
                        highlightElements.removeClass('hover-highlight');
                        highlightElements.addClass('highlight');
                    }
                    else {
                        // no highlights under the cursor, remove all hover
                        highlightElements = self.highlights.filter('.hover-highlight');
                        highlightElements.removeClass('hover-highlight');
                        highlightElements.addClass('highlight');
                    }
                }, 5);
            },

            colorHighlightAnnotation: function(annotation, readium) {
                var idref = annotation.get('bookpartId');
                var cfi = annotation.get('cfi');
                var highlightId = annotation.id;
                var colorId = annotation.get('colorid');
                var text = annotation.getText();

                var doc = $('#reader').find('iframe').contents();

                var highlightElementCount = doc.find('html > .highlight').length;

                try { // giant dragons await
                    readium.reader.plugins.highlights.addHighlight(idref, cfi, highlightId, "highlight");
                }
                catch (error) {
                    // Temporary solution for catching readium-errors. This will allow the code below to run regardless of these errors.
                    console.error('READIUM ERROR: ', error);
                    return;
                }

                var highlightElems = doc.find('html > .highlight').slice(highlightElementCount);
                highlightElems
                    .attr({
                        'data-colorid': colorId,
                        'title': text,
                        'data-id': highlightId,
                        'data-type': 'highlight'
                    })
                    .css('pointer-events', 'auto');

                return highlightElems;
            }
        };

        return HighlightManager;
    });

