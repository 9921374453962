/* globals ga */
define('views/ErrorView',['jquery', 'backbone', 'underscore', 'templates/errorView', 'models/App'], function ($, Backbone, _, mainTemplate, AppModel) {
    return Backbone.View.extend({
        initialize: function (app) {
            this._app = app;
            this._user = null;
            this.render();
        },

        events: {
            //'click .btn-systemInfo': 'systemInfoBox',
        },

        render: function () {
            this.setElement(mainTemplate());
            return this;
        },

        enter: function (code) {
            var me = this;
            var user = this._app.get('activeUser');
            var messages = {
                404: "404 message",
                403: "403 message",
                default: "default message"
            };
            var message = messages[code] || messages['default'];
            var parent = this.$el.parent();
            this.$el.remove();
            this.setElement(mainTemplate({
                loggedin: (user ? true : false),
                message: message,
                incognitoMode: $('body').hasClass('incognitoMode')
            }));
            this.$el.removeAttr('hidden').addClass('active');
            parent.append(this.$el);
        },

        leave: function () {
            this.$el.attr('hidden', 'hidden').removeClass('active');
        }
    });
});

