/* globals ReadiumSDK, window, document */

/**
 * @module  webapp
 * @class  ReadiumFactory
 *
 * Factory class to create a new instance of Readium
 */
define(
'ReadiumFactory',[
    'readium_shared_js/globalsSetup',
    'readium_plugin_highlights',
    'readium_js/Readium',
    'UnibokIFrameLoader',
    'resourcefetchers/UnibokPublicationFetcher',
    'bowser',
    'jquery',
    'views/DialogView',
    'ReadiumUnloadedException'
],
function(ReadiumGlobalSetup, HighlightPlugin, Readium, UnibokIFrameLoader, UnibokPublicationFetcher,
         bowser, $, DialogView, ReadiumUnloadedException)
{

    function ReadiumFactory(readerView) {
        this.readerView = readerView;
    }

    ReadiumFactory.prototype.createReadium = function() {
        var readiumOptions = {
            jsLibRoot: '/javascripts/thirdparty/',
            openBookOptions: {
                settings: {
                    // When this is 'true', Readium does NOT call some expensive code (that we dont need) each time we scroll.
                    mediaOverlaysPreservePlaybackWhenScroll: true
                }
            },
            publicationFetcher: UnibokPublicationFetcher
        };

        var readium = new Readium(readiumOptions, {
            el: "#epub-reader-container",
            externalLinkTarget: this.readerView._app.get('mobileApp') ? '_system' : '_blank',
            //mathJaxUrl: '/js/thirdparty/MathJax.js',
            iframeLoader: new UnibokIFrameLoader(this.readerView.contentDocumentPreprocess.bind(this.readerView)) // See EB-659
        });

        this.registerEventHandlers(readium);

        return readium;
    };

    // private
    ReadiumFactory.prototype.registerEventHandlers = function(readium) {
        var readerView = this.readerView;

        readium.reader.on(ReadiumSDK.Events.CONTENT_DOCUMENT_LOADED, function (iframe, s) {

            if (readerView._app.get('mobileApp') || bowser.ipad) {
                readerView.enableSwipeGesture(iframe[0].contentWindow.document.documentElement);
            }

            var viewType = readium.reader.getCurrentViewType();
            iframe[0].contentWindow.document.documentElement.addEventListener('touchmove', function (e) {
                if (ReadiumSDK.Views.ReaderView) {
                    if (viewType === ReadiumSDK.Views.ReaderView.VIEW_TYPE_FIXED || viewType === ReadiumSDK.Views.ReaderView
                            .VIEW_TYPE_COLUMNIZED) {
                        e.preventDefault();
                    }
                }
            });
        });


        readium.reader.on(ReadiumSDK.Events.CONTENT_DOCUMENT_LOAD_START, function (iframe, s) {
            // onPaginationChanged gets fired contentDocumentLoaded, so we need to clear this
            readerView._currentBookpartId = undefined;
            if ( !readerView.hasLeftView) { // EB-2767, resizeSensor causes content_document_load_start to fire even though we have left
                $('body').addClass('blocking');
            }
        });

        readium.reader.on(ReadiumSDK.Events.CONTENT_DOCUMENT_LOADED, function (iframe, s) {
            readerView.onReadiumContentDocumentLoaded(iframe, s);
        });

        readium.reader.on(ReadiumSDK.Events.CONTENT_DOCUMENT_UNLOADED, function() {
            if (! (readerView._app.get('mobileApp') || bowser.safari)) { // failed on some version of iOS, EB-2767
                if (readerView.$el.find('iframe').contents().find('body')[0] &&
                    readerView.$el.find('iframe').contents().find('body')[0].resizeSensor) {
                        readerView.$el.find('iframe').contents().find('body')[0].resizeSensor.detach(); // fant ikke noen måte å forlate readium-instansen på i deres API
                }
            }
            if (!readerView._app.get('isOnline') && !readerView._userbook.isAvailableOffline()) {
                new DialogView({
                    'isAlert': true,
                    'title': 'Ikke tilgjengelig frakoblet',
                    'message': 'Du får ikke åpnet et nytt kapittel fordi denne boka ikke er tilgjengelig frakoblet. Når du har tilgang til internett kan du gjøre boka tilgjengelig frakoblet.',
                    'btnText': 'OK'
                });

                // stop readium here, no unloading has yet been done.
                // uncaught exception, but will not triggered bugsnag/error msg
                // See AppView::(window.onerror)
                throw new ReadiumUnloadedException();
            }
        });

        readium.reader.on(ReadiumSDK.Events.PAGINATION_CHANGED, function (pageChangeData) {
            readerView.onReadiumPaginationChanged(pageChangeData);
        });

        // DISABLED BECAUSE OF READIUM ROLLBACK
        // readium.reader.on(ReadiumSDK.Events.CONTENT_SIZE_CHANGED, function (pageChangeData) {
        //     $('body').addClass('blocking');
        // });

        ReadiumSDK.on(ReadiumSDK.Events.PLUGINS_LOADED, function () {
            if (readium.reader.plugins.highlights) {
                readium.reader.plugins.highlights.initialize({});
            }
        });

        // -------------------------------------------------------------------
        // Prevent rubberband bouncing of main content
        // when swiping up or or down in a direction that no content to scroll
        // -------------------------------------------------------------------
        (function () {
            var preventBounce = null;
            var startPageY = null;
            readium.reader.addIFrameEventListener('touchstart', function (e) {
                preventBounce = null;
                startPageY = e.originalEvent.pageY;
                var scrolledContentFrameElem = readerView.$el.find('#scrolled-content-frame')[0];
                if (scrolledContentFrameElem) {
                    if (scrolledContentFrameElem.scrollTop === 0) {
                        //scrolledContentFrameElem.scrollTop = 1;
                        preventBounce = 'top';
                    } else if (scrolledContentFrameElem.scrollHeight === scrolledContentFrameElem.scrollTop + scrolledContentFrameElem.offsetHeight) {
                        //scrolledContentFrameElem.scrollTop -= 1;
                        preventBounce = 'bottom';
                    }
                }
            });

            readium.reader.addIFrameEventListener('touchmove', function (e) {
                var deltaY = e.originalEvent.pageY - startPageY;
                if (preventBounce === 'top') {
                    if (deltaY > 0) {
                        e.preventDefault();
                    }
                } else if (preventBounce === 'bottom') {
                    if (deltaY < 0) {
                        e.preventDefault();
                    }
                }
            });
        }());

        // ------------------------------------------------------------------
        // Toggling the class "hideMenus" which in turn hides the .topbar and .bottombar
        // ------------------------------------------------------------------
        (function() {
            /* If we select something under where the menues will appear */
            var scrollIfSelectionHiddenByMenues = function (e) {
                var topbarHeight = readerView.$el.find('.topbar').height();
                var bottombarHeight = readerView.$el.find('.bottombar').height();
                var scrolledContentFrame = readerView.$el.find('#scrolled-content-frame');
                var scrollTop = scrolledContentFrame.scrollTop();
                var newScrollTop;

                var pageY = e.pageY !== undefined ? e.pageY : e.originalEvent.pageY; // handles both mouse and touchend
                if ((pageY - scrollTop) < topbarHeight + 20) {
                    newScrollTop = scrollTop - topbarHeight - 20;
                } else if ((pageY > (scrollTop + scrolledContentFrame.height() - (bottombarHeight + 10)))) {

                    newScrollTop = scrollTop + (bottombarHeight + 20);
                }

                if (newScrollTop) {
                    readerView.$el.find('#scrolled-content-frame').animate({scrollTop: newScrollTop}, 300);
                }
            };

            readium.reader.addIFrameEventListener('click', function (e) {

                if (readerView._selectionManager.hasSelection()) {
                    readerView.showMenus();
                    scrollIfSelectionHiddenByMenues(e);
                } else {
                    // if there is a menudialog open, close it (them)
                    if (readerView.$el.hasClass('show-enterBookmark-panel') ||
                        readerView.$el.hasClass('show-layout-panel') ||
                        readerView.$el.hasClass('show-textlang-panel') ||
                        readerView.$el.hasClass('show-TTSDialog') ||
                        readerView.$el.hasClass('show-annotationsEditor')) {
                        readerView.closeDialogPopups();
                    } else {
                        readerView.toggleMenus(e);
                    }
                }
            });

            readium.reader.addIFrameEventListener('mouseup', function (e) {
                var me = this;
                /* EB-1832, selection must extend to the full math-expression */

                /* HACK: At this moment, start and endpoints of selections/ranges must be in textnodes
                 * If only parts of math-expression are selected, we extend selection to the last element of the textnode before/the first element of the textnode after
                 * We are depending on a hack that inserts empty textnodes when epub is loaded,  before/after math expression if it is at the beginning/end of an element.
                 * extend selection if only parts of math expression is selected
                 */
                readerView._selectionManager.expandIfSelectedPartOfMathExpression();
            });

            readium.reader.addIFrameEventListener('touchend', function (e) {
                if (readerView._selectionManager.hasSelection()) {
                    readerView.showMenus();
                    scrollIfSelectionHiddenByMenues(e);
                }
                readerView._selectionManager.expandIfSelectedPartOfMathExpression({timeout: 100});
            });

        }());
    };

    return ReadiumFactory;
});

