define('views/BookmarksPanel',['jquery', 'underscore', 'views/PanelBase', 'templates/bookmarksPanel', 'templates/bookmarkItem', 'bowser'],
function ($, _, PanelBase, mainTemplate, bookmarkItemTemplate, bowser) {

    function renderBookmarkItem(bookmark, mobileApp) {
        return bookmarkItemTemplate({
            cfi: bookmark.get('cfi'),
            idref: bookmark.get('bookpartId'),
            id: bookmark.get('id'),
            label: (bookmark.get('label') ? bookmark.get('label') : 'Uten tittel'),
            physicalPageNumber: bookmark.get('physicalPageNumber'),
            invalid: bookmark.get('invalid'),
            mobileApp: mobileApp
        });
    }

    return PanelBase.extend({

        initialize: function (onGotoBookmark, app) {
            this._onGotoBookmark = onGotoBookmark;
            this._app = app;
            this._mobileApp = app.get('mobileApp');
            this.render();
        },

        events: {
            'click .close-icon': 'onClose', // defined in superclass
            'click .label .labeltext:not(.edit-label)': 'gotoBookmark',
            'click .bookmark .edit': 'editLabel',
            'click .bookmark .btn-delete': 'deleteBookmark',
            'click .bookpartTitle': 'onToggleExpand',
            'keyup .label .labetext:not(.edit-label)': 'onKeyUpBookmark',
            'keyup .bookmark': 'onKeyUpBookmark',
            'keyup .edit-label': 'saveBookmarkLabel',
            'keyup .confirm-edit': 'saveBookmarkLabel'
        },

        gotoBookmark: function (e) {

            // If the event comes from editing the label with ENTER button, don't go anywhere
            if ($(e.target).hasClass("edit-label")) {
                return false;
            }

            var elem;
            if (e.type === "keyup") {
                elem = $(e.currentTarget);
            }
            else {
                elem = $(e.currentTarget).parent().parent();
            }
            var bookmarkId = '' + elem.data('id');
            var bookmark = this._userbook.get('bookmarks').where({id: bookmarkId})[0];
            if (!bookmark.get('invalid')) {
                var idref = elem.data('idref');
                var cfi = elem.data('cfi');
                this._onGotoBookmark(idref, cfi);
            }

            // Hide Panel on mobile screens
            if (this._app.get('mobileApp') || bowser.ipad || bowser.iphone) {
                this.panelController.hidePanel();
            }
            return false;
        },

        render: function () {
            this.setElement(mainTemplate({
                mobileApp:  this._mobileApp
            }));
            return this;
        },

        _renderContent: function () {
            var me = this;
            var frags = [];
            var validIdrefs = {};
            var bookResource = this._userbook.getBookResource();
            var groupedBookmarks = this._userbook.get('bookmarks').groupBy(function (model) {
                return model.get('bookpartId');
            });
            bookResource.getSpineItems().forEach(function (item) {
                validIdrefs[item.idref] = true;
                var bookmarks = groupedBookmarks[item.idref];
                if (bookmarks) {
                    if (!item.label) {
                        item.label = "Uten tittel";
                    }
                    frags.push('<div class="bookpart">');
                    frags.push('<div class="bookpartTitle"><button class="toggleExpandIcon" aria-expanded="true" aria-label="Minimer ' + item.label + '"></button><div class="label">' + item.label + '</div></div>');
                    bookmarks.forEach(function (bookmark) {
                        frags.push(renderBookmarkItem(bookmark,  me._mobileApp));
                    });
                    frags.push('</div>');
                }
            });

            // Render bookmarks with invalid idrefs
            var frags2 = [];
            frags2.push('<div class="bookpart">');
            frags2.push('<div class="bookpartTitle"><button class="toggleExpandIcon" aria-expanded="true" aria-label="Minimer kapittel"></button><div class="label">' + '(missing chapter)' + '</div></div>');
            _.each(groupedBookmarks, function (bookmarks, key) {
                if (!validIdrefs[key]) {
                    bookmarks.forEach(function (bookmark) {
                        frags2.push(renderBookmarkItem(bookmark,  me._mobileApp));
                    });
                }
            });
            frags2.push('</div>');
            if (frags2.length > 3) { // Meaning: some annotation fragments have been pushed
                frags = frags.concat(frags2);
            }


            this.$el.find('.content').html(frags.join(''));
        },

        onToggleExpand: function (e) {
            var elem = $(e.currentTarget).parent();
            elem.toggleClass('collapsed');
            $('.toggleExpandIcon').attr('aria-expanded', ! elem.hasClass('collapsed'));
        },

        saveBookmarkLabel: function (e) {
            if (e.keyCode === 13) {
                var elem = $(e.currentTarget).parent().parent();
                var item = this._userbook.get('bookmarks').findWhere({id: '' + elem.data('id')});

                var noteInput = elem.find('.edit-label');
                var text = noteInput.val();
                text = _.escape(text);
                this._userbook.updateBookmark(item, text);
                elem.removeClass('editing');
            }
        },

        editLabel: function (e) {
            var me = this;
            var elem = $(e.currentTarget).parent().parent();
            var item = this._userbook.get('bookmarks').findWhere({id: '' + elem.data('id')});

            var noteInput = elem.find('.edit-label');
            var confirmEdit = elem.find('.confirm-edit');
            elem.addClass('editing');
            noteInput.show();
            confirmEdit.show().css('display', 'inline-block');
            elem.find('.write-comment').hide();
            elem.find('.edit').hide();
            elem.find('.labeltext').hide();
            elem.find('#edit').hide();

            confirmEdit.on('click', function () {
                var text = noteInput.val();
                text = _.escape(text);
                me._userbook.updateBookmark(item, text);
                elem.removeClass('editing');
            });
            return false;
        },

        deleteBookmark: function (e) {
            var elem = $(e.currentTarget).parent();
            var item = this._userbook.get('bookmarks').findWhere({id: '' + elem.data('id')});
            this._userbook.deleteBookmark(item);
            this.$el.find('.bookmark').focus();
            return false;
        },

        onDataChanged: _.debounce(function () {
            // NOTE: midlertidig brute-force implementation.
            this._renderContent();
        }, 300),

        setUserbook: function (userbook) {
            this.$el.find('.content').empty();
            if (userbook !== this._userbook) {
                if (this._userbook) {
                    this._userbook.get('bookmarks').off(null, null, this);
                }
                this._userbook = userbook;
                if (userbook) {
                    this._renderContent();
                    userbook.get('bookmarks').on('add remove change', this.onDataChanged, this);
                }
            }
        },

        onKeyUpBookmark: function (e) {
            if (e.keyCode === 37 || e.keyCode === 39) {
                e.stopPropagation();
                return; // arrow keys, do nothing
            }
            if (e.keyCode === 13) {
                if(e.target.classList.contains('btn-delete')) {
                    return; // delete-button
                }
                this.gotoBookmark(e);
                e.stopPropagation();
            }
        }
    });
});

