/* global define, location, window, navigator, document, console, alert, Offline, Bugsnag */
/**
 * @module  webapp
 * @namespace  models
 * @class  UnibokRouter
 */
define('UnibokRouter',['backbone', 'jquery', 'redirectToLogin'], function (Backbone, $, redirectToLogin) {

    return Backbone.Router.extend({

        initialize: function (appView) {
            this._appView = appView;
        },

        routes: {
            "": "handleBookshelfView",
            "confirmlogin": "handleConfirmLogin",
            "samtykke": "handleSamtykkeView",
            "bok/:publisher/:bookId(/)(:epubId)(/)(:page)": "handleIncognitoBookView",
            "abok/:publisher/:bookId(/)(:epubId)(/)(:page)": "handleAnonymousBookView",
            "preview/:publisher/:bookId(/)(:epubId)(/)(:page)": "handlePreviewBookView",
            ":publisher/:bookId(/)(:epubId)(/)(:page)": "handleBookView",
            "*path": "handleBookshelfView"
        },

        handleBookshelfView: function() {
            var user;
            if (!(user = this._appView.model.get('activeUser'))) {
                return redirectToLogin();
            }
            if (
                user &&
                user.get('activeUserbook') &&
                user.get('activeUserbook').get('isOpening')) {
                        window.location.href="/";
                }

            document.title = 'Unibok';
            this._appView.enterBookshelfView(user);
        },

        handleConfirmLogin: function() {
            this._appView.enterConfirmLoginView();
        },

        handleSamtykkeView: function () {
            this._appView._showSamtykke = true;
            this.handleBookshelfView();
        },

        handleBookView: function(publisher, bookId, epubId, page) {
            var user;
            if (!(user = this._appView.model.get('activeUser'))) {
                return redirectToLogin();
            }
            this._appView.loadUserbook(user, publisher, bookId, epubId, page);
        },

        handleIncognitoBookView: function(publisher, bookId, epubId, page) {
            $('body').addClass('incognitoMode');
            this.incognitoMode = true;
            this.handleBookView(publisher, bookId, epubId, page);
        },

        handleAnonymousBookView: function(publisher, bookId, epubId, page) {
            this.anonymousMode = true;
            this.handleBookView(publisher, bookId, epubId, page);
        },

        handlePreviewBookView: function(publisher, bookId, epubId, page) {
            this._appView.previewMode = true;
            this.handleBookView(publisher, bookId, epubId, page);
        }
    });
});

