/* global URI, require, navigator, console, async, window, alert, Blob, FileReader, XMLHttpRequest, JSON */
/**
 * This class handles downloading and fetching of downloaded epub content in webapp using localForage
 *
 * @module webapp
 * @namespace  ResourceManagers
 * @class  LocalFileResourceFetcher
 */
define('resourcefetchers/LocalForageResourceFetcher',[
    'jquery',
    'underscore',
    'resourcefetchers/ResourceFetcherBase',
    'localforage',
    'async',
    'URIjs',
    'bowser',
    'blobifier',
    'resourcefetchers/EpubDownloader'
],
    function ($, _, ResourceFetcherBase, localforage, async, URI, bowser, blobifier, EpubDownloader) {

        var LocalForageResourceFetcher = function (bookResource) {
            ResourceFetcherBase.call(this, bookResource);
        };


        LocalForageResourceFetcher.prototype = Object.create(ResourceFetcherBase.prototype);


        LocalForageResourceFetcher.prototype.getDataStore = function () {
            var me = this;
            if (!this.dataStoreDeferred) {
                this.dataStoreDeferred = $.Deferred();

                if (this.dataStore) {
                    me.dataStoreDeferred.resolve(me.dataStore);
                } else {
                    me._bookResource._app.storageManager.getImplementedLocalForageVersion()

                        .then(function (version) {
                            if (version === 2) {
                                me.dataStore = localforage.createInstance({ name: "bookId_" + me._bookResource.get('bookId') });
                            } else {
                                me.dataStore = localforage;
                            }
                            me.dataStoreDeferred.resolve(me.dataStore);
                        });

                }

            }
            return this.dataStoreDeferred.promise();

        };

        LocalForageResourceFetcher.prototype.getIframeContentCssUrl = function (callback) {
            ResourceFetcherBase.prototype.getIframeContentCssUrl.call(this, function (cssUrl) {
                blobifier.generateCssBlobUrl(cssUrl, {})
                    .then(function (iframeContentCssBlobUrl) {
                        callback(iframeContentCssBlobUrl);
                    })
                    .fail(function (err) {
                        console.error(err);
                        callback(cssUrl);
                    });

            });
        };

        /*
         * Gets the base url of the epub, this means the path to the epub resources on the server.
         *
         * This method overrides ResourceFetcherBase::getRootUrl so the epubid relflects the current
         * downloaded epub (not neccessary the latest epubid).
         *
         * epub path on server <- this is the root url of the epub.
         *     OPS <- this is the base url ofgetthe epub.
         */
        LocalForageResourceFetcher.prototype.getRootUrl = function () {
            var bookResource = this._bookResource;

            var downloadedEpubid = bookResource.get('downloadedEpubid');
            var epubid = downloadedEpubid ? downloadedEpubid : bookResource.get('epubid');

            return this._appModel.get('backendServerUrl') + // if this is a mobile app we prepend the servername
                '/bookresource/publisher/' +
                encodeURIComponent(bookResource.get('publisherRef')) +
                '/book/' +
                encodeURIComponent(bookResource.get('bookId')) +
                '/epub/' +
                encodeURIComponent(epubid);
        };

        LocalForageResourceFetcher.prototype.getDownloader = function () {
            return new EpubDownloader(this);
        };

        LocalForageResourceFetcher.prototype.download = function () {
            var me = this;
            var prefix = this.getLatestEpubidLocalforagePrefix();
            var url = this._bookResource._app.get('backendServerUrl') + prefix + 'offline.ub';

            var downloader = me.getDownloader();
            return downloader.download(url, prefix)
                .then(function () {
                    me._bookResource._app.storageManager.addToDownloadedList(me._bookResource.get('epubid'));
                });
        };

        /**
         * CheckIsDownloaded checks if BookResource exists offline
         * If it does it retrieves which version the local epub is (by epubid)
         * Updates attribute downloadedEpubid on BookResource-model
         * @method checkIsDownloaded
         * @param { function } callback Option callback when the download status is resolved
         */
        LocalForageResourceFetcher.prototype.checkIsDownloaded = function () {
            var me = this;
            return me.getDataStore().then(function (dataStore) {
                return me.checkIsDownloadedInDataStore(dataStore);
            });
        };

        LocalForageResourceFetcher.prototype.checkIsDownloadedInDataStore = function (dataStore) {
            var me = this;
            var prefix = me.getLocalforagePrefix();
            var deferred = $.Deferred();
            dataStore.getItem(prefix + 'downloadedEpubId')
                .then(function (value) {
                    if (value) {
                        me._bookResource._app.storageManager.addToDownloadedList(value);
                        me._bookResource.set('downloadedEpubid', value);
                        me._bookResource.set('isDownloaded', true);
                        deferred.resolve(true);
                    } else {
                        // if downloaded files are gone
                        me._bookResource.setStateToNotDownloaded();
                        deferred.resolve(false);
                    }

                });
            return deferred.promise();
        };

        LocalForageResourceFetcher.prototype.readTextFile = function (path, cb) {

            function success(blob) {
                if (!blob) {
                    cb(new Error("File not found: " + path));
                    return;
                }

                var reader = new FileReader();

                reader.addEventListener("loadend", function () {
                    cb(null, reader.result);
                });

                reader.addEventListener("error", function (e) {
                    e = e || window.event; // Handle IE
                    var err = new Error("Could not read text file");
                    err.code = e.target.error.code;
                    cb(err);
                });

                reader.readAsText(blob);
            }

            function error(err) {
                cb(err);
            }

            this.getDataStore()
                .then(function (dataStore) {
                    dataStore.getItem(path)
                        .then(success)
                        .catch(error);
                }
                );
        };

        LocalForageResourceFetcher.prototype.resolveBookCover = function () {
            var me = this;
            if ('serviceWorker' in navigator) {
                me._bookResource.set('coverUrlToDisplay', me._bookResource.get('thumbnail'));
            } else {
                me.getDataStore().then(me.resolveBookCoverInDataStore.bind(me));
            }
        };

        /**
         * Downloads book covers when online and tries to serve them from
         * localforage if available.
         */
        LocalForageResourceFetcher.prototype.resolveBookCoverInDataStore = function (dataStore) {
            var me = this;
            var key = 'bookcovers/' + me._bookResource.get('bookId');
            if (!me._bookResource.get('thumbnail')) {
                console.log("Book cover", me._bookResource.get('bookId'), " has no thumbnail");
                return;
            }

            var options = {
                mimetype: "image/png",
                fetcher: function (url) {
                    var deferred = $.Deferred();
                    function getBookcoverFromHttp() {
                        var xhr = new XMLHttpRequest();
                        xhr.open('GET', me._bookResource.get('thumbnail'), true);
                        xhr.timeout = 10000;
                        xhr.responseType = 'arraybuffer';

                        xhr.onload = function (e) {
                            if (this.status === 200) {
                                // we don't know the mimetype
                                var blob = new Blob([this.response], { type: "" });
                                dataStore.setItem(url, blob);
                                deferred.resolve(blob);
                            }
                            else {
                                // allready tried to get the thumbnail from localForage,
                                // log and move on.
                                console.debug('Getting cover from server (http) failed, Nothing more to be done.');
                            }
                        };
                        xhr.onerror = function (e) {
                            // allready tried to get the thumbnail from localForage,
                            // log and move on.
                            console.debug('Getting cover from server (http) failed, Nothing more to be done.');
                        };
                        xhr.send();
                    }

                    dataStore.getItem(url, function (err, blob) {
                        if (err || !blob) {
                            console.debug('Couldn\' fetch cover from LocalForage, trying http (server) instead.');
                            getBookcoverFromHttp();
                        } else {
                            deferred.resolve(blob);
                            /* Sync bookcover, but wait a bit to avoid blocking other http-requests */
                            /* TODO: this should be done in userbookSyncer.js based on online status */
                            window.setTimeout(function () {
                                if (me._bookResource._app.get('isOnline')) {
                                    getBookcoverFromHttp();
                                }

                            }, 2000);
                        }
                    });
                    return deferred;
                }
            };

            blobifier.generateGenericBlobUrl(
                key,
                options,
                function (err, blobUrl) {
                    if (err) {
                        console.error("Could not get bookcover from neither server nor localForage:", err);
                    }
                    else {
                        me._bookResource.set('coverUrlToDisplay', blobUrl);
                    }
                }
            );
        };

        /**
         * Removes a (possibly partially) downloaded book from localforage.
         * @method removeDownloadedBook
         */
        LocalForageResourceFetcher.prototype.removeDownloadedBook = function () {
            var me = this;
            var downloadedEpubid = me._bookResource.get('downloadedEpubid');
            console.log("Removing downloaded book with download epubid: " + me._bookResource.get('downloadedEpubid'));
            return me._bookResource._app.storageManager.getImplementedLocalForageVersion()
                .then(function (storageVersion) {
                    switch (storageVersion) {
                        case 2:
                            console.log("version 2");
                            return me.removeDownloadedBookVersion2()
                                .then(function () {
                                    me._bookResource._app.storageManager.removeFromDownloadedList(downloadedEpubid);
                                });
                        case 1:
                            console.log("version 1");
                            return me.removeDownloadedBookVersion1()
                                .then(function () {
                                    me._bookResource._app.storageManager.removeFromDownloadedList(downloadedEpubid);
                                });
                    }
                });
        };

        LocalForageResourceFetcher.prototype.removeDownloadedBookVersion2 = function () {
            var me = this;
            return me.getDataStore()
                .then(function (dataStore) {
                    console.log('clearing data store');
                    dataStore.clear()
                        .then(function () {
                            console.log("Successfully cleared the data store");
                            me.checkIsDownloaded();
                        })
                        .catch(function (err) { console.log(err); });
                });
        };

        LocalForageResourceFetcher.prototype.removeDownloadedBookVersion1 = function () {
            var me = this;

            var prefix = me.getLocalforagePrefix();
            console.log("version 1");
            return localforage.getItem(prefix + 'filelist', function (err, entries) {
                if (err) {
                    alert('Ukjent feil oppsto. Fikk ikke slettet boken.');
                    return console.log('Could not get filelist!', err);
                }

                if (!entries) {
                    // could not find filelist. Has the entry been deleted by user agent?
                    // keep on deleting what we can (downloadedepubId)
                    entries = [];
                }

                // Mark book as not downloaded even when deletion fails
                entries.unshift('downloadedEpubId');

                // Keep the filelist until the bitter end so that files can be deleted anyway
                entries.push('filelist');

                //entries = _.map(entries, function(entry) {
                //    return prefix + entry;
                //});

                me.removeFromLocalForage(entries);
            });
        };

        /**
        * Check if all files are present in localForage
        * Browsers can delete files haphazardly
        * @method checkIntegrity
        */
        LocalForageResourceFetcher.prototype.checkIntegrity = function () {
            var me = this;
            var prefix = me.getLocalforagePrefix();
            var deferred = $.Deferred();

            if (!me._bookResource.get('isDownloaded')) {
                deferred.resolve("Bookresource is not downloaded");
                return;
            }

            var verifyFileList = function (dataStore) {
                return dataStore.getItem(prefix + 'filelist')
                    .then(function (entries) {
                        if (!entries) {
                            // filelist is missing
                            if (window.Bugsnag) {
                                window.Bugsnag.notify(new Error("could not find the list of files in localforage"), function (event) {
                                    event.errors[0].errorClass = 'EB-1861. Downloaded book failed integrity check';
                                    event.severity = 'warning';
                                });
                            }
                            deferred.reject({
                                "title": "Lokale filer mangler",
                                "message": "Noe har dessverre gått galt med den nedlastede boka. Gå til bokhylla, slett boka og gjør boka tilgjengelig frakoblet på nytt."
                            });
                            return;
                        }
                        // get all keys in local storage
                        var keysInLocalForage = {};
                        dataStore.keys(function (err, keys) {
                            keys.forEach(function (key) {
                                keysInLocalForage[key] = true;
                            });

                            for (var i = 0; i < entries.length; i++) {
                                // if any entries are missing in 
                                if (!keysInLocalForage[prefix + entries[i]]) {
                                    if (window.Bugsnag) {
                                        window.Bugsnag.notify(new Error("Could not find " + entries[i] + " in localforage"), function (event) {
                                            event.errors[0].errorClass = 'EB-1861. Downloaded book failed integrity check';
                                            event.severity = 'warning';
                                        });

                                    }
                                    deferred.reject({
                                        "title": "Lokale filer mangler",
                                        "message": "Noe har dessverre gått galt med den nedlastede boka. Gå til bokhylla, slett boka og gjør boka tilgjengelig frakoblet på nytt."
                                    }
                                    );
                                    return;
                                }
                            }
                            deferred.resolve();
                        });
                    });
            };

            me.getDataStore().then(verifyFileList);
            return deferred.promise();
        };

        /**
         * Gets the resource url for the specified url. For LocalForageResourceFetcher
         * this is to use the specified url as key to look up in localforage.
         * @method getResourceUrl
         * @param {String} url The url to use to get the resource url.
         * @param {Function} callback A callback of the type 'function(err, resourceUrl)'.
         *
         */
        LocalForageResourceFetcher.prototype.getResourceUrl = function (url, callback) {
            var me = this;
            if (new URI(url).is("absolute")) {
                callback(null, url);
                return;
            }

            var options = {
                fetcher: function (url) {
                    var deferred = $.Deferred();
                    me.getDataStore()
                        .then(function (dataStore) {
                            dataStore.getItem(url, function (err, blob) {
                                if (err) {
                                    console.log(err);
                                    deferred.reject(err);

                                }

                                if (!blob) {
                                    deferred.reject(new Error('File not found in localForage: "' + url + '".'), null);
                                    return;
                                }

                                return deferred.resolve(blob);
                            });
                        });
                    return deferred;
                }
            };

            blobifier.generateGenericBlobUrl(url, options)
                .then(function (blobUrl) {
                    callback(null, blobUrl);
                })
                .fail(function (err) {
                    console.log(err);
                    callback(err, null);
                });

        };

        /**
         * Removes a (possibly partially) downloaded book from localforage.
         * @method removeFromLocalForage
         * @param {Array} entries An array containing filenames (full path/key in localforage)
         * @param {Function} callback A callback that is called after the book is removed from the device
         *                            which has the following signature: function(error).
         */
        LocalForageResourceFetcher.prototype.removeFromLocalForage = function (entries, callback) {
            var me = this;
            me._bookResource.set('isRemoving', true);
            me._bookResource.setStateToNotDownloaded();
            var prefix = me.getLocalforagePrefix();

            async.mapSeries(
                entries,
                function (entry, asyncEndCallback) {
                    me.getDataStore()
                        .then(function (dataStore) {
                            dataStore.removeItem(prefix + entry, asyncEndCallback);
                        });
                },
                function (err, results) {
                    if (err) {
                        console.log("Could not remove entries:", err);
                    }
                    me._bookResource.set('isRemoving', false);
                    if (callback) {
                        callback();
                    }
                }
            );
        };

        LocalForageResourceFetcher.prototype.abortDownload = function () {
            this.downloadAborted = true;
        };

        /**
         * Gets the ravn-bookinfo.json file from device and runs callback function on the content.
         *
         * @method getBookinfoFromLocalforage
         * @param  {Function} cb           Callback to be called when finished.
         */
        LocalForageResourceFetcher.prototype.getBookinfo = function () {
            var deferred = $.Deferred();
            var key = 'ravn-bookinfo.json';
            var prefix = this.getLocalforagePrefix();

            this.readTextFile(prefix + key, function (err, value) {
                if (err) {
                    deferred.reject("Could not get bookinfo in localforage");
                    return;
                } else {
                    deferred.resolve(JSON.parse(value));
                }
            });
            return deferred.promise();
        };

        /**
         * Converts a localforage URL to a blob url.
         * The callback has the form cbb(err, dataurl).
         * @method getDataURLFromBlobURL
         */
        LocalForageResourceFetcher.prototype.getDataURLFromBlobURL = function (path, callback) {
            this.getDataStore()
                .then(function (dataStore) {
                    dataStore.getItem(path).then(
                        function (blob) {
                            if (!blob) {
                                return callback(null, null);
                            }
                            callback(null, window.URL.createObjectURL(blob));
                        },
                        function (err) {
                            callback(err);
                        });
                });
        };

        /**
         * Returns the localforage key prefix for the current bookresource,
         * using the downloaded epubid (not necessarly the latest).
         *
         * @method getLocalforagePrefix
         * @returns {String} The key prefix used to generate localforage keys.
         */
        LocalForageResourceFetcher.prototype.getLocalforagePrefix = function () {
            return this.getRootUrl() + '/';
        };

        /**
         * Returns the localforage key prefix for the latest epubid. This is used
         * to download the latest book. Every other LocalForageFetcher operation
         * should use the 'downloadedEpubid'.
         *
         * @method getLocalforagePrefix
         * @returns {String} The key prefix used to generate localforage keys.
         */
        LocalForageResourceFetcher.prototype.getLatestEpubidLocalforagePrefix = function () {
            return this.getLocalforagePrefix().replace(
                this._bookResource.get('downloadedEpubid'),
                this._bookResource.get('epubid'));
        };

        /**
         * @method getAssetUrl
         * @async
         * @param {string} originalUrlStr
         * @param {function} mainCallback
         */
        LocalForageResourceFetcher.prototype.getAssetUrl = function (originalUrlStr, mainCallback) {
            var me = this;
            var originalUrl = new URI(originalUrlStr);

            var idref = this._bookResource._spine._idrefMap[originalUrl.path().replace(me.getBaseUrl(), "")];

            var useBlob = !(bowser.msie || bowser.msedge);

            var options = {
                bloburlKey: originalUrlStr,
                fetcher: function (url) {
                    var deferred = $.Deferred();
                    if (url === idref) {
                        me._bookResource._spine.getSpineItemHtmlByHref(originalUrl.path().replace(me.getBaseUrl(), ""))
                            .then(function (htmlStr) {
                                var loc = window.location.origin && !me._bookResource._app.get('mobileApp') ? window.location.origin : ''; // IE10 inserts 'undefined' as part of url without this
                                var baseUrl = originalUrl.directory() + "/";

                                htmlStr = htmlStr.replace('<head>', '<head>' + '<base href="' + loc + baseUrl + '"/>');

                                if (!bowser.safari) {
                                    htmlStr = htmlStr.replace(/(<object .*?\b)(type)(=.*?>)/g, function (match, p1, p2, p3) { // See EB-1200
                                        return p1 + 'typo' + p3;
                                    });
                                }

                                deferred.resolve(htmlStr);
                            });

                    }
                    else if (["css", "xml"].indexOf(new URI(url).suffix()) > -1) {
                        me.getDataStore()
                            .then(function (dataStore) {
                                dataStore.getItem(url, function (err, blob) {
                                    if (err) {
                                        throw new Error('Error while fetching from LocalForage: "' + url + '".');
                                    }

                                    if (!blob) {
                                        throw new Error('File not found: "' + url + '".');
                                    }

                                    var fileReader = new FileReader();

                                    fileReader.onload = function (e) {
                                        deferred.resolve(e.target.result);
                                    };

                                    fileReader.onerror = function (err) {
                                        throw err;
                                    };

                                    fileReader.readAsText(blob);
                                });
                            });
                    }
                    else {
                        me.getDataStore()
                            .then(function (dataStore) {
                                dataStore.getItem(url, function (err, blob) {
                                    if (err) {
                                        throw new Error('Error while fetching from LocalForage: "' + url + '".');
                                    }

                                    if (blob) {
                                        deferred.resolve(blob);
                                    } else {
                                        // ikke krasje leseren pga 404-ressurser
                                        deferred.reject('File not found: "' + url + '".');
                                    }
                                });
                            });
                    }
                    return deferred;
                }
            };

            blobifier.generateXhtmlBlobUrl(idref, options)
                .then(function (blobUrl) {
                    if (useBlob) {
                        mainCallback(blobUrl, useBlob);
                    }
                    else {
                        $.ajax({
                            url: blobUrl,
                            dataType: 'text',
                            success: function (data) {
                                mainCallback(data, useBlob);
                            },
                            error: function (jqXHR, textStatus, errorThrown) {
                                console.log(errorThrown);
                            }
                        });
                    }
                })
                .fail(function (err) {
                    console.log(err);
                });


        };

        /**
         * Returns the localforage key prefix for the current bookresource.
         *
         * @method getLocalforagePrefix
         * @returns {String} The key prefix used to generate localforage keys.
         */
        LocalForageResourceFetcher.prototype.fetchSpineItem = function (href) {
            var me = this;
            var deferred = $.Deferred();
            var filepath = this.getBaseUrl() + href;
            this.readTextFile(filepath, function (err, result) {
                if (err) {
                    deferred.reject("Could not get file:" + filepath + "  :" + JSON.stringify(err));
                }
                deferred.resolve(result);
            });
            return deferred.promise();
        };

        LocalForageResourceFetcher.prototype.fetchFileContentsText = function (fileUrl) {
            var deferred = $.Deferred();
            if (/META-INF\/com.apple.ibooks.display-options.xml$/.test(fileUrl)) {
                return deferred.reject('Not Found');
            }
            if (/META-INF\/encryption.xml$/.test(fileUrl)) {
                return deferred.reject('Not Found');
            }
            this.readTextFile(fileUrl, function (err, result) {
                if (err) {
                    console.error('Error when reading from file/localforage: ' + fileUrl + ':' +
                        JSON.stringify(err));
                }
                //console.log("Got file contents:", result);
                deferred.resolve(result);
            });
            return deferred;
        };
        return LocalForageResourceFetcher;
    });

