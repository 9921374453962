define('templates/confirmLoginView',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;

buf.push("<div id=\"confirmLogin\" hidden=\"hidden\"><div class=\"page-head\"><div class=\"container\"><img alt=\"Unibok - Digitale lærebøker\" src=\"images/login/sitename_x2.png\"/></div></div><div class=\"page-main\"><div class=\"container\"><h1>Du er logget inn i Unibok</h1><p>Du er logget inn i Unibok og vil forbli innlogget helt til du velger å logge deg ut.</p><p>Hvis du logger deg ut, vil bøkene du har gjort tilgjengelig for lesing uten nettilgang bli slettet.</p><p>Klikk på \"Gå til min bokhylle\" for å:<ul><li>Se alle bøkene du har lisens på i én oversiktlig bokhylle.</li><li>Studieverktøy som søk, bokmerker, notater og markering.</li><li>Mulighet for å gjøre bøkene tilgjengelig frakoblet og bruke dem når du ikke er tilkoblet internett.</li><li>Innlest læreboktekst med god lydkvalitet.</li><li>Talesyntese som leser opp teksten for deg.</li></ul></p><button class=\"btn bokhylle login\"><span class=\"bokikon\"></span><span>Gå til min bokhylle</span></button></div></div></div>");;return buf.join("");
}

});
