/*global console */
define('models/Annotation',['backbone', 'underscore'], function (Backbone, _) {

    function generateCfiSortkey(cfi) {
        return cfi.replace(/\[.+?\]/, '').replace(/\d+/g, function (m) {return ('00000' + m).substr(m.length);});
    }

    /*
    model-data:

     id
     bookpartId
     cfi
     colorid
     text
     markedText
     timestamp
     physicalPageNumber

     markedTextWithMarkup

    */
    return Backbone.Model.extend({
        defaults: {
            invalid: false
        },
        initialize: function (data, options) {
            this._userbook = options.userbook;
            this.cfiSortkey = generateCfiSortkey(data.cfi);
        },

        delete: function () {
            this._userbook.deleteAnnotation(this);
            this._userbook.ariaAnnounce('Notat slettet');
        },

        setColor: function (colorid) {
            this.set('dirty', true);
            this.set('colorid', colorid);
        },

        setText: function (text) {
            this.set('dirty', true);
            this.set('text', text);
            this._userbook.ariaAnnounce('Notat lagret');
        },

        getText: function () {
            return this.get('text');
        },

        getMarkedText : function () {
            return this.get('markedTextWithMarkup') || this.get('markedText');
        },

        isLocal: function () {
            return this.get('local') ? true: false;
        },

        isDirty: function () {
            return this.get('dirty') ? true: false;
        },

        validateAndRepair: function () {
            var me = this;
            var bookResource = this._userbook.getBookResource();
            var idref = this.get('bookpartId');

            if (!idref || !bookResource._spine.checkIdrefExists(idref)) {
                me.set('invalid', true);
                return;
            }

            var cfi = this.get('cfi');
            var href = bookResource.getUrlFromIdref(idref);

            bookResource.validateRangeCfi(idref, cfi, true)
                .then(function (validated) {
                    // this is a valid cfi according to readium, all good.
                    if (validated) {
                        me.set('invalid', false);
                        return;
                    }
                });

            bookResource.getSearcher(idref, href)
                .then(function(searcher) {
                if (_.isEmpty(searcher)) {
                    me.set('invalid', true);
                    return;
                }

                var markedText = me.get('markedText');
                var range = searcher.find(markedText);

                // test both that the searcher found a range and that readium agrees that this is a valid cfi.
                if (range) {
                    bookResource.validateRangeCfi(idref, range.cfi, true, function (validated) {
                        me.set('cfi', range.cfi);
                        me.set('invalid', !validated);
                    });
                } else {
                    me.set('invalid', true);
                }
            });
        },

        getJson: function () {
            var data = this.toJSON();
            return data;
        }
    });
});

