define('templates/annotationsPanel',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (ie11, mobileApp, safari) {
buf.push("<div class=\"panel annotationsPanel\"><div class=\"head\"><div class=\"annotationsPanel-head__inner\"><h2 class=\"label\">Mine markeringer</h2>");
if (!( safari || ie11))
{
buf.push("<button id=\"DOCX\" title=\"Last ned dine notater som DOCX\" class=\"btn-export\">DOCX</button>");
}
buf.push("<button id=\"PDF\" title=\"Last ned dine notater som PDF\" class=\"btn-export\">PDF</button></div><button aria-label=\"Lukk Mine markeringer\" class=\"close-icon\">");
if ( mobileApp)
{
buf.push("<span class=\"icon\"></span>");
}
else
{
buf.push("<svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_lukk.svg#lukk\"></use></svg>");
}
buf.push("</button></div><div class=\"hintTextAnnotations\">Marker tekst for å lage en markering og sette et notat</div><div class=\"content scrollableContent\"></div></div>");}.call(this,"ie11" in locals_for_with?locals_for_with.ie11:typeof ie11!=="undefined"?ie11:undefined,"mobileApp" in locals_for_with?locals_for_with.mobileApp:typeof mobileApp!=="undefined"?mobileApp:undefined,"safari" in locals_for_with?locals_for_with.safari:typeof safari!=="undefined"?safari:undefined));;return buf.join("");
}

});
