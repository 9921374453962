/*globals localStorage */
define('syncers/AppSyncer',['underscore', 'timer'], function (_, timer) {

    function AppSyncer(app) {

        if (app.get('activeSessionInfo')) {
            localStorage.setItem('activeSessionInfo', JSON.stringify(app.get('activeSessionInfo')));
        } else {
            if (localStorage.getItem('activeSessionInfo')) {
                app.set('activeSessionInfo', JSON.parse(localStorage.getItem('activeSessionInfo')));
            }
        }
        app.on('change:activeSessionInfo', function (app, activeSessionInfo) {
            if (activeSessionInfo) {
                localStorage.setItem('activeSessionInfo', JSON.stringify(activeSessionInfo));
            }
            else {
                localStorage.removeItem('activeSessionInfo');
            }
        });
    }

    return AppSyncer;
});

