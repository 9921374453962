define('resourcefetchers/UnibokPlainResourceFetcher',[
    'URIjs',
    'readium_js/epub-fetch/plain_resource_fetcher',
    'bookResourceMap',
],
function(URI, ReadiumPlainResourceFetcher, bookResourceMap) {
    var UnibokPlainResourceFetcher = function(parentFetcher) {

        ReadiumPlainResourceFetcher.call(this, parentFetcher);

        var publicationid = bookResourceMap.extractBookIdFromURL(parentFetcher.getEbookURL());
        var bookResource = bookResourceMap.getBookResource(publicationid);

        this.resolveURI = function (pathRelativeToPackageRoot) {
            return bookResource._resourceManager.getRootUrl() + '/' + pathRelativeToPackageRoot;
        };

        this.fetchFileContentsText = function(pathRelativeToPackageRoot, fetchCallback, onerror) {
            var fileUrl = this.resolveURI(pathRelativeToPackageRoot);

            if (typeof fileUrl === 'undefined') {
                throw 'Fetched file URL is undefined!';
            }

            bookResource.fetchFileContentsText(fileUrl, fetchCallback, onerror);
        };
    };

    return UnibokPlainResourceFetcher;
});

