/*global console, window */
define('views/videoLightboxView',['jquery', 'underscore', 'views/LightboxBase', 'templates/videoLightbox', 'EventMediaHandler', 'views/DialogView'],
    function ($, _, LightboxBase, mainTemplate, EventMediaHandler, DialogView) {
        return LightboxBase.extend({
            initialize: function (data, app) {
                LightboxBase.prototype.initialize.call(this, data);
                _.extend(this.events, LightboxBase.prototype.events);

                this._data = data;
                this._app = app;
                if (app.get('activeUser').get('activeUserbook').get('publisherRef') === 'aschehoug') {
                    var token = app.get('activeUser').get('activeUserbook').get('jwt');
                    this._data.source = this._data.source + '?access_token=' + token;
                }
                this._eventMediaHandler = new EventMediaHandler(app, 'Video');
                this.render();
                this.show();
            },

            render: function () {
                var me = this;
                this.setElement(mainTemplate(me._data));
                this.$el.addClass('videoLightbox');
                if (!this._app.get('mobileApp')) {
                    this.$el.hide();
                }
                var videoElement = this.$el.find('video');
                videoElement.bind('contextmenu', function () { return false; });
                if (!this._app.get('mobileApp')) {
                    videoElement.attr('autoplay', true);
                }
                videoElement.attr('controlsList', "nodownload");

                if (me._data.tracks) {
                    me._data.tracks.forEach(t => {
                        var track = window.document.createElement('track');
                        track.setAttribute('src', t.src);
                        if (t.label) {
                            track.setAttribute('label', t.label);
                        }
                        if (t.srclang) {
                            track.setAttribute('srclang', t.srclang || '');
                        }
                        if (t.kind) {
                            track.setAttribute('kind', t.kind || '');
                        }
                        if (t.default) {
                            track.setAttribute('default', t.default || '');
                        }
                        videoElement.append(track);
                    });
                }

                videoElement.bind('timeupdate ended', function () {
                    var percent = videoElement[0].currentTime / videoElement[0].duration;
                    me._eventMediaHandler.checkAndSend(percent);
                });

                videoElement.bind('loadeddata', function () {
                    me.$el.show();
                });

                videoElement.bind('error', function () {
                    if (this.error) {
                        var errorMsg = 'Error when loading video. ErrorCode: ' + this.error.code + ', NetworkState: ' + this.networkState;
                        if (window.Bugsnag) {
                            window.Bugsnag.notify(new Error(errorMsg), function (event) {
                                event.severity = 'warning';
                                event.groupingHash = 'videoLightboxView';
                            });
                        } else {
                            console.error(errorMsg);
                        }

                        new DialogView({
                            "title": "Feil ved lasting av video",
                            "message": "Videofilen er dessverre utilgjengelig. Prøv igjen senere. Feilkode: " + this.error.code,
                            "isAlert": true,
                        });

                        me._destroy();
                    }
                });

                return this;
            },

            _destroy: function () {
                this.$el.find('video').remove();
                LightboxBase.prototype._destroy.call(this);
            },

            /* This method is called by the base class on window resize */
            updateLayout: function () { }
        });
    });

