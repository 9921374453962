/*global alert, console, window, setTimeout, document */
define('views/UserbookView',[
    'jquery',
    'backbone',
    'templates/userbook',
    'models/App',
    'hammer'
], function ($, Backbone, mainTemplate, AppModel, Hammer) {

    return Backbone.View.extend({
        initialize: function (options) {
            this._app = options.app;
            this.render();
            this.model.on('change:cloudStatus', this.onCloudStatusChanged, this);
            this.model.getBookResource().on('change:coverUrlToDisplay', this.changeThumbnail, this);
            this.model.getBookResource().on('change:downloadProgress', this.onDownloadProgressChanged, this);
        },

        events: {
        },

        changeThumbnail: function() {
            this.render();
        },

        onCloudStatusChanged: function() {
            var newCloudStatus = this.model.get('cloudStatus');

            if (newCloudStatus === 'isDownloaded') {
                this._app.plausibleManager.sendEvent(
                    this.model.get('publisherRef'),
                    "Bokhylla",
                    "Lastet ned bok",
                    this.model.getBookResource().get('title')
                );
            }

            this.render();
        },

        render: function () {
            //var bookId = this.model.get('bookId');
            var old = this.$el;
            var me = this;
            var bookResource = this.model.getBookResource();
            var bookUrl = (window.location.search ? window.location.search : '') + '#' + encodeURIComponent(bookResource.get('publisherRef')) + '/' + encodeURIComponent(bookResource.get('bookId'));

            var prettyLevels = bookResource.get('level') || [];
            prettyLevels = prettyLevels.map( function (level) {return level[0].toUpperCase() + level.substr(1).toLowerCase(); });
            prettyLevels.sort();
            this.setElement(mainTemplate({
                bookId: bookResource.get('bookId'),
                title: bookResource.get('title'),
                thumbnail: bookResource.get('coverUrlToDisplay'),
                subject: bookResource.get('subject'),
                subjectArea: bookResource.get('subjectArea'),
                level: prettyLevels.join(', '),
                cloudStatus: this.model.get('cloudStatus'),
                mobileApp: this.model._user._app.get('mobileApp') || window.location.search.match(/mobileApp/),
                offlineApp: this.model._user._app.get('offlineApp'),
                url: bookUrl,
            }));
            if (this._hidden) {
                this.$el.hide();
            }
            this.$el.data('view', this);

            old.replaceWith(this.$el);

            if (this.model._user._app.get('offlineApp') ||
                this.model._user._app.get('mobileApp'))
            {
                var downloadButton = this.$el.find('.btn-download, .btn-update');

                downloadButton.on('click', function (event) {
                    me.model.getBookResource().on('change:downloadProgress', this.onDownloadProgressChanged, this);
                    return me.model.doSomethingCloudStatus(event);
                });

                var removeButton = this.$el.find('.btn-remove-download');

                removeButton.on('click', function (event) {
                    return me.model.deleteBook();
                });
            }

            if (this.$el.hasClass('isDownloaded') && !$('.offline-check input[value="alle"]').prop('checked')) {
                this.$el.find('.btn-remove-download').show();
            }

            return this;
        },

        onEnterPressed: function(fn, event) {
            if(event.which === 13) {
                fn.call(this);
            }
        },

        destroy: function () {
            this.$el.remove();
        },


        show: function () {
            this._hidden = false;
            this.$el.show();
        },

        hide: function () {
            this._hidden = true;
            this.$el.hide();
        },

        onDownloadProgressChanged: function () {
            var progress = this.model.getBookResource().get('downloadProgress');
            this.$el.find('.progress-bar').css("width", progress + "%");
        }
    });
});

