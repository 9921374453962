/* globals window, Request, fetch */

define('TextToSpeechManager',['jquery', 'soundmanager', 'Config'], function ($, soundmanager) {

    var TO_START = true;
    var TO_END = false;

    function createHighlightDiv(rect, leftOffset) {
        var wordDiv = $('<div>');
        wordDiv.css('position', 'absolute');
        wordDiv.css('background-color', 'blue');
        wordDiv.css('opacity', '0.5');
        wordDiv.css('pointer-events', 'none');
        wordDiv.css('top', rect.top + 'px');
        wordDiv.css('height', rect.height + 'px');
        wordDiv.css('left', leftOffset + rect.left);
        wordDiv.css('width', rect.width + 'px');
        wordDiv.attr('data-id', '');

        return wordDiv;
    }

    function TextToSpeechManager(backendUrl) {
        this.options = {};
        this._range = null;
        this.onEndedPlayerProxy = this.onEndedPlayer.bind(this);
        this.markDownInfoHandlerProxy = this.markDownInfoHandler.bind(this);
        soundmanager.onNew(this.clear.bind(this));
        this.backendUrl = backendUrl;

    }

    TextToSpeechManager.prototype.playCereproc = async function (textRange, voice, callback) {
        var url = this.backendUrl + '/cereproc-speak';
        var params = {};
        params.voice = voice ? voice : "Clara-CereWave";
        this._range = textRange;
        var clone = textRange.cloneRange();
        var speakText = clone.text();
        if (Object.keys(params).length > 0) {
            url += '?' + Object.entries(params).map(x => x.join('=')).join('&');
        }
        var body = JSON.stringify({ text: speakText });
        var req = new Request(url, {
            method: 'POST',
            body: body,
            headers: {
                "Content-Type": "application/json"
            }
        });

        let audioUrl, metadata;
        try {
            const response = await fetch(req);
            const data = await response.json();
            audioUrl = data.url;
            metadata = data.metadata;
        } catch (e) {
            console.error('Error retrieving Cereproc data', e);
            callback(e);
            return;
        }
/*
        if (metadata) {
            var markdown = this.xmlToMarkdown(metadata);
            this.metadataHandler(markdown);
        }
*/
        if (audioUrl) {
            var player = this.getPlayer();
            $(player).on('ended', this.onEndedPlayerProxy);
            soundmanager.showAudioWrapper();
            soundmanager.loadPlayer(audioUrl);
            $(".speed-btn").css("pointer-events", "all");
            $("#voiceList").removeAttr("disabled");
            $("#speedSelect").css("opacity", "1");
            $(".btn-TTS").css("opacity", "1");
            $(".play-pause").focus();
            soundmanager.play();
            callback();
        }
    };

    TextToSpeechManager.prototype.getCereprocVoices = async function () {
        var req = new Request(this.backendUrl + '/cereproc-voices', {
            method: 'GET',
        });

        const response = await fetch(req);
        const voiceArray = await response.json();
        return voiceArray;
    };

    TextToSpeechManager.prototype.getDoc = function () {
        var doc = $('#reader iframe')[0];
        if (!doc) {
            return null;
        }
        else {
            return $(doc.contentDocument);
        }
    };

    TextToSpeechManager.prototype.setVoice = function (voice) {
        this.options.voice = voice;
    };

    TextToSpeechManager.prototype.getVoice = function () {
        return this.options.voice;
    };

    TextToSpeechManager.prototype.clear = function () {
        this._speeking = false;
        this._index = 0;
        this._highlightDivList = [];
        this._fileId = null;
        if (this.getDoc()) {
            this.getDoc().find('html').find('#TTSHighlight').remove();
            this.getDoc().find('html').find('#TTSHighlightPre').remove();
        }
        window.clearInterval(this._intervalId);
    };

    TextToSpeechManager.prototype.clearAndStop = function () {
        this.clear();
        soundmanager.close();
    };

    TextToSpeechManager.prototype.xmlToMarkdown = function (xml) {
        var markdown = {};
        markdown.Words = [];
        var xmlDoc = $.parseXML(xml);

        $(xmlDoc).context.querySelectorAll('word').forEach((word) => {
            var wordObj = {name: $(word).attr('name'), start: $(word).attr('start'), end: $(word).attr('end')};
            markdown.Words.push(wordObj);
        });
        return markdown;
    };

    TextToSpeechManager.prototype.metadataHandler = function (markdown) {

        this._highlightDivList = [];
        this._index = 0;
        var leftOffset = this.getLeftOffset();

        var item = null;
        var last = 0;

        for (var i = 0; i < markdown.Words.length; i++) {
            item = markdown.Words[i];

            // make sure we still reference this._range. (can be changed in the code that extracts
            // text nodes, see code below).
            var currentRange = this._range;

            currentRange.moveStart('character', last);
            currentRange.moveEnd('character', item.name.length);
            last = last + item.name.length;

            var spacePos = currentRange.text().indexOf(' ');
            if (spacePos !== -1) {

                var textLength = currentRange.text().length;

                var start = currentRange.cloneRange();
                start.collapse(TO_START);
                var left = start.getBoundingClientRect().left;

                var end = currentRange.cloneRange();
                end.collapse(TO_END);
                var right = end.getBoundingClientRect().right;

                if (left > right) {
                    // seems like this is a range that spans two lines
                    start.moveEnd('character', spacePos);
                    var el1 = createHighlightDiv(start.getBoundingClientRect(), leftOffset);

                    start.collapse(TO_END);
                    start.moveStart('character', 1);
                    start.moveEnd('character', textLength - spacePos - 1);
                    var el2 = createHighlightDiv(start.getBoundingClientRect(), leftOffset);

                    // create a jQuery set of el1 and el2
                    var elSet = $([el1[0], el2[0]]);

                    this._highlightDivList.push({
                        el: elSet,
                        timing: item.start,
                    });

                    // we're done, move to next item in the Words list.
                    continue;
                }
            }

            if (['\n', '\t'].indexOf(currentRange.toString().substr(0, 1)) !== -1) {
                // HACK: rangy doesn't handle newline/tabs as expected.
                currentRange.moveStart('character', 2);
                currentRange.moveStart('character', -2);
            }

            if (currentRange.getNodes().length === 2) {
                // the range should only containg one element (a text element).
                // this is apperently not the case and we try to extract the correct text node.
                for (var j = 0; j < currentRange.getNodes().length; j++) {
                    var currentNode = currentRange.getNodes()[j];
                    if (currentNode.nodeType === window.Node.TEXT_NODE) {
                        currentRange = this.getDoc()[0].createRange();
                        currentRange.selectNode(currentNode);
                        break;
                    }
                }
            }

            this._highlightDivList.push({
                el: createHighlightDiv(currentRange.getBoundingClientRect(), leftOffset),
                timing: item.start
            });
        }

        // collapse range to end so it's ready for the next file.
        this._range.collapse(TO_END);

        var div = $('<div id="TTSHighlight">');
        this._highlightDivList.forEach(function (highlight) {
            div.append(highlight.el);
        });

        this.getDoc().find('html').append(div);

    };

    TextToSpeechManager.prototype.getLeftOffset = function () {
        var leftOffset = parseInt(this.getDoc().find('html').css('left'), 10) * -1;
        if (isNaN(leftOffset)) {
            leftOffset = 0;
        }

        return leftOffset;
    };

    TextToSpeechManager.prototype.playNextInQue = function () {
        if (this._fileQueue && this._fileQueue.length > 0) {
            this._fileId = this._fileQueue[0].FilePrefix;
            var metadataHandler = $.proxy(this.metadataHandler, this);
            this._fileQueue.shift();
        }
        else {
            this.clearAndStop();
        }
    };

    TextToSpeechManager.prototype.onEndedPlayer = function () {
        $(this.getPlayer()).off("ended", this.onEndedPlayerProxy);
        this.getDoc().find('html').find('#TTSHighlight').remove();
        window.clearInterval(this._intervalId);
        this.playNextInQue();
    };

    TextToSpeechManager.prototype.ttsplay = function () {
        soundmanager.play();
    };

    TextToSpeechManager.prototype.markDownInfoHandler = function (res) {
        this._fileQueue = res;
        this.playNextInQue();
    };

    TextToSpeechManager.prototype.getPlayer = function () {
        if (!this._player) {
            this._player = soundmanager._player[0].element;
        }

        return this._player;
    };

    TextToSpeechManager.prototype.isSpeeking = function () {
        return this._speeking;
    };

    return TextToSpeechManager;
});

