/*global console */


/**
 * Simple implementation of client-side search.
 *
 * @module webapp
 * @namespace Searcher
 */
define('Searcher',['binarySearch', 'buildSearchmapWithSax', 'thirdparty/sax', 'repairAndFilterXmlString'], function (binarySearch, buildSearchmapWithSax, sax, repairAndFilterXmlString) {

    function deltaOffsetInMarkupToPureTextDelta(origText, deltaOffset) {
        var parts = origText.split(/(&.+?;)/g);
        var _offset = 0;
        var _entityAdjust = 0;
        for (var i = 0; i < parts.length; i += 2) {
            var t = parts[i];
            var entity = parts[i+1];
            if (deltaOffset <= (_offset + t.length)) {
                break;
            }
            _entityAdjust += entity.length - 1;
            _offset += t.length + entity.length;
        }
        return deltaOffset - _entityAdjust;
    }

    function deltaOffsetInPureTextToDeltaOffsetInMarkup(origText, deltaOffsetInPureText) {
        var parts = origText.split(/(&.+?;)/g);
        var _entityAdjust = 0;
        var _offset = 0;

        for (var i = 0; i < parts.length; i += 2) {
            var t = parts[i];
            var entity = parts[i+1];
            if (deltaOffsetInPureText <= (_offset + t.length)) {
                break;
            }
            if (entity !== undefined) {
                _entityAdjust += entity.length - 1;
                _offset += t.length + 1;// + entity.length;
            }
        }
        return deltaOffsetInPureText + _entityAdjust;
    }

    /**
     * Represents the position of a search result.
     *
     * @class Position
     */
    function Position(searcher, offset, inMarkupText) {
        this._searcher = searcher;

        if (typeof offset === 'string') {
            this._initFromCfi(offset);
        } else if (inMarkupText) {
            this._initFromOffsetInMarkup(offset);
        } else {
            this._initFromOffset(offset);
        }

        this._hasAdjacentTag = false;
        if (this._precedingTag) {
            if (this._precedingTag.next && this._precedingTag.next.localOffset === this._offset) {
                this._hasAdjacentTag = true;
            } else if (this._precedingTag.localOffset === this._offset) {
                this._hasAdjacentTag = true;
            }
        } else if (this._searcher._map2.length && this._searcher._map2[0].localOffset === this._offset) {
            this._hasAdjacentTag = true;
        }
    }

    Position.prototype = {
        _initFromCfi: function (cfi) {
            var map = this._searcher._map2;
            var nearestTagInfoToTheLeft = null;

            var cfiParts = cfi.split('/');
            var textTerminus = cfiParts.pop();
            var elementCfi = cfiParts.join('/');
            var terminusParts = textTerminus.split(':');
            var a = parseInt(terminusParts[0], 10);
            var deltaOffsetInPureText = parseInt(terminusParts[1], 10);
            var jumps = (a - 1) / 2;

            var tagInfo, i;
            if (elementCfi) {
                // We have an elementCfi. (The first part of the cfi that only have even numbers, for example: '/2/4/').
                // TODO: use binary search to find matchedTagInfo
                var matchedTagInfo = null;
                for (i = 0; i < map.length; i++) {
                    tagInfo = map[i];
                    if (tagInfo.typ !== 'closing') {
                        if (tagInfo.testElementCfi(elementCfi)) {
                            matchedTagInfo = tagInfo;
                            break;
                        }
                    }
                }

                if (!matchedTagInfo) {
                    throw new Error('Could not find element from element-cfi');
                }

                // If we have found the tag corresponding to the elementCfi, we must 'jump' rightwards in what is assumed to be child-tags.
                // TODO: make sure we only traverse child-tags. Else raise error
                while (jumps) {
                    tagInfo = tagInfo.next.closingTag;
                    jumps--;
                }
                nearestTagInfoToTheLeft = tagInfo;


            } else {
                // We dont have an elementCfi. This means that we are jumping through sibling-tags at the root-level.
                // This will probably only occur in unittests.

                if (jumps) {
                    // We have some 'jumping' to do.
                    tagInfo = map[0].closingTag;
                    jumps -= 1; // The first tag counts for the first jump. Therefor the jumps are decreased here.
                    while (jumps) {
                        tagInfo = tagInfo.next.closingTag;
                        jumps--;
                    }
                    nearestTagInfoToTheLeft = tagInfo;
                }
            }

            if (nearestTagInfoToTheLeft) {
                this._offset = nearestTagInfoToTheLeft.localOffset + deltaOffsetInPureText;
            } else {
                this._offset = deltaOffsetInPureText;
            }
            this._offsetInMarkupText = this._searcher.convertDeltaOffsetInPureTextToOffsetInMarkup(nearestTagInfoToTheLeft, deltaOffsetInPureText);
            this._precedingTag = nearestTagInfoToTheLeft;

            /* This binarySearch code is has bugs. Can not be used yet.
            function generateCfiSortkey(cfi) {
                return cfi.replace(/\[.+?\]/g, '').replace(/\d+/g, function (m) {return ('00000' + m).substr(m.length);});
            }
            var elementIndex = binarySearch(this._map2, function (tagInfo) {
                //console.log(tagInfo.typ);
                var tagInfoCfiSortkey;
                if (tagInfo.typ === 'closing') {
                    tagInfoCfiSortkey = generateCfiSortkey(tagInfo.openingTag.elementCfi);
                    if (tagInfoCfiSortkey < sortableElementCfi) {
                        return -1;
                    } else if (sortableElementCfi !== tagInfoCfiSortkey.substr(0, sortableElementCfi.length)) {
                        // Means that we do NOT look for a child of current tagInfo. We kan safely move right
                        return -1;
                    } else {
                        return 1;
                    }

                } else {
                    tagInfoCfiSortkey = generateCfiSortkey(tagInfo.elementCfi);
                    //console.log('tagInfoCfiSortkey', tagInfoCfiSortkey, sortableElementCfi, 'tagInfo.elementCfi', tagInfo.elementCfi);
                    if (tagInfoCfiSortkey < sortableElementCfi) {
                        return -1;
                    } else if (tagInfoCfiSortkey > sortableElementCfi) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            }, false, false);
            if (elementIndex !== -1) {
                var nearestTagInfoToTheLeft = this._map2[elementIndex];

                var terminusParts = textTerminus.split(':');
                var a = parseInt(terminusParts[0], 10);
                var b = parseInt(terminusParts[1], 10);
                var jumps = (a - 1) / 2;
                while (jumps) {
                    nearestTagInfoToTheLeft = nearestTagInfoToTheLeft.next.closingTag;

                    jumps--;
                }
                return new Position(this, nearestTagInfoToTheLeft.endOffset + b, true);
            }
            return null;
             */
        },

        _initFromOffset: function (offset) {
            var map = this._searcher._map2;
            var nearestTagInfoToTheLeft = null;
            var deltaOffsetInPureText;

            if (offset < 0 || offset > this._searcher.getPureText().length) {
                throw new Error('Index out of range');
            }

            var tagInfoIndex = binarySearch(map, function (tagInfo) {
                return offset <= tagInfo.localOffset ? 1 : 0;

            }, false, true);
            if (tagInfoIndex !== -1) {
                nearestTagInfoToTheLeft = map[tagInfoIndex];
            }
            this._precedingTag = nearestTagInfoToTheLeft;
            this._offset = offset;

            if (nearestTagInfoToTheLeft) {
                deltaOffsetInPureText = offset - nearestTagInfoToTheLeft.localOffset;
            } else {
                deltaOffsetInPureText = offset;
            }
            this._offsetInMarkupText = this._searcher.convertDeltaOffsetInPureTextToOffsetInMarkup(nearestTagInfoToTheLeft, deltaOffsetInPureText);
        },

        _initFromOffsetInMarkup: function (offsetInMarkup) {
            var map = this._searcher._map2;
            var nearestTagInfoToTheLeft = null;
            var deltaOffsetInMarkup;

            if (offsetInMarkup < 0 || offsetInMarkup > this._searcher.getXhtmlString().length) {
                throw new Error('Index out of range');
            }

            var tagInfoIndex = binarySearch(map, function (tagInfo) {
                return offsetInMarkup <= tagInfo.offset ? 1 : 0;

            }, false, true);
            if (tagInfoIndex !== -1) {
                nearestTagInfoToTheLeft = map[tagInfoIndex];
            }

            this._precedingTag = nearestTagInfoToTheLeft;
            this._offsetInMarkupText = offsetInMarkup;
            if (nearestTagInfoToTheLeft) {
                deltaOffsetInMarkup = offsetInMarkup - nearestTagInfoToTheLeft.endOffset;
                if (deltaOffsetInMarkup < 0) {
                    throw new Error('Invalid offset in markupText');
                }
            } else {
                deltaOffsetInMarkup = offsetInMarkup;
            }
            this._offset = this._searcher.convertDeltaOffsetInMarkupToOffsetInPureText(nearestTagInfoToTheLeft, deltaOffsetInMarkup);
        },

        /**
         * Description here.
         * @method jumpBackward
         */
        jumpBackward: function (log) {
            var moved = false;

            if (this._hasAdjacentTag && this._precedingTag) {
                if (this._precedingTag.endOffset === this._offsetInMarkupText) {
                    if (this._precedingTag.prev) {
                        this._offsetInMarkupText = this._precedingTag.offset;
                        this._precedingTag = this._precedingTag.prev;
                    } else {
                        this._offsetInMarkupText = this._precedingTag.offset;
                        this._precedingTag = null;
                    }
                    moved = true;
                }
            }
            return moved;
        },

        jumpForward: function () {
            var moved = false;
            if (this._hasAdjacentTag) {
                var map = this._searcher._map2;
                if (this._precedingTag && this._precedingTag.next && this._precedingTag.next.offset === this._offsetInMarkupText) {
                    this._precedingTag = this._precedingTag.next;
                    this._offsetInMarkupText = this._precedingTag.endOffset;
                    moved = true;
                } else if (map.length && map[0].offset === this._offsetInMarkupText) {
                    this._precedingTag = map[0];
                    this._offsetInMarkupText = this._precedingTag.endOffset;
                    moved = true;
                }
            }
            return moved;
        },

        get hasAdjacentTag () {
            return this._hasAdjacentTag;
        },

        get offset () {
            return this._offset;
        },

        get offsetInMarkupText () {
            return this._offsetInMarkupText;
        },

        get cfi () {
            var offsetInMarkupText = this._offsetInMarkupText;
            var cfi = '';
            var nearestTagInfoToTheLeft = this._precedingTag;

            if (nearestTagInfoToTheLeft) {
                var prevSibling;
                var parent = null;
                if (nearestTagInfoToTheLeft.typ === 'closing' || nearestTagInfoToTheLeft.typ === 'selfclosing') {
                    prevSibling = nearestTagInfoToTheLeft.openingTag;
                    parent = prevSibling.parent;
                    cfi = '/' + ((prevSibling.siblingIndex + 1) * 2 + 1) + ':';
                } else if (nearestTagInfoToTheLeft.typ === 'opening') {
                    prevSibling = null;
                    parent = nearestTagInfoToTheLeft;
                    cfi = '/1:';
                }
                cfi += '' + (this._searcher.convertDeltaOffsetInMarkupToOffsetInPureText(nearestTagInfoToTheLeft, offsetInMarkupText - nearestTagInfoToTheLeft.endOffset) - nearestTagInfoToTheLeft.localOffset);

                while (parent) {
                    var assertion = parent.id ? '[' + parent.id + ']' : '';
                    cfi = '/' + ((parent.siblingIndex + 1) * 2) + assertion + cfi;
                    parent = parent.parent;
                }
            } else {
                cfi = '/1:' +  this._searcher.convertDeltaOffsetInMarkupToOffsetInPureText(null, offsetInMarkupText);
            }
            return cfi;
        },

        getHeadContext: function (maxChars, keepTags) {
            var result;
            maxChars = Math.min(this._offset, maxChars);
            if (keepTags) {
                var startPosition = this._searcher.createPosition(this._offset - maxChars);
                result = repairAndFilterXmlString(this._searcher.getXhtmlString().substr(startPosition.offsetInMarkupText, this.offsetInMarkupText - startPosition.offsetInMarkupText), keepTags);
            } else {
                result = this._searcher.getPureText().substr(this._offset - maxChars, maxChars);
            }
            result = result.substr(result.indexOf(' ')+1); // trim stuff before first space
            return result;
        },

        getTailContext: function (maxChars, keepTags) {
            var pureText = this._searcher.getPureText();
            var result;
            maxChars = Math.min(pureText.length - this._offset, maxChars);
            if (keepTags) {
                var endPosition = this._searcher.createPosition(this._offset + maxChars);
                result = repairAndFilterXmlString(this._searcher.getXhtmlString().substr(this.offsetInMarkupText, endPosition.offsetInMarkupText - this.offsetInMarkupText), keepTags);
            } else {
                result = pureText.substr(this._offset, maxChars);
            }
            result = result.substr(0, result.lastIndexOf(' ')); // trim stuff after last space
            return result;
        }
    };

    function Range(searcher, startPosition, endPosition) {
        this._searcher = searcher;
        if (startPosition.offset > endPosition.offset) {
            throw new Error('StartOffset should not be greater than endOffset');
        }

        this._startPosition = startPosition;
        this._endPosition = endPosition;
    }
    Range.prototype = {
        balance: function () {
            if (this._startPosition.hasAdjacentTag || this._endPosition.hasAdjacentTag) {
                while (this._startPosition.jumpForward()) {}
                while (this._endPosition.jumpBackward()) {}
                if (this._startPosition._precedingTag === this._endPosition._precedingTag) {
                    // happy
                    //console.log('happy');
                } else {
                    while (this._startPosition.jumpBackward()) {}
                    while (this._endPosition.jumpForward()) {}
                    //console.log('try move outwards');
                }
            } else {
                //console.log('no need to balance');
            }
        },

        get cfi () {
            var startCfi = this._startPosition.cfi.split('/');
            var endCfi = this._endPosition.cfi.split('/');

            var commonPath = [];

            while (startCfi.length && endCfi.length && startCfi[0] === endCfi[0]) {
                if (startCfi.length === 1 && endCfi.length === 1) {
                    break;
                }
                commonPath.push(startCfi.shift());
                endCfi.shift();
            }
            return commonPath.join('/') + ',/' + startCfi.join('/') + ',/' + endCfi.join('/');
        },

        get startOffsetInMarkupText () {
            return this._startPosition.offsetInMarkupText;
        },

        get endOffsetInMarkupText () {
            return this._endPosition.offsetInMarkupText;
        },

        get text () {
            return this._searcher.getPureText().substr(this._startPosition.offset, this._endPosition.offset - this._startPosition.offset);
        },

        getHeadContext: function (maxChars, keepTags) {
            return this._startPosition.getHeadContext(maxChars, keepTags);
        },

        getTailContext: function (maxChars, keepTags) {
            return this._endPosition.getTailContext(maxChars, keepTags);
        },

        getText: function (keepTags) {
            var result;
            if (keepTags) {
                var rawText = this._searcher.getXhtmlString().substr(this._startPosition.offsetInMarkupText, this._endPosition.offsetInMarkupText - this._startPosition.offsetInMarkupText);
                result = repairAndFilterXmlString(rawText, keepTags);
            } else {
                result = this.text;
            }
            return result;
        }
    };


    function Searcher(xhtmlString) {
        var searcherFromSax = buildSearchmapWithSax(xhtmlString);
        this._xhtmlString = searcherFromSax.xhtmlString;
        this._cleanText = searcherFromSax.cleanText;
        this._map2 = searcherFromSax.map;
        this._mathExpressionPositions = searcherFromSax.mathExpressionPositions;
        this.entityCount = searcherFromSax.entityCount;

    }
    Searcher.prototype = {
        find: function (searchString) {
            // only look for one match (for now)

            // cleanup whitespaces in searchString
            function escapeRegExp(str) {
                 return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
            }
            searchString = escapeRegExp(searchString);
            searchString = searchString.replace(/\s+/g, '\\s+');

            var result = null;
            try {
                var regexp = new RegExp(searchString, 'gi');
                var m = regexp.exec(this._cleanText);
                if (m) {
                    result = this.createRange(m.index, m.index + m[0].length);
                }
            } catch(e) { }

            return result;
        },

        findAll: function (searchString) {
            function escapeRegExp(str) {
                return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
            }
            searchString = escapeRegExp(searchString);
            searchString = searchString.replace(/\s+/g, '\\s+');

            var result = [];
            try {
                var regexp = new RegExp(searchString, 'gi');

                var m;
                while ((m = regexp.exec(this._cleanText)) !== null){
                    result.push(this.createRange(m.index, m.index + m[0].length));
                }
            } catch(e) { }

            return result;
        },

        getRangeWithContext: function (range, keepTags) {
            var me = this;
            function isPosInMath(offset) {
                for (var i=0;i<me._mathExpressionPositions.length;i++) {
                    var math = me._mathExpressionPositions[i];
                    if (offset > math[0] && offset<math[1]) {
                        return math;
                    }
                }
                return false;
            }
            var start = range._startPosition.offset - 30;
            var end = range._endPosition.offset + 30;
            if (start < 0) { start = 0; }
            if (end >= this._cleanText.length) {
                end = this._cleanText.length;
            }
            var math = isPosInMath(start);
            if (math) {
                start = math[0] - 1;
            }
            math = isPosInMath(end);
            if (math) {
                end = math[1] + 1;
            }

            return this.createRange(start, end);
            

        },

        pureTextSearch: function (searchString) {
            // TODO: should be more lenient about whitespaces
            var result = null;
            var index = this._cleanText.indexOf(searchString);
            if (index !== -1) {
                var startPos = index;
                var endPos = index + searchString.length;
                result = [startPos, endPos];
            }
            return result;
        },

        createPosition: function (offset) {
            return new Position(this, offset);
        },

        createPositionFromOffsetInMarkup: function (offsetInMarkup) {
            return new Position(this, offsetInMarkup, true);
        },

        createPositionByCfi: function (cfi) {
            try {
                return new Position(this, cfi);
            } catch (err) {
                console.error(err);
                return null;
            }
        },

        createPositionByCfi_BINARY: function (cfi) {
            // Not implemented

            // TODO remove last part of cfi
            var cfiParts = cfi.split('/');
            var textTerminus = cfiParts.pop();
            var elementCfi = cfiParts.join('/');

            function generateCfiSortkey(cfi) {
                return cfi.replace(/\[.+?\]/g, '').replace(/\d+/g, function (m) {return ('00000' + m).substr(m.length);});
            }


            var sortableElementCfi = generateCfiSortkey(elementCfi);
            //console.log('elementCfi', elementCfi, sortableElementCfi, 'terminus', textTerminus);

            var elementIndex = binarySearch(this._map2, function (tagInfo) {
                //console.log(tagInfo.typ);
                var tagInfoCfiSortkey;
                if (tagInfo.typ === 'closing') {
                    tagInfoCfiSortkey = generateCfiSortkey(tagInfo.openingTag.elementCfi);
                    if (tagInfoCfiSortkey < sortableElementCfi) {
                        return -1;
                    } else if (sortableElementCfi !== tagInfoCfiSortkey.substr(0, sortableElementCfi.length)) {
                        // Means that we do NOT look for a child of current tagInfo. We kan safely move r   ht
                        return -1;
                    } else {
                        return 1;
                    }

                } else {
                    tagInfoCfiSortkey = generateCfiSortkey(tagInfo.elementCfi);
                    //console.log('tagInfoCfiSortkey', tagInfoCfiSortkey, sortableElementCfi, 'tagInfo.elementCfi', tagInfo.elementCfi);
                    if (tagInfoCfiSortkey < sortableElementCfi) {
                        return -1;
                    } else if (tagInfoCfiSortkey > sortableElementCfi) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            }, false, false);
            if (elementIndex !== -1) {
                var nearestTagInfoToTheLeft = this._map2[elementIndex];

                var terminusParts = textTerminus.split(':');
                var a = parseInt(terminusParts[0], 10);
                var b = parseInt(terminusParts[1], 10);
                var jumps = (a - 1) / 2;
                while (jumps) {
                    nearestTagInfoToTheLeft = nearestTagInfoToTheLeft.next.closingTag;

                    jumps--;
                }
                return new Position(this, nearestTagInfoToTheLeft.endOffset + b, true);
            }
            return null;
        },

        createPositionByPagenumber: function (pagenumber) {
            // TODO: see if we can use binary search

            var tagInfoMatch = null;
            var tagInfo, i;
            for (i = 0; i < this._map2.length; i++) {
                tagInfo = this._map2[i];
                //console.log(tagInfo.typ, tagInfo.pagenumber, pagenumber);
                if (pagenumber === tagInfo.pagenumber) {
                    tagInfoMatch = tagInfo;
                    break;
                }
            }
            if (tagInfoMatch) {
                return new Position(this, tagInfoMatch.closingTag.endOffset, true);
            } else {
                return null;
            }
        },

        createRange: function (startOffset, endOffset) {
            var startPosition = this.createPosition(startOffset);
            var endPosition = this.createPosition(endOffset);

            var range = new Range(this, startPosition, endPosition);
            range.balance();
            return range;
        },

        createRangeFromOffsetsInMarkup: function (startOffsetInMarkup, endOffsetInMarkup) {
            var startPosition = this.createPositionFromOffsetInMarkup(startOffsetInMarkup);
            var endPosition = this.createPositionFromOffsetInMarkup(endOffsetInMarkup);
            return new Range(this, startPosition, endPosition);
        },

        createRangeByCfi: function (rangeCfi) {
            var cfiParts = rangeCfi.split(',');
            var startCfi = cfiParts[0] + cfiParts[1];
            var endCfi = cfiParts[0] + cfiParts[2];
            var range = null;

            var startPosition = this.createPositionByCfi(startCfi);
            var endPosition = this.createPositionByCfi(endCfi);

            if (startPosition && endPosition) {
                range = new Range(this, startPosition, endPosition);
            }
            return range;
        },

        getPureText: function () {
            return this._cleanText;
        },

        getXhtmlString: function () {
            return this._xhtmlString;
        },

        /**
         * Method used by Position in order to adjust its offsetInMarkupText.
         * The adjustment is calculated only if the relevant text-chunk in _xhtmlString contains entities.
         */
        convertDeltaOffsetInPureTextToOffsetInMarkup: function (nearestTagInfoToTheLeft, deltaOffsetInPureText) {
            var map = this._map2;
            var origTextLength;
            var pureTextLength;
            var origText;
            var deltaOffsetInMarkup;
            var offsetInMarkupText;

            if (deltaOffsetInPureText) {
                if (nearestTagInfoToTheLeft) {
                    if (nearestTagInfoToTheLeft.next) {
                        origTextLength = nearestTagInfoToTheLeft.next.offset - nearestTagInfoToTheLeft.endOffset;
                        pureTextLength = nearestTagInfoToTheLeft.next.localOffset - nearestTagInfoToTheLeft.localOffset;
                    } else {
                        origTextLength = this.getXhtmlString().length - nearestTagInfoToTheLeft.endOffset;
                        pureTextLength = this.getPureText().length - nearestTagInfoToTheLeft.localOffset;
                    }

                    if (origTextLength !== pureTextLength) {
                        origText = this.getXhtmlString().substr(nearestTagInfoToTheLeft.endOffset, origTextLength);
                        offsetInMarkupText = nearestTagInfoToTheLeft.endOffset + deltaOffsetInPureTextToDeltaOffsetInMarkup(origText, deltaOffsetInPureText);
                    } else {
                        offsetInMarkupText = nearestTagInfoToTheLeft.endOffset + deltaOffsetInPureText;
                    }
                } else {
                    if (map.length) {
                        pureTextLength = map[0].localOffset;
                        origTextLength = map[0].offset;
                    } else {
                        origTextLength = this.getXhtmlString().length;
                        pureTextLength = this.getPureText().length;
                    }
                    if (origTextLength !== pureTextLength) {
                        origText = this.getXhtmlString().substr(0, origTextLength);
                        offsetInMarkupText = deltaOffsetInPureTextToDeltaOffsetInMarkup(origText, deltaOffsetInPureText);
                    } else {
                        offsetInMarkupText = deltaOffsetInPureText;
                    }
                }
            } else {
                // No need to adjust anything when deltaOffsetInPureText === 0
                if (nearestTagInfoToTheLeft) {
                    offsetInMarkupText = nearestTagInfoToTheLeft.endOffset;// + deltaOffsetInPureText;
                } else {
                    offsetInMarkupText = 0;//deltaOffsetInPureText;
                }
            }
            return offsetInMarkupText;
        },

        /**
         * Method used by Position in order to adjust its (pureText) offset.
         * The adjustment is calculated only if the relevant text-chunk in _xhtmlString contains entities.
         */
        convertDeltaOffsetInMarkupToOffsetInPureText: function (nearestTagInfoToTheLeft, deltaOffsetInMarkup) {
            var map = this._map2;
            var origTextLength;
            var pureTextLength;
            var origText;
            var offsetInPureText;

            if (deltaOffsetInMarkup) {

                if (nearestTagInfoToTheLeft) {
                    if (nearestTagInfoToTheLeft.next) {
                        origTextLength = nearestTagInfoToTheLeft.next.offset - nearestTagInfoToTheLeft.endOffset;
                        pureTextLength = nearestTagInfoToTheLeft.next.localOffset - nearestTagInfoToTheLeft.localOffset;
                    } else {
                        origTextLength = this.getXhtmlString().length - nearestTagInfoToTheLeft.endOffset;
                        pureTextLength = this.getPureText().length - nearestTagInfoToTheLeft.localOffset;
                    }
                    if (origTextLength !== pureTextLength) {
                        origText = this.getXhtmlString().substr(nearestTagInfoToTheLeft.endOffset, origTextLength);
                        offsetInPureText = nearestTagInfoToTheLeft.localOffset + deltaOffsetInMarkupToPureTextDelta(origText, deltaOffsetInMarkup);
                    } else {
                        offsetInPureText = nearestTagInfoToTheLeft.localOffset + deltaOffsetInMarkup;
                    }
                } else {
                    if (map.length) {
                        pureTextLength = map[0].localOffset;
                        origTextLength = map[0].offset;

                    } else {
                        origTextLength = this.getXhtmlString().length;
                        pureTextLength = this.getPureText().length;
                    }
                    if (origTextLength !== pureTextLength) {
                        origText = this.getXhtmlString().substr(0, origTextLength);
                        offsetInPureText = deltaOffsetInMarkupToPureTextDelta(origText, deltaOffsetInMarkup);
                    } else {
                        offsetInPureText = deltaOffsetInMarkup;
                    }
                }

            } else {
                if (nearestTagInfoToTheLeft) {
                    offsetInPureText = nearestTagInfoToTheLeft.localOffset;
                } else {
                    offsetInPureText = 0;
                }
            }
            return offsetInPureText;
        },

        // This method is currently used in the unittests
        getJsonMap: function (attributesOfInterest) {
            var markupText = this._xhtmlString;
            return this._map2.map(function (tagInfo) {
                var processedTagInfo = {};
                if (attributesOfInterest) {
                    attributesOfInterest.forEach(function (attrName) {
                        if (attrName === 'content') { // for debugging
                            processedTagInfo[attrName] = markupText.substr(tagInfo.offset, tagInfo.length);
                        } else if (attrName === 'prev' && tagInfo.prev) {
                            processedTagInfo[attrName] = tagInfo.prev.index;
                        } else if (attrName === 'next' && tagInfo.next) {
                            processedTagInfo[attrName] = tagInfo.next.index;
                        } else if (attrName === 'openingTag' && tagInfo.openingTag) {
                            processedTagInfo[attrName] = tagInfo.openingTag.index;
                        } else if (attrName === 'closingTag' && tagInfo.closingTag) {
                            processedTagInfo[attrName] = tagInfo.closingTag.index;
                        } else if (attrName === 'parent' && tagInfo.parent) {
                            processedTagInfo[attrName] = tagInfo.parent.index;
                        } else if (attrName === 'endOffset') {
                            processedTagInfo[attrName] = tagInfo.endOffset;
                        } else if (tagInfo.hasOwnProperty(attrName)) {
                            processedTagInfo[attrName] = tagInfo[attrName];
                        }
                    });
                } else {
                    processedTagInfo.offset = tagInfo.offset;
                    processedTagInfo.localOffset = tagInfo.localOffset;
                    processedTagInfo.siblingIndex = tagInfo.siblingIndex;
                }
                return processedTagInfo;
            });
        }
    };

    return Searcher;

});

