define('templates/tocPanelContent',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (href, resources, toc, tocBasepath, undefined) {
jade_mixins["tocEntry"] = jade_interp = function(data, nivaa){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if ( data.subitems)
{
buf.push("<li" + (jade.attr("data-href", data.href, true, false)) + (jade.cls(['bookpart','entry','collapsed','nivaa-' + nivaa], [null,null,null,true])) + "><div class=\"bookpartTitle\"><button" + (jade.attr("aria-label", 'ekspander ' + data.label, true, false)) + " aria-expanded=\"false\" class=\"toggleExpand\"><span class=\"toggleExpandIcon\"></span></button><a" + (jade.attr("href", data.href, true, false)) + " aria-expanded=\"false\" class=\"label\"><div>" + (null == (jade_interp = data.label) ? "" : jade_interp) + "</div>");
if ( data.page)
{
buf.push("<div class=\"pageNumber\"><span class=\"visually-hidden\">side </span><span>" + (jade.escape((jade_interp = data.page) == null ? '' : jade_interp)) + "</span></div>");
}
buf.push("</a></div><ul>");
// iterate data.subitems
;(function(){
  var $$obj = data.subitems;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var subitem = $$obj[$index];

jade_mixins["tocEntry"](subitem, nivaa + 1);
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var subitem = $$obj[$index];

jade_mixins["tocEntry"](subitem, nivaa + 1);
    }

  }
}).call(this);

buf.push("</ul></li>");
}
else
{
buf.push("<li" + (jade.attr("data-href", data.href, true, false)) + (jade.cls(['bookpart','entry','collapsed','nivaa-' + nivaa], [null,null,null,true])) + "><div class=\"bookpartTitle\"><div class=\"toggleExpand placeholder\"><span class=\"toggleExpandIcon placeholder\"></span></div><a" + (jade.attr("href", data.href, true, false)) + " class=\"label\"><div>" + (null == (jade_interp = data.label) ? "" : jade_interp) + "</div>");
if ( data.page)
{
buf.push("<div class=\"pageNumber\"><span class=\"visually-hidden\">side </span><span>" + (jade.escape((jade_interp = data.page) == null ? '' : jade_interp)) + "</span></div>");
}
buf.push("</a></div></li>");
}
};
jade_mixins["resourceElement"] = jade_interp = function(type, resource, idref){
var block = (this && this.block), attributes = (this && this.attributes) || {};
buf.push("<li" + (jade.attr("data-src", resource.src, true, false)) + (jade.attr("data-data", resource.data, true, false)) + (jade.attr("data-idref", idref, true, false)) + (jade.attr("data-cfi", resource.cfi, true, false)) + (jade.cls(['resource',type], [null,true])) + "><div class=\"label\">");
if ( type === "bildegallerier")
{
buf.push("<button" + (jade.attr("aria-label", 'Åpne ' + resource.label, true, false)) + " class=\"icon\"></button>");
if ( resource.label)
{
buf.push("<a" + (jade.attr("href", idref, true, false)) + " class=\"title\">" + (null == (jade_interp = resource.label) ? "" : jade_interp) + "</a>");
}
else
{
buf.push("<a" + (jade.attr("href", idref, true, false)) + " class=\"title\">Bildegalleri uten navn</a>");
}
}
if ( type === "lyder")
{
buf.push("<button" + (jade.attr("aria-label", 'Spill av ' + resource.label, true, false)) + " class=\"icon\"></button>");
if ( resource.label)
{
buf.push("<a" + (jade.attr("href", idref, true, false)) + " class=\"title\">" + (null == (jade_interp = resource.label) ? "" : jade_interp) + "</a>");
}
else
{
buf.push("<a" + (jade.attr("href", idref, true, false)) + " class=\"title\">Lydfil uten navn</a>");
}
}
if ( type === "videoer")
{
buf.push("<button" + (jade.attr("aria-label", 'Spill av ' + resource.label, true, false)) + " class=\"icon\"></button>");
if ( resource.label)
{
buf.push("<a" + (jade.attr("href", idref, true, false)) + " class=\"title\">" + (null == (jade_interp = resource.label) ? "" : jade_interp) + "</a>");
}
else
{
buf.push("<a" + (jade.attr("href", idref, true, false)) + " class=\"title\">Video uten navn</a>");
}
}
if ( type === "eksterntinnhold")
{
if ( resource.resourceType)
{
buf.push("<button" + (jade.attr("aria-label", 'Åpne ' + resource.label, true, false)) + (jade.cls(['icon',resource.resourceType], [null,true])) + "></button>");
}
if ( resource.label)
{
buf.push("<a" + (jade.attr("href", idref, true, false)) + " class=\"title\">" + (null == (jade_interp = resource.label) ? "" : jade_interp) + "</a>");
}
else
{
buf.push("<a" + (jade.attr("href", idref, true, false)) + " class=\"title\">Ressurs uten navn</a>");
}
}
buf.push("</div></li>");
};
jade_mixins["entry"] = jade_interp = function(data, type){
var block = (this && this.block), attributes = (this && this.attributes) || {};
// there could be several toc entries in the same spine
// We resolve tocs to spine refs and we delete each resource after it is rendered to avoid rendering it multiple times for each toc entry in the spine
href = data.href
if (href.indexOf('#') > -1) { href = href.substring(0,  href.indexOf('#')); }
var fullTocHref = href
if (tocBasepath) {fullTocHref = tocBasepath + href}
buf.push("<li" + (jade.attr("data-href", href, true, false)) + " class=\"bookpart collapsed\"><div class=\"bookpartTitle\"><button" + (jade.attr("aria-label", 'ekspander ' + data.label, true, false)) + " aria-expanded=\"false\" class=\"toggleExpand\"><span class=\"toggleExpandIcon\"></span></button><a" + (jade.attr("href", href, true, false)) + " class=\"label\">" + (null == (jade_interp = data.label) ? "" : jade_interp) + "</a></div>");
if ( resources[fullTocHref])
{
buf.push("<!-- first loop eksternal content to see if it has resourceType === type-->");
var typeMap = {'lyder': 'audio', 'videoer': 'video'} // POLR-solution to resource/resourceType mismatch
var externalMediaList = resources[fullTocHref]['eksterntinnhold']
if ( externalMediaList)
{
buf.push("<ul class=\"resourceList\">");
// iterate externalMediaList
;(function(){
  var $$obj = externalMediaList;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var resource = $$obj[$index];

if ( resource.resourceType && resource.resourceType === typeMap[type] && !resourcesShown['external' + '_' + resource.resourceType + '_' + resource.data])
{
jade_mixins["resourceElement"](type, resource, resources[fullTocHref]["idref"]);
buf.push("<!-- TODO: more unique id when multi-beriking-fil work starts-->");
resourcesShown['external' + '_' + resource.resourceType + '_' + resource.data] = 1;
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var resource = $$obj[$index];

if ( resource.resourceType && resource.resourceType === typeMap[type] && !resourcesShown['external' + '_' + resource.resourceType + '_' + resource.data])
{
jade_mixins["resourceElement"](type, resource, resources[fullTocHref]["idref"]);
buf.push("<!-- TODO: more unique id when multi-beriking-fil work starts-->");
resourcesShown['external' + '_' + resource.resourceType + '_' + resource.data] = 1;
}
    }

  }
}).call(this);

buf.push("</ul>");
}
buf.push("<!-- then loop the current type-->");
var resourceList = resources[fullTocHref][type]
if ( resourceList && !resourcesShown[fullTocHref + '_' + type])
{
buf.push("<ul class=\"resourceList\">");
// iterate resourceList
;(function(){
  var $$obj = resourceList;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var resource = $$obj[$index];

if ( !resourcesShown['external' + '_' + resource.resourceType + '_' + resource.data])
{
jade_mixins["resourceElement"](type, resource, resources[fullTocHref]["idref"]);
}
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var resource = $$obj[$index];

if ( !resourcesShown['external' + '_' + resource.resourceType + '_' + resource.data])
{
jade_mixins["resourceElement"](type, resource, resources[fullTocHref]["idref"]);
}
    }

  }
}).call(this);

resourcesShown[fullTocHref + '_' + type] = 1;
buf.push("</ul>");
}
}
if ( data.subitems)
{
// iterate data.subitems
;(function(){
  var $$obj = data.subitems;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var subitem = $$obj[$index];

jade_mixins["entry"](subitem, type);
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var subitem = $$obj[$index];

jade_mixins["entry"](subitem, type);
    }

  }
}).call(this);

}
buf.push("</li>");
};
// keep track of resources shown to avoid them being  listed in multiple chapters in the same spine
var resourcesShown = {}
if ( toc)
{
buf.push("<ul role=\"tabpanel\" id=\"tab-toc\" class=\"tab toc\">");
// iterate toc
;(function(){
  var $$obj = toc;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var bookpart = $$obj[$index];

jade_mixins["tocEntry"](bookpart, 1);
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var bookpart = $$obj[$index];

jade_mixins["tocEntry"](bookpart, 1);
    }

  }
}).call(this);

buf.push("</ul><ul role=\"tabpanel\" id=\"tab-bildegallerier\" class=\"tab bildegallerier\">");
// iterate toc
;(function(){
  var $$obj = toc;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var bookpart = $$obj[$index];

jade_mixins["entry"](bookpart, "bildegallerier");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var bookpart = $$obj[$index];

jade_mixins["entry"](bookpart, "bildegallerier");
    }

  }
}).call(this);

buf.push("</ul><ul role=\"tabpanel\" id=\"tab-lyder\" class=\"tab lyder\">");
// iterate toc
;(function(){
  var $$obj = toc;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var bookpart = $$obj[$index];

jade_mixins["entry"](bookpart, "lyder");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var bookpart = $$obj[$index];

jade_mixins["entry"](bookpart, "lyder");
    }

  }
}).call(this);

buf.push("</ul><ul role=\"tabpanel\" id=\"tab-videoer\" class=\"tab videoer\">");
// iterate toc
;(function(){
  var $$obj = toc;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var bookpart = $$obj[$index];

jade_mixins["entry"](bookpart, "videoer");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var bookpart = $$obj[$index];

jade_mixins["entry"](bookpart, "videoer");
    }

  }
}).call(this);

buf.push("</ul><ul role=\"tabpanel\" id=\"tab-eksterntinnhold\" class=\"tab eksterntinnhold\">");
// iterate toc
;(function(){
  var $$obj = toc;
  if ('number' == typeof $$obj.length) {

    for (var $index = 0, $$l = $$obj.length; $index < $$l; $index++) {
      var bookpart = $$obj[$index];

jade_mixins["entry"](bookpart, "eksterntinnhold");
    }

  } else {
    var $$l = 0;
    for (var $index in $$obj) {
      $$l++;      var bookpart = $$obj[$index];

jade_mixins["entry"](bookpart, "eksterntinnhold");
    }

  }
}).call(this);

buf.push("</ul>");
}}.call(this,"href" in locals_for_with?locals_for_with.href:typeof href!=="undefined"?href:undefined,"resources" in locals_for_with?locals_for_with.resources:typeof resources!=="undefined"?resources:undefined,"toc" in locals_for_with?locals_for_with.toc:typeof toc!=="undefined"?toc:undefined,"tocBasepath" in locals_for_with?locals_for_with.tocBasepath:typeof tocBasepath!=="undefined"?tocBasepath:undefined,"undefined" in locals_for_with?locals_for_with.undefined:typeof undefined!=="undefined"?undefined:undefined));;return buf.join("");
}

});
