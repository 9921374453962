/* global window */
define('PanelController',['jquery'], function ($) {

    /** Panelcontroller
     *
     * @param {Object} panels The panels to control
     * @param {Object} mainCont Html element as a jquery object
     * @param getReadium
     * @class  PanelController
     * @constructor
     *
     */
    function PanelController(panels, mainCont, getReadium) {
        var me = this;
        this._panels = panels;
        this._mainCont = mainCont;
        this._audioWrapper = mainCont.find('.audioWrapper');
        this._getReadium = getReadium;
        this._activePanelName = null;

        var panelBox = this._mainCont.find('.panelBox');
        for (var panel in this._panels) {
            panelBox.append(this._panels[panel].$el);
            this._panels[panel].panelController = this;
        }

    }
    PanelController.prototype = {
        setUserbook: function (userbook) {
            for (var panel in this._panels) {
                this._panels[panel].setUserbook(userbook);
            }
        },

        showTocPanel: function (e) {
            this._showPanel(e, 'tocPanel');
            $('#table-of-contents-btn').attr('aria-expanded', 'true');
        },

        showResourcesPanel: function (e) {
            this._showPanel(e, 'resourcesPanel');
        },

        showAnnotationsPanel: function (e) {
            this._showPanel(e, 'annotationsPanel');
            $('#list-markings-btn').attr('aria-expanded', 'true');
        },

        showAnnotationsEditor: function (e) {
            this._showPanel(e, 'annotationsEditor');
            $('#list-markings-btn').attr('aria-expanded', 'true');
        },

        showBookmarksPanel: function (e) {
            this._showPanel(e, 'bookmarksPanel');
            $('#list-bookmarks-btn').attr('aria-expanded', 'true');
        },

        showSettingsPanel: function (e) {
            this._showPanel(e, 'settingsPanel');
            $('#textlang-btn').attr('aria-expanded', 'true');
            this._panels.settingsPanel.updateSlider();
            this._panels.settingsPanel.populateGlossaryLang();
        },

        showSearchPanel: function (e) {
            this._showPanel(e, 'searchPanel');
            $('#search-btn').attr('aria-expanded', 'true');
            this._panels.searchPanel.focusSearchfield();
        },

        setActiveChapterInToc: function (xx) {
            this._panels.tocPanel.setActiveChapter(xx);
        },

        getActivePanel: function () {
            return this._activePanelName;
        },

        hidePanel: function (e) {
            if (this._activePanelName) {
                this._mainCont.removeClass('show-panel').removeClass('show-' + this._activePanelName);
                this._panels[this._activePanelName].hide();
                this._panels[this._activePanelName].$el.removeClass('panel-open');
                // only refocus on close-button click
                if (e !== undefined && e.target.parentElement.classList.contains('close-icon')) {
                    this._returnFocusTopbar(this._activePanelName);
                }
                this._activePanelName = null;
            }
            this._audioWrapper.removeClass('show-panel');
            this._mainCont.find('.panelBox').removeClass('panelbox-right');
            this._mainCont.find('.contentBox').removeClass('desktop');
            $('.itemList').find('.item').removeClass('panel-open');
        },

        _showPanel: function (e, panelName) {
            if (panelName !== this._activePanelName) {
                // active panel indicator
                $('.itemList').find('.item').removeClass('panel-open');
                if (e) {
                    $(e.currentTarget).addClass('panel-open');
                }

                if (this._activePanelName) {
                    this._mainCont.removeClass('show-' + this._activePanelName);
                    this._panels[this._activePanelName].hide();
                }

                if (panelName === 'bookmarksPanel' || panelName === 'settingsPanel') {
                    this._mainCont.find('.panelBox').addClass('panelbox-right');
                    this._mainCont.removeClass('show-panel');
                    this._audioWrapper.removeClass('show-panel');
                } else {
                    this._mainCont.find('.panelBox').removeClass('panelbox-right');
                    this._mainCont.addClass('show-panel');
                    this._audioWrapper.addClass('show-panel');
                }
                this._handleViewportResize(panelName);
                this._mainCont.addClass('show-' + panelName);
                this._panels[panelName].show();
                this._activePanelName = panelName;

            } else {
                this.hidePanel(e);
            }
        },

        _returnFocusTopbar: function (panelName) {
            switch (panelName) {
                case "tocPanel":
                    window.setTimeout(function () {
                        $('#table-of-contents-btn').attr('aria-expanded', 'false');
                        $('#table-of-contents-btn').focus();
                    }, 100);
                    break;
                case "annotationsPanel":
                    window.setTimeout(function () {
                        $('#list-markings-btn').attr('aria-expanded', 'false');
                        $('#list-markings-btn').focus();
                    }, 100);
                    break;
                case "annotationsEditor":
                    window.setTimeout(function () {
                        $('#list-markings-btn').attr('aria-expanded', 'false');
                        $('#list-markings-btn').focus();
                    }, 100);
                    break;
                case "searchPanel":
                    window.setTimeout(function () {
                        $('#search-btn').attr('aria-expanded', 'false');
                        $('#search-btn').focus();
                    }, 100);
                    break;
                case "settingsPanel":
                    window.setTimeout(function () {
                        $('#textlang-btn').attr('aria-expanded', 'false');
                        $('#textlang-btn').focus();
                    }, 100);
                    break;
                case "bookmarksPanel":
                    window.setTimeout(function () {
                        $('#list-bookmarks-btn').attr('aria-expanded', 'false');
                        $('#list-bookmarks-btn').focus();
                    }, 100);
                    break;
                default:
                    console.log('unknown panel', panelName);
                    break;
            }
        },

        _handleViewportResize: function (panelName) {
            if (panelName === "bookmarksPanel") {
                this._mainCont.find('.contentBox').removeClass('desktop');
            }
            else if (window.innerWidth >= 1200) {
                this._mainCont.find('.contentBox').addClass('desktop');
            }
        }
    };

    return PanelController;
});

