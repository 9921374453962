/*global require, window, navigator, location, document, cordova, confirm, console, applicationCache */

require.config({
    baseUrl: '../js/',
    waitSeconds: 200, // Default is 7, tests timeout when run through Sauce Labs

    paths: {
        'jade': 'thirdparty/jade-1.11.0/runtime.min',
        'hammer': 'thirdparty/hammer-2.0.8',
        'localforage': 'thirdparty/localforage',
        'audiojs': 'thirdparty/audiojs/audio',
        'jsPDF': 'thirdparty/jsPDF/jspdf.debug',
        'html2canvas': 'thirdparty/html2canvas/html2canvas',
        'svg4everybody': 'thirdparty/svg4everybody-master/dist/svg4everybody',
        'docxTemplater': 'thirdparty/docxtemplater/docxtemplater.v2.1.1.min',
        'FileSaver': 'thirdparty/docxtemplater/FileSaver.min',
        'jszipUtils': 'thirdparty/docxtemplater/jszip-utils.min',
        'CSSMatrix': 'thirdparty/cssmatrix',
        'ClientConfig': '../client-config',
        'async': 'thirdparty/async-2.3.0/async.min',
        'backbone': 'thirdparty/backbone-1.3.3.min',

        'readium-external-libs': 'thirdparty/readium/readium-external-libs',
        'readium_cfi_js': 'thirdparty/readium/readium-cfi-js',
        'readium-shared-js': 'thirdparty/readium/readium-shared-js',
        'readium-plugin-highlights': 'thirdparty/readium/readium-plugin-highlights',
        'readium-js': 'thirdparty/readium/readium-js',

        'templates': '../templates'
    },
    shim: {
        'thirdparty/sax': {
            exports: 'sax'
        },
        audiojs: {
            exports: 'audiojs'
        },
        ClientConfig: {
            exports: 'ClientConfig'
        },
        CSSMatrix: {
            exports: "CSSMatrix"
        }
    }
});


require([
    'jquery',
    'views/AppView',
    'views/DialogView',
    'models/App',
    'underscore',
    'CSSMatrix'
],
function (
    $,
    AppView,
    DialogView,
    AppModel,
    underscore,
    CSSMatrix
)
{


    function startApp() {
        // HOTFIX EB-1393 - Force uncached reload for users that has cached /bok/<publisher>/<bookId>
        // so the server side redirect is triggered.
        var match = window.location.pathname.match(/^\/bok\/([^,\/]+)\/([^,\/]+).*/);
        if (match) {
            window.location = ['/bok', match[1], match[2]].join("/") + "?time=" + Date.now().toString();
            return;
        }

        var appModel = new AppModel({mobileApp: false, backendServerURL: ''});
        var app = new AppView({model: appModel});
    }

    // When the page is displayed in an iframe, redirect to the iframe version
    // NB! Does not work in IE 8. In IE 8, window.parent.frames.length needs to be used
    if (window !== window.top) {
        location.href = '/bookshelf-iframe/';
        return;
    }

    if (underscore.isFunction(document.createElement('object'))) {
        underscore.isFunction = function (obj) { // Fixing firefox-bug, where typeof of an <object> dom-element is 'function', even though it has no .call method.
            return typeof obj === 'function' && obj.call ? true : false;
        };
    }

    if (window.WebKitCSSMatrix === undefined) {window.WebKitCSSMatrix = CSSMatrix;}


    $(function () {
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('controllerchange', function(e) {
                var confirmDialog = new DialogView({
                    'isAlert': true,
                    'title': 'Oppdatering',
                    'message': 'Vi har oppdatert Unibok med nye forbedringer',
                    'btnText': 'OK',
                    'onConfirm': function () {window.location.reload();}
                });
            });
        }
        startApp();
    });
});

define("main", function(){});

