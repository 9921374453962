define('templates/bookmarksPanel',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (mobileApp) {
buf.push("<div class=\"panel bookmarksPanel\"><div class=\"head\"><h2 class=\"label\">Bokmerker</h2><button aria-label=\"Lukk bokmerker\" class=\"close-icon\">");
if ( mobileApp)
{
buf.push("<span class=\"icon\"></span>");
}
else
{
buf.push("<svg><use xlink:href=\"../images/leser_ikoner/leser_ikoner_lukk.svg#lukk\"></use></svg>");
}
buf.push("</button></div><div class=\"hintTextBookmarks\">Trykk på bokmerket i verktøylinja for å sette et bokmerke</div><div class=\"content\"></div></div>");}.call(this,"mobileApp" in locals_for_with?locals_for_with.mobileApp:typeof mobileApp!=="undefined"?mobileApp:undefined));;return buf.join("");
}

});
