/* global cordova, alert, window */
define('bookResourceMap',[], function () {
    var bookResourceMap = {};

    function registerBookResource(bookResource) {
        bookResourceMap[encodeURIComponent(bookResource.get('bookId'))] = bookResource;
    }

    function unregisterBookResource(bookResource) {
        delete bookResourceMap[encodeURIComponent(bookResource.get('bookId'))];
    }

    function getBookResource(bookName) {
        return bookResourceMap[encodeURIComponent(bookName)];
    }

    function extractBookIdFromURL(url) {
        function escapeRegExp(s) {
            return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
        }
        var m = url.match(/epub_content\/(.+?)\//);
        if (!m) {
            m = url.match(/.*\/bookresource\/publisher\/\w+\/book\/([^\/]*)/); // Midlertidig hack
        }
        if (!m) {
            try {

                m = url.match(new RegExp("^" + escapeRegExp(cordova.file.applicationStorageDirectory +
                                                            "Documents/NoCloud/") + "\\w+\\/([^\\/]*)"));
            } catch (e) {
                alert('something wrong');
            }
        }
        return m[1];
    }

    return {
        registerBookResource: registerBookResource,
        unregisterBookResource: unregisterBookResource,
        getBookResource: getBookResource,
        extractBookIdFromURL: extractBookIdFromURL
    };


});

