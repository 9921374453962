/*global console, window */
define('views/iframeVideoLightboxView',['jquery', 'underscore', 'views/LightboxBase', 'templates/iframeVideoLightbox', 'views/DialogView', 'URLParser'],
    function ($, _, LightboxBase, mainTemplate, DialogView, URLParser) {
    return LightboxBase.extend({
        initialize: function (data, app) {
            LightboxBase.prototype.initialize.call(this);
            _.extend(this.events, LightboxBase.prototype.events);
            this._data = data;
            this.render();
            this.updateLayout();
            this.show();
        },

        render: function () {
            var me = this;

            var url = new URLParser( me._data.source );
            url.appendParameter("autoplay=1");
            me._data.source  = url.toString();

            this.setElement(mainTemplate(me._data));
            this.$el.addClass('videoLightbox');
            return this;
        },

        _destroy: function () {
            this.$el.find('video').remove();
            LightboxBase.prototype._destroy.call(this);
        },

        /* This method is called by the base class on window resize */
        updateLayout: function () {

            this.requestSize(1280,720,true,0);
        }
    });
});
