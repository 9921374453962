define('templates/printViewTemplate',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (cloneSection, isIos, styleUrl) {
buf.push("<div class=\"printWraps\"><div class=\"blockingLayer\"></div><div role=\"dialog\" aria-labelledby=\"dialog-title\" aria-describedby=\"dialog-intro\" class=\"printDialog scrollableContent\"><link" + (jade.attr("href", styleUrl, true, false)) + " rel=\"stylesheet\"/><div class=\"header\"><div id=\"dialog-title\" class=\"label\">Skriv ut oppskriften</div><button type=\"button\" title=\"lukk dialog\" class=\"closeButton\"></button></div><div class=\"sectionContainer\"><div class=\"clonedContent scrollableContent\">" + (((jade_interp = cloneSection) == null ? '' : jade_interp)) + "</div></div><div class=\"userContainer\"><div id=\"dialog-intro\" class=\"caption\">Legg til notater og last opp egne bilder (maks. 6). Bildene og notatene blir ikke lagret når du har lukket vinduet.</div><textarea placeholder=\"Notater\" class=\"userComment\"></textarea><form method=\"post\" action=\"\" enctype=\"multipart/form-data\" class=\"box\"><div class=\"box__input\"><div class=\"box__icon\"></div><input type=\"file\" id=\"file\" multiple=\"multiple\" class=\"box__file\"/><label for=\"file\">Velg en fil</label><div class=\"dragText\">eller dra og slipp her</div></div></form><div class=\"buttonGroupFooter\"><div class=\"button printButton\">Skriv ut</div>");
if (!( isIos))
{
buf.push("<div class=\"button exportPDF\">Lagre PDF</div>");
}
buf.push("</div></div><div class=\"imageContainer\"></div></div></div>");}.call(this,"cloneSection" in locals_for_with?locals_for_with.cloneSection:typeof cloneSection!=="undefined"?cloneSection:undefined,"isIos" in locals_for_with?locals_for_with.isIos:typeof isIos!=="undefined"?isIos:undefined,"styleUrl" in locals_for_with?locals_for_with.styleUrl:typeof styleUrl!=="undefined"?styleUrl:undefined));;return buf.join("");
}

});
