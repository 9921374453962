define('URLParser',['underscore'], function (_) {

    var URLParser = function( url ) {

        if (_.isString( url ) ) {
            this._url = url;
        } else {
            this._url = "";
        }

    };

    URLParser.prototype = {

        appendParameter: function( parameter ) {
            if ( ! this.contains( parameter ) ) {
                this._url += this.delimiter()+parameter;
            }
        },

        isVimeo: function( ) {
            return this.contains( "player.vimeo.com" );
        },

        isYouTube: function( ) {
            return this.contains( "www.youtube.com" );
        },

        delimiter: function() {
            if ( this.contains("?") ) {
                return "&";
            } else {
                return "?";
            }
        },

        contains: function( searchvalue ) {
            return this._url.toLowerCase().indexOf( searchvalue ) !== -1;
        },

        toString: function() {
            return this._url;
        }

    };

    return URLParser;
});

