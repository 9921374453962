/* global Worker */
define('models/bookResource/Spine',['async', 'jquery'], function (async, $) {
    function generateCfiSortkey(cfi) {
        return cfi.split('/').map(function (part) {
            var m = part.match(/^\d+/);
            return m ? ('00000' + m[0]).substr(m[0].length) : '';
        }).join('-');
    }

    var Spine = function (bookResource, spineData) {
        var me = this;
        this._bookResource = bookResource;
        this._spineData = spineData;
        this._idrefMap = {};
        this._hrefMap = {};
        this._linearSpine = [];

        /* object with keys idref, values the corresponding xhtml's */
        this._spineDocs = {};
        //this._worker = new Worker('/js/models/bookResource/SpineWorker.js');
        //this._worker.postMessage(["hei","sann"]);
        me._spineData.forEach(function (spineItem) {
            me._idrefMap[spineItem.idref] = spineItem;
            me._hrefMap[spineItem.href] = spineItem;
            spineItem.physicalPages.forEach(function (pageInfo) {
                pageInfo.push(generateCfiSortkey(pageInfo[0]));
            });
            if (spineItem.linear !== 'no') {
                me._linearSpine.push(spineItem);
            }
        });

    };

    Spine.prototype.getPreviousSpineItemOnIdref = function(idref)  {
        for (var i=0;i<this._linearSpine.length;i++) {
            if (this._linearSpine[i].idref === idref) {
                if (i > 0) {
                    return this._linearSpine[i-1];
                }
            }
        }
    };

    Spine.prototype.getNextSpineItemOnIdref = function(idref)  {
        for (var i=0;i<this._linearSpine.length;i++) {
            if (this._linearSpine[i].idref === idref) {
                if (i < (this._linearSpine.length - 1)) {
                    return this._linearSpine[i+1];
                }
            }
        }
    };

    Spine.prototype.getLinearSpineItems = function () {
        return this._linearSpine;
    };

    /* Assume that caller knows that this has been fetched already */
    Spine.prototype.getLoadedSpineItemByIdref = function(idref) {
        var me = this;
        return this._loadedSpineItems[idref];

    };

    /* Fetch all spineitems */
    Spine.prototype.getSpineItems = function() {
        var me = this;

        me._linearSpine.forEach(function(item) {
            if (me._spineDocs[item.idref]) {

            } else {
                me._spineDocs[item.idref] = me._bookResource._resourceManager.fetchSpineItem(item.href);
            }
        });

        var spineDocPromiseAry = Object.keys(me._spineDocs).map(function (key) {
            return me._spineDocs[key];
        });

        return $.when.apply($, spineDocPromiseAry)
            .then(function(spineDocs){
                console.log('done');
            });
    };

    Spine.prototype.checkIdrefExists = function (idref) {
        return this._idrefMap[idref] ? true : false;
    };

    /* Fetch one spineItem */
    Spine.prototype.getSpineItemHtmlByIdref = function (idref) {
        var me = this;
        var spineItem = me._idrefMap[idref];
        var href = spineItem.href;

        if (me._spineDocs[idref]) {
            return me._spineDocs[idref];
        } else {
            me._spineDocs[idref] = me._bookResource._resourceManager.fetchSpineItem(href);
        }
        return me._spineDocs[idref];
    };

    /* Fetch one spineItem */
    Spine.prototype.getSpineItemHtmlByHref = function (href) {
        var me = this;
        var spineItem = me._hrefMap[href];
        var idref = spineItem.idref;
        if (me._spineDocs[idref]) {
            return me._spineDocs[idref];
        } else {
            me._spineDocs[idref] = me._bookResource._resourceManager.fetchSpineItem(href);
        }
        return me._spineDocs[idref];
    };

    return Spine;
});
