/* globals ga, console, document, alert, window */
define('views/DialogView',[
    'jquery', 'backbone', 'underscore', 'templates/dialogTemplate'
],
    function ($, Backbone, _, dialogTemplate) {
        return Backbone.View.extend({
            initialize: function (options) {
                var me = this;
                me._onConfirm = options.onConfirm;
                me._appendTo = options.appendTo ? $(options.appendTo) : $('body');

                this._blockingLayer = $('<div class="blockingLayerGenericDialog blockingLayer"></div>');
                this._blockingLayer.on('click', $.proxy(me.cancel, me));
                this._blockingLayer.on('keyup', $.proxy(me._keyupTriggered, me));
                me._appendTo.append(this._blockingLayer);

                var dialog = this.render(options);
                this._blockingLayer.append(dialog.$el);
                this._appendTo.find('.close-btn').focus();
            },

            render: function (options) {
                this.setElement(dialogTemplate({
                    title: options.title,
                    message: options.message,
                    isAlert: options.isAlert,
                    isConfirm: options.isConfirm,
                    btnText: options.btnText || null,
                }));
                return this;
            },

            events: {
                'click .btn.btn-primary.ok': 'okConfirm',
                'click .btn.btn-primary.confirm': 'okConfirm',
                'click .btn.btn-primary.cancel': 'cancel',
                'click .close-btn': 'cancel'
            },

            _keyupTriggered: function (e) {
                if(e.keyCode === 27) {
                    this.cancel();
                } else {
                    return true;
                }
            },

            okConfirm: function () {
                this._appendTo.find('.genericDialog').remove();
                this._appendTo.find('.blockingLayerGenericDialog').remove();
                this.$el.remove();
                if (this._onConfirm) {
                    this._onConfirm();
                }
                this.focusSpawnElement();
                return false;
            },

            cancel: function () {
                this._appendTo.find('.blockingLayerGenericDialog').remove();
                this._appendTo.find('.genericDialog').remove();
                this.$el.remove();
                this.focusSpawnElement();
                return false;
            },

            focusSpawnElement: function () {
                var spawnedElement = $('[data-dialog-spawned]').length ? $('[data-dialog-spawned]') : $('iframe').contents().find('[data-dialog-spawned]');
                if (spawnedElement) {
                    window.setTimeout(() => {
                        spawnedElement.focus();
                        spawnedElement.removeAttr('data-dialog-spawned');
                    }, 100);
                }
            }
        });
    });

