define('templates/searchResultItem',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (cfi, idref, pageNum, resultText) {
buf.push("<div" + (jade.attr("data-idref", idref, true, false)) + (jade.attr("data-cfi", cfi, true, false)) + " class=\"searchResultItem\"><a href=\"#\" class=\"resultText\">" + (((jade_interp = resultText) == null ? '' : jade_interp)) + "</a>");
if ( pageNum)
{
buf.push("<div class=\"physicalPageNumber\">" + (jade.escape(null == (jade_interp = pageNum) ? "" : jade_interp)) + "</div>");
}
buf.push("</div>");}.call(this,"cfi" in locals_for_with?locals_for_with.cfi:typeof cfi!=="undefined"?cfi:undefined,"idref" in locals_for_with?locals_for_with.idref:typeof idref!=="undefined"?idref:undefined,"pageNum" in locals_for_with?locals_for_with.pageNum:typeof pageNum!=="undefined"?pageNum:undefined,"resultText" in locals_for_with?locals_for_with.resultText:typeof resultText!=="undefined"?resultText:undefined));;return buf.join("");
}

});
