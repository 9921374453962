/*global EPUBcfi, setTimeout, Node, window, console */


define('SelectionManager',['jquery', 'rangy'], function ($, rangy) {

    /*
     * Explanation of this rather hacky function to remove 'selection' on ios (cordova):
     *
     * Even though we can programmtically remove the text selection (Selection.removeAllRanges()) will this not
     * remove the selection handle bars on ios (cordova, uiWebView specifically).
     *
     * So to actually remove both the selection and the selection handle bars (the visual stuff) we have to first
     * call 'Selection.removeAllRanges()' then call a cordova-plugin which runs some stuf to remove the handle bars
     * through the uiWebView api. See <root-of-repo>/plugin-cordova-selection-handle-bars.
     *
     * http://stackoverflow.com/questions/3721371/programatically-remove-selection-in-uiwebview
     * http://stackoverflow.com/questions/3542347/hide-text-selection-handles-after-action-in-uiwebview
     */
    function removeSelectionHandleBarsOnUiWebView(selection) {
        if (!window.cordova) {
            return;
        }

        function success() {
            // do nothing.
        }

        function error(e) {
            console.log('Error while running hack to remove selection handle bars:', e);
        }

        selection.removeAllRanges();
        window.cordova.exec(success, error, "SelectionHandleBars", 'remove', []);
    }

    function SelectionManager(onSelectionChangedCallback) {
        this._onSelectionChangedCallback = onSelectionChangedCallback;
        this._hasSelection = false;
        this._recordedSelectionInfo = null;

        this._doc = null; // These are updated in .setDocument()
        this._bookResource = null;
        this._idref = null;
    }

    SelectionManager.prototype = {
        setDocument: function (doc, bookResource, idref) {
            this._doc = doc;
            this._bookResource = bookResource;
            this._idref = idref;

            doc.removeEventListener('selectionchange', $.proxy(this._onSelectionChanged, this));
            doc.addEventListener('selectionchange', $.proxy(this._onSelectionChanged, this));
        },

        /*
        * RETURNS STRING
        * This function is implemented to pick the correct word for Ordbok Lightbox.
        * getOrdbokWord() expands the selected text by filtering non-alpanumeric separators.
        * */
        getOrdbokWord: function(sentence, startOffset, endOffset) {

            function itIsSeparator(character) {
                var nonAlphaNumericRegex = RegExp(/[^a-z0-9\æ\ø\å\à\á\â\ä\ê\è\é\ë\ù\ú\û\ü\ñ\í\ì\î\ï\ò\ó\ô\œ\ö\ß\ç\ÿ]/gmi);
                return nonAlphaNumericRegex.test(character);
            }

            function removePunctuation(word) {
                var punctuationArr = [".", ",", ";", ":"];
                for (var i = 0; i < punctuationArr.length; i++) {
                    word = word.replace(punctuationArr[i], "");
                }
                return word;
            }

            var i;
            for (i = startOffset; i > -1; i--) {
                if (itIsSeparator(sentence[i])) {
                    startOffset = i+1;
                    break;
                }
                else if (i === 0) {
                    startOffset = 0;
                }
            }

            for (i = endOffset-1; i < sentence.length; i++) {
                if (itIsSeparator(sentence[i])) {
                    endOffset = i;
                    break;
                }
                else if (i === sentence.length-1) {
                    endOffset = sentence.length;
                }
            }

            return removePunctuation(sentence.substr(startOffset, endOffset-startOffset));
        },

        preventNextBlur: function () {
            if (this._hasSelection) {
                var selection = this._doc.getSelection();
                console.log('preventNextBlur', selection.rangeCount);
                this._preventNextBlur = true;
                var me = this;
                setTimeout(function () {
                    me._preventNextBlur = false;
                }, 0);
            }
        },

        hasSelection: function () {
            return this._hasSelection;
        },

        getLength: function() {
            return this._hasSelection && this._recordedSelectionInfo ? this._recordedSelectionInfo.length : 0;
        },

        getError: function () {
            var error = null;
            if (this._recordedSelectionInfo) {
                error = this._recordedSelectionInfo.error;
            }
            return error;
        },

        deselect: function () {
            if (this._hasSelection) {
                var selection = this._doc.getSelection();

                //Extra test because of EB-1311. because of the timeout in the IE hack, there's a risk that we've left the book for the bookshelf
                // If that's the case the selection will be gone and selectionManager don't know yet causing unibok to crash
                if (!selection) {
                    return;
                }

                selection.removeAllRanges();

                // IE hack: removeAllRanges() does not trigger selectionchange !
                // We therefore call _onSelectionChanged manually if we _hasSelection still is true in next tick
                var me = this;
                setTimeout(function () {
                    if (me._hasSelection === true) {
                        me._onSelectionChanged();
                    }
                }, 0);
            }
        },

        getRange: function () {
            var range = null;
            var me = this;
            if (this._doc) {
                if (this._hasSelection && this._recordedSelectionInfo.cfi && this._recordedSelectionInfo.idref) {
                    var href = this._bookResource.getUrlFromIdref(this._recordedSelectionInfo.idref);
                    this._bookResource.getSearcher(this._recordedSelectionInfo.idref, href)
                        .then(function (searcher) {
                        range = searcher.createRangeByCfi(me._recordedSelectionInfo.cfi);
                        if (range) {
                            range.idref = me._recordedSelectionInfo.idref;
                        }
                    });
                }
            }
            return range;
        },

        _getFirstTextNodeNotBefore: function (n) {
            var result;
            switch (n.nodeType) {
                case Node.TEXT_NODE:
                    return n;
                case Node.ELEMENT_NODE:
                    if (n.firstChild !== null) {
                        result = this._getFirstTextNodeNotBefore(n.firstChild);
                        if (result !== null) {
                            return result;
                        }
                    }
                    break;
            }
            n = n.nextSibling;
            if (n !== null) {
                return this._getFirstTextNodeNotBefore(n);
            } else {
                return null;
            }
        },

        _getLastTextNodeUpTo: function (n) {
            var result;
            switch (n.nodeType) {
                case Node.TEXT_NODE:
                    return n;
                case Node.ELEMENT_NODE:
                    if (n.lastChild !== null) {
                        result = this._getLastTextNodeUpTo(n.lastChild);
                        if (result !== null) {
                            return result;
                        }
                    }
                    break;
            }
            n = n.previousSibling;
            if (n !== null) {
                return this._getLastTextNodeUpTo(n);
            } else {
                return null;
            }
        },

        _recordSelection: function () {
            var selectedRange = this._doc.getSelection().getRangeAt(0);
            this._recordedSelectionInfo = {
                range: selectedRange,
                error: null,
                length: selectedRange.toString().length
            };

            var startNode = selectedRange.startContainer;
            var endNode = selectedRange.endContainer;
            var startOffset = selectedRange.startOffset;
            var endOffset = selectedRange.endOffset;
            var tempcfi;

            if (startNode.nodeType !== Node.TEXT_NODE) {
                startNode = this._getFirstTextNodeNotBefore(startNode);
            }

            if (endNode.nodeType !== Node.TEXT_NODE) {
                endNode = this._getLastTextNodeUpTo(endNode);
            }
            try {
                tempcfi = EPUBcfi.generateCharOffsetRangeComponent(startNode, startOffset, endNode, endOffset, [ "cfi-marker", "mo-cfi-highlight" ], [], []);

                if (tempcfi === undefined || tempcfi.length === 0) {
                    this._recordedSelectionInfo.error = 'generateCharOffsetRangeComponent failed';
                } else {
                    this._recordedSelectionInfo.cfi = tempcfi;
                    this._recordedSelectionInfo.idref = this._idref;
                }

            } catch (err) {
                this._recordedSelectionInfo.error = 'error from Readium: ' + err;
            }
        },

        expandIfSelectedPartOfMathExpression: function (options) {
            var timeout = options && options.timeout ? options.timeout : 0;
            var sel = rangy.getSelection(this._doc);
            window.setTimeout(function () {
                if (sel.rangeCount > 0) {
                    var r = sel.getRangeAt(0);
                    var elem1 = $(r.startContainer.parentNode).closest('.mjx-chtml');
                    var elem2 = $(r.endContainer.parentNode).closest('.mjx-chtml');

                    if (elem1.length) {
                        var previousNode = elem1[0].previousSibling;
                        if (previousNode) {
                            r.setStart(previousNode, previousNode.textContent.length);
                        }
                    }
                    if (elem2.length) {
                        var nextNode = elem2[0].nextSibling;
                        if (nextNode) {
                            r.setEnd(nextNode, 0);
                        }
                    }
                    if (elem1.length || elem2.length) {
                        sel.setSingleRange(r);
                    }
                }
            }, timeout);
        },

        _onSelectionChanged: function (e) {
            var currentSelection = this._doc.getSelection();
            // Fix EB-1840
            if (currentSelection === null)
            {
                return;
            }
            if (currentSelection.rangeCount && !currentSelection.isCollapsed) {
                this._recordSelection();
                this._hasSelection = true;
            }
            else {
                this._hasSelection = false;


                if (this._preventNextBlur && this._recordedSelectionInfo.range) {
                    this._doc.getSelection().addRange(this._recordedSelectionInfo.range);
                    this._preventNextBlur = false;
                }
                else {
                    this._recordedSelectionInfo = null;

                    // hack to remove selection on cordova apps
                    removeSelectionHandleBarsOnUiWebView(currentSelection);
                }
            }

            this._onSelectionChangedCallback();
        }
    };

    return SelectionManager;
});

