/*global window, console, EPUBcfi */

/**
 * This module creates and positions marginElements that represents elements in the typed area (the epub)
 * Currently it also creates the margins themselves
 * Currently it handles Annotations and page numbers
 *
 * @module webapp
 * @namespace  Views
 * @class  MarginManager
 */
define('MarginManager',[
        'jquery',
        'models/MarginIndicatorContainer',
        'models/AnnotationIndicator',
        'models/BookmarkIndicator',
        'models/PageNumberIndicator'
    ],
    function($, MarginIndicatorContainer, AnnotationIndicator, BookmarkIndicator, PageNumberIndicator) {

        var readiumIframe;

        /**
         * @param {Object} readerView the ReaderView instance used by the unibok app.
         * @constructor
         * @class  MarginManager
         */
        function MarginManager(readerView) {
            this._readerView = readerView;
            this._marginLeft = undefined;
            this._marginRight = undefined;
            this._containersLeftMargin = [];
            this._containersRightMargin = [];
            this._annotationIndicators = {};
            this._bookmarkIndicators = {};
        }

        MarginManager.prototype = {
            /**
             * This is triggered when there has been a real layoutchange
             * When we open a book, change chapter or change layoutStyle
             * the physical margins has already been created in cleanUpOldAndCreateNewMargins called from readerView in the layoutTypeChanged event
             * @param {Object} _readium   The readium object used in readerview
             * @param {Object} pageChangeData   From readium
             * @method onLayoutUpdate
             *
             */
            onLayoutUpdate: function(currentBookpartId) {
                var me = this;
                this._currentBookpartId = currentBookpartId;
                readiumIframe = this._readerView.$el.find('iframe').contents();
                this.cleanUpOldAndCreateNewMargins();
                this._getAndRenderMarginObjects();
            },

            /**
             * Is called from readerView when we enter a view/book
             * @method  startListeningOnUserbook
             * @param  {Object} userbook
             */
            startListeningOnUserbook: function (userbook) {
                this._userbook = userbook;
                this._userbook.get('annotations').on('add', this.onAnnotationAdded, this);
                this._userbook.get('annotations').on('remove', this.onAnnotationRemoved, this);
                this._userbook.get('annotations').on('change', this.onAnnotationChanged, this);
                this._userbook.get('bookmarks').on('add', this.onBookmarkAdded, this);
                this._userbook.get('bookmarks').on('remove', this.onBookmarkRemoved, this);
            },

            /**
             * Called from readerView when we leave the book
             *
             */
            stopListeningOnUserbook: function () {
                this._userbook.get('annotations').off(null, null, this);
                this._userbook.get('bookmarks').off(null, null, this);
            },

            /**
             * Resets the data structures
             * gets the marginObjects
             * Creates containers and renders
             *
             * @method  _getAndRenderMarginObjects
             */
            _getAndRenderMarginObjects: function () {
                var me = this;
                this._containersLeftMargin = [];
                this._containersRightMargin = [];
                this._annotationIndicators = {};

                var marginObjects = this.getVisibleMarginObjects();

                marginObjects.forEach(function (object) {
                    me.insertMarginObjectInContainer(object);
                });

                this._containersLeftMargin.concat(this._containersRightMargin).forEach(function (container) {
                    container.render();
                });

                var readerHeight = this._readerView.$el.find(".content-doc-frame,.bookMargin").height();
                this.setContainerHeights(this._containersLeftMargin, readerHeight);
            },

            setContainerHeights: function (containers, readerHeight) {
                /* sorterer array */
                containers.sort(function (a, b) {
                    return a._top < b._top ? -1 : 1;
                });
                for (var i=0;i<containers.length;i++) {
                    if (i === containers.length - 1) {
                        containers[i].$el.css('height', (readerHeight - containers[i]._top) + "px");
                    } else {
                        containers[i].$el.css('height', (containers[i + 1]._top - containers[i]._top) + 'px');
                    }
                }
            },

            /**
             * Finds out if marginObject belongs in left or right margin
             * searches for a container in margin
             * if found, insert marginObject in container
             * if not found, creates the container and insert marginObject
             *
             * @param  {Object} marginObject
             * @method insertMarginInObjectInContainer
             * @return {[type]}        [description]
             */
            insertMarginObjectInContainer: function (marginObject) {
                var containers = this._containersLeftMargin;
                var insertedInExistingContainer = false;

                for (var i=0;i<containers.length;i++) {
                    var container = containers[i];

                    if ((container._top - 20) < marginObject.yPos && marginObject.yPos < (container._top + 20 )) {
                        container.insert(marginObject);
                        insertedInExistingContainer = true;
                        break;
                    }
                }

                if (!insertedInExistingContainer) {
                    var newContainer = new MarginIndicatorContainer(this._marginLeft, this);
                    newContainer.insert(marginObject);
                    containers.push(newContainer);
                }
            },

            onBookmarkAdded: function(model, collection) {

                if (this.isBookmarkInCurrentChapter( model ) ) {
                    var bookmarkIndicator = new BookmarkIndicator(model, this);
                    this.insertMarginObjectInContainer(bookmarkIndicator);
                    bookmarkIndicator.renderContainer();
                }

            },

            isBookmarkInCurrentChapter: function(model) {
                return (this._readerView._currentBookpartId === model.get("bookpartId"));
            },

            onBookmarkRemoved: function(model) {
                var bookmarkIndicator = this._bookmarkIndicators[model.cid];

                if (bookmarkIndicator && bookmarkIndicator._container) {
                    bookmarkIndicator._container.removeObject(bookmarkIndicator);
                    delete this._bookmarkIndicators[model.cid];
                }
                else {
                    console.error("ERROR: bookmarkIndicator misses data. Bookmark is not removed.", model);
                }
            },

            onAnnotationAdded: function (model, collection) {
                if (model.get('bookpartId') === this._currentBookpartId) {
                    var highlight = readiumIframe.find('[data-id="' + model.id + '"]');
                    if (highlight && highlight[0]) {
                        var marginObject = new AnnotationIndicator(highlight[0], this);
                        this.insertMarginObjectInContainer(marginObject);
                        marginObject.renderContainer();
                    }
                }
            },

            onAnnotationRemoved: function (model, collection) {
                var marginObject = this._annotationIndicators[model.get('id')];

                /* If the annotation is in a different chapter, our annotationIndicator does not exist */
                if (marginObject && marginObject._container) {
                    marginObject._container.removeObject(marginObject);
                    delete this._annotationIndicators[model.get('id')];
                }
            },

            onAnnotationChanged: function (model, collection) {
                if (model.hasChanged('id')) {
                    this._annotationIndicators[model.get('id')] = this._annotationIndicators[model.previous('id')];
                    this._annotationIndicators[model.previous('id')] = undefined;

                }
                var annotationIndicator = this._annotationIndicators[model.get('id')];
                /* If the annotation is in a different chapter, our annotationIndicator does not exist */
                if (annotationIndicator) {
                    annotationIndicator.annotationid = model.get('id');
                    annotationIndicator.colorid = model.get('colorid');
                    annotationIndicator.renderContainer();
                }
            },

            /**
             * @method renderCon
            renderContainer: function (marginObject) {
                if marginObject
            }

            /**
             * Gets all pagebreak/highlight elements from dom and creates marginObjects representing them
             * checks if they are visible
             * @method  getVisibleMarginObjects
             * @return {[type]} [description]
             */
            getVisibleMarginObjects: function () {
                var visiblePages = this.getVisiblePages();
                var me = this;
                var result = [];

                readiumIframe.find('span').each(function () {
                    if (this.getAttribute('epub:type') === 'pagebreak') {
                        var marginObject = new PageNumberIndicator(this, me);
                        result.push(marginObject);

                        // Insert a hidden page number indicator element under every pagebreak item.
                        // These indicators will help Jaws Software to read the page numbers.
                        var pageNumber = $(this).attr("title");
                        var pageBreakParent = $(this).parent();
                        if (pageBreakParent.find(".screen-reader-text").length < 1) {
                            pageBreakParent.append('<span class="screen-reader-text">Side ' + pageNumber + '</span>');
                        }

                        // Hide the original pagebreak element from screen readers
                        this.setAttribute("aria-hidden", "true");
                    }
                });

                var highlights = readiumIframe.find('html > .highlight[data-id]');
                highlights.each(function () {
                    var annotationIndicator = me._annotationIndicators[$(this).data('id')];
                    if (!annotationIndicator) {
                        annotationIndicator = new AnnotationIndicator(this, me);
                        result.push(annotationIndicator);
                    }
                    else {
                        // Do nothing, only create one annotation per annotation
                        // Multiline annotations are splitted into multiple visual dom elements
                        // to draw the actual annotation.
                    }
                });

                var bookmarks = this._readerView._userbook.get("bookmarks");
                bookmarks.models.forEach(function(bookmark) {
                    if (bookmark.attributes.bookpartId !== me._currentBookpartId) {
                        return;
                    }

                    if (bookmark.get('invalid')) {
                        // if the bookmark is marked as 'invalid' don't try to render a bookmark indicator for it.
                        return;
                    }

                    var bookmarkIndicator = new BookmarkIndicator(bookmark, me);
                    result.push(bookmarkIndicator);
                });

                return result;
            },

            _emptyMargins: function () {
                this._marginLeft.empty();
                if (this._marginRight) {
                    this._marginRight.empty();
                }
            },

            cleanUpOldAndCreateNewMargins: function () {
                var me = this;
                readiumIframe = $('#reader iframe').contents();
                if (this._marginLeft) {this._marginLeft.remove();}
                if (this._marginRight) {this._marginRight.remove();}

                this._marginLeft = $('<div id="marginLeft" class="bookMargin"></div>');
                this._readerView.$el.find('#epub-reader-container').css({
                    margin: '0px 0px 0px 0px'
                });
                readiumIframe.find('html').append(this._marginLeft);
                this._marginLeft.css("left", "10px");

                // setting padding to make room for the margins.
                readiumIframe.find('html').css('padding-left', '40px');
                readiumIframe.find('html').css('padding-right', '40px');

                this._marginLeft.click(function(){
                    me._readerView.toggleMenus();
                    return false;
                });
            },

            getVisiblePages: function () {
                var visiblePages = [];
                this._pageChangeData.paginationInfo.openPages.forEach(function(page){
                    visiblePages.push(page.spineItemPageIndex);
                });
                return visiblePages;
            }
        };

        return MarginManager;

    });

