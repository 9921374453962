/* global window, navigator */


/* This module handles different localforage storage regimes/versions.
 *  It finds which code version we are using
 *  It uses DownloadedEpubsList to keep track of which epubs are downloaded
 *
 */


define('StorageManager',[
        'localforage',
        'jquery',
        'views/DialogView',
        'resourcefetchers/convertLocalForage',
        'DownloadedEpubsList',
        'bowser'
    ],
    function (localforage, $, DialogView, convertLocalForage, DownloadedEpubsList, bowser) {
        var StorageManager = function () {

            var me = this;
            me.latestVersion = 2;
            me._systemStorage = me._getLocalForageInstance();
            me._downloadedEpubsList = new DownloadedEpubsList(me._systemStorage);
            //me._checkIfNewLocalForageVersions();
            me._checkAndAskForPersistentStorage();


        };

        StorageManager.prototype.addToDownloadedList = function (epubId) {
            this._downloadedEpubsList.addToDownloadedList(epubId);
        };

        StorageManager.prototype.removeFromDownloadedList = function (epubId) {
            return this._downloadedEpubsList.removeFromDownloadedList(epubId);
        };
        StorageManager.prototype.getDownloadedEpubsList = function () {
            return this._downloadedEpubsList._getDownloadedEpubsList();
        };

        StorageManager.prototype._getLocalForageInstance = function () {
            return localforage.createInstance({
                "name": "unibok_system"
            });
        };

        StorageManager.prototype._checkIfNewLocalForageVersions = function () {
            var me = this;
            return this.getImplementedLocalForageVersion()
                .then(function (implementedVersion) {
                    if (me.latestVersion !== implementedVersion) {
                        me.promptForConvertion(me.latestVersion, implementedVersion);
                    }
                });
        };

        StorageManager.prototype.convert = function () {
            var converter = new convertLocalForage();
            this._systemStorage.setItem("storageVersion", 2);
            new DialogView({
                "title": "Har begynt å fikse",
                "message": "<span id='heifallera'>Henter filer...</span>/<span id='fhtotal'></span> filer"
            });
            var setNrConvertedFiles = function (progress) {
                $('#heifallera').text(progress.converted);
                $('#fhtotal').text(progress.total);
            };

            converter.convert()
                .then(function () {
                    window.location.reload();
                })
                .progress(setNrConvertedFiles)
                .fail(function (err) {
                    // alert('failed during convert operation');
                });


        };

        StorageManager.prototype.promptForConvertion = function (latestVersion, implementedVersion) {
            var me = this;
            if (latestVersion === 2 && implementedVersion === 1) {
                new DialogView({
                    "title": "Ny versjon av lagringsmåte",
                    "message": "Det har kommet blabla",
                    "isConfirm": true,
                    "onConfirm": me.convert.bind(me)
                });
            }
        };

        StorageManager.prototype.getImplementedLocalForageVersion = function () {
            if (!this._implementedLocalForageVersion) {
                this._findImplementedLocalForageVersion();
            }
            return this._implementedLocalForageVersion.promise();
        };

        StorageManager.prototype._getDownloadedEpubsList = function () {

        };

        StorageManager.prototype._findImplementedLocalForageVersion = function () {
            var me = this;
            me._implementedLocalForageVersion = $.Deferred();

            me._systemStorage.getItem("storageVersion")
                .then(function (version) {
                    if (!version) {
                        me._calculateVersion()
                            .then(function (calculatedVersion) {
                                me._systemStorage.setItem("storageVersion", calculatedVersion);
                                me._implementedLocalForageVersion.resolve(calculatedVersion);

                            });
                    } else {
                        me._implementedLocalForageVersion.resolve(version);
                    }

                });
            return me._implementedLocalForageVersion.promise();
        };

        StorageManager.prototype._userAgentSupportsMultipleDataStores = function(){
            if (bowser.chrome) {
                return true;
            } else {
                return false;
            }
        };

        StorageManager.prototype._nrItemsInLocalForage = function() {
            return localforage.length();
        };

        StorageManager.prototype._calculateVersion = function () {
            var me = this;
            // No version at all or first version?
            // If there are keys in local storage already or we are not using google chrome, hold back to version 1
            if (! me._userAgentSupportsMultipleDataStores()) {
                return $.Deferred().resolve(1).promise();
            } else {
                return me._nrItemsInLocalForage()
                    .then(function (length) {
                        if (length) {
                            return 1;
                        } else {
                            return me.latestVersion;
                        }
                    });
            }
        };

        /**
         * If the user agent has implemeneted the navigator.storage.persist api (like Chrome version 56)
         * we can check if user agent uses persistent storage
         * We can also ask user agent for persistent storage
         * Persistent Storage means that user agent will not delete offline data haphazardly, offline data can only be deletet by explicit user action
         * @method _checkAndAskForPersistentStorage
         * @return {void}  No return values
         */
        StorageManager.prototype._checkAndAskForPersistentStorage = function () {
            if (navigator.storage && navigator.storage.persist && navigator.storage.persisted) {

                var askForPersistentStorage = function () {
                    if (navigator.storage && navigator.storage.persist) {
                        navigator.storage.persist()
                            .then(function (granted) {
                                if (granted) {
                                    if (window.Bugsnag) {
                                        window.Bugsnag.leaveBreadcrumb("Persistent storage granted");
                                    }
                                    console.log('Persistent storage granted');
                                } else {
                                    if (window.Bugsnag) {
                                        window.Bugsnag.leaveBreadcrumb("Persistent storage request denied");
                                    }
                                    console.log('Persistent storage not granted by the UA');
                                }
                            });
                    }
                };

                navigator.storage.persisted()
                    .then(function (persistent) {
                        if (!persistent) {
                            askForPersistentStorage();
                        }
                    });
            }

        };


        return StorageManager;
    });
