/*global console, window */
define('views/ordbokLightboxView',[
        'jquery',
        'underscore',
        'bowser',
        'views/LightboxBase',
        'templates/ordbokLightbox'
    ],
    function ($, _, bowser, LightboxBase, mainTemplate) {
    return LightboxBase.extend({
        initialize: function (ordbokHash, languageOption, word) {
            LightboxBase.prototype.initialize.call(this);
            _.extend(this.events, LightboxBase.prototype.events);
            this._ordbokUrl = "https://oppslag.cappelendamm.no";
            this._data = {
                altText: "Ordbok",
                description: "",
                poster: "",
                publisherRef: "",
                source: this.createSourceUrl(ordbokHash, languageOption, word),
                title: ""
            };

            if (bowser.name === "Internet Explorer" || bowser.name === "Microsoft Edge") {
                window.open(encodeURI(this._data.source), "_blank");
            }
            else {
                this.render();
                this.updateLayout();
                this.show();
            }
        },

        render: function () {
            var me = this;

            this.setElement(mainTemplate(me._data));
            this.$el.addClass('ordbok-lightbox');
            return this;
        },

        createSourceUrl: function(ordbokHash, languageOption, word) {
            var sourceUrl = this._ordbokUrl;
            switch (languageOption) {
                case "nob-nob":
                    sourceUrl += "/cappelen-nobm";
                    break;
                case "nob-nno":
                    sourceUrl += "/cappelen-nony";
                    break;
                case "nob-fra":
                    sourceUrl += "/cappelen-frno";
                    break;
                case "nob-eng":
                    sourceUrl += "/cappelen-enno";
                    break;
                case "nob-deu":
                    sourceUrl += "/cappelen-tyno";
                    break;
                case "nob-esp":
                    sourceUrl += "/cappelen-spno";
                    break;
                default:
                    sourceUrl += "/cappelen-nobm";
            }

            sourceUrl += "/ordbok.html?c=" + ordbokHash;
            sourceUrl += "&search=" + word;

            return sourceUrl;
        },

        _destroy: function () {
            this.$el.find('video').remove();
            LightboxBase.prototype._destroy.call(this);
        },

        /* This method is called by the base class on window resize */
        updateLayout: function () {

            this.requestSize(1280,720,true,0);
        }
    });
});
