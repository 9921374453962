/*global define, localStorage, console, navigator, window, alert, document */

/**
 * @module webapp
 * @namespace  Models
 * @class  Userbook
 */

define('models/Userbook',[
    'jquery',
    'backbone',
    'collections/Annotations',
    'models/Annotation',
    'collections/Bookmarks',
    'models/Bookmark',
    'async',
    'underscore',
    'views/DialogView'
],
function ($, Backbone, Annotations, Annotation, Bookmarks, Bookmark, async, _, DialogView) {

    function stripCfiForSpatialOffset(cfi) {
        return cfi.replace(/@\d+:\d+$/, '');
    }

    function stripReadingPositionForSpatialOffset(readingPositionData) {
        if (!readingPositionData) {
            return readingPositionData;
        }

        var decoded = JSON.parse(readingPositionData);
        if (decoded && decoded.contentCFI) {
            decoded.contentCFI = stripCfiForSpatialOffset(decoded.contentCFI);
            var encoded = JSON.stringify(decoded);
            return encoded;
        }
        else {
            return readingPositionData;
        }
    }

    return Backbone.Model.extend({
        defaults: {
            fontSize: 100,
            minFontSize: 60,
            maxFontSize: 200,
            layoutType: 'scroll',
            readingPositionCfi: null,
            glossaryLang: null,
            isLoaded: false,
            /**
             * True when a userbook is started to load, false when readium is done loading it
             * @attribute isOpening
             */
            isOpening: false,
            dirty: false,
            cloudStatus: null,
            noLicence: false,
            dirty_settings: false,
            dirty_bookmarks: false,
            dirty_annotations: false,
            jwt: null
        },

        initialize: function (attributes, options) {
            this._user = options.user;
            this._bookResource = options.bookResource;
            this.set(this.defaults);
            this.set('noLicence', attributes.noLicence);
            this._deletedAnnotations = [];
            this._deletedBookmarks = [];
            this.set('bookId', this._bookResource.get('bookId'));
            this.set('publisherRef', this._bookResource.get('publisherRef'));
            this.set('epubid', this._bookResource.get('epubid'));
            this.set('annotations', new Annotations());
            this.set('bookmarks', new Bookmarks());
            this._fontSizeDirty = false;
            this._readingPositionCfiDirty = false;
            this._glossaryLangDirty = false;
            this.checkCloudStatus();
            var me = this;
            me.get('annotations').on('add remove change', me.onAnnotationsChanged, me);
            me.get('bookmarks').on('add remove change', me.onBookmarksChanged, me);
            me.on('change:fontSize change:readingPositionCfi change:glossaryLang', me.onSettingsChanged, me);

            this.on('change:dirty_settings change:dirty_bookmarks change:dirty_annotations', function () {
                me.set('dirty', me.get('dirty_settings') || me.get('dirty_bookmarks') || me.get('dirty_annotations'));
            }, this);
            this.on('change:noLicence', me.checkCloudStatus, this);
            this._bookResource.on('change:isDownloaded', me.checkCloudStatus, this);
            this._bookResource.on('change:isDownloading', me.checkCloudStatus, this);
            this._bookResource.on('change:isUnpacking', me.checkCloudStatus, this);
            this._bookResource.on('change:epubid', me.checkCloudStatus, this);
            this._bookResource.on('change:downloadedEpubid', me.checkCloudStatus, this);
            this._bookResource.on('change:isRemoving', me.checkCloudStatus, this);
            this._bookResource.on('change:latestUbFileHash', me.checkCloudStatus, this);
            this._bookResource.on('change:downloadedUbFileHash', me.checkCloudStatus, this);
            this._user._app.on('change:isOnline', me.checkCloudStatus, this);
            this.on('change:dirty_bookmarks change:dirty_annotations', function () {
                // temporary hax
                if(me.get('dirty_annotations') || me.get('dirty_bookmarks')) {
                    if (!this._user._app.get('isOnline')) {
                        this.set('offline_and_dirty', true);
                    }
                } else {
                    this.set('offline_and_dirty', false);
                }
            });

            this._user._app.on('change:isOnline', function () {
                if (! me._user._app.get('isOnline')) {
                    if(me.get('dirty_annotations') || me.get('dirty_bookmarks')) {
                        me.set('offline_and_dirty', true);
                    }
                }
            });

            this.on('change:offline_and_dirty', function (model, offline_and_dirty) {
                me.checkCloudStatus();
            });
            this.on('change:isOpening', function (model, isOpening) {
                if (isOpening === false) {
                    if (this._user._app.get('hasReceived403FromBackend') === true) {
                        this._user._app.set('activeUser', null);
                    }
                }
            });
        },

        // TODO: refaktor to something more similar to User.load()
        load: function (mainCallback) {
            var me = this;

            this.trigger('requestInitialData');

            return me._bookResource.load()
                .then(function(){
                    me.set('isLoaded', true);
                    me.sendPlausibleOpenBookEvent();
                })
                .fail(function(e){
                     console.log('userbook load error', e);
                });
        },

        sendPlausibleOpenBookEvent: function () {
            var publisher = this.get('publisherRef');
            this._user._app.plausibleManager.sendEvent(
                publisher,
                "Bokhylla",
                "Åpnet bok",
                this.getBookResource().get('title')
            );

            // EB-2780 - rapportere fag og trinn ved åpning av bok
            this._user._app.plausibleManager.sendEvent(
                publisher,
                "Bokhylla",
                "Åpnet bok trinn",
                this.getBookResource().get('level') ? this.getBookResource().get('level').toString().toUpperCase() : "Mangler trinn"
            );

            this._user._app.plausibleManager.sendEvent(
                publisher,
                "Bokhylla",
                "Åpnet bok fag",
                this.getBookResource().get('subject') ? this.getBookResource().get('subject') : "Mangler fag"
            );

            this._user._app.plausibleManager.sendEvent(
                publisher,
                "Bokhylla",
                "Åpnet bok fagområde",
                this.getBookResource().get('subjectArea') ? this.getBookResource().get('subjectArea') : "Mangler fagområde"
            );

        },

        isAvailableOffline: function() {
            return this.getBookResource().get('downloadedEpubid') ||
                   this.getBookResource().get('isDownloaded');
        },

        // NOTE: temp naming. or setInitialData
        onInitialData: function (data) {
            var dataStr = true;
        //},
        // TODO: move to sync helper
        //_loadLocalUserdata: function (callback) {
            var me = this;
            if (dataStr) {
                //var data = JSON.parse(dataStr);
                var annotations = this.get('annotations');
                var bookmarks = this.get('bookmarks');

                data.annotations.forEach(function (data) {
                    var annotation = new Annotation({
                        id: data.id,
                        bookpartId: data.bookpartId,
                        cfi: data.cfi,
                        colorid: data.colorid,
                        text: data.text,
                        markedText: data.markedText,
                        markedTextWithMarkup: data.markedTextWithMarkup,
                        physicalPageNumber: data.physicalPageNumber,
                        timestamp: data.timestamp,
                        dirty: data.dirty,
                        local: data.local
                    }, {userbook: me});
                    annotations.push(annotation);
                });
                annotations.sort();

                data.bookmarks.forEach(function (data) {
                    var bookmark = new Bookmark({
                        id: data.id,
                        bookpartId: data.bookpartId,
                        cfi: data.cfi,
                        label: data.label,
                        physicalPageNumber: data.physicalPageNumber,
                        dirty: data.dirty,
                        local: data.local
                    }, {userbook: me});
                    bookmarks.push(bookmark);
                });
                bookmarks.sort();

                this._deletedAnnotations = data.deletedAnnotations;
                this._deletedBookmarks = data.deletedBookmarks;

                me.set('jwt', data.token);

                me.set('fontSize', data.fontSize);
                me.set('layoutType', me.defaults.layoutType);
                me.set('glossaryLang', data.glossaryLang);

                // remove spatial offset from cfi (not supported by readiums cfi library)
                // see EB-1663
                var fixed = stripReadingPositionForSpatialOffset(data.readingPositionCfi);
                me.set('readingPositionCfi', fixed);

                this._fontSizeDirty = data.fontSize ? true : false;
                this._readingPositionCfiDirty = data.readingPositionCfi ? true : false;
                me.set('dirty_settings', true);

            } else {
                this._deletedAnnotations = [];
                this._deletedBookmarks = [];
                this.set(this.defaults);
            }

            //callback(null);
        },

        // rename to validateAndRepairUserdata
        validateUserdata: function () {
            this.get('bookmarks').forEach(function (bookmark) {
                bookmark.validateAndRepair();
            });
            this.get('annotations').forEach(function (annotation) {
                annotation.validateAndRepair();
            });

            this.getBookResource().cleanupTemporaryDocuments();
        },

        unload: function (callback) {
            this.set('isLoaded', false);
            this._bookResource.unload(function () {
                if (callback) {
                    callback();
                }
            });
        },

        getUser: function () {
            return this._user;
        },

        getBookResource: function () {
            return this._bookResource;
        },

        onRemoteChange_settings: function (incomingData, fromTimestamp) {
            var me = this;
            var data = incomingData;
            // fix for a bug where client has changed font-size rapidly before remote settings is synced, which
            // causes font-size to 'jump back' to an older synced setting. Solved by checking against timestamp
            var fromStamp = Math.round(Date.parse(fromTimestamp) / 1000);
            var tenSecondsAgo = Math.round(Date.now() / 1000) -10;
            if (data.bookdata.font_size !== me.get('fontSize')) {
                if (fromStamp > tenSecondsAgo) {
                    me.set('fontSize', data.bookdata.font_size || me.defaults.fontSize);
                }
            } else {
                me.set('fontSize', data.bookdata.font_size || me.defaults.fontSize);
            }

            if (data.bookdata.glossary_lang !== me.get('glossaryLang')) {
                me.set('glossaryLang', data.bookdata.glossary_lang);
            }

            // remove spatial offset from cfi (not supported by readiums cfi library)
            // see EB-1663
            var fixed = stripReadingPositionForSpatialOffset(data.bookdata.reading_position);
            me.set('readingPositionCfi', fixed);

            me._fontSizeDirty = true; // Simplified
            me._readingPositionCfiDirty = true;
        },

        onRemoteChange_bookmarks: function (incomingData, fromTimestamp) {
            var data = incomingData;
            var me = this;

            this.set('dirty_bookmarks', false);

            // TODO: refaktor slik at bookmarks og annotations kan behandles av samme kode
            var publicBookmarksMap = {};

            if (! fromTimestamp) {
                // all bookmarks, not just change log
                data.forEach(function (bookmarksData) {
                    publicBookmarksMap[bookmarksData.id] = bookmarksData;
                    publicBookmarksMap[bookmarksData.properties.localId] = bookmarksData;
                });
                var bookmarksKeepMap = {};

                var bookmarksKeepList = []; //list of bookmarks to keep after remotedata has been received

                // loop through all bookmarks in model
                // if they also are in the remote data
                // set local and dirty to false and add to bookmarkskeeplists
                me.get('bookmarks').each(function (bookmark) {
                    var bookmarkData = publicBookmarksMap[bookmark.id];
                    var attributes;
                    if (bookmarkData) {
                        attributes = bookmarkData.properties;
                        attributes.id = '' + bookmarkData.id;
                        attributes.local = false;
                        attributes.dirty = false;
                        delete attributes.localId;
                        bookmark.set(attributes);
                        bookmarksKeepList.push(bookmark);
                        bookmarksKeepMap[bookmarkData.id] = true;
                    }
                });

                // Loop through data from server

                data.forEach(function (bookmarkData) {
                    // if not already in model, add it
                    if (!bookmarksKeepMap[bookmarkData.id]) {
                        var attributes = bookmarkData.properties;
                        attributes.id = '' + bookmarkData.id;
                        attributes.local = false;
                        attributes.dirty = false;
                        delete attributes.localId; // TODO: trengs kanskje aldri her?
                        var bookmark = new Bookmark(attributes, {userbook: me});
                        bookmarksKeepList.push(bookmark);
                    }
                });
                me._deletedBookmarks = [];
                me.get('bookmarks').set(bookmarksKeepList);
                me.get('bookmarks').sort();
            } else {
                // applying changelog from server

                me.applyRemoteChangelog(
                    incomingData,
                    me.get('bookmarks'),
                    Bookmark,
                    function(id){
                        me._deletedBookmarks.splice(me._deletedBookmarks.indexOf(id), 1);
                    }

                );

            }
        },

        onRemoteChange_annotations: function (incomingData, fromTimestamp) {
            var data = incomingData;
            var me = this;

            this.set('dirty_annotations', false);
            if (! fromTimestamp) {
                var publicAnnotationsMap = {};
                data.forEach(function (annotationsData) {
                    publicAnnotationsMap[annotationsData.id] = annotationsData;
                    publicAnnotationsMap[annotationsData.properties.localId] = annotationsData;
                });
                var annotationsKeepMap = {};

                var annotationsKeepList = [];
                me.get('annotations').each(function (annotation) {
                    var annotationData = publicAnnotationsMap[annotation.id];
                    var attributes;
                    if (annotationData) {
                        attributes = annotationData.properties;
                        attributes.id = '' + annotationData.id;
                        attributes.local = false;
                        attributes.dirty = false;
                        delete attributes.localId;
                        annotation.set(attributes);
                        annotationsKeepList.push(annotation);
                        annotationsKeepMap[annotationData.id] = true;
                    }
                });

                data.forEach(function (annotationData) {
                    if (!annotationsKeepMap[annotationData.id]) {
                        var attributes = annotationData.properties;
                        attributes.id = '' + annotationData.id;
                        attributes.local = false;
                        attributes.dirty = false;
                        delete attributes.localId; // TODO: trengs kanskje aldri her?
                        var annotation = new Annotation(attributes, {userbook: me});
                        annotationsKeepList.push(annotation);
                    }
                });
                me._deletedAnnotations = [];
                me.get('annotations').set(annotationsKeepList);
                me.get('annotations').sort();
            } else {
                me.applyRemoteChangelog(
                    incomingData,
                    me.get('annotations'),
                    Annotation,
                    function (id) {
                        me._deletedAnnotations.splice(me._deletedAnnotations.indexOf(id), 1);
                    }
                );
            }
        },



        /**
         * Generic function for use for both annotations and bookmarks
         * @method  applyRemoteChangelog
         * @param  {object}   changelog                         Changelog from server
         * @param  {object}   collection                        Collection of bookmarks or annotations
         * @param  {function} modelClass                        Model class for either bookmarks or annotations, used when creating new models
         * @return {function} resetDeletedModelListCallback     Fired when a model is deleted, removes the model-id from an array of deleted models of that type in userbook.
         */
        applyRemoteChangelog: function (changelog, collection, modelClass, resetDeletedModelListCallback) {
            var me = this;
            changelog.forEach(function (data) {
                var model;
                var attributes;
                // id is string in the models
                var id = data.id + '';
                var localId = data.properties.localId ? data.properties.localId + '' : undefined;

                // Look for the model in collection

                if (collection.where({id: id}).length) {
                    model = collection.findWhere({id: id});
                } else {
                    if (localId && collection.where({id: localId}).length) {
                        model = collection.findWhere({id: localId});
                    }
                }

                if (data.properties.deleted) {
                    collection.remove(model);
                    resetDeletedModelListCallback(id);
                } else {
                    attributes = data.properties;
                    attributes.id = id;
                    attributes.local = false;
                    attributes.dirty = false;
                    delete attributes.localId;

                    //update or create model
                    if (model) {
                        model.set(attributes);
                    } else {
                        model = new modelClass(attributes, {userbook: me});
                        collection.add(model);
                    }
                }
            });
        },

        onRemoteChange_bookmarksAndAnnotations: function (incomingData, fromTimestamp) {
            this.set('jwt', incomingData.token);
            this.onRemoteChange_bookmarks(incomingData.bookmarks, fromTimestamp);
            this.onRemoteChange_annotations(incomingData.annotations, fromTimestamp);
        },

        getPreparedData_settings: function () {
            var bookResource = this.getBookResource();
            return {
                keys: {
                    publisherid: bookResource.get('publisherRef'),
                    productid: bookResource.get('productId'),
                    epubid: bookResource.get('epubid')
                },
                data: {
                    fontSize: this._fontSizeDirty ? this.get('fontSize') : null,
                    readingPositionCfi: this._readingPositionCfiDirty ? this.get('readingPositionCfi') : null,
                    glossaryLang: this._glossaryLangDirty ? this.get('glossaryLang') : null
                }
            };
        },

        getPreparedData_bookmarksAndAnnotations: function () {
            var bookResource = this.getBookResource();
            var changelog = this.getBookmarksChangelog().concat(this.getAnnotationsChangelog());
            return {
                keys: {
                    publisherid: bookResource.get('publisherRef'),
                    productid: bookResource.get('productId'),
                    epubid: bookResource.get('epubid')
                },
                data: changelog
            };
        },

        getBookmarksChangelog: function () {
            var changelog = [];
            var timestamp = (new Date()).toISOString();
            this._deletedBookmarks.forEach(function (bookmarkId) {
                changelog.push({
                    type: 'del-bookmark',
                    id: bookmarkId,
                    timestamp: timestamp
                });
            });

            this.get('bookmarks').each(function (bookmark) {
                var bookmarkData = {
                    bookpartId: bookmark.get('bookpartId'),
                    cfi: bookmark.get('cfi'),
                    label: bookmark.get('label'),
                    physicalPageNumber: bookmark.get('physicalPageNumber')
                };
                if (bookmark.isLocal()) {
                    bookmarkData.localId = bookmark.get('id');
                    changelog.push({
                        type: 'add-bookmark',
                        data: bookmarkData,
                        timestamp: timestamp
                    });
                } else {
                    if (bookmark.get('dirty')) {
                        changelog.push({
                            id: parseInt(bookmark.get('id'), 10),
                            type: 'upd-bookmark',
                            data: bookmarkData,
                            timestamp: timestamp
                        });
                    }
                }
            });
            return changelog;
        },

        getAnnotationsChangelog: function () {
            var changelog = [];
            var timestamp = (new Date()).toISOString();
            this._deletedAnnotations.forEach(function (annotationId) {
                changelog.push({
                    type: 'del-annotation',
                    id: annotationId,
                    timestamp: timestamp
                });
            });
            this.get('annotations').each(function (annotation) {
                var annotationData = {
                    bookpartId: annotation.get('bookpartId'),
                    cfi: annotation.get('cfi'),
                    colorid: annotation.get('colorid'),
                    text: annotation.get('text'),
                    markedText: annotation.get('markedText'),
                    markedTextWithMarkup: annotation.get('markedTextWithMarkup'),
                    timestamp: annotation.get('timestamp'),
                    physicalPageNumber: annotation.get('physicalPageNumber')

                };
                if (annotation.isLocal()) {
                    annotationData.localId = annotation.get('id');
                    changelog.push({
                        type: 'add-annotation',
                        data: annotationData,
                        timestamp: timestamp
                    });
                } else {
                    if (annotation.get('dirty')){
                        changelog.push({
                            id: parseInt(annotation.get('id'), 10),
                            type: 'upd-annotation',
                            data: annotationData,
                            timestamp: timestamp
                        });
                    }
                }
            });
            return changelog;
        },

        createAnnotation: function (bookpartId, cfi, colorid, text, markedText, markedTextWithMarkup) {
            var newLocalId, timestamp, collection = this.get('annotations');
            do {
                timestamp = Date.now();
                newLocalId = 'local-annotation-' + timestamp;
            } while (collection.get(newLocalId));

            var physicalPageNumber = this.getBookResource().getClosestPagebreak(bookpartId, cfi);

            var annotation = new Annotation({
                id: newLocalId,
                bookpartId: bookpartId,
                cfi: cfi,
                colorid: colorid,
                text: text,
                markedText: markedText,
                markedTextWithMarkup: markedTextWithMarkup,
                physicalPageNumber: physicalPageNumber,
                timestamp: timestamp,
                dirty: true,
                local: true
            }, {userbook: this});

            this._user._app.plausibleManager.sendEvent(
                this.get('publisherRef'),
                'Leseren',
                'Opprettet notat',
                this._bookResource.get('title')
            );
            this.ariaAnnounce('Opprettet markering på side ' + physicalPageNumber);
            collection.add(annotation);
            return annotation;
        },

        deleteAnnotation: function (annotation) {
            if (!annotation.isLocal()) {
                this._deletedAnnotations.push(annotation.get('id'));
            }
            this.get('annotations').remove(annotation);
        },

        createBookmark: function (bookpartId, cfi, label, physicalPageNumber) {
            var newLocalId, collection = this.get('bookmarks');

            do {
                newLocalId = 'local-bookmark-' + Date.now();
            } while (collection.get(newLocalId));

            var bookmark = new Bookmark({
                id: newLocalId,
                bookpartId: bookpartId,
                cfi: cfi,
                label: label,
                physicalPageNumber: physicalPageNumber,
                dirty: true,
                local: true
            }, {userbook: this});
            collection.add(bookmark);

            this._user._app.plausibleManager.sendEvent(
                this.get('publisherRef'),
                'Leseren',
                'Opprettet bokmerke',
                this._bookResource.get('title')
            );
            this.ariaAnnounce('Opprettet bokmerke på side ' + physicalPageNumber);
            return bookmark;
        },

        updateBookmark: function (bookmark, label) {
            bookmark.set('label', label);
            bookmark.set('dirty', true);
            this.ariaAnnounce('Bokmerketittel oppdatert til ' + label);
            return bookmark;
        },

        deleteBookmark: function (bookmark) {
            if (!bookmark.isLocal()) {
                this._deletedBookmarks.push(bookmark.get('id'));
            }
            this.get('bookmarks').remove(bookmark);
            $('#reader').removeClass('just-createdBookmark');
        },

        onSettingsChanged: function () {
            this.set('dirty_settings', true);
            this._fontSizeDirty = true; // Simplified
            this._readingPositionCfiDirty = true;
            this._glossaryLangDirty = true;
        },

        onBookmarksChanged: function (bookmark) {
            if (bookmark.isLocal()) {
                this.set('dirty_bookmarks', true);
            }
        },

        onAnnotationsChanged: function (annotation) {
            if (annotation.isLocal()) {
                this.set('dirty_annotations', true);
            }
        },

        setGlossaryLang: function (glossarylang) {
            this.set('glossaryLang', glossarylang);
            this.set('dirty_settings', true);
            this._glossaryLangDirty = true;
        },

        /**
         * Checks the userdata and bookresource status and sets the attribute cloudStatus to the computed cloudstatus
         * The algorithm to check what the current cloud status is as follows:
         *    - check different 'states' on this userbook's bookresource
         *    - the order in which these 'states' are checked is important (e.g. 'noLicence' trumps every state)
         *
         * @method  checkCloudStatus
         */
        checkCloudStatus: function () {
            if (this.get('noLicence') === true) {
                this.set('cloudStatus', 'noLicence');
                return;
            }

            // the book is downloaded and is currently unzipping to the file system.
            // (only valid for the cordova app).
            if (this._bookResource.get('isUnpacking')) {
                this.set('cloudStatus', 'isUnpacking');
                return;
            }

            if (this._bookResource.get('isRemoving')) {
                this.set('cloudStatus', 'isRemoving');
                return;
            }

            if (this._bookResource.get('isDownloading')) {
                this.set('cloudStatus', 'isDownloading');
                return;
            }

            // important that this has a higher priority than '_not_ downloaded'.
            if (this._bookResource.newVersionAvailable()) {
                this.set('cloudStatus', 'newVersionAvailable');
                return;
            }

            if (!this._bookResource.get('isDownloaded')) {
                this.set('cloudStatus', "isNotDownloaded");
                return;
            }

            if (this.get('offline_and_dirty') && this._user._app.get('isOnline')) {
                this.set('cloudStatus', 'isSyncing');
                return;
            }

            if (this.get('offline_and_dirty')) {
                this.set('cloudStatus', 'isDirty');
                return;
            }

            this.set('cloudStatus', "isDownloaded");
            return;
        },

        openNoLicenseDialog: function(e) {
            $(e.target).attr({ 'data-dialog-spawned': 1 });
            new DialogView({
                "title": "Boka er ikke tilgjengelig",
                "message": "Du har ikke lisens på denne boka.",
                "isAlert": true,
                "appendTo": 'body'
            });
        },

        openAllIsSynchronizedDialog: function(e) {
            $(e.target).attr({ 'data-dialog-spawned': 1 });
            new DialogView({
                "title": "Alt er synkronisert!",
                "message": "Denne boka er lastet ned og er tilgjengelig uten nettilgang. Alle notater, bokmerker og markeringer er synkronisert med din brukerkonto.",
                "isAlert": true,
                "appendTo": 'body'
            });
        },

        openRememberToSynchronizeDialoge: function(e) {
            $(e.target).attr({ 'data-dialog-spawned': 1 });
            new DialogView({
                "title": "Husk å synkronisere!",
                "message": "Du har opprettet notater, bokmerker eller markeringer som ikke er synkronisert med din brukerkonto. Når du har nettilgang blir dette synkronisert automatisk.",
                "isAlert": true,
                "appendTo": 'body'
            });
        },

        onDownloadBtnClicked: function(e) {
            var me = this;
            if (this._user._app.get('isOnline')) {
                if ($('body').hasClass('anonymousUser')) {
                    $(e.target).attr({ 'data-dialog-spawned': 1 });
                    new DialogView({
                        'isConfirm': true,
                        'title': 'Gjør boka tilgjengelig frakoblet',
                        'message': 'Ønsker du å gjøre boka tilgjengelig frakoblet?',
                        'onConfirm': function() {
                            me.downloadBook();
                        }
                    });
                } else {
                    this.downloadBook();
                }
            }
            else {
                $(e.target).attr({ 'data-dialog-spawned': 1 });
                new DialogView({
                    "title": "Ikke tilgjengelig frakoblet",
                    "message": "Denne uniboka kan ikke åpnes når du ikke er tilkoblet internett. Du kan gjøre den tilgjengelig frakoblet når du er tilkoblet igjen.",
                    "isAlert": true,
                    "appendTo": 'body'
                });
            }
        },

        onNewVersionBtnClicked: function(e) {
            if (this.getBookResource()._app.get('isOnline')) {
                var me = this;
                this.deleteBook()
                    .then(me.downloadBook.bind(me));

            }
            else {
                $(e.target).attr({ 'data-dialog-spawned': 1 });
                new DialogView({
                    "title": "Ny versjon tilgjengelig",
                    "message": "Denne uniboka er tilgjengelig i ny versjon, men kan ikke oppdateres når du ikke har tilgang til nettet. Når du har tilgang til nettet igjen kan du oppdatere boka.",
                    "isAlert": true,
                    "appendTo": 'body'
                });
            }
        },

        doSomethingCloudStatus: function(event) {
            $(document.activeElement).attr({ 'data-dialog-spawned': 0 });
            switch(this.get('cloudStatus')) {
            case 'noLicence':
                this.openNoLicenseDialog(event);
                break;
            case 'isNotDownloaded':
                this.onDownloadBtnClicked(event);
                break;
            case 'newVersionAvailable':
                this.onNewVersionBtnClicked(event);
                break;
            case 'isDownloaded':
                this.deleteBook();
                break;
            case 'isDownloading':
                this.abortDownload(event);
                break;
            case 'isDirty':
                this.openRememberToSynchronizeDialoge(event);
                break;
            case 'isSyncing':
                alert('Du har gjort endringer i boka som ikke er synkronisert med brukerkontoen din. Du må være tilkoblet internett for å synkronisere.');
                break;
            }

            return false;
        },

        deleteBook: function() {
            var deferred = $.Deferred();
            var me = this;

            $(document.activeElement).attr({ 'data-dialog-spawned': 1 });
            new DialogView({
                'isConfirm': true,
                'title': 'Advarsel',
                'message': 'Vil du fjerne den frakoblede versjonen av boka?',
                "appendTo": 'body',
                'onConfirm': function() {
                    me.getBookResource().remove()
                        .then(function(){
                            me._user._app.plausibleManager.sendEvent(
                                me.get('publisherRef'),
                                "Bokhylla",
                                "Slettet bok",
                                me.getBookResource().get('title')
                            );
                            deferred.resolve();
                        })
                        .fail(function(err){
                            deferred.reject(err);
                        });
                }
            });


            return deferred.promise();
        },

        downloadBook: function() {
            var me = this;
            this.ariaAnnounce('Laster ned ' + this._bookResource.get('title'));
            return this.getBookResource().download(function () {
                me._user._app.trigger('offlineBooks');
                me.ariaAnnounce(me._bookResource.get('title') + ' ferdig nedlastet');
            });
        },

        abortDownload: function (e) {
            var me = this;
            $(e.target).attr({ 'data-dialog-spawned': 1 });
            new DialogView({
                'isConfirm': true,
                'title': 'Advarsel',
                'message': 'Vil du avbryte prosessen med å gjøre boka tilgjengelig frakoblet?',
                "appendTo": 'body',
                'onConfirm': function() {
                    me.getBookResource().abortDownload();
                }
            });
        },

        ariaAnnounce: function (message) {
            $('#aria-live-region').html(message);
            window.setTimeout(() => {
                $('#aria-live-region').empty();
            }, 1000);
        },

        setReaderPosition: function(page) {
            var spineItems = this._bookResource._spine.getLinearSpineItems();

            var matcher = function(p) {
                return p[1] === page;
            };

            for(var i = 0; i < spineItems.length; i++) {
                var matchingPages = spineItems[i].physicalPages.filter(matcher);
                if (matchingPages.length > 0) {

                    var idref = spineItems[i].idref;

                    // only use first (index 0) (should never be more than one page matching anyways)
                    // index 0 is the cfi
                    var cfi = matchingPages[0][0];

                    if (idref && cfi) {
                        this.set('readingPositionCfi', JSON.stringify({
                            idref: idref,
                            contentCFI: cfi
                        }));
                    }
                }
            }
        }
    });
});

