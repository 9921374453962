/* globals console, window, Blob, document */

define('blobifier',['jquery', 'underscore', 'async', 'localforage', 'URIjs'], function($, _, async, localforage, URI) {


    var CSS_URL_REGEXP = /[Uu][Rr][Ll]\(\s*([']([^']+)[']|["]([^"]+)["]|([^)]+))\s*\)/g;
    var CSS_IMPORT_URL_REGEXP = /@[Ii][Mm][Pp][Oo][Rr][Tt]\s*('([^']+)'|"([^"]+)")/g;

    var blobUrlMap = {};

    function default_fetcher(url) {
        return $.ajax({
            url: url,
            dataType: "text"
        });
    }

    function getAttr(el) {
        if (el.href) {
            return "href";
        }
        else if (el.src) {
            return "src";
        }
        else {
            return "data";
        }
    }

    function guessMimetype(url) {
        var suffix = new URI(url).suffix();
        if (["ttf", "otf"].indexOf(suffix) > -1) {
            return "font";
        }
        else if (["png", "jpg", "jpeg"].indexOf(suffix) > -1) {
            return "image/" + suffix;
        }
        else if (["css"].indexOf(suffix) > -1) {
            return "text/css";
        }
        else if (["svg"].indexOf(suffix) > -1) {
            return "image/svg+xml";
        }
        else if (["xhtml"].indexOf(suffix) > -1) {
            return "application/xhtml+xml";
        }
        else {
            return "";
        }
    }

    function generateCssBlobUrl(url, options) {
        var deferred = $.Deferred();
        var fetcher = options.fetcher ? options.fetcher : default_fetcher;
        var bloburlKey = options.bloburlKey ? options.bloburlKey+url : url;

        // TODO strip url for #...

        var blobUrl = blobUrlMap[bloburlKey];
        if (blobUrl) {
            return deferred.resolve(blobUrl);
        }

        fetcher(url)
            .then(function(cssString) {

            var baseUrl = new URI(url).directory() + "/";


            var normalizedMap = {};

            // TODO: enable CSS_IMPORT_URL_REGEXP, but before that make sure the regexp's is not overlapping.
            [CSS_URL_REGEXP].forEach(function (processingRegexp) {
                var cssUrlMatch = processingRegexp.exec(cssString);

                var matchGroups = function(matchGroup) {
                    return typeof matchGroup !== 'undefined';
                };

                while (cssUrlMatch !== null) {
                    var extractedUrlCandidates = cssUrlMatch.slice(2);
                    var extractedUrl = _.find(extractedUrlCandidates, matchGroups);

                    var normalisedUrl = new URI(extractedUrl).absoluteTo(baseUrl).toString();
                    var generateFunc;

                    if (processingRegexp === CSS_IMPORT_URL_REGEXP) {
                        generateFunc = generateCssBlobUrl;
                    }
                    else {
                        generateFunc = generateGenericBlobUrl;
                    }

                    normalizedMap[normalisedUrl] = {
                        url: extractedUrl,
                        generateFunc: generateFunc,
                    };

                    cssUrlMatch = processingRegexp.exec(cssString);
                }
            });

            async.each(
                Object.keys(normalizedMap),
                function(normalizedUrl, callback) {
                    normalizedMap[normalizedUrl].generateFunc(normalizedUrl, options)
                        .then(function (genericBlobUrl) {
                            cssString = cssString.replace(normalizedMap[normalizedUrl].url, genericBlobUrl);
                            callback(null);
                        })
                        .fail(function(err) {
                            console.log(err);
                            callback(null);
                        });
                },
                function() {
                    var blob = new Blob([cssString], {type: "text/css"});
                    blobUrl = window.URL.createObjectURL(blob);
                    blobUrlMap[bloburlKey] = blobUrl;
                    deferred.resolve(blobUrl);
                }
            );
        
        })
        .fail(function(err) {
            deferred.reject(err);
        });
        return deferred;
    }

    function regExpEscape(s) {
        return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    function generateXhtmlBlobUrl(url, options) {
        var deferred = $.Deferred();
        var fetcher = options.fetcher ? options.fetcher : default_fetcher;
        var bloburlKey = options.bloburlKey ? options.bloburlKey+url : url;

        var blobUrl = blobUrlMap[bloburlKey];
        if (blobUrl) {
            return deferred.resolve(blobUrl);
        }

        fetcher(url)
            .then(function(htmlString) {

            var doc = document.implementation.createHTMLDocument("parserDoc");
            doc.documentElement.innerHTML = htmlString;

            var elements = doc.querySelectorAll("link[href],object[data],[src]");

            async.eachLimit(
                elements,
                3,
                function(el, callback) {
                    if (el.nodeName.toUpperCase() === "LINK" && el.rel.toUpperCase() === "STYLESHEET") {
                        generateCssBlobUrl(new URI(el.href).pathname(), options)
                            .then(function(blobUrl) {
                                var regExpEscapedAttrValue = regExpEscape(el.getAttribute("href"));
                                var regExp = new RegExp("href=(?:\"" + regExpEscapedAttrValue + "\"|'" + regExpEscapedAttrValue + "')");
                                htmlString = htmlString.replace(regExp, 'href="' + blobUrl + '"');
                                callback(null);
                            })
                            .fail(function(err) {
                                console.log(err);
                                callback(null);
                                return;
                            });
                    }
                    else {

                        var attr = getAttr(el);
                        generateGenericBlobUrl(
                            new URI(el[attr]).pathname(),
                            options)
                            .then(function(blobUrl) {
                                var uri = new URI(el[attr]);
                                var regExpEscapedAttrValue = regExpEscape(el.getAttribute(attr) + uri.hash());
                                var regExp = new RegExp(attr + "=(?:\"" + regExpEscapedAttrValue + "\"|'" + regExpEscapedAttrValue + "')");
                                htmlString = htmlString.replace(regExp, attr + '="' + blobUrl + uri.hash() + '"');
                                callback(null);
                            })
                            .fail(function(err) {
                                console.log(err);
                                callback(null);
                                return;
                            });
                    }
                },
                function() {
                    var blob = new Blob([htmlString], {type: "application/xhtml+xml"});
                    var blobUrl = window.URL.createObjectURL(blob);
                    blobUrlMap[bloburlKey] = blobUrl;
                    deferred.resolve(blobUrl);
                });
        })
        .fail(function(err) {
            return deferred.reject(err);
        });
        return deferred;
    }

    function generateGenericBlobUrl(url, options) {
        var deferred = $.Deferred();
        var fetcher = options.fetcher ? options.fetcher : default_fetcher;
        var bloburlKey = options.bloburlKey ? options.bloburlKey+url : url;

        var blobUrl = blobUrlMap[bloburlKey];
        if (blobUrl) {
            return deferred.resolve(blobUrl);
        }

        fetcher(url)
            .then(function(content) {
                var blob;
                if (content instanceof Blob) {
                    blob = content;
                }
                else {
                    blob = new Blob([content], {type: options.mimetype || guessMimetype(url)});
                }

                blobUrl = window.URL.createObjectURL(blob);
                blobUrlMap[bloburlKey] = blobUrl;
                return deferred.resolve(blobUrl);
            })
            .fail(function(err) {
                console.log(err);
                deferred.reject(err);
            });
        return deferred;
    }

    return {
        generateCssBlobUrl: generateCssBlobUrl,
        generateXhtmlBlobUrl: generateXhtmlBlobUrl,
        generateGenericBlobUrl: generateGenericBlobUrl
    };

});

