/* global window, URI */
define('EnhancementController',['jquery', 'bowser', 'async', 'require'], function ($, bowser, async, require) {
    var bindings = {
        'application/x-epub-unibok-imagegallery': 'bildegalleri',
        'application/x-epub-unibok-audio': 'lyd',
        'application/x-epub-unibok-video': 'video',
        'application/x-epub-unibok-embedvideo': 'video',
        'application/x-epub-unibok-externalcontent': 'eksterntinnhold'
    };

    var adaptorsMap = {
        bildegalleri: {
            'eb-imagegallery-1.1': 'widgets/bildegalleri/adaptor01'
        },
        video: {
            'eb-video-1.1': 'widgets/video/adaptor01',
            'eb-embedvideo-1.1': 'widgets/video/adaptor02'
        },
        lyd: {
            'eb-audio-1.1': 'widgets/lyd/adaptor01'
        },
        eksterntinnhold: {
            'eb-externalcontent-1.1': 'widgets/eksterntinnhold/adaptor01'
        }
    };

    var EnhancementController = function(iframe, userbook, app) {
        this._iframe = iframe;
        this._userbook = userbook;
        this._app = app;
    };

    EnhancementController.prototype = {
        /**
         * Find all mathjax expressions that is wider than epub column and apply horizontal scroll bar
         */
        postprocessMath: function (asyncDoneCallback) {
            var me = this;
            var iframeDoc = me._iframe.contents()[0];
            if (! iframeDoc) { return; }
            $(iframeDoc).find('.mjpage').each(function () {
                try {
                    if ($(this.parentNode).is('li') && $(this)[0].firstChild.scrollWidth > this.parentNode.clientWidth && this.parentNode.clientWidth > 0) {
                        $(this).css('height', $(this).height() + 8);
                        $(this).parent().parent().addClass('mathjax-formula');
                    }
                    else if ($(this)[0].firstChild.scrollWidth > this.parentNode.clientWidth && this.parentNode.clientWidth > 0) {
                        $(this).css('height', $(this).height() + 8);
                        $(this).parent().addClass('mathjax-formula');
                    }
                    else if ($(this)[0].firstChild.scrollWidth > this.parentNode.parentNode.clientWidth && this.parentNode.parentNode.clientWidth > 0) {
                        $(this).css('height', $(this).height() + 8);
                        $(this).parent().parent().addClass('mathjax-formula');
                    }
                } catch (e) {
                    console.error('Mathjax error:', e);
                }
            });
            if (asyncDoneCallback) {
                asyncDoneCallback(null);
            }
        },

        /*
         * Initializing berikinger (widgets)
         * This is asyncronous. When all widgets are ready, the onReadiumPaginationChanged will be called.
         * @param {object} spineItem spineItem to initialize the widgets on.
         * @param {function} mainCallback aka 'onReadiumPaginationChanged'.
         */
        initWidgets: function(spineItem, asyncDoneCallback) {
            var me = this;

            me._currentWidgetViews = [];

            // Setter inn widgets
            var epubBase = new URI(me._userbook.getBookResource().getBaseUrl()).pathname();

            var epubHtml = me._iframe.contents().find('html');

            // Add ie11 class into iframe content
            // to handle MathJax problems of Internet Explorer
            if (bowser.name.toLowerCase() === 'internet explorer' && parseInt(bowser.version, 10) === 11) {
                epubHtml.addClass('ie11');
            }

            me._epubHtml = epubHtml;

            async.each(epubHtml.find('object'), function (obj, callback) {
                var mediaType = $(obj).attr('type') || $(obj).attr('typo'); // NOTE: Document is manipulated to replate type -> typo, because of EB-1200, but not for cordova app
                var handlerName = bindings[mediaType];

                if (handlerName) {

                    var uri = new URI($(obj).attr('data'));
                    if (uri.is("relative")) {
                        uri = uri.absoluteTo(new URI(spineItem.href).absoluteTo(epubBase)).toString();
                    }
                    else {
                        uri = uri.toString();
                    }

                    // location.origin is file:// which fails 404 when trying to $.get(uri). need to hack in prod origin to get resource
                    if (me._userbook.getBookResource()._app.get('mobileApp') && me._userbook.get('cloudStatus') === 'isNotDownloaded' && me._userbook.getBookResource()._app.get('isOnline')) {
                        uri = 'https://les.unibok.no' + uri;
                    }


                    var displayOptions = {};
                    $.each(obj.attributes, function (i, attrib) {
                        if (/^data-displayoptions-/.test(attrib.name)) {
                            displayOptions[attrib.name.substr(20)] = attrib.value;
                        }
                    });

                    var mediaArr = mediaType.split('-');
                    var mediaClass = mediaArr[mediaArr.length - 1];
                    var playerStyle = $(obj).attr('data-displayoptions-mode');
                    // hack to replace object tag with div (browser compatibility)
                    // TODO: is this causing safari-solitaire?
                    var replaceDiv = $('<div class="' + mediaClass + ' ' + playerStyle +'">' + $(obj).contents() + '</div>');
                    $(obj).replaceWith(replaceDiv);


                    $.get(uri, function (xmlData) {
                        var version = xmlData.documentElement.getAttribute('version');
                        if (!version) {
                            version = xmlData.documentElement.children[0].getAttribute('version');
                        }
                        var widgetDir = 'widgets/' + handlerName;
                        require([widgetDir + '/Model', widgetDir + '/View', adaptorsMap[handlerName][version]], function (Model, View, adaptor) {
                            adaptor(
                                $(xmlData),
                                me._userbook.getBookResource().getOpfUrl(),
                                function(url, callback) {
                                    me._userbook.getBookResource().getResourceUrl(url, callback);
                                },
                                function(data) {
                                    data.publisherRef = me._userbook.get('publisherRef');
                                    var model = new Model(data, me._app);
                                    var rendrer = new View({model: model, el: replaceDiv}, displayOptions);
                                    me._currentWidgetViews.push(rendrer);
                                    callback(null);
                                }
                            );
                        });
                    }, 'xml').fail(function () {
                        // TODO: what should we do here?
                        callback(null);
                    });
                } else {
                    callback(null);
                }

            }, function(err) {
                asyncDoneCallback(null);
            });
        },

    };

    return EnhancementController;
});

