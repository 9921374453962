/* global define, window, console */
define('views/OverlayView',['jquery', 'backbone', 'templates/overlayView', 'models/App'], function ($, Backbone, mainTemplate) {
    return Backbone.View.extend({
        targetElement: '#message',

        tagName: 'div',

        className: 'notification',

        defaultMessages: {
            'success': 'OK!',
            'error': 'Beklager. Det har oppstått en feil.',
            'warning': 'Er du sikker?',
            'information': 'Det oppstod en ukjent feil.'
        },

        cssClasses: {
            'success': 'accepted',
            'error': 'cancel',
            'warning': 'warning',
            'information': 'information'
        },

        events: {
            'click' : 'closeNotification',
            'click .btn-closebtn': 'closeNotification'
        },

        automaticClose: false,
        manualClose: true,

        initialize: function (options) {
            var self = this;
            // defaults
            var type = 'information';
            var text = this.defaultMessages[type];
            var target = this.targetElement;
            var buttonText = "OK";
            var title = "Melding";
            // on click handler for button
            var buttonHandler = function () {
                self.closeNotification();
            };

            // if any options were set, override defaults
            if (options && options.hasOwnProperty('type')) {
                type = options.type;
            }
            if (options && options.hasOwnProperty('text')) {
                text = options.text;
            }
            if (options && options.hasOwnProperty('title')) {
                title = options.title;
            }
            if (options && options.hasOwnProperty('buttonText')) {
                buttonText = options.buttonText;
            }
            if (options && options.hasOwnProperty('buttonHandler')) {
                buttonHandler = options.buttonHandler;
            }
            if (options && options.hasOwnProperty('target')) {
                target = options.target;
            }
            if (options && options.hasOwnProperty('automaticClose')) {
                this.automaticClose = options.automaticClose;
            }
            if (options && options.hasOwnProperty('manualClose')) {
                this.manualClose = options.manualClose;
            }
            if (options && options.hasOwnProperty('moreInfo')) {
                this.moreInfo = options.moreInfo;
            }

            // is message already displayed in view? if yes, don't show again
            if ($('.notification:contains('+text+')').length === 0) {
                this.render(type, title, text, buttonText, buttonHandler, target);
            }

            $('.btn-closebtn').on('click', buttonHandler);
        },

        open: function () {
            var user = this.model.getUser();
            user.set('activeUserbook', this.model);
        },

        render: function (type, title, text, buttonText, buttonHandler, target) {
            var self = this;
            $(this.targetElement).empty().append(mainTemplate({
                title: title,
                text: text,
                buttonText: buttonText
            })).removeAttr('hidden').show();

            if (this.moreInfo) {
                $(this.targetElement).find('.moreInfo').append(this.moreInfo.$el);
                $(this.targetElement).find('.moreInfoBtn').show();
                $(this.targetElement).find('.moreInfoBtn').on('click', $.proxy(this.toggleMoreInfo, this));
            } else {
                $(this.targetElement).find('.moreInfoBtn').hide();
            }

            if (buttonHandler) {
                $(this.targetElement).find('.button').on('click', buttonHandler);
            }

            // Automatically close after set time. also closes on click
            if (this.automaticClose) {
                window.setTimeout(function (){
                    self.closeNotification();
                }, 3000);
            }

            if (this._hidden) {
                this.$el.hide();
            }
            this.$el.data('view', this);
            return this;
        },

        toggleMoreInfo: function() {
            var moreInfo = $(this.targetElement).find('.moreInfo');
            moreInfo.toggle();
        },

        closeNotification: function () {
            var self = this;

            $(this.el).fadeOut(function() {
                self.unbind();
                self.remove();
                $(self.targetElement).attr('hidden', 'hidden').hide();
            });
        }
    });
});

