/* global JSON, setTimeout, window, document, navigator, localStorage */
define('views/TopbarView',[
    'jquery',
    'underscore',
    'backbone',
    'bowser',
    'templates/topbar',
    'PanelController',
    'views/TocPanel',
    'views/AnnotationsPanel',
    'views/AnnotationEditor',
    'views/BookmarksPanel',
    'views/SearchPanel',
    'views/SettingsPanel',
    'views/DialogView',
    'views/PrintView',
    'views/ordbokLightboxView',
    'rangy'
], function (
    $,
    _,
    Backbone,
    bowser,
    topbar,
    PanelController,
    TocPanel,
    AnnotationsPanel,
    AnnotationEditor,
    BookmarksPanel,
    SearchPanel,
    SettingsPanel,
    DialogView,
    PrintView,
    ordbokLightboxView,
    rangy
) {

    function showGoBackButton() {
        $('#goBack-btn').show();
        $('div.progressbar').css('width', $("div#reader").width() - 50);
        $('div.textualIndicators').css('width', $("div#reader").width() - 50);
        $('div.textualIndicators').css('margin-left', '50px');
    }

    function createHighlightDiv(range, leftOffset) {
        var counter = 0;
        while (true) {
            if (['\n', '\t'].indexOf(range.toString().substr(range.toString().length - 1, 1)) !== -1) {
                range.moveEnd('character', -2);
                range.moveEnd('character', 2);
            }
            if (typeof (range.text) === 'function' && range.text && ['\n', '\t'].indexOf(range.text().substr(range.text().length - 1, 1)) !== -1) { /* EB-2668 */
                range.moveEnd('character', -1);
            }
            else {
                break;
            }

            counter++;
            if (counter > 1000) {
                throw new Error('Creating highlight div for TTS text-to-speech have failed: infinit loop.');
            }
        }

        var rect = range.getBoundingClientRect();
        var div = $('<div id="TTSHighlightPre">');
        div.css('position', 'absolute');
        div.css('background-color', 'grey');
        div.css('opacity', '0.1');
        div.css('pointer-events', 'none');
        div.css('top', rect.top + 'px');
        div.css('height', rect.height + 'px');
        div.css('left', leftOffset + rect.left);
        div.css('width', rect.width + 'px');

        return div;
    }

    return Backbone.View.extend({
        initialize: function (options) {
            this._options = options;
            this._readerView = options.readerView;
            this._readerView.prod = this.produceSyntheticSpeech;
            this._readerView._range = "";
            this._app = options.app;
            this._getReadium = options.getReadium;
            this._visibleBookmarks = []; // populated in _updateBookmarkIndicator()
            this._isIos = false;

            this._panelController = new PanelController(
                {
                    tocPanel: new TocPanel(this._app, $.proxy(this.onGotoFromToc, this), $.proxy(this.onGotoResource, this)),
                    annotationsPanel: new AnnotationsPanel($.proxy(this.onGotoAnnotation, this), this._app),
                    annotationsEditor: new AnnotationEditor({ readerView: this._readerView }),
                    bookmarksPanel: new BookmarksPanel($.proxy(this.onGotoBookmark, this), this._app),
                    searchPanel: new SearchPanel($.proxy(this.onGotoPlace, this), $.proxy(this.onGotoFromToc, this), this._app),
                    settingsPanel: new SettingsPanel({ readerView: this._readerView })
                },
                this._readerView.$el,
                this._getReadium
            );

            // redraw content on resize/orientation change
            this._onDeviceOrientationChanged = _.debounce((function (e) {

                /* EB-1892, if only vertical resize and scrolling. Readium does not rerender. So we handle things differently */
                var noResizeOnReadiumContentElement = this.windowSize.width === $(window).width();
                this.windowSize = {
                    width: $(window).width(),
                    height: $(window).height()
                };
                this._readerView._updateLayout(noResizeOnReadiumContentElement);

                if (this._readerView.enhancementController) { /* Could be resizing while we are initializing */
                    this._readerView.enhancementController.postprocessMath();
                }
            }).bind(this), 50);

            this.render();

        },

        events: {
            'click #goto-bookshelf-btn': 'gotoBookshelf',
            'click #table-of-contents-btn': 'openTocPanel',
            'click #list-bookmarks-btn': 'openBookmarksPanel',
            'click #list-markings-btn': 'openAnnotationsPanel',
            'click #search-btn': 'openSearchPanel',
            'click #goBack-btn': 'onGoBackButtonClicked',

            // 'keyup .item': 'onKeyUpMenuItem',

            'click #annotation-widget-btn': 'onAnnotationButtonClicked',
            'touchstart #annotation-widget-btn': 'onAnnotationButtonClicked',

            'click #ordbok-btn': 'onOrdbokButtonClicked',
            'touchstart #ordbok-btn': 'onOrdbokButtonClicked',

            'click #bookmark-btn': 'onBookmarkButtonClicked',

            'click #textlang-btn': 'openSettingsPanel',
            'touchstart #textlang-btn': 'openSettingsPanel',

            'click #TTS-widget-btn': 'onTTSButtonClicked',
            'touchstart #TTS-widget-btn': 'onTTSButtonClicked',

            'click .cloudStatus': 'doSomethingCloudStatus',

            'click .offline-status-label': 'offlineDialog',
            'click .offline-status-close': 'closeOfflineDialog',

            'focusin #itemContainer': 'focusInTopbar'
        },

        render: function () {
            var topBarView = this;

            if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
                this._isIos = true;
            }

            this.$el.append(topbar({
                mobileApp: topBarView._options.mobileApp,
                offlineApp: topBarView._options.offlineApp,
                'isIos': this._isIos,
                isOffline: $('body').hasClass('offline')
            }));

            return this;
        },

        enter: function (userbook) {
            if (window.orientation !== undefined) {
                window.addEventListener('orientationchange', this._onDeviceOrientationChanged);
            }

            /* Keeping track of window size, to see change in onResize */
            this.windowSize = {
                width: $(window).width(),
                height: $(window).height()
            };

            window.addEventListener('resize', this._onDeviceOrientationChanged);

            this._readium = this._getReadium();

            this.setUserBook(userbook);
        },

        leave: function () {
            if (window.orientation !== undefined) {
                window.removeEventListener('orientationchange', this._onDeviceOrientationChanged);
            }
            window.removeEventListener('resize', this._onDeviceOrientationChanged);

            this.setUserBook(null);
        },

        setUserBook: function (userbook) {
            //remove any listeners on previous userbook and collections
            if (!userbook && this._userbook) {
                this._userbook.off(null, null, this);
                this._userbook.get('bookmarks').off(null, null, this);
                this._userbook.get('annotations').off(null, null, this);
            }

            this._userbook = userbook;
            this._panelController.setUserbook(userbook);

            if (!userbook) {
                return;
            }

            //setup userbook listeners
            this._userbook.get('bookmarks').on('change add remove', this.onBookmarkChanged, this);
            this._userbook.get('annotations').on('update', this.onAnnotationsChanged, this);
            this._userbook.on('change:annotationBeingEdited', this.onAnnotationBeingEditedChanged, this);
            this._userbook.on('change:printerIconClicked', this.onPrinterIconClicked, this);

            this._app.on('change:isOnline', this.onlineStatusChange, this);

            if (this._userbook.getBookResource().get('ordboklang') === 'none') {
                // Remove click handler for book without dictionary
                this.delegateEvents(
                    _(this.events).omit(['click #ordbok-btn', 'touchstart #ordbok-btn'])
                );
                $('#ordbok-btn').addClass('btn-disabled');
                $('#ordbok-btn').attr('title', 'Ordbok ikke tilgjenglig for denne boka');
            } else {
                // Restore event handlers
                this.undelegateEvents(this.events);
                this.delegateEvents(this.events);
                $('#ordbok-btn').removeClass('btn-disabled');
                $('#ordbok-btn').attr('title', 'Slå opp i ordbok');
            }

            // If there are no bookmarks, hide hint text. vice versa...
            if (this._userbook.get('bookmarks').length > 0) {
                $("div.hintTextBookmarks").hide();
            }
            else {
                $("div.hintTextBookmarks").show();
            }

            // If there are no annotations, hide hint text. vice versa...
            if (this._userbook.get('annotations').length > 0) {
                $("div.hintTextAnnotations").hide();
            }
            else {
                $("div.hintTextAnnotations").show();
            }
        },

        onlineStatusChange: function () {
            if (this._app.get('isOnline')) {
                $('.offline-status-label').parent().removeClass('is-offline');
                $('.offline-status-label').parent().show();
                $('.offline-status-label').text('Synkroniserer');
                // ending sync and fadeout in userbooksyncer
            } else {
                $('.offline-status-label').parent().addClass('is-offline');
                $('.offline-status-label').parent().fadeIn(function () {
                    $(this).css({ 'display': 'flex' });

                });
                $('.offline-status-label').text('Ikke tilkoblet');
            }
        },

        /**
        * Button click event functions
        */

        _alertAnonymous: function (e) {
            $(e.target).attr({ 'data-dialog-spawned': 1 });
            new DialogView({
                'isConfirm': true,
                'title': 'Logg inn med FEIDE',
                'message': 'Du bruker Unibok uten innlogging. For å få tilgang til bokhylla og flere nyttige studieverktøy, må du logge inn med FEIDE. Vil du logge inn?',
                'onConfirm': function () { localStorage.clear(); window.location = '/login'; }
            });

        },

        focusInTopbar: function () {
            this._readerView.showMenus();
        },

        gotoBookshelf: function (e) {
            if (this._app.get('activeUser').get('anonymous')) {
                this._alertAnonymous(e);
                return;
            }
            this._userbook.getUser().set('activeUserbook', null);
            this._app.set('fromReader', true);
            this._readerView._appView._router.navigate('', { trigger: true });
            return false;
        },

        onGoBackButtonClicked: function () {
            if ($('#goBack-btn .icon').hasClass('active')) {
                try {
                    if (this._userbook.get('_stackedReadingPosition')) {
                        var stackPos = JSON.parse(this._userbook.get('_stackedReadingPosition'));
                        this._readium.reader.openSpineItemElementCfi(stackPos.idref, stackPos.contentCFI);
                    }
                } catch (e) {
                    console.log('No stacked reading position: ', e);
                }
                $('#goBack-btn .icon').removeClass('active');

                // track back button use with plausible
                this._app.plausibleManager.sendEvent(
                    this._userbook.get('publisherRef'),
                    'Leseren',
                    '"Tilbake"-knapp',
                    this._userbook.getBookResource().get('title')
                );
            }
            return false;
        },

        /**
         * Bookmark-related functions
         */
        onBookmarkChanged: function (model) {
            if (model.get('bookpartId') === this._readerView._currentBookpartId) {
                this._updateBookmarkIndicator();
            }

            if (this._userbook.get('bookmarks').length > 0) {
                $("div.hintTextBookmarks").hide();
            }
            else {
                $("div.hintTextBookmarks").show();
            }
        },

        onAnnotationsChanged: function () {
            if (this._userbook.get('annotations').length > 0) {
                $("div.hintTextAnnotations").hide();
            }
            else {
                $("div.hintTextAnnotations").show();
            }
        },

        onBookmarkButtonClicked: function (e) {
            if (this._app.get('activeUser').get('anonymous')) {
                this.showErrorMessage('Denne funksjonen er kun tilgjengelig når du er innlogget.');
                return;
            }

            var currentPageNumber = this.getCurrentPageNumber();

            if (currentPageNumber) {
                if (!this.pagePreviouslyBookmarked(currentPageNumber)) {
                    this._readerView.createBookmark(e);
                }

                this._panelController.showBookmarksPanel(e);
                this.animateLastAddedBookmark(currentPageNumber);
            }
            else {
                this._panelController.showBookmarksPanel(e);
            }
            const me = this;
            setTimeout(function () {
                if (e && (e.type === 'keyup' || e.type === 'click')) {
                    me._panelController._panels.bookmarksPanel.$el.find('.bookmark').focus();
                }
            }, 200);

            return false;
        },

        getCurrentPageNumber: function () {
            var currentBookpartId = this._readerView._currentBookpartId;
            if (!this._userbook.get('readingPositionCfi')) {
                return false; // EB-2667
            }
            var curCfi = JSON.parse(this._userbook.get('readingPositionCfi')).contentCFI;
            return this._userbook.getBookResource().getClosestPagebreak(currentBookpartId, curCfi);
        },

        pagePreviouslyBookmarked: function (currentPageNumber) {
            var bookmarkDivItems = $("div.bookmarksPanel").find(".physicalPageNumber");
            for (var i = 0; i < bookmarkDivItems.length; i++) {
                if (currentPageNumber === $(bookmarkDivItems[i]).text()) {
                    return true;
                }
            }
            return false;
        },

        animateLastAddedBookmark: function (currentPageNumber) {

            setTimeout(
                function (currentPageNumber) {
                    var bookmarkItems = $("div.bookmark");
                    for (var i = 0; i < bookmarkItems.length; i++) {
                        var bookmarkedPageNumber = $(bookmarkItems[i]).find(".physicalPageNumber").text();

                        if (currentPageNumber === bookmarkedPageNumber) {
                            $(bookmarkItems[i]).animate({ opacity: '0.05' }, 1000);
                            $(bookmarkItems[i]).animate({ opacity: '1' }, 2000);
                            break;
                        }
                    }
                }, 300, currentPageNumber);
        },

        deleteVisibleBookmarks: function () {
            this._visibleBookmarks.forEach(function (bookmark) {
                bookmark.delete();
            });
        },

        _updateBookmarkIndicator: _.debounce(function () {
            var readerView = this._readerView;

            if (!this._userbook) {
                return false;
            }

            var reader = readerView._readium.reader;
            var spine = reader.spine();
            if (!spine) {
                return false; // EB-2658, reader is not ready
            }
            var spineItem = spine.getItemById(readerView._currentBookpartId);

            this._visibleBookmarks = this._userbook.get('bookmarks').filter(function (bookmark) {
                if (bookmark.get('invalid')) {
                    return;
                }

                if (bookmark.get('bookpartId') !== readerView._currentBookpartId) {
                    // bookmark belongs to another chapter in the book.
                    return;
                }

                var $el = reader.getElementByCfi(spineItem.idref, bookmark.get('cfi'));
                if (!$el || $el.length < 1) {
                    // couldn't find an element by the cfi, just stop here.
                    return false;
                }

                var range = document.createRange();
                range.selectNode($el[0]);
                var el = range.getBoundingClientRect();
                range.detach();

                var $scrolledContentFrame = readerView.$el.find("#scrolled-content-frame");
                var top = $scrolledContentFrame.scrollTop();
                var bottom = top + $scrolledContentFrame.height();

                return (el.top > top && el.top < bottom) || (el.bottom > top && el.bottom < bottom);
            });

            var currentPageNumber = this.getCurrentPageNumber();

            if (currentPageNumber) {
                if (this.pagePreviouslyBookmarked(currentPageNumber)) {
                    this._readerView.$el.addClass('has-visibleBookmark');

                    if (this._app.get('mobileApp')) {   // Fill bookmark icon
                        $("span.icon.active.unfilled").removeClass("unfilled").addClass("filled");
                    }
                } else {
                    this._readerView.$el.removeClass('has-visibleBookmark');

                    if (this._app.get('mobileApp')) {   // Unfill bookmark icon
                        $("span.icon.active.filled").removeClass("filled").addClass("unfilled");
                    }
                }
            }
        }, 50),

        bookmarkDelete: function () {
            $('#enterBookmarkPanel').addClass('confirmation');
            return false;
        },

        bookmarkConfirmDeletion: function () {
            this.deleteVisibleBookmarks();
            this._readerView.$el.removeClass('show-enterBookmark-panel');
            $('#enterBookmarkPanel').removeClass('confirmation');
            return false;
        },

        bookmarkCancelDeletion: function () {
            $('#enterBookmarkPanel').removeClass('confirmation');
            return false;
        },

        /**
         * Open ordbokLightboxView
         */
        onOrdbokButtonClicked: function (e) {

            // Do not run click event on cordova app
            if (this._app.get('mobileApp') && e.type === "click") {
                return;
            }

            if (!this._app.get('isOnline')) {
                $(e.target).attr({ 'data-dialog-spawned': 1 });
                new DialogView({
                    'isAlert': true,
                    'title': 'Ordbok er ikke tilgjengelig',
                    'message': 'Ordbok er ikke tilgjengelig uten nettilgang. Prøv igjen når du er på tilkoblet internett.',
                    'btnText': 'OK'
                });
                return;
            }

            var ordbokHash = this._app.get('activeUser').get('ordbokHash');
            var languageOption = this._userbook.getBookResource().get('ordboklang');

            // Click handler is disabled in this.setUserBook()
            // This code is only for testing
            if (languageOption === 'none') {
                $(e.target).attr({ 'data-dialog-spawned': 1 });
                new DialogView({
                    'isAlert': true,
                    'title': 'Ordbok er ikke tilgjengelig',
                    'message': 'Denne boken støtter ikke ordbok.',
                    'btnText': 'OK'
                });
                return;
            }

            var doc = $('#reader iframe')[0];

            var r, sentence, word;
            if (this._app.get('mobileApp')) {
                r = this._readerView._selectionManager.getRange();
                try {
                    word = r.getText();
                } catch (error) {
                    console.log("Supress unknown fail");
                }
            }
            else {
                r = rangy.getSelection(doc).getRangeAt(0);
                sentence = $(r.startContainer).text();
                word = this._readerView._selectionManager.getOrdbokWord(sentence, r.startOffset, r.endOffset);
            }

            if (!languageOption) {
                languageOption = "nob-nob";
            }

            if (!word || word === " " || word === "") {
                $(e.target).attr({ 'data-dialog-spawned': 1 });
                new DialogView({
                    isAlert: true,
                    title: 'Ugyldig markering',
                    message: 'Ordboka kan ikke slå opp markeringen du gjorde. Gjør en ny markering.',
                    btnText: 'OK'
                });
                return;
            }

            if (this._app.get('mobileApp') || bowser.name === "iPhone" || bowser.name === "iPad") {
                this._readerView._selectionManager.deselect();
            }

            this._app.plausibleManager.sendEvent(
                this._userbook.get('publisherRef'),
                'Leseren',
                'Åpnet ordbok',
                this._userbook.getBookResource().get('title')
            );

            new ordbokLightboxView(ordbokHash, languageOption, word);

        },

        /**
         * Annotations-related functions
         */
        onAnnotationButtonClicked: function () {
            if (!this._readerView.$el.hasClass('show-annotationsEditor')) {
                this._readerView.closeDialogPopups();
            }

            if (this._app.get('activeUser').get('anonymous')) {
                this.showErrorMessage('Denne funksjonen er kun tilgjengelig når du er innlogget.');
                return;
            }

            if (this._userbook.get('annotationBeingEdited')) {
                this._userbook.set('annotationBeingEdited', null);
            } else {
                if (this._readerView._selectionManager.hasSelection()) {
                    this._readerView._annotationDialogShowing = true; //todo: move
                    var err = this._readerView._selectionManager.getError();
                    if (err) {
                        this.showErrorMessage('Obs! Prøv å markere teksten på nytt.');
                    } else {
                        var maxAnnotationLength = 1000;
                        if (this._readerView._selectionManager.getLength() > maxAnnotationLength) {
                            this.showErrorMessage('Du har markert for mye tekst - maks ' + maxAnnotationLength + ' tegn.');
                            return;
                        }
                        this.openAnnotationsEditor();
                        this._panelController._panels.annotationsEditor.$el.removeClass('has-errorMessage');
                        this._panelController._panels.annotationsEditor.createAndExpand();
                    }
                } else {
                    if (this._readerView._annotationDialogShowing) {
                        this._panelController._panels.annotationsEditor.close();
                        this._readerView._annotationDialogShowing = false;
                    } else {
                        this._readerView.$el.addClass('show-annotationDialog noSelect');
                        this._readerView._annotationDialogShowing = true;
                    }
                }
            }
            return false;
        },

        onAnnotationBeingEditedChanged: function (model, annotation) {
            var doc = $('iframe', this._readerView.$el).contents();
            doc.find('html > .highlight.active, html > .hover-highlight.active').removeClass('active');
            if (annotation) {
                var id = annotation.get('id');
                doc.find('html > .highlight[data-id="' + id + '"], html > .hover-highlight[data-id="' + id + '"]').removeClass('justJumpedTo').addClass('active');
                this._panelController._panels.annotationsEditor.setAnnotation(annotation);
                if (!this._readerView._annotationDialogShowing) {
                    this.openAnnotationsEditor();
                    this._readerView._annotationDialogShowing = true;
                }
            } else {
                this._panelController._panels.annotationsEditor.setAnnotation(null);
                if (!this._readerView._selectionManager.hasSelection()) {
                    this._readerView.$el.removeClass('show-annotationsEditor');
                    $('#annotation-widget-btn .icon').removeClass('active');
                }
            }

            window.setTimeout(function () {
                $("textarea.notefield").focus();
            }, 200);
        },

        /**
         * print icon related functions
         */
        onPrinterIconClicked: function (model, section) {
            if (section) {
                var me = this;
                var printView = new PrintView({
                    'section_id': section.section_id,
                    'base_url': me._userbook.getBookResource()._baseUrl
                }, this._userbook, this._app);
                $('body').append(printView.$el);
            }
        },

        /**
         * Open panels related functions
         */

        openTocPanel: function (e) {
            const me = this;
            this._panelController.showTocPanel(e);
            //her er det noe ukjent som tar bort focus fra input, må bruke timeout for at focus skal flyttes til input litt senere
            setTimeout(function () {
                me._panelController._panels.tocPanel.$el.find('input').focus();
            }, 200);
            return false;
        },

        openBookmarksPanel: function (e) {
            if (this._app.get('activeUser').get('anonymous')) {
                this.showErrorMessage('Denne funksjonen er kun tilgjengelig når du er innlogget.');
                return;
            }

            if ($('#list-bookmarks-btn .icon').hasClass('active')) {
                this._panelController.showBookmarksPanel(e);
                if (e && (e.type === 'keyup' || e.type === 'click')) {
                    this._panelController._panels.bookmarksPanel.$el.focus();
                }
            }
            return false;
        },

        openAnnotationsPanel: function (e) {
            if (this._app.get('activeUser').get('anonymous')) {
                this.showErrorMessage('Denne funksjonen er kun tilgjengelig når du er innlogget.');
                return;
            }

            if ($('#list-markings-btn .icon').hasClass('active')) {
                this._readerView._clickedAnnotation = false;
                this._panelController.showAnnotationsPanel(e);

                if (e && (e.type === 'keyup' || e.type === 'click')) {
                    this._panelController._panels.annotationsPanel.$el.find('#DOCX').focus();
                }
            }
            return false;
        },

        openAnnotationsEditor: function () {
            this._panelController.showAnnotationsEditor();
        },

        openSearchPanel: function (e) {
            this._panelController.showSearchPanel(e);
            return false;
        },

        openSettingsPanel: function (e) {
            this._panelController.showSettingsPanel(e);
            const me = this;
            //her er det noe ukjent som tar bort focus, må bruke timeout for at focus skal flyttes til input litt senere
            setTimeout(function () {
                if (e && (e.type === 'keyup' || e.type === 'click')) {
                    me._panelController._panels.settingsPanel.$el.find('#fontsize-slider').focus();
                }
            }, 200);
            return false;
        },

        /**
         * Goto functions
         */
        onGotoFromToc: function (href) {
            this._userbook.set('_stackedReadingPosition', this._userbook.get('readingPositionCfi'));
            showGoBackButton();
            this._readium.reader.openContentUrl(href);
            this._readerView.focusOnHeaderFromToc(href);
        },

        onGotoAnnotation: function (idref, cfi, annotationId) {
            var topbarView = this;
            this._userbook.set('_stackedReadingPosition', this._userbook.get('readingPositionCfi'));
            showGoBackButton();
            var annotation = this._userbook.get('annotations').where({ id: annotationId })[0];
            if (annotation) {
                if (!annotation.get('invalid')) {
                    if (this._readerView._annotationJustJumpedTo === annotation || this._userbook.get('annotationBeingEdited')) {
                        this._userbook.set('annotationBeingEdited', annotation);
                    } else {
                        // Only come here if annotationPanel will not be closed automatically
                        this._readerView._annotationJustJumpedTo = annotation;
                        setTimeout(function () {
                            var doc = topbarView._readerView.$el.find('iframe').contents();
                            var highlight = doc.find('html > .highlight[data-id="' + annotationId + '"], html > .hover-highlight[data-id="' + annotationId + '"]');
                            highlight.removeClass('justJumpedTo');
                            highlight[0].setAttribute('tabindex', '-1');
                            highlight[0].focus();
                            topbarView._readerView._annotationJustJumpedTo = null;
                        }, 1000);
                    }

                    this._readium.reader.openSpineItemElementCfi(idref, cfi);
                }

            } else {
                console.log('annotation undefined!');
            }
        },

        onGotoBookmark: function (idref, cfi) {
            var me = this;
            cfi = this._readerView.dropRedundantPartOfGivenCfi(cfi);
            this._userbook.set('_stackedReadingPosition', this._userbook.get('readingPositionCfi'));
            showGoBackButton();
            this._readium.reader.openSpineItemElementCfi(idref, cfi);
            window.setTimeout(function () {
                const doc = me._readerView.$el.find('iframe').contents();
                const cfiAry = cfi.split('/');
                let id = cfiAry[cfiAry.length - 1];
                id = id.substring(id.indexOf('[') + 1, id.indexOf(']'));
                if (id) {
                    const pagebreak = doc.find('#' + id);
                    pagebreak[0].setAttribute('tabindex', '-1');
                    pagebreak[0].focus();
                }
            }, 1000);
        },

        onGotoPlace: function (idref, cfi) {
            this._userbook.set('_stackedReadingPosition', this._userbook.get('readingPositionCfi'));
            showGoBackButton();
            this._readium.reader.openSpineItemElementCfi(idref, cfi);

            this._readerView.highlightSearchResult(idref, cfi);
        },

        onGotoResource: function (idref, cfi) {
            // TODO: fix better than try/catch
            try {
                this._readium.reader.openSpineItemElementCfi(idref, cfi);
                this._readerView.focusOnResourceFromResourcePanel(cfi);
            } catch (error) {
                console.log('CFI error', error);
            }
        },

        doSomethingCloudStatus: function (event) {
            this._userbook.getBookResource().on('change:downloadProgress', this.onDownloadProgressChanged, this);
            return this._userbook.doSomethingCloudStatus(event);
        },

        offlineDialog: function (e) {
            if (!this._app.get('isOnline')) {
                // TTS only available online
                $(e.target).attr({ 'data-dialog-spawned': 1 });
                new DialogView({
                    'isAlert': true,
                    'title': 'Du er frakoblet',
                    'message': 'Notater, bokmerker og markeringer vil synkroniseres med din brukerkonto automatisk når du har nettilgang.',
                    'btnText': 'OK'
                });
                return;
            }
        },

        closeOfflineDialog: function () {
            $('.offline-status-label').parent().fadeOut();
        },

        /**
         * Panelcontroller related
         */

        hidePanels: function () {
            this._panelController.hidePanel();
        },

        getActivePanel: function () {
            return this._panelController.getActivePanel();
        },

        /**
         * TTS related
         */
        onTTSButtonClicked: function (e) {
            if (!this._app.get('isOnline')) {
                // TTS only available online
                $(e.target).attr({ 'data-dialog-spawned': 1 });
                new DialogView({
                    'isAlert': true,
                    'title': 'Tekst til tale er ikke tilgjengelig',
                    'message': 'Tekst til tale er ikke tilgjengelig uten nettilgang. Prøv igjen når du er på tilkoblet internett.',
                    'btnText': 'OK'
                });
                return;
            }

            var doc = $('#reader iframe')[0];
            var selection = rangy.getSelection(doc);

            if (!this._readerView.$el.hasClass('show-TTSDialog')) {
                this._readerView.closeDialogPopups();
            }

            if (selection.rangeCount > 0 && (this._readerView._range = selection.getRangeAt(0)).text() !== '') {
                var err = this._readerView._selectionManager.getError();
                if (err) {
                    this.showErrorMessage('Obs! Prøv å markere teksten på nytt.');
                }
                else if (this._readerView._selectionManager.getLength() > 5000) {
                    this.showErrorMessage('Du har markert for mye tekst - maks 5000 tegn.');
                    return;
                }
                else {
                    this._readerView._TTSDialogShowing = true;
                    this._readerView.$el.addClass('show-TTSDialog');
                    this._readerView._selectionManager.deselect();
                    this._readerView._TTSEditor.open(this._readerView._range);
                }
            } else {
                if (this._readerView._TTSDialogShowing) {
                    this._readerView._TTSEditor.close();
                    this._readerView._TTSDialogShowing = false;
                } else {
                    this._readerView.$el.addClass('show-TTSDialog noSelect');
                    this._readerView._TTSDialogShowing = true;
                }
            }
            return false;
        },

        showErrorMessage: function (errorMessage) {
            var readerView = this._readerView.$el;
            var errorMessageEl = readerView.find('.errorMessage');
            errorMessageEl.stop(true, true);
            errorMessageEl.show().text(errorMessage);
            readerView.addClass('has-errorMessage');
            errorMessageEl.fadeOut(6000, function () {
                readerView.removeClass('has-errorMessage');
                readerView._TTSDialogShowing = false;
                readerView._annotationDialogShowing = false;
            });
        },

        onKeyUpMenuItem: function (e) {
            if (e.keyCode === 13) {
                // TODO cloudstatus button does not have an id, give id and move this code to switch statement
                if ($(e.currentTarget).hasClass('cloudStatus')) {
                    this._userbook.doSomethingCloudStatus(e);

                } else if ($(e.currentTarget).attr("id") === "annotation-widget-btn") {
                    this.onAnnotationButtonClicked();

                } else if ($(e.currentTarget).attr("id") === "TTS-widget-btn") {
                    this.onTTSButtonClicked();
                }
                else {
                    switch (e.currentTarget.getAttribute('id')) {
                        case 'goto-bookshelf-btn':
                            this.gotoBookshelf(e);
                            break;
                        case 'table-of-contents-btn':
                            this.openTocPanel(e);
                            break;
                        case 'list-bookmarks-btn':
                            this.openBookmarksPanel(e);
                            break;
                        case 'list-markings-btn':
                            this.openAnnotationsPanel(e);
                            break;
                        case 'search-btn':
                            this.openSearchPanel(e);
                            break;
                        case 'goBack-btn':
                            this.onGoBackButtonClicked(e);
                            break;
                        case 'bookmark-btn':
                            this.onBookmarkButtonClicked(e);
                            break;
                        case 'inc-fontsize-btn':
                            this._readerView.increaseFontsize(e);
                            break;
                        case 'dec-fontsize-btn':
                            this._readerView.decreaseFontsize(e);
                            break;
                        case 'cloudStatus':
                            this.doSomethingCloudStatus(e);
                            break;
                        case 'textlang-btn':
                            this.openSettingsPanel(e);
                            break;
                    }
                }
                e.stopPropagation();
            }
        }
    });
});

