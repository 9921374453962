/*global localStorage, console */

/**
 * @module webapp
 * @namespace  Syncers
 * @class  UserbookSyncer
 */

define('syncers/UserbookSyncer',['underscore', 'jquery', 'timer'], function (_, $, timer) {

    /**
     * A UserbookSyncer-object fetches and synchronizes data for a userbook
     *
     * The UserbookSyncer-object is initialized when the userbook is added to activeUsers userbook collection
     *
     * The UserbookSyncer-object listens to the bookResource::isDownloaded and userbook::isLoaded and schedules synchronization and polling
     *
     * When an app becomes online it fires the synchronization event for all registered channels
     *
     * The channels are registered if it is the active book in the reader or if it is downloaded on device
     *
     * @constructor
     * @class UserbookSyncer
     */
    function UserbookSyncer(userbook) {
        var me = this;
        this._userbook = userbook;
        this._storageKey = userbook.get('bookId') + '-' + userbook._user.get('userid');
        this._timestampLastSynced_annotationAndBookmarkData = undefined;
        this._timestampLastSynced_settings = undefined;

        userbook.get('annotations').on('add remove change', this.onAnnotationsChanged, this);
        userbook.get('bookmarks').on('add remove change', this.onBookmarksChanged, this);
        userbook.on('change:fontSize change:readingPositionCfi change:glossaryLang', this.onSettingsChanged, this);

        userbook.getBookResource().on('change:isDownloaded', this.scheduleSynchronization, this);
        userbook.on('change:isLoaded', this.scheduleSynchronization, this);
        userbook.on('change:isLoaded', this.sendBookOpenedStatistics, this);
        userbook.on('change:isLoaded', this.setupPolling, this);

        userbook._user._app.on('change:isOnline', this.sendWaitingOfflineStatistics, this);

        this._requestUpdate_settings_debounce = _.debounce(_.bind(this.requestUpdate_settings, this), 1000);
        this._requestUpdate_bookmarksAndAnnotations_debounce = _.debounce(_.bind(this.requestUpdate_bookmarksAndAnnotations, this), 1000);


        userbook.on('requestInitialData', function () {
            if (localStorage.getItem(me._storageKey)) {
                var localData = JSON.parse(localStorage.getItem(me._storageKey));
                userbook.onInitialData(localData);
            }
            if (!userbook._user.get('anonymous')) {
                me.requestUpdate_settings();
                me.requestUpdate_bookmarksAndAnnotations();
            }

        }, this);

        this.scheduleSynchronization();
    }

    UserbookSyncer.prototype = {

        /**
         * Removes the old synchronization scheme and sets a new one.
         *
         * Called every time there is a change in bookResource::isDownloaded or userbook::isLoaded
         *
         * @method scheduleSynchronization
         */
        scheduleSynchronization: function () {
            var me = this;
            var userbook = me._userbook;
            var interval = me.getSyncInterval();

            // remove old channels regardless
            timer.off('activeUser/' + userbook.get('bookId') + '/settings');
            timer.off('activeUser/' + userbook.get('bookId') + '/bookmarksAndAnnotations');
            if (interval && !userbook._user.get('anonymous')) {
                timer.on('activeUser/' + userbook.get('bookId') + '/settings', function () {
                    console.log('on activeUser/'  + userbook.get('bookId') + '/settings');
                    me._requestUpdate_settings_debounce();
                }, this, interval); // NOTE: interval must be heighter than the requestUpdate_settings debounce value

                timer.on('activeUser/' + userbook.get('bookId') + '/bookmarksAndAnnotations', function () {
                    console.log('on activeUser/'  + userbook.get('bookId') + '/bookmarksAndAnnotations');
                    me._requestUpdate_settings_debounce();
                    me._requestUpdate_bookmarksAndAnnotations_debounce();
                }, this, interval); // NOTE: interval must be heighter than the requestUpdate_bookmarksAndAnnotations debounce value
            }
        },

        /**
         * Called when the userbook:isLoaded changes
         *
         * @method  setupPolling
         *
         * if the book is not downloaded we need to poll the epubversion when reading the book  to find out if a new version has been published in bakveggen
         *
         */
        setupPolling: function () {
            var me = this;
            var userbook = me._userbook;
            timer.off('activeUser/activeUserbook/epubIdSeen');
            if (userbook.get('isLoaded') && ! userbook.getBookResource().get('isDownloaded')) {
                // Start polling
                timer.on('activeUser/activeUserbook/epubIdSeen', function () {
                    me.pollEpubIdSeen();
                }, this, 30000);
            }
        },
        /**
         * Depending on whether this is the active book and if it is downloaded, returns the interval for the book to be synchronized in
         * @method  getSyncInterval
         * @return {Integer} The interval for which this userbooks should be synchronized
         *
         */
        getSyncInterval: function () {
            var me = this;
            if (me._userbook.get('isLoaded')) {
                return 1000 * 60 * 10;
            }
            if (me._userbook.getBookResource().get('isDownloaded')) {
                return 1000 * 60 * 60;
            } else {
                return null;
            }
        },

        requestUpdate_settings: function () {
            var me = this;
            var model = this._userbook;
            var prepared = model.getPreparedData_settings();
            var timestamp = new Date().toISOString();
            $.ajax({
                method: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                timeout: 10000,
                url: model._user._app.get('backendServerUrl') + '/api/v1/publisher/' + encodeURIComponent(prepared.keys.publisherid) +
                    '/productid/' + encodeURIComponent(prepared.keys.productid) +
                    '/epub/' + encodeURIComponent(prepared.keys.epubid),
                data: JSON.stringify(prepared.data)
            }).done(function (data) {
                if (data && data.success) {
                    model.onRemoteChange_settings(data, me._timestampLastSynced_settings);
                    me._timestampLastSynced_settings = timestamp;
                }
            }).fail(function (err) {
                model._user._app.onBackendRequestFail(err);
            });
        },

        requestUpdate_bookmarksAndAnnotations: function () {
            var me = this;
            var model = this._userbook;
            var timestamp = new Date().toISOString();
            var prepared = model.getPreparedData_bookmarksAndAnnotations();

            // First time get all data (don't send timestamp)
            // then just ask for changelog
            var requestData = this._timestampLastSynced_annotationAndBookmarkData ?
                {
                    fromTimestamp: this._timestampLastSynced_annotationAndBookmarkData,
                    changelog: prepared.data
                }:
                prepared.data;
            $.ajax({
                method: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                timeout: 10000,
                url: model._user._app.get('backendServerUrl') + '/api/v1/publisher/' + encodeURIComponent(prepared.keys.publisherid) +
                    '/productid/' + encodeURIComponent(prepared.keys.productid) +
                    '/epub/' + encodeURIComponent(prepared.keys.epubid) + '/changelog',
                data: JSON.stringify(requestData)
            }).done(function (data) {
                if (data && data.success) {
                    model.onRemoteChange_bookmarksAndAnnotations(data, requestData.fromTimestamp);
                    me._timestampLastSynced_annotationAndBookmarkData = timestamp;
                    // not the perfect place to update ui
                    $('.offline-status-label').text('Synkronisert');
                    $('.offline-status-label').parent().fadeOut(2000);
                }
            }).fail(function (err) {
                model._user._app.onBackendRequestFail(err);
            });
        },


        /**
         * Called repeatedly while app is online and the associated userbook is loaded.
         * Currently not used in mobileApp-mode.
         *
         * @method pollEpubIdSeen
         */
        pollEpubIdSeen: function () {

            var userbook = this._userbook;
            // Epub = 0 fetches the current published epubid
            var url = userbook._user._app.get('backendServerUrl') + '/bookresource/publisher/' + encodeURIComponent(userbook.get('publisherRef')) +
                    '/book/' + encodeURIComponent(userbook.get('bookId')) + '/epub/0/mimetype';
            $.ajax({
                url: url,
                headers: {'Accept': 'json'},
                timeout: 5000,
                success: function (result, textStatus, request) {
                    var epubid = request.getResponseHeader('x-unibok-epubid');
                    if (epubid && !userbook.get('epubid')) {
                        console.log("Initializing epub id.");
                        userbook.set('epubid', epubid);
                    }
                    if (epubid && userbook.get('epubid') !== epubid) {
                        userbook._user.trigger('requestUpdate_bookshelf', {showLoadingSpinnerWhileSyncing: false});
                    }
                },
                error: function (xhr/*, status, errorThrown*/) {
                    if (xhr.status === 403) {
                        // Two cases: user logged out or use has license but epub not published.
                        var result = xhr.responseJSON;
                        if (result && result.hasOwnProperty('published') && result.published === false) {
                            userbook.trigger('epubunaccessible');
                        } else {
                            if (result && result.message === 'Not authorized') {
                                // User has been logged out. Could be expired session,
                                // cleared cookie or manual logout in second browser tab
                                userbook.trigger('usersessionlost');
                            }
                        }
                        return;
                    }
                    console.log("Could not poll book info.");
                }
            });
        },

        onSettingsChanged: function () {
            this._fontSizeDirty = true; // Simplified
            this._readingPositionCfiDirty = true;
            this._glossaryLangDirty = true;

            this._updateLocalStorage();
            if (! this._userbook._user.get('anonymous')) {
                this._requestUpdate_settings_debounce();
            }
        },

        onBookmarksChanged: function () {
            this._updateLocalStorage();
            if (! this._userbook._user.get('anonymous')) {
                this._requestUpdate_bookmarksAndAnnotations_debounce();
            }
        },

        onAnnotationsChanged: function () {
            this._updateLocalStorage();
            if (! this._userbook._user.get('anonymous')) {
                this._requestUpdate_bookmarksAndAnnotations_debounce();
            }
        },

        /**
         * We are logging how often users are opening books
         * If app is online, sendBookOpenedStatistics sends request with statistics to server immediately
         * If not, the statistics data is stored in localStorage, with key "_storageKey" + _offlineStatistics
         *
         * @method sendBookOpenedStatistics
         */
        sendBookOpenedStatistics: function (userbook, isLoaded) {
            var me = this;
            var isOnline = userbook._user._app.get('isOnline');
            if (isLoaded) {
                var bookresource = userbook.getBookResource();
                if (bookresource) {
                    if (isOnline) {
                        $.ajax({
                            url: userbook._user._app.get('backendServerUrl') + '/api/v1/stats/openbook',
                            type: "POST",
                            data: {
                                publisherid: bookresource.get('publisherRef'), // 'aschehoug'
                                productid: bookresource.get('bookId'), // 'produkt-101'
                                epubid: bookresource.get('epubid') // 123
                            }
                        });
                    } else {
                        var unsentStatistics = localStorage.getItem(me._storageKey + '_offlineStatistics') ?
                            JSON.parse(localStorage.getItem(me._storageKey + '_offlineStatistics')) :
                            [];
                        unsentStatistics.push({
                            type: "POST",
                            url: userbook._user._app.get('backendServerUrl') + '/api/v1/stats/openbook',
                            data: {
                                publisherid: bookresource.get('publisherRef'), // 'aschehoug'
                                productid: bookresource.get('bookId'), // 'produkt-101'
                                epubid: bookresource.get('epubid'), // 123
                                client_timestamp : new Date().toISOString()
                            }
                        });

                        localStorage.setItem(me._storageKey + '_offlineStatistics', JSON.stringify(unsentStatistics));

                    }
                }
            }
        },

       /**
        * Statistics gathered while offline are sent when the app go online
        * @method  sendWaitingOfflineStatistics
        * @param  {[type]}  userbook [description]
        * @param  {Boolean} isOnline [description]
        * @return {[type]}           [description]
        */
        sendWaitingOfflineStatistics: function (userbook, isOnline) {
            var me = this, waitingEvents;
            if (isOnline) {
                waitingEvents = localStorage.getItem(me._storageKey + '_offlineStatistics');
                if (waitingEvents !== null) {
                    waitingEvents = JSON.parse(waitingEvents);
                    waitingEvents.forEach(function(event) {
                        $.ajax({
                            url: event.url,
                            type: event.type,
                            data: event.data
                        });
                    });
                }
                localStorage.removeItem(me._storageKey + '_offlineStatistics');
            }
        },

        // TODO: split into seperate storage keys for settings and bookmarks and annotations ?
        // TODO: should get prepared data from userbook?
        _updateLocalStorage: _.debounce(function () {
            // NOTE: Dette er brute-force kode.
            var userbook = this._userbook;
            var annotationsData = userbook.get('annotations').map(function (annotation) {
                return annotation.getJson();
            });
            var bookmarksData = userbook.get('bookmarks').map(function (bookmark) {
                return bookmark.getJson();
            });

            var data = {
                annotations: annotationsData,
                bookmarks: bookmarksData,

                deletedAnnotations: userbook._deletedAnnotations,
                deletedBookmarks: userbook._deletedBookmarks,

                fontSize: userbook.get('fontSize'),
                readingPositionCfi: userbook.get('readingPositionCfi'),
                glossaryLang: userbook.get('glossaryLang')
            };
            // Silently ignore errors. Cannot check exception type here to be
            // cross-browser-compatible.
            // http://chrisberkhout.com/blog/localstorage-errors/
            try {
                localStorage.setItem(this._storageKey, JSON.stringify(data));
            } catch (e) {
            }
        }, 500),

        destroy: function () {
            this._userbook.get('annotations').on(null, null, this);
            this._userbook.get('bookmarks').off(null, null, this);
            this._userbook.off(null, null, this);
            timer.off(null, null, this);
        }
    };

    return UserbookSyncer;
});

