/**
 * @module webapp
 * @namespace  Models
 * @class  MarginIndicatorBase
 */
define('models/MarginIndicatorBase',[],function() {
    var MarginIndicatorBase = function (yPos, pageIndex, marginManager) {
        this._marginManager = marginManager;
        this.yPos = yPos;
        this.pageIndex = pageIndex;
    };

    MarginIndicatorBase.prototype.renderContainer = function() {
        if (this._container) {
            this._container.render();
        }
    };

    MarginIndicatorBase.prototype.render = function() {};

    return MarginIndicatorBase;
});

