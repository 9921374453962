define('templates/userbook',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (bookId, cloudStatus, level, mobileApp, offlineApp, subject, thumbnail, title, url) {
buf.push("<div" + (jade.attr("data-bookid", bookId, true, false)) + (jade.cls(['book',mobileApp ? 'mobileApp ' + cloudStatus : '',offlineApp ? 'offlineApp ' + cloudStatus : ''], [null,true,true])) + "><div class=\"wrapper\"><div class=\"bookinfo\"><h3 class=\"tittel\">" + (jade.escape((jade_interp = title) == null ? '' : jade_interp)) + "</h3><p class=\"fag\">" + (jade.escape((jade_interp = subject) == null ? '' : jade_interp)) + "</p><p class=\"trinn\">" + (jade.escape((jade_interp = level) == null ? '' : jade_interp)) + "</p></div><a" + (jade.attr("href", url, true, false)) + " target=\"_self\" class=\"omslag\">");
if ( thumbnail)
{
buf.push("<img" + (jade.attr("src", thumbnail, true, false)) + (jade.attr("alt", title, true, false)) + "/>");
}
else
{
buf.push("<img src=\"images/default_cover_thumbnail.png\"" + (jade.attr("alt", title, true, false)) + " class=\"default\"/>");
}
buf.push("<div class=\"progress\"><div class=\"progress-bar\"></div></div></a>");
if ( mobileApp || offlineApp)
{
buf.push("<div class=\"statusheader\">");
if ( cloudStatus == 'noLicence')
{
buf.push("<div title=\"Ingen lisens\" class=\"icon\"></div>");
}
else if ( cloudStatus == 'isDownloading')
{
buf.push("<div class=\"btn-download download-progress\"></div>");
}
else if ( cloudStatus == 'isNotDownloaded')
{
buf.push("<button aria-label=\"Gjør boka tilgjengelig frakoblet\" class=\"btn-download\"></button>");
}
else if ( cloudStatus == 'newVersionAvailable')
{
buf.push("<button title=\"Ny versjon tilgjengelig\" class=\"btn-update icon\"></button>");
}
else if ( cloudStatus == 'isDirty')
{
buf.push("<div title=\"Bør synkroniseres\" class=\"icon\"></div>");
}
else if ( cloudStatus == 'isUnpacking')
{
buf.push("<div title=\"Pakker ut\" class=\"icon\"></div>");
}
else if ( cloudStatus == 'isRemoving')
{
buf.push("<div title=\"Sletter...\" class=\"icon\"></div>");
}
else
{
buf.push("<div title=\"Tilgjengelig offline\" class=\"icon\"></div>");
}
buf.push("</div>");
}
if ( cloudStatus == 'isDownloaded' || cloudStatus == 'isDownloading' || cloudStatus == 'newVersionAvailable')
{
buf.push("<div class=\"remove-download\"><button class=\"btn-remove-download\">Fjern boka</button></div>");
}
buf.push("</div></div>");}.call(this,"bookId" in locals_for_with?locals_for_with.bookId:typeof bookId!=="undefined"?bookId:undefined,"cloudStatus" in locals_for_with?locals_for_with.cloudStatus:typeof cloudStatus!=="undefined"?cloudStatus:undefined,"level" in locals_for_with?locals_for_with.level:typeof level!=="undefined"?level:undefined,"mobileApp" in locals_for_with?locals_for_with.mobileApp:typeof mobileApp!=="undefined"?mobileApp:undefined,"offlineApp" in locals_for_with?locals_for_with.offlineApp:typeof offlineApp!=="undefined"?offlineApp:undefined,"subject" in locals_for_with?locals_for_with.subject:typeof subject!=="undefined"?subject:undefined,"thumbnail" in locals_for_with?locals_for_with.thumbnail:typeof thumbnail!=="undefined"?thumbnail:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined,"url" in locals_for_with?locals_for_with.url:typeof url!=="undefined"?url:undefined));;return buf.join("");
}

});
