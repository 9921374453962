/*global console*/

/**
 * @module webapp
 * @namespace  Models
 * @class  Bookmark
 */

define('models/Bookmark',['backbone', 'underscore'], function (Backbone, _) {

    function generateCfiSortkey(cfi) {
        return cfi.replace(/\[.+?\]/g, '').replace(/\d+/g, function (m) {return ('00000' + m).substr(m.length);});
    }

    function stripCfiForSpatialOffset(cfi) {
        return cfi.replace(/@\d+:\d+$/, '');
    }

    /*
    data:

    id
    bookpartId
    cfi

    */
    return Backbone.Model.extend({
        initialize: function (data, options) {
            this._userbook = options.userbook;
            this.cfiSortkey = generateCfiSortkey(data.cfi);
        },

        delete: function () {
            this._userbook.deleteBookmark(this);
        },

        isLocal: function () {
            return this.get('local') ? true: false;
        },

        validateAndRepair: function () {
            var me = this;
            // old bookmark cfis have spatial offset, this is a syntax error in the cfi library in newer
            // versions of the library. Stripping spatial offset to prevent client errors.
            this.set('cfi', stripCfiForSpatialOffset(this.get('cfi')));

            var bookResource = this._userbook.getBookResource();
            var idref = this.get('bookpartId');

            if (!idref || !bookResource._spine.checkIdrefExists(idref)) {
                me.set({'invalid': true});
                return;
            }

            bookResource.validateCfi(idref, this.get('cfi'), true)
                .then(function (isValid) {
                    if (!isValid) {
                        /*
                         * The current cfi is _not_ valid according to Readium. We'll use the closest page to
                         * anchor the bookmark. All the page's anchors are allready calculated in the preproccess
                         * so we'll just search for the page and update the cfi and bookpartId.
                         */

                        var PAGE_NUMBER_INDEX = 1;
                        var CFI_INDEX = 0;
                        var pageNumber = me.get('physicalPageNumber');
                        var findPagePredicate = function (item) {
                            return item[PAGE_NUMBER_INDEX] === pageNumber;
                        };

                        var spineItems = bookResource.getSpineItems();

                        for (var i = 0; i < spineItems.length; i++) {

                            var index = _.findIndex(spineItems[i].physicalPages, findPagePredicate);

                            if (index !== -1) {
                                var page = spineItems[i].physicalPages[index];
                                me.set('cfi', page[CFI_INDEX]);
                                me.set('bookpartId', spineItems[i].idref);
                                isValid = true;
                                break;
                            }
                        }
                    }
                    me.set({'invalid': !isValid});
                });
        },

        getJson: function () {
            var data = this.toJSON();
            return data;
        }
    });
});

