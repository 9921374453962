/*global define, localStorage, location, console */

/**
 *
 * @module webapp
 * @namespace  Syncers
 * @class  UserSyncer
 */

define('syncers/UserSyncer',['jquery', 'underscore', 'timer'], function ($, _, timer) {

    function UserSyncer(user) {
        var me = this;

        this._user = user;
        this._storageKey = user.get('userid');

        user.on('change:termsAccepted change:sortOption change:language change:keyboardShortcuts', this.onDataChanged, this);
        user.get('userbooks').on('add remove change', this.onDataChanged, this);

        /* We can force updating of bookshelf-data by trigger the 'requestInitialData' in the user model*/
        user.on('requestUpdate_bookshelf', function(data){
            if (data && data.showLoadingSpinnerWhileSyncing) {
                $('body').addClass('blocking');
                this.requestUpdate_bookshelf(function(){
                    $('body').removeClass('blocking');
                });
            } else {
                this.requestUpdate_bookshelf();
            }
        }, this);

        this._requestUpdate_settings_debounce = _.debounce(_.bind(this.requestUpdate_settings, this), 500);
        this._updateLocalStorage = _.debounce(this._updateLocalStorage, 10);

        this._onRequestInitialData = _.debounce(function () {
            if (localStorage.getItem(me._storageKey)) {
                var localData = JSON.parse(localStorage.getItem(me._storageKey));

                user.onRemoteChange_settings({
                    language: localData.language,
                    sortOption: localData.sortOption,
                    publisherTermsAccepted: localData.publisherTermsAccepted,
                    termsAccepted: localData.termsAccepted,
                    organizationName: localData.organizationName,
                    name: localData.name,
                    ordbokHash: localData.ordbokHash,
                    keyboardShortcuts: localData.keyboardShortcuts,
                });
                if (!user._app.attributes.isOnline && (user._app.attributes.offlineApp || user._app.attributes.mobileApp)) {
                    user.setInitialBookshelfData(localData.userbooks);
                }
            } else {
                me.requestUpdate_settings();
            }

            me.requestUpdate_bookshelf();

            // Start timer unless anonymous users
            if (! user.get('anonymous')) {

                timer.on('user/settings', function () {
                    me._requestUpdate_settings_debounce();
                }, this, 1000 * 60 * 5);

            }

            /* But anonymous user also needs fresh bookshelves */
            timer.on('user/bookshelf', function () {
                me.requestUpdate_bookshelf();
            }, this, 1000 * 60 * 60 * 24);
        }, 10);
        user.on('requestInitialData', this._onRequestInitialData, this);


    }

    UserSyncer.prototype = {
        //requestUpdate_settings: _.debounce(function () {
        requestUpdate_settings: function () {
            var model = this._user;

            var language = model.get('language');
            var sortOption = model.get('sortOption');
            var ordbokHash = model.get('ordbokHash');
            var termsAccepted = model.get('termsAccepted');
            var keyboardShortcuts = model.get('keyboardShortcuts');
	    
            var data = {
                language: language,
                sortOption: sortOption,
                ordbokHash: ordbokHash,
                keyboardShortcuts: keyboardShortcuts,
	    };

	    // Send bare termsAccepted hvis vi har en boolsk verdi, HACK og den er sann
	    if (termsAccepted === true) {
		data.termsAccepted = termsAccepted;
	    }
	    
            $.ajax({
                method: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                timeout: 10000,
                url: model._app.get('backendServerUrl') + '/api/v1/userdata',
                data: JSON.stringify(data)
            }).done(function (data) {
                if (data && data.success && data.user) {
                    model.onRemoteChange_settings({
                        language: data.user.language,
                        sortOption: data.user.properties.sortOption,
                        publisherTermsAccepted: data.user.properties.publisherTermsAccepted,
                        termsAccepted: data.user.properties.termsAccepted,
                        organizationName: data.user.organizationName,
                        name: data.user.properties.feideAttribs.cn,
                        ordbokHash: data.user.properties.ordbokHash,
                        keyboardShortcuts: data.user.properties.keyboardShortcuts,

                    });
                    //callback();
                } else {
                    //callback(new Error(data.message));
                }
            }).fail(function (err/*, res*/) {
                model._app.onBackendRequestFail(err);
            });
        },
        //}, 0),

        requestUpdate_bookshelf: function (cb) {
            var model = this._user;
            if(model.get('previewMode')) {
                if(cb) {
                    cb();
                }
                return;
            }
            if (location.search.match(/\btestdata=yes\b/)) {
                $.getJSON('/js/testdata/testdata-actual.json', function (testdata) {
                    //_processData(testdata);
                    model.onRemoteChange_bookshelf(testdata);
                });

            } else {
                $.ajax({
                    dataType: 'json',
                    timeout: 30000,
                    url: this._user._app.get('backendServerUrl') + '/get-shelf',
                    data: {
                        debug: location.search.match(/\bdebug=1\b/) ? '1' : '0'
                    }
                }).done(function (data) {
                    if (data && data.result) {
                        model.onRemoteChange_bookshelf(data.result);
                        if(cb){cb();}
                    }
                }).fail(function (err) {
                    model._app.onBackendRequestFail(err);
                });
            }
        },

        onDataChanged: function () {
            this._updateLocalStorage();
            if (! this._user.get('anonymous')) {
                this._requestUpdate_settings_debounce();
            }
        },

        _updateLocalStorage: function () {
            var user = this._user;
            var userbooksData = user.get('userbooks').map(function (userbook) {
                return {
                    bookId: userbook.get('bookId'),
                    publisherRef: userbook.get('publisherRef'),
                    noLicence: userbook.get('noLicence')
                };
            });
            localStorage.setItem(this._storageKey, JSON.stringify({
                userbooks: userbooksData,

                language: user.get('language'),
                sortOption: user.get('sortOption'),

                publisherTermsAccepted: user.get('publisherTermsAccepted'),
                termsAccepted: user.get('termsAccepted'),
                organizationName: user.get('organizationName'),
                name: user.get('name'),
                ordbokHash: user.get('ordbokHash'),
                keyboardShortcuts: user.get('keyboardShortcuts'),
            }));

        },

        destroy: function () {
            this._user.get('userbooks').off(null, null, this);
            this._user.off(null, null, this);
            timer.off(null, null, this);
        }
    };


    return UserSyncer;
});

