/*global window, document, SpeechSynthesisUtterance */
define('views/TTSEditor',['jquery', 'backbone', 'views/DialogView', 'Config', 'templates/TTSEditor', 'TextToSpeechManager'], function ($, Backbone, DialogView, clientConfig, mainTemplate, TextToSpeechManager) {

    function createHighlightDiv(range, leftOffset) {
        var counter = 0;
        while (true) {
            if (['\n', '\t'].indexOf(range.toString().substr(range.toString().length - 1, 1)) !== -1) {
                range.moveEnd('character', -2);
                range.moveEnd('character', 2);
            }
            if (['\n', '\t'].indexOf(range.text().substr(range.text().length - 1, 1)) !== -1) {
                range.moveEnd('character', -1);
            }
            else {
                break;
            }

            counter++;
            if (counter > 1000) {
                throw new Error('Creating highlight div for TTS text-to-speech have failed: infinit loop.');
            }
        }

        var rect = range.getBoundingClientRect();
        var div = $('<div id="TTSHighlightPre">');
        div.css('position', 'absolute');
        div.css('background-color', 'grey');
        div.css('opacity', '0.1');
        div.css('pointer-events', 'none');
        div.css('top', rect.top + 'px');
        div.css('height', rect.height + 'px');
        div.css('left', leftOffset + rect.left);
        div.css('width', rect.width + 'px');

        return div;
    }

    return Backbone.View.extend({
        initialize: function (options) {
            var me = this;
            this._readerView = options.readerView;
            this._app = options.readerView._app;
            this.synth = window.speechSynthesis;
            this._selectedSpeed = 1;
            this.textToSpeechManager = new TextToSpeechManager(this._app.get('backendServerUrl'));

            this.render();
            this._readerView.$el.append(this.$el);
        },

        events: {
            'click .btn-TTS': 'play',
            'click .btn-close': 'close',
            'change #voiceList': 'chooseVoice',
        },

        render: function () {
            this.setElement(mainTemplate());
            this.voiceList();
            return this;
        },

        init: function () {
            // TODO: placeholder if we want to set language from user settings
            this._userbook = this._app.get('activeUser').get('activeUserbook');
            this._language = this._app.get('activeUser').get('language') || 'nob';
            this._bookLanguage = this._userbook.getBookResource().get('language');
            this._ordboklang = this._userbook.getBookResource().get('ordboklang');
        },

        voiceList: function () {
            var me = this;
            $('#voiceList').empty();
            this.textToSpeechManager.getCereprocVoices().then(voiceList => {
                const langMap = {
                    'nb': 'bokmål 🇳🇴',
                    'nn': 'nynorsk 🇳🇴',
                    'rp': 'engelsk 🇬🇧',
                    'hd': 'tysk 🇩🇪',
                    'fr': 'fransk 🇫🇷',
                    'ca': 'spansk 🇪🇸'
                };
                voiceList.forEach(voice => {
                    $('#voiceList').append($('<option></option>').val(voice.name).html(voice.name + ' - ' + langMap[voice.accent_code]));
                });
            });
        },

        chooseVoice: function () {
            var target = $('#voiceList');
            var selected = target.find(':selected').val();
            this.textToSpeechManager.setVoice(target.val());
        },

        playCereproc: function (textRange) {
            var me = this;
            $("div.audiojs").addClass("playing");
            $('.btn-TTS').addClass("loading");
            const selectedVoice = me.textToSpeechManager.getVoice() || $('#voiceList').first().val();
            me.textToSpeechManager._speeking = true;
            me.textToSpeechManager.playCereproc(textRange, selectedVoice, function (e) {
                if (e) {
                    console.log(e, 'Failed to play cereproc, fallback to speech-synth');
                    me.playSpeechSynth(textRange.toString());
                    me.sendPlausibleEvent("SpeechSynth");
                } else {
                    me.sendPlausibleEvent(selectedVoice);
                }
                $('.btn-TTS').removeClass("loading");
                me.close();
            });
        },

        playSpeechSynth: function (text) {
            var utterance = new SpeechSynthesisUtterance(text);
            utterance.lang = "nb-NO";
            this.synth.cancel();
            this.synth.speak(utterance);
            this.close();
        },

        play: function () {
            var textRange = this._readerView._range;
            this.playCereproc(textRange);
        },

        sendPlausibleEvent: function (voice) {
            var publisher = this._userbook.get('publisherRef');
            var book_title = this._userbook.getBookResource().get('title');
            this._app.plausibleManager.sendEvent(
                publisher,
                "Leseren",
                "Tekst-til-tale - " + voice,
                book_title,
            );
        },

        open: function (range) {
            $('#TTS-widget-btn').find('.icon').addClass('active');
            $('.dialog-TTS').removeAttr('hidden');

            var clone = range.cloneRange();
            var div = createHighlightDiv(clone, this.textToSpeechManager.getLeftOffset());
            this._markedHighlightDiv = div;

            var doc = $('div#reader').find('iframe')[0];
            $(doc.contentDocument).find('html').append(div);
        },

        close: function () {
            $('div#reader').removeClass('show-TTSDialog noSelect');
            $('#TTS-widget-btn').find('.icon').removeClass('active');
            $('.dialog-TTS').attr('hidden', 'hidden');
            $(this).detach();

            if (!this.textToSpeechManager.isSpeeking()) {
                this.textToSpeechManager.clear();
            }
        },

        closeAndStop: function () {
            this.textToSpeechManager.clear();
            this.close();
        }
    });
});

