/*global console */

define('buildSearchmapWithSax',['thirdparty/sax'], function (sax) {

    /**
     * Put description of class here.
     *
     * @class TagInfo
     */
    function TagInfo() {
        this.childOpeningTags = [];
        // parent
        // childCnt
    }
    TagInfo.prototype = {
        addChild: function (childTag) {
            this.childOpeningTags.push(childTag);
        },

        get endOffset () {
            return this.offset + this.length;
        },

        get elementCfi () {
            var cfi = '';
            if (this.typ !== 'closing') {
                var parent = this;
                while (parent) {
                    var assertion = parent.id ? '[' + parent.id + ']' : '';
                    cfi = '/' + ((parent.siblingIndex + 1) * 2) + assertion + cfi;
                    parent = parent.parent;
                }
            }
            return cfi;
        },

        /**
         * Returns true if otherElementCfi matches this.elementCfi.
         * Assertions are optional in otherElementCfi.
         */
        testElementCfi: function (otherElementCfi) {
            var result = true;
            if (this.typ !== 'closing') {
                var parent = this;
                var otherCfiParts = otherElementCfi.split('/');
                otherCfiParts.shift(); // remove first elem ''
                while (parent && otherCfiParts.length) {
                    var part = otherCfiParts.pop();
                    if (part.indexOf('[') !== -1) {
                        // NOTE: parent.id can be 'undefined', which is OK.
                        if (part !== (parent.siblingIndex + 1) * 2 + '[' + parent.id + ']') {
                            result = false;
                            break;
                        }
                    } else {
                        if ((parent.siblingIndex + 1) * 2 !== parseInt(part, 10)) {
                            result = false;
                            break;
                        }
                    }
                    parent = parent.parent;
                }
                if (result === true && (otherCfiParts.length || parent)) {
                    result = false;
                }
            }
            return result;
        }
    };

    function buildSearchmapWithSax(xhtmlString, keepAttributes) {
        var didWrapInHtmlTag = false;
        if (xhtmlString.indexOf('xmlns="http://www.w3.org/1999/xhtml"') === -1) {
            xhtmlString = '<html>' + xhtmlString + '</html>';
            didWrapInHtmlTag = true;
        }

        var me = this;
        var map = [];
        var textFragments = [];
        var mathExpressions = [];
        var openingTagStack = [];
        var parent;

        var lastParent = null;
        var lastTag = null;
        var topLevel = {
            childCnt: 0
        };

        var ignore = true;
        var mathExpressionsStartedAtPosition; // når vi er inne it matteuttrykk, husk hvor det begynte

        var strict = true;
        var parser = sax.parser(strict);
        var localOffsetAdjust = 0;
        var localOffset = 0; // this will increase each time we have appended to textFragments


        function getAdjustedOffset() {
            return parser.startTagPosition - 1;
        }

        function getTagLength() {
            return parser.position - parser.startTagPosition + 1;
        }

        parser.onerror = function (e) {
            // an error happened.
            console.log('ERROR', e);
        };
        parser.ontext = function (t) {
            if (!ignore) {
                textFragments.push(t);
                localOffset += t.length;
            }
        };
        parser.onopentag = function (node) {
            if (node.name === 'html') {
                localOffsetAdjust = parser.position;
                ignore = false;
                return;
            }
            if (ignore) {
                return;
            }
            
            if (node.name === 'style') {
                ignore = true;
            }

            if (node.name === 'span') {
                if (node.attributes.class && node.attributes.class.match('mjpage')) {
                    // mathExpressionsStartedAtPosition = textFragments.join('').length;
                    mathExpressionsStartedAtPosition = localOffset;
                    
                }
            }


            var tagInfo = new TagInfo();
            var parent  = null;
            if (openingTagStack.length) {
                parent = openingTagStack[openingTagStack.length - 1];
            }

            if (node.isSelfClosing) {
                tagInfo.typ = 'selfclosing';
                if (parent) {
                    tagInfo.siblingIndex = parent.childCnt;
                    parent.childCnt++;
                } else {
                    tagInfo.siblingIndex = topLevel.childCnt;
                    topLevel.childCnt++;
                }
                tagInfo.closingTag = tagInfo;
                tagInfo.openingTag = tagInfo;
            } else {
                tagInfo.typ  = 'opening';

                if (parent) {
                    tagInfo.siblingIndex = parent.childCnt;
                    parent.childCnt++;
                } else {
                    tagInfo.siblingIndex = topLevel.childCnt;
                    topLevel.childCnt++;
                }
                tagInfo.childCnt = 0;
                openingTagStack.push(tagInfo);
            }


            if (node.attributes.id) {
                tagInfo.id = node.attributes.id;
            }

            if (node.attributes['epub:type'] === 'pagebreak') {
                var pagenumber = node.attributes.title;
                if (pagenumber) {
                    tagInfo.pagenumber = parseInt(pagenumber, 10);
                } else {
                    // something wrong. TODO raise error?
                }
            }

            tagInfo.parent = parent;
            if (parent) {
                parent.addChild(tagInfo);
            }
            tagInfo.offset = getAdjustedOffset();
            tagInfo.length = getTagLength();
            tagInfo.localOffset = localOffset;
            tagInfo.index = map.length;
            tagInfo.debug_tagname = node.name;
            if (lastTag) {
                tagInfo.prev = lastTag;
                lastTag.next = tagInfo;
            }
            if (keepAttributes) {
                tagInfo.attrs = node.attributes;
            }

            map.push(tagInfo);
            lastTag = tagInfo;
        };
        parser.onclosetag = function (tag) {
            var tagname = tag ? tag.name : null;
            if (tagname === 'html') {
                ignore = true;
            }
            if (tagname === 'style') {
                ignore = false;
            }

            var node = tag;
            if (node.attributes.class && node.attributes.class.match('mjpage')) {
                // mathExpressions.push([mathExpressionsStartedAtPosition, textFragments.join('').length]);
                mathExpressions.push([mathExpressionsStartedAtPosition, localOffset]);
                
                mathExpressionsStartedAtPosition = undefined;
            }

            if (ignore) {
                return;
            }
            if (parser.tag.isSelfClosing) {
                // Do nothing. We allready processed this tag in onopentag
            } else {
                var tagInfo = new TagInfo();
                tagInfo.typ = 'closing';
                var poppedTag = openingTagStack.pop();

                poppedTag.closingTag = tagInfo;
                tagInfo.openingTag = poppedTag;
                tagInfo.offset = getAdjustedOffset();
                tagInfo.length = getTagLength();
                tagInfo.localOffset = localOffset;
                tagInfo.index = map.length;
                tagInfo.prev = lastTag;
                lastTag.next = tagInfo;
                map.push(tagInfo);
                lastTag = tagInfo;
            }
        };
        parser.onend = function () {
        };

        parser.write(xhtmlString).close();

        // adjust offsets (needed for some unittests)
        if (didWrapInHtmlTag) {
            xhtmlString = xhtmlString.substr(6, xhtmlString.length - 6 - 7);
            map.forEach(function (tagInfo) {
                tagInfo.offset -= 6;
            });
        } else {
            var m = xhtmlString.match(/(<html\b[\s\S]*?>)([\s\S]*?)<\/html>/);
            if (!m) {
                throw new Error('Could not parse xhtml');
            }
            var delta = m.index + m[1].length;
            xhtmlString = m[2];
            map.forEach(function (tagInfo) {
                tagInfo.offset -= delta;
            });
        }
        return {
            xhtmlString: xhtmlString,
            cleanText: textFragments.join(''),
            map: map,
            mathExpressionPositions: mathExpressions
        };
    }

    return buildSearchmapWithSax;

});

