define('templates/publisherTermsBox',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (pubTermsAschehoug, pubTermsCapdamm) {
buf.push("<div role=\"dialog\" aria-labelledby=\"dialog-title\" aria-describedby=\"terms-intro\" class=\"publisher-terms-box terms-modal\"><div class=\"modal-header\"><h2 id=\"dialog-title\">Din konto</h2><button type=\"button\" title=\"lukk dialog\" class=\"close-btn close-terms-modal\"></button></div><div class=\"modal-body\"><p id=\"terms-intro\" class=\"terms-intro\">Unibok tilbyr bøker fra flere forlag. For å se bøker du har tilgang til, kan du knytte kontoen din til forlagene under. Dette trenger du bare å gjøre en gang.</p><form action=\"/samtykke\" method=\"post\" target=\"_self\" class=\"terms-container\"><div" + (jade.cls(['terms-box','terms-aco',(pubTermsAschehoug ? 'terms-accepted' : '')], [null,null,true])) + "><h3>Aschehoug</h3>");
if ( pubTermsAschehoug)
{
buf.push("<p>Konto tilknyttet</p>");
}
else
{
buf.push("<p>Knytt kontoen din til Aschehoug Undervisning for å bruke bøkene deres.</p><input name=\"submit_aschehoug\" type=\"submit\" value=\"Knytt til\" class=\"terms-link btn-primary\"/>");
}
buf.push("</div><div" + (jade.cls(['terms-box','terms-cdu',(pubTermsCapdamm ? 'terms-accepted' : '')], [null,null,true])) + "><h3>Cappelen Damm</h3>");
if ( pubTermsCapdamm)
{
buf.push("<p>Konto tilknyttet</p>");
}
else
{
buf.push("<p>Knytt kontoen din til Cappelen Damm Undervisning for å bruke bøkene deres.</p><input name=\"submit_cappelendamm\" type=\"submit\" value=\"Knytt til\" class=\"terms-link btn-primary\"/>");
}
buf.push("</div></form><div class=\"close-button-container\"><button class=\"btn-primary close-terms-modal\">OK, gå til Unibok</button></div></div></div>");}.call(this,"pubTermsAschehoug" in locals_for_with?locals_for_with.pubTermsAschehoug:typeof pubTermsAschehoug!=="undefined"?pubTermsAschehoug:undefined,"pubTermsCapdamm" in locals_for_with?locals_for_with.pubTermsCapdamm:typeof pubTermsCapdamm!=="undefined"?pubTermsCapdamm:undefined));;return buf.join("");
}

});
