define('templates/videoLightbox',['jade'], function(jade) { if(jade && jade['runtime'] !== undefined) { jade = jade.runtime; }

return function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (poster, source, title) {
buf.push("<dialog class=\"lightbox\"><div class=\"lightbox-header\"><button title=\"Lukk dialog\" class=\"closeButton\"></button></div><div class=\"content\"><video controls=\"controls\"" + (jade.attr("src", source, true, false)) + " type=\"video/mp4\"" + (jade.attr("poster", poster, true, false)) + (jade.attr("title", title, true, false)) + "></video></div></dialog>");}.call(this,"poster" in locals_for_with?locals_for_with.poster:typeof poster!=="undefined"?poster:undefined,"source" in locals_for_with?locals_for_with.source:typeof source!=="undefined"?source:undefined,"title" in locals_for_with?locals_for_with.title:typeof title!=="undefined"?title:undefined));;return buf.join("");
}

});
