/* global require, window */
define('views/AnnotationsPanel',[
    'jquery', 'underscore', 'views/PanelBase', 'templates/annotationsPanel', 'templates/annotationItem', 'AnnotationsToPDF', 'AnnotationsToDOCX', 'bowser'
], function ($, _, PanelBase, mainTemplate, annotationItemTemplate, AnnotationsToPDF, AnnotationsToDOCX, bowser) {

    function renderAnnotationItem(annotation, mobileApp) {
        var dateOptions = {
            weekday: "short",
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
        };

        function urlify(text) {
            var urlRegex = /((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;
            return text.replace(urlRegex, function (url) {
                if (url.substring(0, 3) === 'www') {
                    return '<a href="https://' + url + '">' + (url.length > 50 ? url.substring(0, 50) : url) + '</a>';
                } else {
                    return '<a href="' + url + '">' + (url.length > 50 ? url.substring(0, 50) : url) + '</a>';
                }
            });
        }

        function cleanScriptTags(resultText) {
            var cleanedResultText = resultText.replace(/<script>/g, '&lt;script&gt;');
            return cleanedResultText.replace(/<\/script>/g, '&lt;&#47;script&gt;');
        }

        return annotationItemTemplate({
            colorid: annotation.get('colorid'),
            cfi: annotation.get('cfi'),
            text: urlify(cleanScriptTags(annotation.getText())),
            markedText: cleanScriptTags(annotation.getMarkedText()),
            idref: annotation.get('bookpartId'),
            physicalPageNumber: annotation.get('physicalPageNumber'),
            id: annotation.get('id'),
            date: (new Date(annotation.get('timestamp'))).toLocaleDateString('no-NO', dateOptions),
            invalid: annotation.get('invalid'),
            mobileApp: mobileApp
        });
    }

    return PanelBase.extend({
        initialize: function (onGotoAnnotation, app) {
            this._onGotoAnnotation = onGotoAnnotation;
            this._app = app;
            this._mobileApp = app.get('mobileApp');
            this.render();
        },

        events: {
            'click .btn-export#PDF': 'downloadAnnotationsPDF',
            'click .btn-export#DOCX': 'downloadAnnotationsDOCX',
            'click .annotation .data': 'gotoAnnotation',
            'click .annotation .write-comment': 'editComment',
            'click .annotation .edit-comment': 'editComment',
            'click .annotation .comment a': 'followCommentLink',
            'click .annotation .btn-delete': 'deleteAnnotation',
            'click .annotation .btn-invalid': 'showExplanationForInvalidItem',
            'click .bookpartTitle': 'onToggleExpand',
            'click .close-icon': 'onClose'
        },

        render: function () {
            var isSafari = false;

            if (bowser.safari || bowser.ipad || bowser.iphone || this._app.get('mobileApp')) {
                isSafari = true;
            }

            var isIE11 = false;
            if (bowser.name === "Internet Explorer") {
                isIE11 = true;
            }

            this.setElement(mainTemplate({
                safari: isSafari,
                ie11: isIE11,
                mobileApp: this._mobileApp
            }));
            return this;
        },

        _renderContent: function () {
            var me = this;
            var frags = [];
            var bookResource = this._userbook.getBookResource();
            var groupedAnnotations = this._userbook.get('annotations').groupBy(function (model) {
                return model.get('bookpartId');
            });

            var validIdrefs = {};
            bookResource.getSpineItems().forEach(function (item) {
                validIdrefs[item.idref] = true;
                var annotations = groupedAnnotations[item.idref];
                if (!annotations) {
                    return;
                }

                frags.push('<div class="bookpart">');
                frags.push('<div class="bookpartTitle"><span class="toggleExpandIcon"/><div class="label">' + item.label + '</div></div>');
                annotations.forEach(function (annotation) {
                    frags.push(renderAnnotationItem(annotation, me._mobileApp));
                });
                frags.push('</div>');
            });

            // Render annotations with invalid idrefs
            var frags2 = [];
            frags2.push('<div class="bookpart">');
            frags2.push('<div class="bookpartTitle"><span class="toggleExpandIcon"/><div class="label">' + '(missing chapter)' + '</div></div>');
            _.each(groupedAnnotations, function (annotations, key) {
                if (!validIdrefs[key]) {
                    annotations.forEach(function (annotation) {
                        frags2.push(renderAnnotationItem(annotation, me._mobileApp));
                    });
                }
            });
            frags2.push('</div>');
            if (frags2.length > 3) { // Meaning: some annotation fragments have been pushed
                frags = frags.concat(frags2);
            }

            this.$el.find('.content').html(frags.join(''));

        },

        onDataChanged: _.debounce(function () {
            // NOTE: midlertidig brute-force implementation.
            this._renderContent();
        }, 300),

        onAnnotationBeingEditedChanged: function (annotation) {
            this.$el.find('.annotation.active').removeClass('active');
            if (annotation) {
                this.$el.find('.annotation[data-id="' + annotation.get('id') + '"]').addClass('active');
            }
        },

        onToggleExpand: function (e) {
            var elem = $(e.currentTarget).parent();
            elem.toggleClass('collapsed');
            return false;
        },

        deleteAnnotation: function (e) {
            var me = this;
            var elem = $(e.currentTarget).parent().parent();
            var item = this._userbook.get('annotations').findWhere({ id: '' + elem.data('id') });
            elem.addClass('confirmation');

            elem.find('.btn-cancel').on('click', function () {
                elem.removeClass('confirmation');
                return false;
            });

            elem.find('.btn-confirm').on('click', function () {
                if (item) {
                    me._userbook.deleteAnnotation(item);
                    return false;
                }
            });
            return false;
        },

        gotoAnnotation: function (e) {

            // If the event comes from editing the label with ENTER button, don't go anywhere
            if ($(e.target).hasClass("edit-label")) {
                return false;
            }

            var elem = $(e.currentTarget).closest('.annotation');
            var idref = elem.data('idref');
            var annotationId = '' + elem.data('id');
            this._onGotoAnnotation(idref, elem.data('cfi'), annotationId);

            // Hide Panel on mobile screens
            if (this._app.get('mobileApp') || bowser.ipad || bowser.iphone) {
                this.panelController.hidePanel();
            }
            return false;
        },

        saveCommentByKeyboard: function (e) {
            if (e.keyCode === 13) {
                var elem = $(e.currentTarget).parent().parent().parent();
                var item = this._userbook.get('annotations').findWhere({ id: '' + elem.data('id') });
                var noteInput = elem.find('.edit-label');
                var text = noteInput.val();
                text = _.escape(text);
                item.setText(text);
                elem.removeClass('editing');
            }
        },

        editComment: function (e) {
            var elem = $(e.currentTarget).parent().parent().parent();
            var item = this._userbook.get('annotations').findWhere({ id: '' + elem.data('id') });

            var noteInputLabel = elem.find('.note-edit-label');
            var noteInput = elem.find('.edit-label');
            var confirmEdit = elem.find('.confirm-edit');
            elem.addClass('editing');
            noteInputLabel.show();
            noteInput.show();
            noteInput.focus();
            confirmEdit.show();
            elem.find('.write-comment').hide();
            elem.find('.edit-comment').hide();
            elem.find('.comment').hide();

            confirmEdit.on('click', function () {
                var text = noteInput.val();
                text = _.escape(text);
                item.setText(text);
                elem.removeClass('editing');
            });
            return false;
        },

        followCommentLink: function (e) {
            var url = e.currentTarget.href;
            var win;
            if (this._app.get('mobileApp')) {
                win = window.open(url, '_system');
            } else {
                win = window.open(url, '_blank');
                win.focus();
            }
        },

        showExplanationForInvalidItem: function (e) {
            return false;
        },

        setUserbook: function (userbook) {
            if (userbook !== this._userbook) {
                this.$el.find('.content').empty();
                if (this._userbook) {
                    this._userbook.off(null, null, this);
                    this._userbook.get('annotations').off(null, null, this);
                }
                this._userbook = userbook;
                if (userbook) {
                    this._renderContent();
                    userbook.on('change:annotationBeingEdited', this.onAnnotationBeingEditedChanged, this);
                    userbook.get('annotations').on('add remove change', this.onDataChanged, this);
                }
            }
        },

        downloadAnnotationsPDF: function (e) {

            if (e.keyCode && e.keyCode !== 13) { return; }

            new AnnotationsToPDF().generatePDF(this._userbook);

            this._app.plausibleManager.sendEvent(
                this._userbook.get('publisherRef'),
                'Leseren',
                'Laget PDF',
                this._userbook.getBookResource().get('title')
            );
            return false;
        },

        downloadAnnotationsDOCX: function (e) {

            if (e.keyCode && e.keyCode !== 13) { return; }

            new AnnotationsToDOCX().generateDocX(this._userbook);
            this._app.plausibleManager.sendEvent(
                this._userbook.get('publisherRef'),
                'Leseren',
                'Laget DOCX',
                this._userbook.getBookResource().get('title')
            );
            return false;
        },

        onKeyUpAnnotation: function (e) {
            if (e.keyCode === 13) {
                this.gotoAnnotation(e);
                e.stopPropagation();
            }
        }
    });
});

