/*global setInterval, clearInterval */
define('timer',['backbone', 'underscore', 'jquery'], function (Backbone, _, $) {


    /**
     * Will trigger 'events' on given intervals.
     * Each event-type (called 'channel') can have its own interval.
     * If a channel is registered more times, the latest interval will be used.
     *
     * On start() (When app is going online) all channels will trigger.
     *
     * @module webapp
     * @class timer
     */
    function Timer() {
        this._timerInfoByInterval = {};
        this._timerInfoByChannel = {};

        this._isRunning = false;

        this._events = {};
        _.extend(this._events, Backbone.Events);

    }
    Timer.prototype = {
        /**
         * Register callback that will be triggered repeatedly by the given interval.
         *
         * @method on
         */
        on: function (channel, func, context, interval) {
            interval = interval || 2000;

            var timerInfo = this._timerInfoByChannel[channel];
            if (timerInfo) {
                if (timerInfo.interval !== interval) {
                    this._removeChannel(channel);
                    this._addChannel(channel, interval);
                }
            } else {
                this._addChannel(channel, interval);
            }


            this._events.on(channel, func, context);
        },

        /**
         * Unregister callback.
         *
         * @method off
         */
        off: function (channel, func, context) {
            this._events.off(channel, func, context);

            if (!this._events[channel]) {
                var timerInfo = this._timerInfoByChannel[channel];
                if (timerInfo) {
                    timerInfo.channels.splice(timerInfo.channels.indexOf(channel), 1); // Remove from channels
                    if (timerInfo.channels.length === 0) {
                        if (timerInfo.intervalId) {
                            clearInterval(timerInfo.intervalId);
                        }
                        delete this._timerInfoByInterval[timerInfo.interval];
                        delete this._timerInfoByChannel[channel];
                    }
                }

            }
        },


        /**
         * Start all timers, and trigger all channels immediately.
         *
         * @method start
         */
        start: function () {
            this._isRunning = true;
            var me = this;

            // Start timers:
            _.each(this._timerInfoByInterval, function (timerInfo, interval) {
                timerInfo.intervalId = setInterval(function () {
                    timerInfo.channels.forEach(function (channel) {
                        me._events.trigger(channel);
                    });
                }, interval);
            });

            // Trigger all now!
            _.each(this._events._events, function (object, key) {
                me._events.trigger(key);
            });
        },

        /**
         * Stop all timers.
         *
         * @method
         */
        stop: function () {
            this._isRunning = false;
            _.each(this._intervalMap, function (object, key) {
                clearInterval(object.intervalId);
                object.intervalId = null;
            });
        },

        _removeChannel: function (channel) {
            var timerInfo = this._timerInfoByChannel[channel];
            if (timerInfo) {
                timerInfo.channels.splice(timerInfo.channels.indexOf(channel), 1);
                if (timerInfo.channels.length === 0) {
                    if (timerInfo.intervalId) {
                        clearInterval(timerInfo.intervalId);
                    }
                    delete this._timerInfoByInterval[timerInfo.interval];
                    delete this._timerInfoByChannel[channel];
                }
            }
        },

        _addChannel: function (channel, interval) {
            // Assumes that the channel does not exist already.

            var me = this;

            var timerInfo = this._timerInfoByInterval[interval];
            if (timerInfo) {
                timerInfo.channels.push(channel);
            } else {
                timerInfo = {
                    interval: interval,
                    intervalId: null,
                    channels: [channel]
                };
                if (this._isRunning) {
                    timerInfo.intervalId = setInterval(function () {
                        //me._events.trigger(channel);
                        timerInfo.channels.forEach(function (channel) {
                            me._events.trigger(channel);
                        });
                    }, interval);
                }
                this._timerInfoByInterval[interval] = timerInfo;
            }
            this._timerInfoByChannel[channel] = timerInfo;
        }
    };

    return new Timer();
});

